import { Button, Input, Popconfirm, Typography } from "antd";
import FCOsTable from "../../FCOManagement/ViewFCOManagers/FCOsTable"
import React from "react";


interface Props {
    onSubmitLoanContractFCOReassignment: (props: {
        officerCode: string;
        reason: string;
        callback(data: { requestSuccessfull: boolean }): void
    }) => void
    reassignmentLoading: boolean
    initialOfficerCode: string
}

const MINIMUM_LENGTH_OF_REASIGNMENT_REASON = 6;

const LoanContractFCOReassignmentView: React.FC<Props> = ({
    onSubmitLoanContractFCOReassignment,
    reassignmentLoading,
    initialOfficerCode,
}) => {
    const [selectedFCO, setSelectedFCO] = React.useState("");
    const [reassignmentReason, setReassignmentReason] = React.useState("");
    const popWrapperRef = React.useRef<HTMLDivElement>(null);

    const onConfirmReassignment = () => {

        onSubmitLoanContractFCOReassignment({
            officerCode: selectedFCO,
            reason: reassignmentReason,
            callback: ({ requestSuccessfull }) => {
                if (requestSuccessfull) { setSelectedFCO("") }
            }
        })
    }

    const onCancelReassignment = () => {
        if (!reassignmentLoading) {
            setSelectedFCO("")
        }
    }

    const defaultSearchParams = React.useRef({ status: "ENABLED" });

    return (
        <>
            <FCOsTable
                fixedSearchParams={defaultSearchParams.current}
                extraColumns={[
                    {
                        title: "",
                        dataIndex: "fcoCode",
                        key: "fcoCode",
                        render: (value, record) => {
                            return (
                                <div ref={popWrapperRef} className=" w-[170px] relative">
                                    <Popconfirm
                                        arrowPointAtCenter={true}
                                        placement="bottom"
                                        icon={null}
                                        getPopupContainer={target => popWrapperRef?.current || target}
                                        title={
                                            <div className=" min-w-[500px]">
                                                <Typography.Title level={5}>
                                                    Reassign loan contract to <span className=" capitalize">{`${record?.firstName} ${record?.lastName}`}</span> ({record?.username})?
                                                </Typography.Title>
                                                <Typography.Text>
                                                    Please confirm reassignment by stating a reason
                                                </Typography.Text>
                                                <Input.TextArea
                                                    rows={4}
                                                    placeholder="Reason for reassignment..."
                                                    value={reassignmentReason}
                                                    onChange={(event) => setReassignmentReason(event.target.value)}
                                                />
                                            </div>
                                        }
                                        open={Boolean(selectedFCO) && selectedFCO === value}
                                        okButtonProps={{
                                            loading: reassignmentLoading, 
                                            disabled:  reassignmentReason.length < MINIMUM_LENGTH_OF_REASIGNMENT_REASON
                                        }}
                                        onConfirm={onConfirmReassignment}
                                        onCancel={onCancelReassignment}
                                    >
                                        <Button
                                            type="primary"
                                            className=" w-[170px] rounded"
                                            onClick={() => setSelectedFCO(value)}
                                            disabled={value === initialOfficerCode}
                                        >
                                            {value === initialOfficerCode ? "Current Officer" : "Reassign"}
                                        </Button>
                                    </Popconfirm>
                                </div>
                            )
                        }
                    },
                ]}
            />
        </>
    )
}

export default LoanContractFCOReassignmentView;
