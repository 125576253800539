
import config from "../../../../config"
import server from "../../../../server"
import { IResponse } from "../../../../server/server.types"
import { MoniepointUserAccountDetails } from "../UtilsSlice/utilsSlice.types"
import { IState, ICountry } from "./utilsAPI.types"

export const api_get_countries = async () => {
    const response = await server.get(`${config.WCL_MANAGEMENT_BASE_URL}/wcl/locations/countries`)

    return response.data as IResponse<ICountry[]>
}

export const api_get_states_by_country_code = async (countryCode: string) => {
    const response = await server.get(`${config.WCL_MANAGEMENT_BASE_URL}/wcl/locations/states/country-code?countryCode=${countryCode}`)

    return response.data as IResponse<ICountry[]>
}

export const api_get_states = async () => {
    const response = await server.get(`${config.LOCATION_BASE_URL}/states`)

    return response.data as IResponse<IState[]>
}


export const api_get_LGAs_by_state_code = async (stateCode: string) => {
    const response = await server.get(`${config.LOCATION_BASE_URL}/lga/state/${stateCode}`)

    return response.data as IResponse<IState[]>
}

export const api_get_LGAs = async () => {
    const response = await server.get(`${config.LOCATION_BASE_URL}/lga`)

    return response.data as IResponse<IState[]>
}

export const get_moniepoint_user_account_details = async (accountNumber: string) => {
    const response = await server.get(
        `${config.WCL_PORTFOLIO_BASE_URL}/moniepoint/accounts/${encodeURIComponent(accountNumber)}`
    )

    return response.data as IResponse<MoniepointUserAccountDetails>
}
