import { Descriptions, Typography } from "antd"
import React from "react"


interface Props {
    pmData: any
    title: string
}

export const PMDetails: React.FC<Props> = ({ pmData, title }) => {
    return (
        <Descriptions title={title} layout="vertical" column={{ md: 2, sm: 1, xs: 1 }}>
            <Descriptions.Item
                label={<Typography.Text strong>Name</Typography.Text>}
            >
                {pmData?.name}
            </Descriptions.Item>
            <Descriptions.Item 
                label={<Typography.Text strong>Email</Typography.Text>}
            >
                {pmData?.email}
            </Descriptions.Item>
            <Descriptions.Item 
                label={<Typography.Text strong>Phone Number</Typography.Text>}
            >
                {pmData?.phoneNumber}
            </Descriptions.Item>
            <Descriptions.Item 
                label={<Typography.Text strong>Code</Typography.Text>}
            >
                {pmData?.code}
            </Descriptions.Item>
        </Descriptions>
    )
}


