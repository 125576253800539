import { parseError, parseJwt, saveSession } from '../../../utils/server';
import { showMessage, showError } from '../../../utils/notifications'
import { AppThunk } from '../../../store';
import { api_cosmos_login, api_resend_activation_email } from '../authAPI';
import { clearUser, saveUser, setAuthError, setAuthLoading, setResendctivationMailLoading } from '.';


export const cosmosLogin =
    (code: string): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(setAuthLoading(true))

                const response = await api_cosmos_login(code);
                const { accessToken, expiresIn } = response.responseBody;
                const user = parseJwt(accessToken);

                saveSession({ accessToken, expiresIn });
                dispatch(saveUser(user))
            } catch (error: any) {
                dispatch(setAuthError(parseError(error)))
            } finally {
                dispatch(setAuthLoading(false))
            }
        };

export const logout = (): AppThunk =>
    async (dispatch) => {
        try {
            localStorage.clear();
            dispatch(clearUser())
        } catch (error) {
        }
    };

    export const getAuthFromLocalStorage = () => {
        let auth = {
            user: null,
            isAuthenticated: false,
        };
    
        try {
            const accessToken = localStorage.getItem("accessToken")
    
            if (accessToken) {
                const user = parseJwt(accessToken);
                auth = {
                    ...auth,
                    user,
                    isAuthenticated: true
                }
            }
        } catch (error) {
    
        } finally {
            return auth
        }
    }

    export const resendActivationMail = (email: string, cb: ({successful}: {successful: boolean}) => void): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(setResendctivationMailLoading(true))

                const response = await api_resend_activation_email(email)

                if (response.requestSuccessful) {
                    showMessage(
                        "Request Successful",
                        "Activation mail was sent successfully"
                    )
                    cb({ successful: true })
                }
            } catch (error) {
                showError(parseError(error))
                cb({ successful: false })
            } finally {
                dispatch(setResendctivationMailLoading(true))
            }
        }