import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Table,
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Tag
} from "antd";
import { useAppDispatch } from "../../../../store";
import { initPagination } from "../../../../utils/pagination";
import {
  fetchStates,

} from "../../Utils/UtilsSlice/utilsSlice.thunks";
import { LoanRequestStatus, loanRequestStatusObj } from "../loanRequestSlice/loanRequest.types";
import { convertStatusTextToColor } from "../../../../utils/workflow";
import moment from "moment";
import { useQueryLoanRequests } from "./hook";
const FormItem = Form.Item;
const { Option } = Select;

interface Props {
  searchParams?: any;
}

export  const loanRequestStatusConverter=(status:string)=>{
  if(status===LoanRequestStatus.AWAITING_ASSIGNMENTS){
   return 'AWAITING ASSIGNMENTS'
  }
  if(status===LoanRequestStatus.AWAITING_VERIFICATIONS_AND_AGREEMENTS_DISPATCH){
    return 'AWAITING VERIFICATIONS AND AGREEMENTS DISPATCH'
  }
  if(status===LoanRequestStatus.AWAITING_VERIFICATIONS_AND_AGREEMENTS_EXECUTION){
    return 'AWAITING VERIFICATIONS AND AGREEMENTS EXECUTION'
  }
  if(status===LoanRequestStatus.AWAITING_FIRST_FCO_APPROVAL){
    return 'AWAITING FIRST FCO APPROVAL'
   }

   if(status===LoanRequestStatus.AWAITING_SECOND_FCO_APPROVAL){
    return 'AWAITING SECOND FCO APPROVAL'
   } 

   if(status===LoanRequestStatus.AWAITING_PM_APPROVAL){
    return 'AWAITING PM APPROVAL'
   }
   if(status===LoanRequestStatus.AWAITING_FINAL_APPROVAL){
    return 'AWAITING FINAL APPROVAL'
   }
   if(status===LoanRequestStatus.APPROVED){
    return  'APPROVED'
   }
   if(status===LoanRequestStatus.REJECTED){
    return 'REJECTED'
   } 
   return status;
}
const ViewLoanRequests: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
 const {loading,loanRequestDataPagination,loanRequestData,getAllLoanRequests}=useQueryLoanRequests({payload: {}})
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      dispatch(fetchStates());
 
    }
  }, [dispatch]);


  const filterLoanRequest = () => {
    const formValues = form.getFieldsValue();

    let searchParams = Object.keys(formValues).reduce(
      (reducedObject: any, key: any) => {
        if (formValues[key]) {
          if (key === "dateRequested") {
            reducedObject[key] = moment(new Date(formValues[key])).format('YYYY-MM-DD');
          } else {
            reducedObject[key] =formValues[key]?.trim ? formValues[key]?.trim() : formValues[key];
          }
        }

        return reducedObject;
      },
      {}
    );
   
    setSearchParams(searchParams);
getAllLoanRequests(searchParams, initPagination);
  };
  const onClearForm = () => {
    form.resetFields();
    setSearchParams({});
    getAllLoanRequests();
  };
  const handleTableChange = (pagination: any) => {
    fetchData(pagination, searchParams);
  };

  const fetchData = (pagination?: any, searchParams?: any) => {
  getAllLoanRequests(searchParams, pagination);
  };
 
  const tableColumns = [
    {
      title: "Business Name",
      dataIndex: "businessName",
      key: "businessName",
      render: (value: string) => {
        return <div>{value?value:'-----'}</div>;
      },
    },
    {
      title: "Business Owner Name",
      dataIndex: "businessOwnerName",
      key: "businessOwnerName",
      render: (value: string) => {
        return <div>{value?value:'-----'}</div>;
      },
    },
    {
      title: "Date Requested",
      dataIndex: "dateRequested",
      key: "dateRequested",
      render: (value: string) => {
        if(value){
          return <div>{moment(value).format(
            "YYYY-MM-DD"
          )}</div>;
        }else{
          return <div>{'-----'}</div>;
        }
       
      },
    },
    {
      title: "First FCO User Name",
      dataIndex: "firstFcoUsername",
      key: "firstFcoUsername",
      render: (value: string) => {
      
        return <div>{value?value:'-----'}</div>;
      },
    },
    {
      title: "Second FCO User Name",
      dataIndex: "secondFcoUsername",
      key: "secondFcoUsername",
      render: (value: string) => {
        return <div>{value?value:'-----'}</div>;
      },
    },
    {
      title: "PM User Name",
      dataIndex: "pmUsername",
      key: "pmUsername",
      render: (value: string) => {
        return <div>{value?value:'-----'}</div>;
      },
    },
    {
      title: "Request Status",
      dataIndex: "requestStatus",
      key: "requestStatus",
      render: (value: string) => {
       return <Tag color={convertStatusTextToColor(value)}>{loanRequestStatusConverter(value)}</Tag>
      },
    },

    {
      title: "",
      dataIndex: "loanRequestReference",
      key: "loanRequestReference",
      render: (value: string,row:any) => {
        return (
          <Link to={`/dashboard/loan-requests-mgt/details/${value}?status=${row?.requestStatus}`}>
            <Button
              type="primary"
              className="bg-[#0190fe] border-[#0190fe] rounded"
            >
              View Details
            </Button>
          </Link>
        );
      },
    },
  ];

  const renderFilterForm = () => {
    return (
      <Form layout="vertical" form={form} name="filter-fco">
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8}>
            <FormItem
              label="Business Name"
              name={"businessName"}
              initialValue=""
            >
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem
              label="Business Owner Name"
              name={"businessOwnerName"}
              initialValue=""
            >
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem
              label="Date Requested"
              name={"dateRequested"}
              initialValue=""
            >
              <DatePicker
                placeholder="Date Requested"
                style={{ height: "40px", width: "100%" }}
                disabledDate={(current) => current.isAfter(moment())}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem
              label="Loan Request Status"
              name={"requestStatus"}
              initialValue=""
            >
              <Select
                placeholder="Select a Loan Request Status"
                optionFilterProp="children"
                size="large"
                showSearch
                allowClear={true}
               dropdownMatchSelectWidth={450}
              >
                {loanRequestStatusObj.map((val) => (
                  <Option key={val.id} value={val.status} name={val.value}>
                    {val.value}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col xs={24} sm={12} md={3}>
            <Button
              type="primary"
              className="w-full rounded"
              onClick={onClearForm}
              danger
            >
              Clear
            </Button>
          </Col>
          <Col xs={24} sm={12} md={4}>
            <Button
              type="primary"
              onClick={filterLoanRequest}
              className="w-full bg-[#0190fe] border-[#0190fe] rounded"
              htmlType="submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };
  return (
    <>
      <Card title="Loan Requests Filter" className="my-[18px]">
        {renderFilterForm()}
      </Card>
      <Card title="Loan Requests" className="my-[18px] w-full">
        <Table
          className="w-full overflow-x-auto"
          dataSource={loanRequestData}
          columns={tableColumns}
          rowKey={(record) => record.loanRequestReference}
          loading={loading}
          pagination={loanRequestDataPagination}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

export default ViewLoanRequests;
