import server from "../../../../server";
import config from "../../../../config";
import { initPagination } from "../../../../utils/pagination";
import { ResendVerificationType } from "../loanRequestSlice/loanRequest.types";

export const get_all_loan_requests_service = async (
  searchParams: Record<string, any> = {},
  pagination: Record<string, any> = initPagination
) => {
  let page = pagination.current - 1;
  const response = await server.get(`${config.WCL_PORTFOLIO_BASE_URL}/business-loan-requests`, {
    params: { ...searchParams, page: page, size: pagination.pageSize },
  });

  return response.data;
};

export const get_loan_request_details_service = async (loanRequestReference: string) => {
  const response = await server.get(`${config.WCL_PORTFOLIO_BASE_URL}/business-loan-requests/${encodeURIComponent(loanRequestReference)}`);

  return response.data;
};

export const reassing_loan_request_service = async (
  payload:any
) => {
  const response = await server.post(`${config.WCL_PORTFOLIO_BASE_URL}/business-loan-requests/reassign`,   payload);
  return response.data;
}

export const get_open_loan_requests_service = async (
  searchParams: Record<string, any> = {},
  pagination: Record<string, any> = initPagination
) => {
  let page = pagination.current - 1;
  const response = await server.get(`${config.WCL_PORTFOLIO_BASE_URL}/business-loan-requests/open-requests`, {
    params: { ...searchParams, page: page, size: pagination.pageSize },
  });

  return response.data;
};

export const resend_verification_loan_request_service = async (
  payload:ResendVerificationType
) => {
  const response = await server.post(`${config.WCL_PORTFOLIO_BASE_URL}/field-verifications/resend-verification`,   payload);
  return response.data;
}

export const trigger_business_verification = async (loanRequestReference: string) => {
  const response = await server.post(`${config.WCL_PORTFOLIO_BASE_URL}/business-loan-requests/business-verification`, { loanRequestReference });

  return response.data;
};
