import { useEffect, useState, useCallback } from "react";
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";
import { FCOListQueryProps, IFCOlist, TFCOListApi } from "../types";
import { get_all_fco_service } from "../api";
import {
  Pagination,
  extractPaginationData,
  initPagination,
} from "../../../../../utils/pagination";

const useQueryFCOList = (props: FCOListQueryProps) => {
  const [loadingFCOList, setloadingFCOList] = useState<boolean>(false);
  const [errorloadingFCOList, setErrorloadingFCOList] = useState<string>("");
  const [loanRequestDataPagination, setLoanRequestDataPagination] =
    useState<Pagination>(initPagination);
  const [fcoList, setFCOList] = useState<IFCOlist[] | []>([]);

  const fetchFCOList = useCallback(
    async ({
      loanRequestReference,
      searchParams,
      pagination = initPagination,
    }: TFCOListApi) => {
      setloadingFCOList(() => true);
      try {
        const response = await get_all_fco_service({
          loanRequestReference,
          searchParams,
          pagination,
        });
        const paginationData = extractPaginationData(response.responseBody);

        setFCOList(response?.responseBody?.content as IFCOlist[]);
        setLoanRequestDataPagination(paginationData);
      } catch (error) {
        showMessage("Cannot fetch FCOs", parseError(error), "error");
        setErrorloadingFCOList(error as string);
      } finally {
        setloadingFCOList(() => false);
      }
    },
    []
  );

  useEffect(() => {
    if (props.payload.id) {
      fetchFCOList({
        loanRequestReference: props.payload.id,
        searchParams: props.payload.searchParams,
      });
    }

    return () => {};
  }, [props.payload.id, fetchFCOList, props.payload.searchParams]);

  return {
    loadingFCOList,
    errorloadingFCOList,
    fcoList,
    loanRequestDataPagination,
    fetchFCOList,
  };
};

export default useQueryFCOList;
