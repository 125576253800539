import React from "react";
import {showMessage} from "../../../../../utils/notifications";
import {parseError} from "../../../../../utils/server";
import {LoanProduct, loanProductToTypeMap} from "../../../LoanContractsManagement/utils/types";
import {
  get_loan_offer_audit_log_service,
  get_loan_offer_details_service,
  save_loan_offer_details_service,
} from "../API";

export const useQueryLoanOfferDetails = () => {
  const [loanOfferDetails, setLoanOffer] = React.useState<any | null>(null);

  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [permissionError, setPermissionError] = React.useState<boolean | null>(
    false
  );

  const getLoanOfferDetail = React.useCallback(
    async (loanProduct: string, loanReference: string, isApproved = false, workflowReference = "") => {
      try {
        setLoading(true);
        setError("");
        const loanProductType = loanProductToTypeMap[loanProduct as LoanProduct];
        const offerDetailsService = isApproved ? get_loan_offer_audit_log_service : get_loan_offer_details_service;
        const data = await offerDetailsService(
            loanProductType,
            loanReference,
            workflowReference
        );
        if(isApproved){
          setLoanOffer({
            ...JSON.parse(data?.preExecution),
            modificationRequest: JSON.parse(data?.modificationRequest),
            loanProduct,
            updateRequestStatus: data?.updateRequestStatus,
          })
          setLoading(false);
          return;
        }
        const loanProductKey = data.loanProduct;
        const offerDetails = data.offerResponse[loanProductKey];
        setLoanOffer({ ...offerDetails, loanProduct });
        setLoading(false);
      } catch (error: any) {
        if (error.response && error.response.status === 403) {
          setPermissionError(true);
          return; //exit early
        }
        showMessage("Loan Requests", parseError(error), "error");
        setError(parseError(error));
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const editLoanOfferDetail = React.useCallback(
    async (loanProduct: string, loanReference: string, loanOffer: any) => {
      try {
        setLoading(true);
        setError("");
        const loanProductType = loanProductToTypeMap[loanProduct as LoanProduct];
        const payload = {
          loanProduct: loanProductType,
          updateRequest: {
            [loanProductType]: {
              ...loanOffer,
              loanTenure: loanOffer?.tenure,
              loanProduct: loanProductType,
            },
          },
        };
        await save_loan_offer_details_service(
          loanProduct,
          loanReference,
          payload
        );
        showMessage("Request Successful", "Your update request has been successfully submitted. The changes will be applied upon approval.");
      } catch (error: any) {
        if (error.response && error.response.status === 403) {
          setPermissionError(true);
          return; //exit early
        }
        showMessage("Loan Requests", parseError(error), "error");
        setError(parseError(error));
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    error,
    loading,
    loanOfferDetails,
    permissionError,
    editLoanOfferDetail,
    getLoanOfferDetail,
  };
};
