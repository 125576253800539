import { ILoanRequestDetails } from "../../utils/types";
import LoanManager from "./LoanManager";

type MoniepointManagersProps = {
  details?: ILoanRequestDetails | undefined;
};

const MoniepointManagers = ({ details }: MoniepointManagersProps) => {
  return (
    <div className="w-full">
      <div className="font-semibold text-base">Moniepoint Managers</div>
      <div className="managers mt-4">
        {details &&
          details?.mpManagers?.rms.map((rm: any) => (
            <LoanManager loanManager={rm} managerRole="Regional Manager" />
          ))}
        {details &&
          details?.mpManagers?.scs.map((sc: any) => (
            <LoanManager loanManager={sc} managerRole="State Coordinator" />
          ))}
        {details &&
          details?.mpManagers?.brms.map((brm: any) => (
            <LoanManager loanManager={brm} managerRole="Business Relationship Manager" />
          ))}
      </div>
    </div>
  );
};
export default MoniepointManagers;
