import config from "../../../../../config";
import server from "../../../../../server";
import { initPagination } from "../../../../../utils/pagination";
import { loanRequestStatusAPIPath } from "../../loanRequestSlice/loanRequest.types";
import { mockLoanRequestDetails } from "../../../Users/types";

const baseURL = config.WCL_LOANS_BO_BASE_URL;

export const get_all_loan_requests_service = async (
  status: string,
  searchParams: Record<string, any> = {},
  pagination: Record<string, any> = initPagination
) => {
  let page = pagination.current - 1;
  const response = await server.get(
    `${baseURL}/loan-requests/${get_api_url(status)}`,
    {
      params: { ...searchParams, page: page, size: pagination.pageSize },
    }
  );

  return response.data;
};
export const get_loan_offer_details_service = async (
  loanProduct: string,
  loanReference: string
) => {
  const response = await server.get(
    `${baseURL}/offer/${loanProduct}/${loanReference}`
  );
  return response.data;
};

export const get_loan_offer_audit_log_service = async (
  loanProduct: string,
  loanReference: string,
  workflowReference: string
) => {
  const response = await server.get(`${baseURL}/loan-offer-approval-requests?workflowReference=${workflowReference}`);
  return response.data;
};

export const save_loan_offer_details_service = async (
  loanProduct: string,
  loanReference: string,
  loanOfferDetails: any
) => {
  const response = await server.put(`${baseURL}/offer/${loanReference}`, {
    ...loanOfferDetails,
  });
  return response.data;
};

export const get_api_url = (status: string) => {
  return loanRequestStatusAPIPath[status];
};

export const get_loan_details_service = async (loanReference: string) => {
  if (config.React_App_Mock) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(mockLoanRequestDetails), 2000)
    );
  } else {
    const response = await server.get(
      `${baseURL}/loan-requests/${loanReference}/details`
    );
    return response.data;
  }
};
