import { parseError } from '../../../../utils/server';
import { AppThunk } from '../../../../store';
import { api_get_LGAs } from '../../Utils/UtilsApi';
import { get_all_fcos_service, get_fco_details_service, assinged_lga_fco_service, unassinged_lga_fco_service, onboard_fco_service } from '../fcoApi';
import { setLoading, saveLgsData, saveAllFcoData, setAllFcosDataError, getTargetKeys, saveFcoDetails, saveFcoDetailsError, resetFcoEnableDetails, enableFcoSuccessAction, enableFcoLoading, enableFcoErorr, unAssignedLgaFcoLoading, unAssignedLgaFcoErorr, unAssignedLgaFcoSuccessAction,resetUnAssignedLgaFcoDetails, saveAllFcoDataPagination, setOnboardFCOError, setOnboardFCOLoading } from '.';
import { extractPaginationData } from '../../../../utils/pagination';
import { FCOOnboardingParams } from './fco.types';
import { showMessage } from '../../../../utils/notifications';



export const getAllFcos =
    (searchParams?: Record<string, any>,
        pagination?: Record<string, any>): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(setLoading(true))

                const response = await get_all_fcos_service(searchParams, pagination);
                const paginationData = extractPaginationData(response.responseBody)

                dispatch(saveAllFcoData(response));
                dispatch(saveAllFcoDataPagination(paginationData))
            } catch (error: any) {

                dispatch(setAllFcosDataError(parseError(error)))
            } finally {
                dispatch(setLoading(false))
            }

        };

export const getFcosDetails =
    (fcoCode: string): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(setLoading(true))

                const response = await get_fco_details_service(fcoCode);

                dispatch(saveFcoDetails(response));
            } catch (error: any) {
                const parsedError = parseError(error)

                showMessage("Field Credit Officer Details", parsedError, "error");
                dispatch(saveFcoDetailsError(parsedError))
            } finally {
                dispatch(setLoading(false))
            }

        };
export const getAllLgs =
    (): AppThunk =>
        async (dispatch) => {
            try {


                const response = await api_get_LGAs();

                dispatch(saveLgsData(response));
            } catch (error: any) {


            }
        };
export const assignedLgsToFco = (
    fcoCode: string,
    lgaCodes: any,
    callBack?: ({requestSuccessful}: {requestSuccessful: boolean}) => void
): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(resetFcoEnableDetails())
                dispatch(enableFcoLoading(true))
                const response = await assinged_lga_fco_service(fcoCode, lgaCodes);

                if (response?.requestSuccessful) {
                    showMessage(
                        "SUCCESSFUL",
                        "Request is Pending approval",
                        "success"
                    );
                    if (callBack) {
                        callBack({requestSuccessful: response?.requestSuccessful})
                    }
                    dispatch(enableFcoSuccessAction(true));
                }   

            } catch (error: any) {
                const errorMessage = parseError(error)

                showMessage("Request Failed", errorMessage, "error")
                if (callBack) {
                    callBack({requestSuccessful: false})
                }
                dispatch(enableFcoErorr(errorMessage))
            } finally {
                dispatch(enableFcoLoading(false))
            }

        };
export const unAssignedLgsToFco = (
    fcoCode: string,
    lgaCodes: any,
    callBack?: ({requestSuccessful}: {requestSuccessful: boolean}) => void
): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(resetUnAssignedLgaFcoDetails())
                dispatch(unAssignedLgaFcoLoading(true))
                const response = await unassinged_lga_fco_service(fcoCode, lgaCodes);

                if (response?.requestSuccessful) {
                    showMessage(
                        "SUCCESSFUL",
                        "Request is Pending approval",
                        "success"
                    );
                    if (callBack) {
                        callBack({requestSuccessful: response?.requestSuccessful})
                    }
                    dispatch(unAssignedLgaFcoSuccessAction(true));
                }

            } catch (error: any) {
                const errorMessage = parseError(error)

                showMessage("Request Failed", errorMessage, "error")
                if (callBack) {
                    callBack({requestSuccessful: false})
                }
                dispatch(unAssignedLgaFcoErorr(errorMessage))
            } finally {
                dispatch(unAssignedLgaFcoLoading(false))
            }

        };
export const resetEnableLgaSuccessState =
    (): AppThunk => async (dispatch) => {
        dispatch(unAssignedLgaFcoSuccessAction(false));
    }
export const resetUnassignLgaSuccessState =
    (): AppThunk => async (dispatch) => {
        dispatch(enableFcoSuccessAction(false));
    }
export const reset =
    (): AppThunk => async (dispatch) => {
        dispatch(resetFcoEnableDetails());
    }


export const getLgsTargetKeys = (targetKeys: any): AppThunk =>
    async (dispatch) => {
        dispatch(getTargetKeys(targetKeys))
    };


export const onboardFCO = (
    params: FCOOnboardingParams,
    onSuccess: (response: any) => void
): AppThunk => async (dispatch, getState) => {
    try {
        const {
            fcos: {
                onboardFCOError
            }
        } = getState()

        if (onboardFCOError) {
            dispatch(setOnboardFCOError(""))
        }
        dispatch(setOnboardFCOLoading(true))

        const response = await onboard_fco_service(params)

        if (response?.requestSuccessful) {
            onSuccess(response?.responseBody)
            showMessage(
                "Successfull",
                "Request to onboard user was sent successfully."
            )
        }

    } catch (error) {
        dispatch(setOnboardFCOError(parseError(error)))
    } finally {
        dispatch(setOnboardFCOLoading(false))
    }
} 
