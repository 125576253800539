import { Empty, Spin } from "antd";
import React, { FC } from "react";
import { useQueryFCODetails } from "../../../FCOManagement/FCOdetails/hooks/useQueryFCODetails";
import { useQueryPortfolioManagerDetails } from "../../../PortfolioManagement/portfolioManagerDetails/hooks/useQueryPortfolioManagerDetails";
import { LoanContractManagerType } from "../../../LoanContractsManagement/utils/types";
import { useQueryLoanContractManagersDetails } from "../../../LoanContractsManagement/hooks/useQueryLoanContractManagersDetails";
import { PMDetails } from "./PMDetails";
import { FCODetails } from "./FCODetails";


interface Props {
    src: any;
    data: any;
}

const ReassignLoanContractManager: FC<Props> = ({ src }) => {

    const {
        loading: loanContractManagersDetailsLoading,
        loanContractManagersDetails,
    } = useQueryLoanContractManagersDetails({ contractRef: src?.request?.contractReference as string });

    const {
        fcoDetailData,
        loading: fcoDetailsLoading,
    } = useQueryFCODetails({
        payload: {
            id: (src?.request?.officerType === LoanContractManagerType.FIRST_FCO ||
                src?.request?.officerType === LoanContractManagerType.SECOND_FCO)
                ? src?.request?.officerCode as string : ""
        }
    })

    const {
        portfolioManagerDetailsLoading,
        portfolioManagerDetails,
    } = useQueryPortfolioManagerDetails({
        payload: {
            id: src?.request?.officerType === LoanContractManagerType.PM
                ? src?.request?.officerCode as string : ""
        }
    })

    return (
        <React.Fragment>
            {src?.request ? (
                <>
                    {
                        src?.request?.officerType === LoanContractManagerType.PM
                            ? (
                                <>
                                    {loanContractManagersDetailsLoading ? (
                                        <section className="centered-container">
                                            <Spin tip="Loading Details..." />
                                        </section>
                                    ) : loanContractManagersDetails?.pm ? (
                                        <>
                                            <PMDetails
                                                pmData={loanContractManagersDetails?.pm}
                                                title="Previously Assigned PM"
                                            />
                                        </>
                                    ) : (
                                        <section className="centered-container">
                                            <Empty />
                                        </section>
                                    )}
                                    {portfolioManagerDetailsLoading ? (
                                        <section className="centered-container">
                                            <Spin tip="Loading Details..." />
                                        </section>
                                    ) : portfolioManagerDetails ? (
                                        <>
                                            <PMDetails
                                                pmData={{
                                                    code: portfolioManagerDetails?.fcoCode,
                                                    name: portfolioManagerDetails?.firstName + portfolioManagerDetails?.lastName,
                                                    email: portfolioManagerDetails?.email,
                                                    phoneNumber: portfolioManagerDetails?.phoneNumber,
                                                }}
                                                title="Newly Assigned PM"
                                            />
                                        </>
                                    ) : (
                                        <section className="centered-container">
                                            <Empty />
                                        </section>
                                    )}
                                </>
                            ) : (
                                <>
                                    {loanContractManagersDetailsLoading ? (
                                        <section className="centered-container">
                                            <Spin tip="Loading Details..." />
                                        </section>
                                    ) : src?.request?.officerType === LoanContractManagerType.FIRST_FCO &&
                                        loanContractManagersDetails?.firstFco ? (
                                        <>
                                            <FCODetails
                                                fcoData={loanContractManagersDetails?.firstFco}
                                                title="Previously Assigned First FCO"
                                            />
                                        </>
                                    ) : src?.request?.officerType === LoanContractManagerType.SECOND_FCO &&
                                        loanContractManagersDetails?.firstFco ? (
                                        <>
                                            <FCODetails
                                                fcoData={loanContractManagersDetails?.firstFco}
                                                title="Previously Assigned Second FCO"
                                            />
                                        </>
                                    ) : (
                                        <section className="centered-container">
                                            <Empty />
                                        </section>
                                    )}
                                    {fcoDetailsLoading ? (
                                        <section className="centered-container">
                                            <Spin tip="Loading Details..." />
                                        </section>
                                    ) : fcoDetailData ? (
                                        <>
                                            <FCODetails
                                                fcoData={{
                                                    code: fcoDetailData?.fcoCode,
                                                    name: fcoDetailData?.firstName + fcoDetailData?.lastName,
                                                    email: fcoDetailData?.email,
                                                    phoneNumber: fcoDetailData?.phoneNumber,
                                                }}
                                                title={src?.request?.officerType === LoanContractManagerType.FIRST_FCO
                                                    ? "Newly Assigned First FCO" : "Newly Assigned Second FCO"
                                                }
                                            />
                                        </>
                                    ) : (
                                        <section className="centered-container">
                                            <Empty />
                                        </section>
                                    )}
                                </>
                            )
                    }
                </>
            ) : (
                <section className="centered-container">
                    <Empty />
                </section>
            )}
        </React.Fragment>
    );
};

export default ReassignLoanContractManager;
