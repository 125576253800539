import { parseError } from '../../../../utils/server';
import { AppThunk } from '../../../../store';
import {
    saveAllProvisioningDataPagination,
    setAllViewProvisioningLoading, saveAllProvisioningTemplateData, setAllViewProvisioningError
} from '.';
// import { showMessage, showServerError } from '../../../../utils/notifications';
import { extractPaginationData } from '../../../../utils/pagination';
// import { IPortfolioManagementSettingsParams } from '../provisionTemplateApi/provisionTemplateAPI.types';
import { get_all_provisioning_template_service } from '../provisionTemplateApi';

export const getAllProvisioningTemplates =
    (
        pagination?: Record<string, any>): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(setAllViewProvisioningLoading(true))
                const response = await get_all_provisioning_template_service(pagination);
                dispatch(saveAllProvisioningTemplateData(response));
                const paginationData = extractPaginationData(response.responseBody)
                dispatch(saveAllProvisioningDataPagination(paginationData))
            }
            catch (error: any) {

                dispatch(setAllViewProvisioningError(parseError(error)))
            } finally {
                dispatch(setAllViewProvisioningLoading(false))
            }
        }
