import React, { FC, useState } from "react";
import {
    Card,
    Table,
    Button,
    Form,
    Input,
    Row,
    Col,
    Select,
    DatePicker,
    Tag,
    TableProps
} from "antd";
import { Pagination, initPagination } from "../../../../utils/pagination";
import { LoanRequestStatus, loanRequestStatusObj } from "../loanRequestSlice/loanRequest.types";
import { convertStatusTextToColor } from "../../../../utils/workflow";
import moment from "moment";
import { loanRequestsStatusMap } from "../utils/dataMaps"


const FormItem = Form.Item;
const { Option } = Select;



interface Props {
    useQueryLoanRequests(prop: { payload: { searchParams?: Record<string, any>, pagination?: Pagination } }): {
        loading: boolean;
        loanRequestDataPagination: Pagination,
        loanRequestData: any,
        getAllLoanRequests(searchParams?: Record<string, any>, pagination?: Pagination): void
    };
    extraColumns?: {
        title: string
        dataIndex: string
        key: string,
        render: (value: string, record: any) => React.ReactNode
    }[];
    hasFilters?: boolean;
    extraFilterFields?: React.ReactNode;
    defaultSearchParams?: Record<string, any>;
}

const LoanRequestsTable: FC<Props & TableProps<any>> = ({
    useQueryLoanRequests,
    extraColumns,
    rowSelection,
    hasFilters,
    extraFilterFields,
    defaultSearchParams
}) => {
    const [form] = Form.useForm();
    const { loading, loanRequestDataPagination, loanRequestData, getAllLoanRequests } = useQueryLoanRequests({
        payload: {
            searchParams: defaultSearchParams
        }
    })
    const [searchParams, setSearchParams] = useState(defaultSearchParams);


    const filterLoanRequest = () => {
        const formValues = form.getFieldsValue();
        const searchValues = searchParams ? { ...searchParams, ...formValues } : formValues

        let validParams = Object.keys(searchValues).reduce(
            (reducedObject: any, key: any) => {
                if (searchValues[key]) {
                    if (key === "dateRequested") {
                        reducedObject[key] = moment(new Date(searchValues[key])).format('YYYY-MM-DD');
                    } else {
                        reducedObject[key] = searchValues[key]?.trim ? searchValues[key]?.trim() : searchValues[key];
                    }
                }

                return reducedObject;
            },
            {}
        );

        setSearchParams(validParams);
        getAllLoanRequests(validParams, initPagination);
    };
    const onClearForm = () => {
        form.resetFields();
        setSearchParams(defaultSearchParams);
        getAllLoanRequests();
    };
    const handleTableChange = (pagination: any) => {
        fetchData(pagination, searchParams);
    };

    const fetchData = (pagination?: any, searchParams?: any) => {
        getAllLoanRequests(searchParams, pagination);
    };

    const tableColumns = [
        {
            title: "Business Name",
            dataIndex: "businessName",
            key: "businessName",
            render: (value: string) => {
                return <div>{value ? value : '-----'}</div>;
            },
        },
        {
            title: "Business Owner Name",
            dataIndex: "businessOwnerName",
            key: "businessOwnerName",
            render: (value: string) => {
                return <div>{value ? value : '-----'}</div>;
            },
        },
        {
            title: "Date Requested",
            dataIndex: "dateRequested",
            key: "dateRequested",
            render: (value: string) => {
                if (value) {
                    return <div>{moment(value).format(
                        "YYYY-MM-DD"
                    )}</div>;
                } else {
                    return <div>{'-----'}</div>;
                }

            },
        },
        {
            title: "First FCO User Name",
            dataIndex: "firstFcoUsername",
            key: "firstFcoUsername",
            render: (value: string) => {

                return <div>{value ? value : '-----'}</div>;
            },
        },
        {
            title: "Second FCO User Name",
            dataIndex: "secondFcoUsername",
            key: "secondFcoUsername",
            render: (value: string) => {
                return <div>{value ? value : '-----'}</div>;
            },
        },
        {
            title: "PM User Name",
            dataIndex: "pmUsername",
            key: "pmUsername",
            render: (value: string) => {
                return <div>{value ? value : '-----'}</div>;
            },
        },
        {
            title: "Request Status",
            dataIndex: "requestStatus",
            key: "requestStatus",
            render: (value: string) => {
                return (
                    <Tag color={convertStatusTextToColor(value)}>
                        {loanRequestsStatusMap[value as LoanRequestStatus]}
                    </Tag>
                )
            },
        },
        ...(extraColumns || [])
    ];

    const renderFilterForm = () => {
        return (
            <Form layout="vertical" form={form} name="filter-fco">
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={8}>
                        <FormItem
                            label="Business Name"
                            name={"businessName"}
                            initialValue=""
                        >
                            <Input size="large" placeholder="" />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <FormItem
                            label="Business Owner Name"
                            name={"businessOwnerName"}
                            initialValue=""
                        >
                            <Input size="large" placeholder="" />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <FormItem
                            label="Date Requested"
                            name={"dateRequested"}
                            initialValue=""
                        >
                            <DatePicker
                                placeholder="Date Requested"
                                style={{ height: "40px", width: "100%" }}
                                disabledDate={(current) => current.isAfter(moment())}
                            />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <FormItem
                            label="Loan Request Status"
                            name={"requestStatus"}
                            initialValue=""
                        >
                            <Select
                                placeholder="Select a Loan Request Status"
                                optionFilterProp="children"
                                size="large"
                                showSearch
                                allowClear={true}
                                dropdownMatchSelectWidth={450}
                            >
                                {loanRequestStatusObj.map((val) => (
                                    <Option key={val.id} value={val.status} name={val.value}>
                                        {val.value}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>
                    {extraFilterFields}
                </Row>

                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={12} md={3}>
                        <Button
                            type="primary"
                            className="w-full rounded"
                            onClick={onClearForm}
                            danger
                        >
                            Clear
                        </Button>
                    </Col>
                    <Col xs={24} sm={12} md={4}>
                        <Button
                            type="primary"
                            onClick={filterLoanRequest}
                            className="w-full bg-[#0190fe] border-[#0190fe] rounded"
                            htmlType="submit"
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    };
    return (
        <>
            {hasFilters && (
                <Card title="Loan Requests Filter" className="my-[18px]">
                    {renderFilterForm()}
                </Card>
            )}
            <Card title="Loan Requests" className="my-[18px] w-full">
                <Table
                    className="w-full overflow-x-auto"
                    rowSelection={rowSelection}
                    dataSource={loanRequestData}
                    columns={tableColumns}
                    rowKey={(record) => record.loanRequestReference}
                    loading={loading}
                    pagination={loanRequestDataPagination}
                    onChange={handleTableChange}
                />
            </Card>
        </>
    );
}

export default LoanRequestsTable
