import { useState } from "react"
import { MaxApprovableAmountUpdateParams } from "../../portfolioApi/portfolioAPI.types";
import { update_pm_max_approvable_amount_service } from "../../portfolioApi";
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";

export const useUpdateMaxApprovableAmount = () => {
    const [Loading, setLoading] = useState(false);


    const updateMaxApprovableAmount = async (params: MaxApprovableAmountUpdateParams) => {
        try {
            setLoading(true);

            const response = await update_pm_max_approvable_amount_service(params);

            if(response.requestSuccessful) {
                showMessage(
                    "SUCCESSFUL",
                    "Request is Pending approval",
                    "success"
                );
            }
        } catch (error) {
            showMessage("Request Failed", parseError(error), "error");
        } finally {
            setLoading(false);
        }
    }

    return {
        Loading,
        updateMaxApprovableAmount
    }
}