import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Table, Button, Form, Input, Row, Col, Tag } from "antd";
import { initPagination } from "../../../../utils/pagination";
import { parseTableSearchParams } from "../../../../utils/dataParser";
import { useQueryBuisinesRelationshipManagers } from "./hook";
interface RowItem {
  code: number;
  username: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  state: { id: number; name: string };
}

const FormItem = Form.Item;

interface Props {
  searchParams?: any;
}

const ViewRelationshipManagers: React.FC<Props> = () => {
  const [form] = Form.useForm();

  const {
    brmData,
    brmPagination,
    loading,
    getAllBusinessRms,
  } = useQueryBuisinesRelationshipManagers({payload: {}})
  const [searchParams, setSearchParams] = useState({});

  const filterRelationshipManagers = () => {
    const formValues = form.getFieldsValue();
    let searchParams = parseTableSearchParams(formValues);
    setSearchParams(searchParams);

    getAllBusinessRms(searchParams, initPagination);
  };
  const onClearForm = () => {
    form.resetFields();
    setSearchParams({})
    getAllBusinessRms();
  };
  const handleTableChange = (pagination: any) => {
    fetchData(pagination, searchParams);
  };

  const fetchData = (pagination?: any, searchParams?: any) => {
    getAllBusinessRms(searchParams, pagination);
  };
  const tableColumns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (value: string, row: RowItem) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstname",
      render: (value: string, row: RowItem) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastname",
      render: (value: string, row: RowItem) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value: string, row: RowItem) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      render: (value: string, row: RowItem) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (value: string, row: RowItem) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Status",
      dataIndex: "enabledAsFco",
      key: "enabledAsFco",
      render: (value: boolean) =>
        value ? (
          <Tag color="success">Enabled</Tag>
        ) : (
          <Tag color="warning">Not Enabled</Tag>
        )
    },
    {
      title: "",
      dataIndex: "code",
      key: "code",
      render: (value: string, row: RowItem) => {
        return (
          <Link
            to={`/dashboard/business-relationship-manager-details/${value}`}
          >
            <Button
              type="primary"
              className="bg-[#0190fe] border-[#0190fe] rounded"
            >
              View Details
            </Button>
          </Link>
        );
      }
    }
  ];

  const renderFilterForm = () => {
    return (
      <Form layout="vertical" form={form} name="filter-brm">
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8}>
            <FormItem label="First Name" name={"firstName"} initialValue="">
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem label="Last Name" name={"lastName"} initialValue="">
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem label="User Name" name={"username"} initialValue="">
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col xs={24} sm={12} md={3}>
            <Button
              type="primary"
              className="w-full rounded"
              onClick={onClearForm}
              danger
            >
              Clear
            </Button>
          </Col>
          <Col xs={24} sm={12} md={4}>
            <Button
              type="primary"
              onClick={filterRelationshipManagers}
              className="w-full bg-[#0190fe] border-[#0190fe] rounded"
              htmlType="submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };
  return (
    <>
      <Card title="Relationship Managers Filter" className="my-[18px]">
        {renderFilterForm()}
      </Card>
      <Card title="Business Relationship Managers" className="my-[18px] w-full">
        <Table
          className="w-full overflow-x-auto"
          dataSource={brmData}
          columns={tableColumns}
          rowKey={(record) => record.code}
          loading={loading}
          pagination={brmPagination}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

export default ViewRelationshipManagers;
