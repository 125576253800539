import React from "react";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { Avatar, Image, Card, Descriptions, Empty, Spin, Typography } from "antd";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import { useParams } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { useQueryRegionalManagerDetails } from "./hooks/useQueryRegionalManagerDetails";
import { useFileRef } from "../../Utils/FilesUtils/hooks/useFileRef";



const breadCrumbItems: BreadCrumbItem[] = [
    {
        title: "Dashboard",
        path: DashboardRoutePaths.home.path,
    },
    {
        title: "View regional manager details",
        active: true,
    },
];


const RegionalManagersDetailsPage = () => {
    const params = useParams<{ "*": string; id: string }>();


    const {
        regionalManagerDetails,
        regionalManagerDetailsLoading,
    } = useQueryRegionalManagerDetails({
        payload: { id: params?.id as string }
    })

    const {
        image
    } = useFileRef(regionalManagerDetails?.passportReference)

    return (
        <Page breadCrumbItems={breadCrumbItems}>
            <Card
                title="Regional Manager details"
                className="my-5"
            >
                {
                    regionalManagerDetailsLoading
                        ? (
                            <section className=" flex items-center justify-center">
                                <Spin tip="Loading Details..." />
                            </section>
                        )
                        : regionalManagerDetails
                            ? (
                                <Descriptions
                                    layout="vertical"
                                    column={{ md: 2, sm: 1, xs: 1 }}
                                >
                                    <Descriptions.Item
                                        label={
                                            <Typography.Text strong>
                                                Passport Photo
                                            </Typography.Text>
                                        }
                                        span={3}
                                    >
                                        {
                                            image
                                                ? <Image src={image} width={200} placeholder />
                                                : <Avatar shape="square" size={200} icon={<UserOutlined />} />
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<strong>{'Name'}</strong>}>
                                        {regionalManagerDetails?.name}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<strong>{'Username'}</strong>}>
                                        {regionalManagerDetails?.username}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<strong>{'Email'}</strong>}>
                                        {regionalManagerDetails?.email}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<strong>{'Phone Number'}</strong>}>
                                        {regionalManagerDetails?.phoneNumber}
                                    </Descriptions.Item>
                                </Descriptions>
                            )
                            : (
                                <section className=" flex items-center justify-center py-8">
                                    <Empty />
                                </section>
                            )
                }
            </Card>
        </Page>
    )
}

export default RegionalManagersDetailsPage
