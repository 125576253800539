import React from "react";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import ViewLoanPrograms from "../ViewLoanPrograms";

const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "View Loan Programs",
    active: true,
  },
];

const LoanPrograms = () => {
  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <ViewLoanPrograms/>
    </Page>
  );
};

export default LoanPrograms;