import { Tag } from "antd";
import { loanContractProductTypeMap } from "../../../../LoanContractsManagement/utils/dataMaps";
import { ELoanContractProduct } from "../../../../LoanContractsManagement/utils/types";

const LoanProductTag = ({ loanProduct }: { loanProduct: string }) => {
  return (
    <Tag
      color={
        loanContractProductTypeMap[loanProduct as ELoanContractProduct]?.color
      }
    >
      {loanContractProductTypeMap[loanProduct as ELoanContractProduct]?.text}
    </Tag>
  );
};

export default LoanProductTag;
