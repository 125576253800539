import React from "react";
import ViewRelationshipManagers from "../ViewBusinessRelationshipManagers";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";

const breadCrumbItems: BreadCrumbItem[] = [
    {
        title: "Dashboard",
        path: DashboardRoutePaths.home.path,
    },
    {
        title: "View Business Relationship Managers",
        active: true,
    }
]

const BusinessRelationshipManagers = () => {
    return (
        <Page breadCrumbItems={breadCrumbItems}>
            <ViewRelationshipManagers />

        </Page>
    )
}

export default BusinessRelationshipManagers
