import React from "react";
import {
  Pagination,
  extractPaginationData,
  initPagination,
} from "../../../../../utils/pagination";
import { parseError } from "../../../../../utils/server";
import { showMessage } from "../../../../../utils/notifications";
import { get_all_loan_requests_service } from "../API";

export interface LoanRequestsQueryProps {
  payload: {
    status: string;
    searchParams?: Record<string, any>;
    pagination?: Pagination;
  };
}

export const useQueryAllLoanRequests = (props: LoanRequestsQueryProps) => {
  const {
    payload: { status, searchParams, pagination },
  } = props;

  const [loanRequests, setLoanRequests] = React.useState<any | null>(null);
  const [loanRequestsPagination, setLoanRequestsPagination] =
    React.useState<Pagination>(initPagination);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [permissionError, setPermissionError] = React.useState<boolean | null>(
    false
  );

 
  const getAllLoanRequests = React.useCallback(
    async (
      status: string,
      searchParams?: Record<string, any>,
      pagination?: Pagination
    ) => {
      try {
        setLoanRequests([]);
        setError("");
        setLoading(true);
        const response = await get_all_loan_requests_service(
          status,
          searchParams,
          pagination
        );
        const paginationData = extractPaginationData(response);
        setLoanRequests(response?.content);
        setLoanRequestsPagination(paginationData);
      } catch (error: any) {
        if (error.response && error.response.status === 403) {
          setPermissionError(true);
          return; //exit early
        }
        showMessage("Loan Requests", parseError(error), "error");
        setError(parseError(error));
      } finally {
        setLoading(false);
      }
    },
    []
  );

  React.useEffect(() => {
    getAllLoanRequests(status, searchParams, pagination);
  }, [searchParams, pagination, getAllLoanRequests, status]);

  return {
    error,
    loading,
    loanRequests,
    permissionError,
    loanRequestsPagination,
    getAllLoanRequests
  };
};
