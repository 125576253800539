import config from "../../../../config";
import server from "../../../../server";
import { IResponse } from "../../../../server/server.types";
import { IPortfolioLevel, IPortfolioManager, onBoardUsersAsPmTypes } from "../portfolioSlice/portfolio.types";
import { IPortfolioManagementSettingsItem, IPortfolioManagementSettingsParams, MaxApprovableAmountUpdateParams, PMSPortfolioManagerDetails } from "./portfolioAPI.types";
import { initPagination } from "../../../../utils/pagination";

export const create_portfolio_level_service = async (rmCode: string) => {
  const response = await server.post(`/field-credit-officers`, {
    rmCode,
  });

  return response.data;
};

export const get_portfolio_manager_details_service = async (
  requestId: string
) => {
  const response = await server.get(`/portfolio-managers/code/${encodeURIComponent(requestId)}`)

  return response.data as IResponse<IPortfolioManager>;
};

export const api_update_portfolio_management_settings = async (
  params: IPortfolioManagementSettingsParams
) => {
  const response = await server.post(
    `${config.WCL_PORTFOLIO_BASE_URL}/system-settings/request-update`,
    params
  );

  return response.data as IResponse<any>
}

export const get_portfolio_levels_service = async () => {
  const response = await server.get(`${config.WCL_PORTFOLIO_BASE_URL}/portfolio-levels`)

  return response.data as IResponse<IPortfolioLevel[]>
}

export const get_portfolio_management_settings = async () => {
  const response = await server.get(`${config.WCL_PORTFOLIO_BASE_URL}/system-settings`)

  return response.data as IResponse<IPortfolioManagementSettingsItem>
}

export const create_fco_portfolio_level_service = async (data: any) => {
  const response = await server.post(
    `${config.WCL_PORTFOLIO_BASE_URL}/portfolio-levels/request-creation`,
    data
  );

  return response.data;
};

export const get_all_portfolio_managers_service = async (
  searchParams: Record<string, any> = {},
  pagination: Record<string, any> = initPagination
) => {
  let page = pagination.current - 1;
  const response = await server.get(`/portfolio-managers`, {
    params: { ...searchParams, page: page, size: pagination.pageSize },
  });

  return response.data;
};

export const assinged_lga_portfolio_manager__service = async (
  pmCode: string,
  lgaCodes: []
) => {
  const response = await server.post(`/portfolio-managers/assign-lgas`, {
    pmCode,
    lgaCodes,
  });

  return response.data;
};

export const removed_lga_portfolio_manager_service = async (
  pmCode: string,
  lgaCodes: []
) => {
  const response = await server.post(`/portfolio-managers/remove-lgas`, {
    pmCode,
    lgaCodes,
  });

  return response.data;
};

export const get_fco_portfolio_level_service = async (
) => {

  const response = await server.get(
    `${config.WCL_PORTFOLIO_BASE_URL}/portfolio-levels`,

  );

  return response.data
};

export const onboard_user_as_portfolio_manager_service = async (payLoad: onBoardUsersAsPmTypes) => {

  const response = await server.post(
    `/portfolio-managers`,
    payLoad
  );

  return response.data
};

export const get_PMS_portfolio_manager_details_service = async (
  requestId: string
) => {
  const response = await server.get(`${config.WCL_PORTFOLIO_BASE_URL}/portfolio-managers/${encodeURIComponent(requestId)}`)

  return response.data as IResponse<PMSPortfolioManagerDetails>;
};

export const update_pm_max_approvable_amount_service = async (
  params: MaxApprovableAmountUpdateParams
) => {
  const response = await server.post(
    `${config.WCL_PORTFOLIO_BASE_URL}/portfolio-managers/update-max-approvable-amount`,
    params
  );

  return response.data as IResponse<any>
}