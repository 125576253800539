export interface ILoanApprovalStatusDetails {
  currentApprovalStatus: string;
  previousApprovalStatus: string;
  previousApprovalStatusPassed: boolean;
  rejectedReason: string;
}

export interface IBusinessOwnerDetails {
  profileImage: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  phoneNumber: string;
  email: string;
}

export interface IResidentialAddress {
  addressLineOne: string;
  addressLineTwo: string;
  nearestLandmark: string;
  nearestBusstop: string;
  lga: string;
  city: string | null;
}

export interface IBusinessAddress {
  businessName: string;
  addressLineOne: string;
  addressLineTwo: string;
  nearestLandmark: string;
  nearestBusstop: string;
  lga: string;
  city: string | null;
}

export interface IManager {
  code: string;
  name: string;
}

export interface ILoanManagers {
  firstFco: IManager;
  secondFco: IManager | null;
  pm: IManager;
  rm: IManager;
}

export interface ILoanRequestDetails {
  amountRequested: string;
  requestStatus: string;
  approvalStatusDetails: ILoanApprovalStatusDetails;
  businessOwnerDetails: IBusinessOwnerDetails;
  residentialAddress: IResidentialAddress;
  businessAddress: IBusinessAddress;
  mpManagers: {
    brms: IManager[];
    scs: IManager[];
    rms: IManager[];
  };
  loanManagers: ILoanManagers | null;
}
export interface IMoniepointManagers {
  mpManagers: {
    brm: IManager;
    sc: IManager;
    rm: IManager;
  }[];
}

export type UseQueryBusinessOwnerDetailsPayload = {
  loanReference: string | null;
};

export enum LoanOfferStatus {
  AWAITING_ELIGIBILITY_CHECK = 'AWAITING_ELIGIBILITY_CHECK',
  ELIGIBILITY_EXPIRED = 'ELIGIBILITY_EXPIRED',
  PENDING_KYC_CONFIRMATION = 'PENDING_KYC_CONFIRMATION',
  FAILED_ELIGIBILITY_CHECK = 'FAILED_ELIGIBILITY_CHECK',
  PASSED_ELIGIBILITY_CHECK = 'PASSED_ELIGIBILITY_CHECK',
  DRAFT = 'DRAFT',
  FILLING_LOAN_REQUEST = 'FILLING_LOAN_REQUEST',
  AWAITING_LOAN_OFFER = 'AWAITING_LOAN_OFFER',
  PERUSING_LOAN_OFFER = 'PERUSING_LOAN_OFFER',
  NO_LOAN_OFFER = 'NO_LOAN_OFFER',
  ACCEPTED = 'ACCEPTED',
  OFFER_EXPIRED = 'OFFER_EXPIRED',
  EXPIRED = 'EXPIRED',
  TRIGGER_LOAN_REQUEST_APPROVAL = 'TRIGGER_LOAN_REQUEST_APPROVAL',
  PENDING_LOAN_APPROVAL='PENDING_LOAN_APPROVAL'
}
  