import React from "react";

import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import ViewUsers from "../ViewUsersManagers";

const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "View Users",
    active: true,
  },
];

const ViewUsersManagers = () => {
  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <ViewUsers />
    </Page>
  );
};

export default ViewUsersManagers ;
