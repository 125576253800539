import CONFIG_DEV from "./config-dev";
import CONFIG_PRODUCTION from "./config-production";
import CONFIG_STAGING from "./config-staging";

export type environments =
  | "playground"
  | "test"
  | "dev"
  | "staging"
  | "production";

const configMap = {
  playground: CONFIG_DEV,
  test: CONFIG_DEV,
  dev: CONFIG_DEV,
  staging: CONFIG_STAGING,
  production: CONFIG_PRODUCTION,
};

const config =
  configMap[
    (process.env.REACT_APP_ENV as environments)
      ? (process.env.REACT_APP_ENV as environments)
      : "dev"
  ];

export default config;
