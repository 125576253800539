import { combineReducers } from '@reduxjs/toolkit'
import authReducer from '../modules/Auth/authSlice'
import brmReducer from '../modules/Dashboard/BRMManagement/brmSlice'
import utilsReducer from '../modules/Dashboard/Utils/UtilsSlice'
import workflowReducer from '../modules/Dashboard/Workflow/workflowSlice'
import portFolioReducer from '../modules/Dashboard/PortfolioManagement/portfolioSlice' 
import fcoReducer from '../modules/Dashboard/FCOManagement/fcoSlice'
import stateCordinatorsReducer from '../modules/Dashboard/StateCoordinatorManagement/stateCoordinatorSlice'
import provisioningTemplateReducer from '../modules/Dashboard/ProvisioningTemplate/provisionTemplateSlice'
import loanProgramsReducer from '../modules/Dashboard/LoanPrograms/loanProgramsSlice'
import loanRequestReducer from '../modules/Dashboard/LoanRequestsManagement/loanRequestSlice'
import regionalManagerReducer from '../modules/Dashboard/WCLRMManagement/Slice'
import lgaActivationReducer from '../modules/Dashboard/LgaActivation/lgaActivationSlice'




export const rootReducer = combineReducers({
    auth: authReducer,
    brmReducer:brmReducer,
    workflow: workflowReducer,
    utils: utilsReducer,
    portFolioReducer:portFolioReducer,
    provisioningTemplateReducer:provisioningTemplateReducer,
    loanProgramsReducer:loanProgramsReducer,
    fcos:fcoReducer,
    stateCordinators:stateCordinatorsReducer,
    loanRequests:loanRequestReducer,
    regionalManager: regionalManagerReducer,
    lgaActivation: lgaActivationReducer,
})
