import React from 'react';
import 'antd/dist/antd.min.css'
import './assets/App.css';
import './assets/utils.css'
import RouteModules from './routes';

function App() {
  return (
    <>
      <RouteModules />
    </>
  );
}

export default App;
