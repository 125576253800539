import config from "../../../../../config";
import server from "../../../../../server";
import { initPagination } from "../../../../../utils/pagination";
import { TFCOListApi } from "../types";


export const get_all_fco_service = async ({
  loanRequestReference,
  searchParams,
  pagination = initPagination
}: TFCOListApi) => {
  let page = pagination.current - 1;
  const response = await server.get(`${config.WCL_PORTFOLIO_BASE_URL}/field-credit-officers/${encodeURIComponent(loanRequestReference)}/loan-request`, {
    params: { ...searchParams, page: page, size: pagination.pageSize },
  });

  return response.data;
};