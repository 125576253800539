import React from "react";
import CompletedWorkflows from ".";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import { WorkflowCategory } from "../workflowSlice/workflowSlice.types";



const breadCrumbItems: BreadCrumbItem[] = [
    {
        title: "Dashboard",
        path: DashboardRoutePaths.home.path,
    },
    {
        title: "Completed Workflows",
        active: true,
    }
]


const BackofficeWCLCompletedWorkflow = () => {
    return (
        <Page breadCrumbItems={breadCrumbItems}>
            <CompletedWorkflows category={WorkflowCategory.WORKING_CAPITAL_LOANS_BO} />
        </Page>
    )
}

export default BackofficeWCLCompletedWorkflow
