import { FC } from 'react';
import { Button, Popconfirm, Typography } from 'antd';
import { triggerBusinessVerification } from "../services/triggerBusinessVerification";
import { QuestionCircleOutlined } from '@ant-design/icons';

const Title = Typography.Title;
interface BusinessVerificationTriggerProps {
    loanRequestRef: string;
}

export const BusinessVerificationTrigger: FC<BusinessVerificationTriggerProps> = ({
    loanRequestRef
}) => {
    const onConfirm = async () => {
        return triggerBusinessVerification(loanRequestRef);
    }

    return (
        <Popconfirm
            title={<PopTitle />}
            onConfirm={onConfirm}
            okText="Yes, Submit"
            okButtonProps={{
                className: 'h-10 w-[100px] px-4 py-2 mt-6 rounded'
            }}
            cancelButtonProps={{
                className: 'h-10 w-[100px] px-4 py-2 mt-6 rounded'
            }}
            icon={<QuestionCircleOutlined className='text-orange-400 mb-3' />}
        >
            <Button className='rounded' type="primary">Trigger Business Verification</Button>
        </Popconfirm>
    );
}

const PopTitle = () => (
    <Title level={5}>
        Send request to trigger business Verification?
    </Title>
);
