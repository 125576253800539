import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Button,Modal,Typography } from "antd";
import { useAppDispatch } from "../../../../store";
import { fetchStates } from "../../Utils/UtilsSlice/utilsSlice.thunks";
import { parsePhoneNumberWithCountryCode } from "../../../../utils/dataParser";
import OnboardingWizard from "../../Utils/Onboarding";
import { resetOnBoardUsersAsPm } from "../portfolioSlice";
import { onBoardUsersAsPmTypes } from "../portfolioSlice/portfolio.types";
import { useOnboardPortfolioManager } from "./hook";
import PmsTable from "./PmsTable";

interface Props {
  searchParams?: any;
  hiddenFields?: boolean;
  pmUserName?: string;
  reassignLoanRequestApi?: (data: any) => void;
}

const ViewPortfolioManagersTable: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const {
    onBoardUsersAsPM,
    onCloseOnBoardModal,
    onBoardUsersAsPmError,
    onBoardUsersAsPmLoading
  } = useOnboardPortfolioManager();

  const [showOnboarding, setShowOnboarding] = useState(false);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(fetchStates());
    }
  }, [dispatch]);

  const tableColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (value: string) => {
        return <div>{parsePhoneNumberWithCountryCode(value)}</div>;
      }
    },

    {
      title: "",
      dataIndex: "pmCode",
      key: "pmCode",
      render: (value: string) => {
        return (
          <Link
            to={`/dashboard/portfolio-management/portfolio-managers/${value}`}
          >
            <Button
              type="primary"
              className="bg-[#0190fe] border-[#0190fe] rounded"
            >
              View Details
            </Button>
          </Link>
        );
      }
    }
  ];
 

  const onCloseOnboardingView = () => {
    onCloseOnBoardModal();
    setShowOnboarding(false);
  };

  const onSubmitOnboardingRequest = (payload: onBoardUsersAsPmTypes) => {
    onBoardUsersAsPM(payload, (data: any) => {
      onCloseOnboardingView();
    });
  };
  const onModalCloses = () => {
    dispatch(resetOnBoardUsersAsPm());
    setShowOnboarding(false);
  };

  return (
    <>
      <section className=" w-full py-4 flex items-center justify-end">
        <Button
          type="primary"
          className="rounded"
          onClick={() => {
            setShowOnboarding(true);
          }}
        >
          Onboard New PM
        </Button>
      </section>

      <Card title="Portfolio Managers" className="my-[18px] w-full">
        <PmsTable extraColumns={tableColumns} />
      </Card>
      <Modal
        destroyOnClose={true}
        centered
        className="modal-lg my-6"
        width={800}
        open={showOnboarding}
        onCancel={onModalCloses}
        title={
          <Typography.Title level={4}>
            {"Onboard new Portfolio Manager"}
          </Typography.Title>
        }
        footer={null}
      >
        <OnboardingWizard
          userType="PM"
          onSubmitOnboardingRequest={onSubmitOnboardingRequest}
          submitError={onBoardUsersAsPmError}
          submitLoading={onBoardUsersAsPmLoading}
        />
      </Modal>
    </>
  );
};

export default ViewPortfolioManagersTable;
