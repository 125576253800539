import React from "react";

import {
 
  get_user_details_username_service
} from "../usersApi";
import { parseError } from "../../../../utils/server";
import { showMessage } from "../../../../utils/notifications";

export interface UserManagersProps {
  payload: {
    userName: string;
  };
}

export const useQueryUserDetailsManagers = (props: UserManagersProps) => {
  const {
    payload: { userName }
  } = props;
  const [usersDetailsData, setUsersDetailsData] = React.useState<any | null>(
    null
  );

  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const getUsersDetailsManager = React.useCallback(async (username: string) => {
    try {
      setError("");
      setLoading(true);

      const response = await get_user_details_username_service(username);

      setUsersDetailsData(response?.responseBody);
    } catch (error: any) {
      showMessage("User  Details", parseError(error), "error");
      setError(parseError(error));
    } finally {
      setLoading(false);
    }
  }, []);
const retryUserDetails=()=>{
  if(userName){
    getUsersDetailsManager(userName)
  }
}

  React.useEffect(() => {
    getUsersDetailsManager(userName);
  }, [getUsersDetailsManager, userName]);

  return {
    error,
    loading,
    usersDetailsData,
    getUsersDetailsManager,
    retryUserDetails
  };
};
