export type UsersTypes = {
  name: string;
  email: string;
  userName: string;
  activation: string;
  status: string;
};
export enum UsersTableStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}
export enum UsersTableActivation {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}
export const mockUsersData = [
  {
    name: "kkk",
    email: "tetet@gmail.com",
    userName: "comeflex",
    activation: "ACTIVE",
    status: "ENABLED",
  },
  {
    name: "kkk",
    email: "tetet@gmail.com",
    userName: "comeflex",
    activation: "INACTIVE",
    status: "DISABLED",
  },
  {
    name: "kkk",
    email: "tetet@gmail.com",
    userName: "comeflex",
    activation: "ACTIVE",
    status: "ENABLED",
  },
  {
    name: "kkk",
    email: "tetet@gmail.com",
    userName: "comeflex",
    activation: "INACTIVE",
    status: "DISABLED",
  },
  {
    name: "kkk",
    email: "tetet@gmail.com",
    userName: "comeflex",
    activation: "ACTIVE",
    status: "ENABLED",
  },
  {
    name: "kkk",
    email: "tetet@gmail.com",
    userName: "comeflex",
    activation: "INACTIVE",
    status: "DISABLED",
  },
];
export const mockLoanRequestDetails = {
  amountRequested: "100000",
  requestStatus: "Pending",
  approvalStatusDetails: {
    currentApprovalStatus: "In Review",
    previousApprovalStatus: "Submitted",
    previousApprovalStatusPassed: true,
    rejectedReason: "",
  },
  businessOwnerDetails: {
    profileImage:
      "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
    firstName: "Chinedu",
    lastName: "Adebayo",
    middleName: "Ifeanyi",
    phoneNumber: "08012345678",
    email: "chineduadebayo@example.com",
  },
  residentialAddress: {
    addressLineOne: "No. 5 Opebi Road",
    addressLineTwo: "Flat 2B",
    nearestLandmark: "Ikeja City Mall",
    nearestBusstop: "Allen Avenue",
    lga: "NGA-LA-010",
    city: "Lagos",
  },
  businessAddress: {
    businessName: "Adebayo Enterprises",
    addressLineOne: "12 Awolowo Way",
    addressLineTwo: "Suite 105",
    nearestLandmark: "Computer Village",
    nearestBusstop: "Medical Road",
    lga: "NGA-LA-008",
    city: "Lagos",
  },
  mpManagers: {
    brm: { code: "BRM100", name: "Oluchi Onwuka" },
    sc: { code: "SC200", name: "Tayo Oyetunde" },
    rm: { code: "RM300", name: "Emeka Nwosu" },
  },
  loanManagers: {
    firstFco: { code: "FCO101", name: "Kemi Adeola" },
    secondFco: null,
    pm: { code: "PM201", name: "Adaora Chukwuma" },
    rm: { code: "RM301", name: "Sani Ahmed" },
  },
};
