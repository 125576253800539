import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { showMessage } from "../../../../utils/notifications";
import {
  createLoanProgram,
  getBusinessTypes,
} from "../loanProgramsSlice/loanPrograms.thunks";

const FormItem = Form.Item;
const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "Loan Programs",
    path: DashboardRoutePaths.loanPrograms.path,
  },
  {
    title: "Create Loan Program",
    active: true,
  },
];

const CreateLoanProgram: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    loanProgramsReducer: {
      createLoanProgramRunning,
      createLoanProgramError,
      createLoanProgramSuccess,
      businessTypesLoading,
      businessTypes,
    },
  } = useAppSelector((state) => state);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    dispatch(getBusinessTypes());
  }, [dispatch]);

  const onSubmit = (values: any) => {
    dispatch(createLoanProgram(values));
  };

  useEffect(() => {
    if (createLoanProgramSuccess) {
      showMessage(
        "Create Loan Program",
        "Loan Program creation was successful",
        "success"
      );
      // dispatch(resetCreateFcoLevelSuccessState());
      form.resetFields();
      navigate(-1);
    }
  }, [createLoanProgramSuccess, dispatch, form, navigate]);

  useEffect(() => {
    if (createLoanProgramError) {
      showMessage("Create Loan Program", createLoanProgramError, "error");
    }
  }, [createLoanProgramError]);

  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <Form
        layout="vertical"
        className="max-w-[720px]"
        form={form}
        name="create-loan-program"
        size="large"
        requiredMark="optional"
        disabled={createLoanProgramRunning}
        initialValues={{
          loanType: "COMMERCIAL",
          loanTenureUnit: "MONTHLY",
          interestCalculationFrequency: "DAILY",
          interestPaymentFrequency: "MONTHLY",
          processingFee: { chargeType: "PERCENTAGE" },
          managementFee: { chargeType: "PERCENTAGE" },
          recoveryFee: { chargeType: "PERCENTAGE" },
        }}
        onFinish={onSubmit}
      >
        <Space direction="vertical" size="middle" className="w-full">
          <Card className="w-full">
            <FormItem
              label="Name"
              name="name"
              hasFeedback
              rules={[
                { required: true },
                {
                  pattern: new RegExp(/^[ A-Za-z0-9]*$/),
                  message: "Special character not allowed",
                },
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Business Type"
              name="businessType"
              rules={[{ required: true }]}
            >
              <Select
                options={businessTypes?.map((businessType: any) => ({
                  label: businessType.name,
                  value: businessType.code,
                }))}
                loading={businessTypesLoading}
              />
            </FormItem>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="Min amount"
                  name="minAmount"
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber
                    addonBefore="₦"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value!.replace(/,*/g, "")}
                    className="w-full"
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Max Amount"
                  name="maxAmount"
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber
                    addonBefore="₦"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value!.replace(/,*/g, "")}
                    className="w-full"
                  />
                </FormItem>
              </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="Product code"
                  name="productCode"
                  rules={[{ required: true }]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Ledger Account Number"
                  name="ledgerAccountNumber"
                  rules={[{ required: true }]}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="Loan Type"
                  name="loanType"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={[
                      { label: "COMMERCIAL", value: "COMMERCIAL" },
                      { label: "ISLAMIC", value: "ISLAMIC" },
                    ]}
                    className="w-full"
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Loan Tenure" requiredMark={false}>
                  <Space.Compact size="large" block>
                    <FormItem
                      name="loanTenure"
                      className="mb-0"
                      rules={[{ required: true }, { type: "number" }]}
                    >
                      <InputNumber size="large" className="w-full" />
                    </FormItem>
                    <FormItem
                      name="loanTenureUnit"
                      className="mb-0"
                      rules={[{ required: true }]}
                    >
                      <Select
                        size="large"
                        options={[
                          { label: "MONTHLY", value: "MONTHLY" },
                          { label: "WEEKLY", value: "WEEKLY" },
                        ]}
                        className="w-[13ch]"
                      />
                    </FormItem>
                  </Space.Compact>
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="Stake to Turnover Ratio"
                  name="stakeToTurnoverRatio"
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber className="w-full" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Limit Evaluation Cycle"
                  name="limitEvaluationCycleInDays"
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber addonAfter="days" className="w-full" />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="Ongoing Assessment Turnover Decline Grace"
                  name="ongoingAssessmentTurnoverDeclineGracePercentage"
                  rules={[
                    { required: true },
                    { type: "number", min: 0, max: 100 },
                  ]}
                >
                  <InputNumber addonAfter="%" className="w-full" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Moratorium"
                  name="moratoriumInDays"
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber addonAfter="days" className="w-full" />
                </FormItem>
              </Col>
            </Row>
            <FormItem
              label="Requires Field Credit Assessment"
              name="requiresFieldCreditAssessment"
              valuePropName="checked"
              rules={[{ required: true }]}
            >
              <Switch unCheckedChildren="No" checkedChildren="Yes" />
            </FormItem>
          </Card>
          <Card title={<strong>Required cycles</strong>}>
            <Row gutter={24}>
              <Col span={8}>
                <FormItem
                  label="For Initial assessment"
                  name="cyclesRequiredForInitialAssessment"
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber addonAfter="cycles" className="w-full" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="For Ongoing assessment"
                  name="cyclesRequiredForOngoingAssessment"
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber addonAfter="cycles" className="w-full" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="For Renewal assessment"
                  name="cyclesRequiredForRenewalAssessment"
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber addonAfter="cycles" />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title={<strong>Eligibility Criteria</strong>}>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="Minimum account age"
                  name={["eligibilityCriteria", "minimumAccountAgeInDays"]}
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber addonAfter="days" className="w-full" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Minimum Turnover"
                  name={["eligibilityCriteria", "minimumTurnover"]}
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber className="w-full" />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title={<strong>Processing Fee</strong>}>
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="Charge" requiredMark={false}>
                  <Space.Compact size="large" block>
                    <FormItem
                      name={["processingFee", "chargeType"]}
                      rules={[{ required: true }]}
                    >
                      <Select
                        options={[
                          { label: "PERCENTAGE", value: "PERCENTAGE" },
                          { label: "VAT", value: "VAT" },
                        ]}
                        className="!w-[17ch]"
                      />
                    </FormItem>
                    <FormItem
                      name={["processingFee", "chargeValue"]}
                      rules={[{ required: true }, { type: "number" }]}
                    >
                      <InputNumber className="w-full" />
                    </FormItem>
                  </Space.Compact>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="Minimum Value"
                  name={["processingFee", "minimumValue"]}
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber className="w-full" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="Maximum Value"
                  name={["processingFee", "maximumValue"]}
                  rules={[{ required: true }, { type: "number", min: 0 }]}
                >
                  <InputNumber className="w-full" />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title={<strong>Management Fee</strong>}>
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="Charge" requiredMark={false}>
                  <Space.Compact size="large" block>
                    <FormItem
                      name={["managementFee", "chargeType"]}
                      rules={[{ required: true }]}
                    >
                      <Select
                        options={[
                          { label: "PERCENTAGE", value: "PERCENTAGE" },
                          { label: "VAT", value: "VAT" },
                        ]}
                        className="!w-[17ch]"
                      />
                    </FormItem>
                    <FormItem
                      name={["managementFee", "chargeValue"]}
                      rules={[{ required: true }, { type: "number" }]}
                    >
                      <InputNumber className="w-full" />
                    </FormItem>
                  </Space.Compact>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="Minimum Value"
                  name={["managementFee", "minimumValue"]}
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber className="w-full" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="Maximum Value"
                  name={["managementFee", "maximumValue"]}
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber className="w-full" />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title={<strong>Recovery Fee</strong>}>
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="Charge" requiredMark={false}>
                  <Space.Compact size="large" block>
                    <FormItem
                      name={["recoveryFee", "chargeType"]}
                      rules={[{ required: true }]}
                    >
                      <Select
                        options={[
                          { label: "PERCENTAGE", value: "PERCENTAGE" },
                          { label: "VAT", value: "VAT" },
                        ]}
                        className="!w-[17ch]"
                      />
                    </FormItem>
                    <FormItem
                      name={["recoveryFee", "chargeValue"]}
                      rules={[{ required: true }, { type: "number" }]}
                    >
                      <InputNumber className="w-full" />
                    </FormItem>
                  </Space.Compact>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="Minimum Value"
                  name={["recoveryFee", "minimumValue"]}
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber className="w-full" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="Maximum Value"
                  name={["recoveryFee", "maximumValue"]}
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber className="w-full" />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title={<strong>Interest Details</strong>}>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="Interest Calculation Frequency"
                  name="interestCalculationFrequency"
                  rules={[{ required: true }]}
                >
                  <Select
                    value="DAILY"
                    options={[
                      { label: "DAILY", value: "DAILY" },
                      { label: "WEEKLY", value: "WEEKLY" },
                      { label: "MONTHLY", value: "MONTHLY" },
                    ]}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Interest Payment Frequency"
                  name="interestPaymentFrequency"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={[
                      { label: "WEEKLY", value: "WEEKLY" },
                      { label: "MONTHLY", value: "MONTHLY" },
                    ]}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="Daily Interest Rate"
                  name="dailyInterestRateInPercentage"
                  rules={[
                    { required: true },
                    { type: "number", min: 0, max: 100 },
                  ]}
                >
                  <InputNumber addonAfter="%" className="w-full" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Hourly Interest Rate"
                  name="hourlyInterestRateInPercentage"
                  rules={[
                    { required: true },
                    { type: "number", min: 0, max: 100 },
                  ]}
                >
                  <InputNumber addonAfter="%" className="w-full" />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title={<strong>Repayment Details</strong>}>
            <FormItem
              label="Repayment Frequency"
              name="repaymentFrequency"
              rules={[{ required: true }, { type: "number", min: 0 }]}
            >
              <InputNumber className="w-full" />
            </FormItem>
            <Row gutter={24}>
              <Col span={8}>
                <FormItem
                  label="Maximum days late on any Repayment"
                  name="maximumDaysLateOnAnyRepayment"
                  rules={[{ required: true }, { type: "number", min: 0 }]}
                >
                  <InputNumber addonAfter="days" className="w-full" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="Grace period for overdue Repayment"
                  name="gracePeriodInDaysForOverdueRepayment"
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber addonAfter="days" className="w-full" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="Max percent of Overdue Repayment on each loan"
                  name="maxPercentOfOverdueRepaymentOnEachLoan"
                  rules={[
                    { required: true },
                    { type: "number", min: 0, max: 100 },
                  ]}
                >
                  <InputNumber addonAfter="%" className="w-full" />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title={<strong>Disbursement & Recovery Details</strong>}>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="Disbursement window in days"
                  name="disbursementWindowInDays"
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber addonAfter="days" className="w-full" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Number of days in default to make recovery"
                  name="numberOfDaysInDefaultToMakeRecovery"
                  rules={[{ required: true }, { type: "number" }]}
                >
                  <InputNumber addonAfter="days" className="w-full" />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card className="text-center">
            <FormItem noStyle shouldUpdate>
              <Button
                type="primary"
                htmlType="submit"
                className="mx-auto"
                loading={createLoanProgramRunning}
              >
                Create Loan Program
              </Button>
            </FormItem>
          </Card>
        </Space>
      </Form>
    </Page>
  );
};

export default CreateLoanProgram;
