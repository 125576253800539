import React, { FC, useState } from 'react'
import { Card, Table, Tag, Button } from 'antd'
import Moment from 'react-moment'
import { convertStatusTextToColor } from "../../../../utils/workflow";
import RequestViewer from './RequestViewer'
import { WorkflowCategory } from '../workflowSlice/workflowSlice.types';
import { Pagination } from '../../../../utils/pagination';


Moment.globalFormat = 'MMM D YYYY h:mm a'


interface WorkflowRequestsProps {
    onFetchData({ params, pagination}: { params: any, pagination: any}): void
    category: WorkflowCategory
    enableActions: boolean
    data: any[],
    loading: boolean,
    pagination: Pagination
    searchParams?: any
    columns?: any
    title?: any
    enableRowExpansion?: boolean
}

const WorkflowRequests: FC<WorkflowRequestsProps> = ({
    enableActions, 
    searchParams, 
    columns, 
    title, 
    data,
    loading,
    pagination,
    onFetchData,
    category,
    enableRowExpansion
}) => {
    
    const [selected, setSelected] = useState<any>()


    let tableColumns = [
        {
            title: 'Type',
            dataIndex: 'description',
        },
        {
            title: 'Initiator',
            dataIndex: 'initiator',
            render: (text: any) => {
                return <Tag color={'#0887c9'}>{text}</Tag>
            },
        },
        {
            title: 'Status',
            dataIndex: 'requestStatus',
            render: (text: any) => {
                return <Tag color={convertStatusTextToColor(text)}>{text}</Tag>
            },
        },
        {
            title: 'Last Modification Date',
            dataIndex: 'lastModifiedOn',
            render: (text: any) => {
                return <Moment date={text} />
            },
        },
    ]

    const openRequestViewer = (record: any) => {
        setSelected(record)
    }

    const hideRequestViewer = () => {
        setSelected(null)
    }

    const refresh = () => {
        hideRequestViewer()
        fetchData()
    }

    const handleTableChange = (pagination: any) => {
        fetchData(pagination, searchParams)
    }

    const fetchData = (pagination?: any, params?: any) => {
        onFetchData({ params, pagination })
    }

    if (columns) {
        tableColumns = columns
    }

    const columnsToRender = [
        ...tableColumns,
        {
            title: '',
            render: (_: any, record: any) => {
                return (
                    <Button
                        onClick={() => openRequestViewer(record)}
                        type="primary"
                        className='primary-btn-base'
                    >
                        View
                    </Button>
                )
            },
        },
    ]

    return (
        <>
            <Card title={title} className="w-full">
                <Table
                    className="w-full overflow-x-auto"
                    columns={columnsToRender}
                    rowKey={record => record.reference}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    expandable={{
                        showExpandColumn: enableRowExpansion
                    }}
                    expandedRowRender={record => {
                        const status = record.requestStatus.toLowerCase()
                        if ('pending' === status) {
                            return null
                        }
                        const actionHeader = status === 'approved' ? 'Authorized by : ' : 'Rejected by : '
                        return (
                            <div>
                                <h5 className="text-black">
                                    <strong>{actionHeader}</strong>
                                </h5>
                                <p>{record.authorizer}</p>
                                <h5 className="text-black">
                                    <strong>Comment : </strong>
                                </h5>
                                <p>{record.authorizersComment}</p>
                            </div>
                        )
                    }}
                />
            </Card>

            <RequestViewer
                enableActions={enableActions}
                visible={Boolean(selected)}
                onCancel={hideRequestViewer}
                onApproved={refresh}
                onRejected={refresh}
                data={selected}
                category={category}
            />
        </>
    )
}

export default WorkflowRequests;
