import { Input } from "antd";
import { useState } from "react";
import { isIntOrTwoDecimalFloat } from "../../utils/validation";


interface PriceInputProps {
    id?: string;
    value?: number;
    onChange?: (value: number) => void;
}

export const CurrencyInput = ({ id, onChange, value }: PriceInputProps) => {
    const [text, setText] = useState((!value || isNaN(value)) ? '' : value.toString());

    const triggerChange = (newValue: string) => {
        onChange?.(Number(newValue));
    }
    const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/₦\s?|(,*)/g, '');

        if ((isIntOrTwoDecimalFloat(newValue) || newValue.length === 0) && !newValue.startsWith('0')) {
            setText(newValue);
            triggerChange(newValue);
        }
    }

    return (
        <div id={id}>
            <Input
                type="text"
                value={`₦ ${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onChange={onNumberChange}
            />
        </div>
    );
}
