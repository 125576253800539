import React from "react";
import { update_fco_portfolio_level_service } from "../../fcoApi";
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";
import { FCOUPDATEPORTFOLIOLEVEL } from "../../fcoSlice/fco.types";

export const useUpdatePortfolioLevelFcos = () => {
  const [updatePortfolioLeveFcosLoading, setUpdatePortfolioLeveFcosLoading] =
    React.useState<boolean>(false);
  const [updatePortfolioLeveFcosError, setUpdatePortfolioLeveFcosError] =
    React.useState<string>("");

  const updatePortfolioLevelFcos = async (
    payLoad: FCOUPDATEPORTFOLIOLEVEL,

    callBack?: () => void
  ) => {
    try {
      setUpdatePortfolioLeveFcosLoading(true);
      setUpdatePortfolioLeveFcosError("");
      const response = await update_fco_portfolio_level_service(payLoad);

      if (response?.requestSuccessful) {
        showMessage("SUCCESSFUL", "Request is Pending approval", "success");
        if (callBack) {
          callBack();
        }
      }
    } catch (error: any) {
      const errorMessage = parseError(error);

      showMessage("Request Failed", errorMessage, "error");
      setUpdatePortfolioLeveFcosError(parseError(error));
    } finally {
      setUpdatePortfolioLeveFcosLoading(false);
    }
  };

  const clearUpdatePortfolioLevelError = () => {
    setUpdatePortfolioLeveFcosError("");
  };
  return {
    updatePortfolioLeveFcosError,
    updatePortfolioLeveFcosLoading,
    updatePortfolioLevelFcos,
    clearUpdatePortfolioLevelError
  };
};
