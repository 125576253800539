import config from "../../../../config";
import server from "../../../../server";
import { initPagination } from "../../../../utils/pagination";
import { WorkflowCategory } from "../workflowSlice/workflowSlice.types";


const WORKFLOW_BASE_URL = '/workflows'
export const PENDING_REQUESTS = `${WORKFLOW_BASE_URL}/pending-requests`


export const WORKFLOW_BASE_URL_MAP = {
    [WorkflowCategory.WORKING_CAPITAL_LOANS]: '/workflows',
    [WorkflowCategory.PORTFOLIO_MANAGEMENT]: `${config.WCL_PORTFOLIO_BASE_URL}/workflows`,
    [WorkflowCategory.WORKING_CAPITAL_LOANS_BO]: `${config.WCL_LOANS_BO_BASE_URL}/workflows`,
}

export const PENDING_REQUESTS_URL_MAP = {
    [WorkflowCategory.WORKING_CAPITAL_LOANS]:
        `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.WORKING_CAPITAL_LOANS]}/pending-requests`,
    [WorkflowCategory.PORTFOLIO_MANAGEMENT]:
    `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.PORTFOLIO_MANAGEMENT]}/pending-requests`,
    [WorkflowCategory.WORKING_CAPITAL_LOANS_BO]:
        `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.WORKING_CAPITAL_LOANS_BO]}/pending-requests`
}

export const COMPLETED_REQUESTS_URL_MAP = {
    [WorkflowCategory.WORKING_CAPITAL_LOANS]:
        `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.WORKING_CAPITAL_LOANS]}/checked-requests`,
    [WorkflowCategory.PORTFOLIO_MANAGEMENT]:
    `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.PORTFOLIO_MANAGEMENT]}/checked-requests`,
    [WorkflowCategory.WORKING_CAPITAL_LOANS_BO]:
        `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.WORKING_CAPITAL_LOANS_BO]}/checked-requests`
}

export const WORKFLOW_TYPES_URL_MAP = {
    [WorkflowCategory.WORKING_CAPITAL_LOANS]:
        `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.WORKING_CAPITAL_LOANS]}/types`,
    [WorkflowCategory.PORTFOLIO_MANAGEMENT]:
    `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.PORTFOLIO_MANAGEMENT]}/types`,
    [WorkflowCategory.WORKING_CAPITAL_LOANS_BO]:
        `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.WORKING_CAPITAL_LOANS_BO]}/types`,
    
}

export const PROCESS_WORKFLOW_APPROVAL_URL_MAP = {
    [WorkflowCategory.WORKING_CAPITAL_LOANS]:
        `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.WORKING_CAPITAL_LOANS]}/process-workflow-request`,
    [WorkflowCategory.PORTFOLIO_MANAGEMENT]:
        `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.PORTFOLIO_MANAGEMENT]}/process-workflow-request`,
    [WorkflowCategory.WORKING_CAPITAL_LOANS_BO]:
    `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.WORKING_CAPITAL_LOANS_BO]}/process-workflow-request`,
}

export const PROCESS_WORKFLOW_REJECTION_URL_MAP = {
    [WorkflowCategory.WORKING_CAPITAL_LOANS]:
        `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.WORKING_CAPITAL_LOANS]}/process-workflow-request`,
    [WorkflowCategory.PORTFOLIO_MANAGEMENT]:
        `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.PORTFOLIO_MANAGEMENT]}/process-workflow-request`,
    [WorkflowCategory.WORKING_CAPITAL_LOANS_BO]:
    `${WORKFLOW_BASE_URL_MAP[WorkflowCategory.WORKING_CAPITAL_LOANS_BO]}/process-workflow-request`,
}


export const api_fetch_workflow_requests = async (
    url: string,
    searchParams: Record<string, any> = {},
    pagination: Record<string, any> = initPagination
) => {
    let page = pagination.current - 1;

    const response = await server.get(
        url,
        {
            params: { ...searchParams, pageNumber: page, pageSize: pagination.pageSize }
        },
    )

    return response.data
}

export const api_fetch_workflow_types = async (category: WorkflowCategory) => {
    const response = await server.get(WORKFLOW_TYPES_URL_MAP[category])

    return response.data
}

export const api_process_workflow = async (
    category: WorkflowCategory, shouldApprove: boolean, comment: string, requestId: string | number,
) => {
    const request = { approved: shouldApprove, authorizersComment: comment }
    const urlMap = shouldApprove ? PROCESS_WORKFLOW_APPROVAL_URL_MAP : PROCESS_WORKFLOW_REJECTION_URL_MAP
    
    const response = await server.put(
        `${urlMap[category]}/${encodeURIComponent(requestId)}`,
        request
    )

    return response.data;
}
