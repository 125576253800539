import { Card, Select, Space, Transfer, Typography } from "antd";
import type { TransferDirection } from "antd/es/transfer";
import React from "react";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  fetchCountries,
  fetchLGAsByStateCode,
  fetchStatesByCountryCode,
  resetLgasByStateCode,
  resetStatesByCountryCode,
} from "../../Utils/UtilsSlice/utilsSlice.thunks";
import {
  activateLGAs,
  deactivateLGAs,
  fetchActiveLGAs,
} from "../lgaActivationSlice/lgaActivationSlice.thunk";

const breadCrumbItems: BreadCrumbItem[] = [
  { title: "Dashboard", path: DashboardRoutePaths.home.path },
  { title: "LGA Activation", active: true },
];

const LgaActivationPage: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const {
    utils: {
      countries,
      countriesLoading,
      statesByCountryCode,
      statesByCountryCodeLoading,
      LGAsByState,
      LGAsByStateLoading,
    },
    lgaActivation: {
      activeLGAs,
      activeLGAsLoading,
      activateLGAsLoading,
      deactivateLGAsLoading,
    },
  } = useAppSelector((state) => state);

  const [targetKeys, setTargetKeys] = React.useState<string[]>([]);

  React.useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  // TODO: uncomment this
  React.useEffect(() => {
    dispatch(fetchActiveLGAs());
  }, [dispatch]);

  React.useEffect(() => {
    if (activeLGAs) {
      setTargetKeys(
        LGAsByState.filter((lga) => activeLGAs.includes(lga.code)).map(
          (lga) => lga.code
        )
      );
    }
  }, [LGAsByState, activeLGAs]);

  function handleCountryChange(value: string) {
    if (value) {
      dispatch(resetStatesByCountryCode());
      dispatch(resetLgasByStateCode());
      dispatch(fetchStatesByCountryCode(value));
    }
  }

  function handleStateChange(value: string) {
    if (value) {
      dispatch(resetLgasByStateCode());
      dispatch(fetchLGAsByStateCode(value));
    }
  }

  async function handleLgaChange(
    newTargetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) {
    if (direction === "right") {
      dispatch(activateLGAs(moveKeys));
    } else {
      dispatch(deactivateLGAs(moveKeys));
    }

    setTargetKeys(newTargetKeys);
  }

  function renderFooter({ direction }: any) {
    if (direction === "left") {
      return !deactivateLGAsLoading ? null : (
        <Typography className="text-center">Deactivating...</Typography>
      );
    }

    return activateLGAsLoading ? (
      <Typography className="text-center">Activating...</Typography>
    ) : null;
  }

  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Card
          title={`Pick Location ${
            activeLGAsLoading ? "(Fetching Active LGAs...)" : ""
          }`}
        >
          <Select
            placeholder="Select a Country"
            optionFilterProp="children"
            options={countries.map((country) => ({
              label: country.name,
              value: country.code,
            }))}
            onChange={handleCountryChange}
            filterOption={(input, option) =>
              (option?.label as string)
                ?.toLowerCase()
                .includes(input.toLowerCase())
            }
            className="w-[12rem] mr-3"
            loading={countriesLoading || activeLGAsLoading}
            disabled={!activeLGAs?.length}
            showSearch
          />
          <Select
            placeholder="Select a State"
            optionFilterProp="children"
            options={(statesByCountryCode || []).map((state) => ({
              label: state.stateName,
              value: state.stateCode,
            }))}
            onChange={handleStateChange}
            filterOption={(input, option) =>
              (option?.label as string)
                ?.toLowerCase()
                .includes(input.toLowerCase())
            }
            className="w-[12rem]"
            loading={statesByCountryCodeLoading}
            disabled={!statesByCountryCode?.length}
            showSearch
          />
        </Card>
        <Card
          title={`Activate LGAs ${LGAsByStateLoading ? "(Loading...)" : ""}`}
        >
          <Transfer
            dataSource={LGAsByState.map((lga) => ({ ...lga, key: lga?.code }))}
            listStyle={{ width: 300, height: 400 }}
            operations={["ACTIVATE", "DEACTIVATE"]}
            targetKeys={targetKeys}
            titles={["Unactivated LGAs", "Activated LGAs"]}
            render={(item) => `${item.name}`}
            onChange={handleLgaChange}
            footer={renderFooter}
            disabled={
              !LGAsByState?.length || deactivateLGAsLoading || activateLGAsLoading
            }
            showSearch
          />
        </Card>
      </Space>
    </Page>
  );
};

export default LgaActivationPage;
