import { Avatar, Badge, Dropdown } from "antd";
import { CaretDownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import React, { FC } from "react";
import { useAppDispatch } from "../../store";
import { logout } from "../../modules/Auth/authSlice/authSlice.thunks";



const TopBar: FC = () => {
    const dispatch = useAppDispatch()

    const userMenuItems = [
        {
            key: 'item-1',
            label: (
                <div className="w-[180px] px-2 py-4 space-x-4">
                    <UserOutlined />
                    <span>Profile</span>
                </div>
            ),
        },
        {
            key: 'item-2',
            label: (
                <div
                    className="w-[180px] px-2 py-4 space-x-4 border-t border-x-0 border-b-0 border-t-[#e4e9f0] border-solid"
                    onClick={() => dispatch(logout())}
                >
                    <LogoutOutlined />
                    <span>Logout</span>
                </div>
            ),
        },
    ];
    
    return (
        <div className="flex items-center justify-end">
            <div>
                <Dropdown
                   menu={{ items: userMenuItems, }}
                    trigger={['click']}
                    placement="bottomRight"
                    className="p-0"
                >
                    <Badge count={0} showZero className="cursor-pointer" offset={[-12, 0]}>
                        <Avatar className="bg-[#e4e9f0]" shape="square" size="large" icon={<UserOutlined />}  />
                        <CaretDownOutlined className="text-[#e4e9f0]" />
                    </Badge>
                </Dropdown>
            </div>
        </div>
    )
}

export default TopBar
