import { createSlice } from "@reduxjs/toolkit";
import { ILgaActivation } from "./lgaActivationSlice.types";

const initialState: ILgaActivation = {
  activeLGAsLoading: false,
  activeLGAs: null,
  activeLGAsError: "",
  activateLGAsLoading: false,
  activateLGAsError: "",
  deactivateLGAsLoading: false,
  deactivateLGAsError: "",
};

const lgaActivationSlice = createSlice({
  name: "lgaActivation",
  initialState,
  reducers: {
    setActiveLGAsLoading: (state, action) => {
      state.activeLGAsLoading = action.payload;
    },
    loadActiveLGAs: (state, action) => {
      state.activeLGAs = action.payload;
      state.activeLGAsError = "";
    },
    setActiveLGAsError: (state, action) => {
      state.activeLGAsError = action.payload;
    },
    setActivateLGAsLoading: (state, action) => {
      state.activateLGAsLoading = action.payload;
    },
    setActivateLGAsError: (state, action) => {
      state.activateLGAsError = action.payload;
    },
    setDeactivateLGAsLoading: (state, action) => {
      state.deactivateLGAsLoading = action.payload;
    },
    setDeactivateLGAsError: (state, action) => {
      state.deactivateLGAsError = action.payload;
    },
    reset: (state) => {
      // state.fcoData = null;
      // state.loading = false;
      // state.error = "";
      // state.fcoDetailData = null;
      // state.success = false;
    },
  },
});

export const {
  setActiveLGAsLoading,
  loadActiveLGAs,
  setActiveLGAsError,
  setActivateLGAsLoading,
  setActivateLGAsError,
  setDeactivateLGAsLoading,
  setDeactivateLGAsError,
} = lgaActivationSlice.actions;

const lgaActivationReducer = lgaActivationSlice.reducer;

export default lgaActivationReducer;
