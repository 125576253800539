import { Avatar, Card, Descriptions, Empty, Spin, Tag, Typography } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import { parsePhoneNumberWithCountryCode } from "../../../../utils/dataParser";
import { useQueryStateCoordinatorDetails } from "./hooks/useQueryStateCoordinatorDetails";
import { useFileRef } from "../../Utils/FilesUtils/hooks/useFileRef";

const breadCrumbItems: BreadCrumbItem[] = [
    {
        title: "Dashboard",
        path: DashboardRoutePaths.home.path,
    },
    {
        title: "State Coordinators",
        path: DashboardRoutePaths.viewStateCoordinators.path,
    },
    {
        title: "State Coordinator Details",

        active: true,
    },
];

const StateCoordinatorsDetails = () => {
    const params = useParams<{ "*": string; id: string }>();


    const {
        stateCoordinatorDetails,
        stateCoordinatorDetailsLoading,
    } = useQueryStateCoordinatorDetails({
        payload: { id: params?.id as string }
    })

    const {
        image
    } = useFileRef(stateCoordinatorDetails?.imageRef)


    return (
        <>
            <Page breadCrumbItems={breadCrumbItems}>
                <Card
                    className="my-6"
                    title={
                        <Typography.Title level={4}>
                            State Coordinator Details
                        </Typography.Title>
                    }
                >
                    {stateCoordinatorDetailsLoading ? (
                        <section className="flex items-center justify-center">
                            <Spin tip="Loading Details..." />
                        </section>
                    ) : stateCoordinatorDetails ? (
                        <Descriptions
                            layout="vertical"
                            column={{ xs: 1, md: 2, xl: 3 }}
                        >
                            <Descriptions.Item
                                label={
                                    <Typography.Text strong>
                                        Passport Photo
                                    </Typography.Text>
                                }
                                span={3}
                            >
                                <Avatar src={image} shape="square" size={200} icon="" />
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Typography.Text strong>
                                        First Name
                                    </Typography.Text>
                                }
                            >
                                {stateCoordinatorDetails.firstName}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Typography.Text strong>
                                        Middle Name
                                    </Typography.Text>
                                }
                            >
                                {""}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Typography.Text strong>
                                        Last Name
                                    </Typography.Text>
                                }
                            >
                                {stateCoordinatorDetails.lastName}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Typography.Text strong>
                                        Email
                                    </Typography.Text>
                                }
                            >
                                {stateCoordinatorDetails.email}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Typography.Text strong>
                                        Phone Number
                                    </Typography.Text>
                                }
                            >
                                {parsePhoneNumberWithCountryCode(stateCoordinatorDetails.phone)}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Typography.Text strong>
                                        State
                                    </Typography.Text>
                                }
                            >
                                {stateCoordinatorDetails.state}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <Typography.Text strong>
                                        Enable As Portfolio Manager
                                    </Typography.Text>
                                }
                            >
                                {stateCoordinatorDetails.enabledAsPm ? (
                                    <Tag color="success">Enabled</Tag>
                                ) : (
                                    <Tag color="warning">Not Enabled</Tag>
                                )}
                            </Descriptions.Item>
                        </Descriptions>
                    ) : (
                        <section className="centered-container">
                            <Empty />
                        </section>
                    )}
                </Card>
            </Page>
        </>
    )
}


export default StateCoordinatorsDetails
