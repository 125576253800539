import config from "../../../../config";
import server from "../../../../server";
import { initPagination } from "../../../../utils/pagination";

export const get_all_provisioning_template_service = async (
  pagination: Record<string, any> = initPagination
) => {
  let page = pagination.current -1;
  const response = await server.get(`${config.WCL_MANAGEMENT_BASE_URL}/wcl/template`, {
    params: {  pageSize: pagination.pageSize, pageNo: page },
  });

  return response.data;
};


 