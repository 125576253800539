import { Button, Card, Col, Form, Row, Select } from "antd";
import React, { useEffect } from "react";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { IPortfolioManagementSettingsParams, PortfolioManagementSettingsEntryKey } from "../portfolioApi/portfolioAPI.types";
import { fetchPortfolioLevels, updatePortfoliomanagementSettings } from "../portfolioSlice/portofolio.thunks";


const FormItem = Form.Item
const { Option } = Select



const breadCrumbItems: BreadCrumbItem[] = [
    {
        title: "Dashboard",
        path: DashboardRoutePaths.home.path,
    },
    {
        title: "Portfolio Management Settings",
        active: true,
    }
]

const PortfolioManagementSettings = () => {
    const dispatch = useAppDispatch()
    const { portfolioLevels, settingsUpdateLoading } = useAppSelector(state => state.portFolioReducer)
    const [form] = Form.useForm()

    useEffect(() => {
        dispatch(fetchPortfolioLevels())
    }, [dispatch])


    const onFinish = (values: Record<PortfolioManagementSettingsEntryKey, string | number>) => {
        const validFields: Partial<Record<PortfolioManagementSettingsEntryKey, string | number>> = {}
        let field: PortfolioManagementSettingsEntryKey

        for (field in values) {
            if (values[field]) {
                validFields[field] = values[field]
            }
        }

        const settingsEntries = Object
            .entries(validFields)
            .map((entry) => ({ key: entry[0], value: entry[1] }))

        dispatch(updatePortfoliomanagementSettings({settingsEntries} as IPortfolioManagementSettingsParams))
    };
    const onFinishFailed = (errorInfo: any) => {
        // 
    };


    return (
        <Page breadCrumbItems={breadCrumbItems}>
            <Card title="Portfolio Management Settings" className="w-full">
                <Form
                    layout="vertical"
                    className="w-full pb-8"
                    form={form}
                    name="portfolio-management-settings-update-form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <section className="w-full space-y-4 mb-6">
                    <Row gutter={24}>
                        <Col xs={24} sm={12} md={8}>
                            <FormItem
                                label="Default FCO Portfolio Level code"
                                name="DEFAULT_FCO_PORTFOLIO_LEVEL_CODE"
                                rules={[{ required: true, message: "This field is required" }]}
                            >
                                <Select placeholder="Select Code" size="large">
                                    {
                                        portfolioLevels.map(item => (
                                            <Option key={item.code} value={item.code}>
                                                {item.code}
                                            </Option>
                                        ))}
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    </section>

                            <FormItem shouldUpdate>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="w-[150px] bg-[#0190fe] border-[#0190fe] rounded"
                                    loading={settingsUpdateLoading}
                                >
                                    Submit Request
                                </Button>
                            </FormItem>
                </Form>
            </Card>
        </Page>
    )
}


export default PortfolioManagementSettings

