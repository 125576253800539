import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Table, Button, Form, Input, Row, Col, Space } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { showMessage } from "../../../../utils/notifications";
import { initPagination } from "../../../../utils/pagination";
import { getAllLoanPrograms } from "../loanProgramsSlice/loanPrograms.thunks";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";

interface Props {
  searchParams?: any;
}

const FormItem = Form.Item;

const ViewLoanPrograms: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const {
    loanProgramsReducer: {
      loanProgramsAllData,
      loanProgramAllLoading,
      loanProgramsAllError,
      loanProgramsPagination,
    },
  } = useAppSelector((state) => state);
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(getAllLoanPrograms());
    }
  }, [dispatch]);

  useEffect(() => {
    if (loanProgramsAllError) {
      showMessage("Loan Programs", loanProgramsAllError, "error");
    }
  }, [loanProgramsAllError]);

  const filterProvisioningTemplates = () => {
    const formValues = form.getFieldsValue();

    let searchParams = Object.keys(formValues).reduce(
      (reducedObject: any, key: any) => {
        if (formValues[key]) {
          reducedObject[key] = formValues[key];
        }
        return reducedObject;
      },
      {}
    );
    setSearchParams(searchParams);
    dispatch(getAllLoanPrograms(searchParams, initPagination));
  };
  const onClearForm = () => {
    form.resetFields();
    dispatch(getAllLoanPrograms());
  };
  const handleTableChange = (pagination: any) => {
    fetchData(searchParams, pagination);
  };

  const fetchData = (searchParams?: any, pagination?: any) => {
    dispatch(getAllLoanPrograms(searchParams, pagination));
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value: string) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (value: string) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "Business Type",
      dataIndex: "businessType",
      key: "businessType",
      render: (value: string) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "Loan Type",
      dataIndex: "loanType",
      key: "loanType",
      render: (value: string) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (value: number) => {
        return (
          <Space size="middle">
            <Link to={`/dashboard/loan-program/details/${value}`}>
              <Button
                type="primary"
                className="bg-[#0190fe] border-[#0190fe] rounded"
              >
                View Details
              </Button>
            </Link>
            <Link
              to={DashboardRoutePaths.editLoanProgram.path.replace(
                ":id",
                value.toString()
              )}
            >
              <Button
                type="primary"
                className="bg-[#0190fe] border-[#0190fe] rounded"
              >
                Edit
              </Button>
            </Link>
          </Space>
        );
      },
    },
  ];

  const renderFilterForm = () => {
    return (
      <Form layout="vertical" form={form} name="filter-fco">
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6}>
            <FormItem label="Name" name={"name"} initialValue="">
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>

          <Col xs={24} sm={12} md={6} style={{ height: "20px" }}>
            <FormItem label="Code" name={"code"} initialValue="">
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>

          <Col xs={24} sm={12} md={6} style={{ height: "20px" }}>
            <FormItem
              label="Businness Type"
              name={"businessType"}
              initialValue=""
            >
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>

          <Col xs={24} sm={12} md={6} style={{ height: "20px" }}>
            <FormItem label="Loan Type" name={"loanType"} initialValue="">
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col xs={24} sm={12} md={3}>
            <Button
              type="primary"
              className="w-full rounded"
              onClick={onClearForm}
              danger
            >
              Clear
            </Button>
          </Col>
          <Col xs={24} sm={12} md={4}>
            <Button
              type="primary"
              onClick={filterProvisioningTemplates}
              className="w-full bg-[#0190fe] border-[#0190fe] rounded"
              htmlType="submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <>
      <Card title="Loan Programs" className="my-[18px]">
        {renderFilterForm()}
      </Card>
      <Card
        title="Loan Programs"
        extra={<CreateLoanProgramButton />}
        className="my-[18px] w-full"
      >
        <Table
          className="w-full overflow-x-auto"
          dataSource={loanProgramsAllData}
          columns={tableColumns}
          rowKey={(record) => record.id}
          loading={loanProgramAllLoading}
          pagination={loanProgramsPagination}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

function CreateLoanProgramButton() {
  return (
    <Link to={DashboardRoutePaths.createLoanProgram.path}>
      <Button type="primary">Create Program</Button>
    </Link>
  );
}

export default ViewLoanPrograms;
