import React, { FC, useEffect, useMemo } from "react";
import { ViewerProps } from "../../workflowSlice/workflowSlice.types";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { fetchLGAsByStateCode, fetchStates } from "../../../Utils/UtilsSlice/utilsSlice.thunks";
import { Alert, Descriptions, Empty, List, Spin, Typography } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useFileRef } from "../../../Utils/FilesUtils/hooks/useFileRef";
import MoniepointUserAccountDetailsView from "../../../../../components/MoniepointUserAccountDetailsView";
import { ILocalGovernment, IState } from "../../../Utils/UtilsApi/utilsAPI.types";
import { useQueryMoniepointUserAccountDetails } from "../../../Utils/hooks/moniepointUserAccount";




const MoniepointUsersOnboarding: FC<ViewerProps> = ({
    src,
}) => {
    const dispatch = useAppDispatch()
    const {
        utils: {
            LGAsByState,
            states,
        }
    } = useAppSelector(state => state)

    const {
        moniepointUserAccountDetails,
        moniepointUserAccountDetailsError,
        moniepointUserAccountDetailsLoading,
        fetchMoniepointUserAccoutDetails
    } = useQueryMoniepointUserAccountDetails({
        payload: { accountNumber: src?.request?.accountNumber }
    })

    useEffect(() => {

        if (src?.request?.stateCode && !states?.length) {
            dispatch(fetchStates())
        }

        if (src?.request?.stateCode && src?.request?.lgaCodes?.length) {
            dispatch(fetchLGAsByStateCode(src?.request?.stateCode))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src?.request?.accountNumber, src?.request?.stateCode, src?.request?.lgaCodes?.length])

    const assignedLGAs = useMemo(
        () => {
            const assignedLGAs: ILocalGovernment[] = []

            if (src.request?.lgaCodes?.length && LGAsByState?.length) {
                for (const code of src.request?.lgaCodes) {
                    const lgaObject = LGAsByState.find(lga => lga.code === code)

                    if (lgaObject) {
                        assignedLGAs?.push(lgaObject)
                    }
                }
            }

            return assignedLGAs
        },

        [LGAsByState, src]
    )

    const assignedState = useMemo(
        () => {
            let state: IState | null = null

            if (states?.length) {
                state = states?.find(item => item.stateCode === src?.request?.stateCode) as IState
            }

            return state
        },
        [src?.request?.stateCode, states]
    )

    const { image } = useFileRef(moniepointUserAccountDetails?.passportRef as string)

    return (
        <>
            {
                moniepointUserAccountDetailsLoading
                    ? (
                        <section className=" flex items-center justify-center py-10">
                            <Spin tip="Loading Details..." />
                        </section>
                    )
                    : moniepointUserAccountDetailsError
                        ? (
                            <Alert
                                description={
                                    <div className="flex items-center justify-center space-x-2">
                                        <span>
                                            An error occurred while fetching user details.
                                        </span>
                                        <span
                                            onClick={() => fetchMoniepointUserAccoutDetails(src?.request?.accountNumber)}
                                        >
                                            <ReloadOutlined />
                                        </span>
                                    </div>
                                }
                                type="error"
                                showIcon
                            />
                        )
                        : moniepointUserAccountDetails
                            ? (
                                <>
                                    <MoniepointUserAccountDetailsView
                                        user={moniepointUserAccountDetails}
                                        image={image}
                                    />
                                    {assignedState && (
                                        <Descriptions
                                            layout="vertical"
                                            column={{ md: 2, sm: 1, xs: 1 }}
                                        >
                                            <Descriptions.Item label={<strong>Assigned State</strong>}>
                                                {assignedState?.stateName}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    )}
                                    {Boolean(assignedLGAs?.length) && (
                                        <List
                                            header={<Typography.Title level={5}>Assigned LGAs</Typography.Title>}
                                            dataSource={assignedLGAs}
                                            renderItem={item => (
                                                <List.Item>
                                                    <Typography.Text>{item.code}</Typography.Text> - <Typography.Text >{item.name}</Typography.Text>
                                                </List.Item>
                                            )}

                                        />
                                    )}
                                </>
                            )
                            : (
                                <section className=" flex items-center justify-center py-8">
                                    <Empty description="No data found" />
                                </section>
                            )
            }
        </>
    )
}


export default MoniepointUsersOnboarding
