import React from "react";
import { LoanContractManagersDetails } from "../utils/types";
import { get_loan_contract_managers_service } from "../API";
import { showServerError } from "../../../../utils/notifications";
import { parseError } from "../../../../utils/server";



interface Props {
    contractRef: string
}

export const useQueryLoanContractManagersDetails = ({ contractRef }: Props) => {

    const [loading, setLoading] = React.useState(false);
    const [loanContractManagersDetails, setLoanContractManagersDetails] = React.useState<
        LoanContractManagersDetails | null
    >(null);
    const [error, setError] = React.useState("")


    const fetchContractManagersDetails = async (contractRef: string) => {
        try {
            setLoading(true);
            setError("");

            const response = await get_loan_contract_managers_service(contractRef);

            setLoanContractManagersDetails(response?.responseBody);
        } catch (error) {
            showServerError(error);
            setError(parseError(error))
        } finally {
            setLoading(false);
        }
    }


    React.useEffect(() => {
        if (contractRef) {
            fetchContractManagersDetails(contractRef)
        }
    }, [contractRef])


    return {
        error,
        loading,
        loanContractManagersDetails,
        fetchContractManagersDetails
    }

}