import numeral from "numeral";

export function parsePhoneNumberWithCountryCode(phoneNumber: string) {
  return phoneNumber && phoneNumber.trim()
    ? phoneNumber.replace(/^(\+)?234/, "0")
    : phoneNumber;
}

export function parseAmountToCurrencyValue(
  amount: number | string,
  currency = "₦"
) {
  let currencyValue = "";

  if (!isNaN(parseFloat(amount as string))) {
    currencyValue = `${currency}${numeral(amount).format("0,0.00")}`;
  }

  return currencyValue;
}

export function parseValuesToPercent(givenValue: number, totalValue: number) {
  return !parseFloat(totalValue.toString()) ||
    isNaN(parseFloat(givenValue.toString()))
    ? 0
    : parseFloat(Number((givenValue / totalValue) * 100).toFixed(2));
}

export function parseAddressObjectToString(addressObj: {
  city: string;
  lineOne: string;
  lineTwo: string;
}) {
  if (addressObj) {
    const { lineOne, lineTwo, city } = addressObj;

    return Object.values({ lineOne, lineTwo, city })
      .filter((itm) => Boolean(itm))
      .join(", ");
  } else {
    return "";
  }
}
export const convertStatusToHumanReadable = (status: string = "") => {
  return status.replaceAll("_", " ");
};
export function parseTableSearchParams(formValues: Record<string, any>) {
  let searchParams = formValues
    ? Object.keys(formValues).reduce((reducedObject: any, key: any) => {
        if (formValues[key]) {
          reducedObject[key] = formValues[key]?.trim
            ? formValues[key]?.trim()
            : formValues[key];
        }
        return reducedObject;
      }, {})
    : formValues;

  return searchParams;
}
export function convertDateToHumanReadableFrequency(
  value: number,
  unit: string
) {
  if (!value || !unit) return;
  let result = ``;
  switch (unit.toUpperCase()) {
    case "DAILY":
    case "DAYS":
      result += value === 1 ? "day" : "days";
      break;
    case "WEEKLY":
      result += value === 1 ? "week" : "weeks";
      break;
    case "MONTHLY":
      result += value === 1 ? "month" : "months";
      break;
    case "YEARLY":
      result += value === 1 ? "year" : "years";
      break;
    default:
      result = "Invalid unit";
  }
  return result;
}
