import React from "react"
import { ELoanContractProduct, LoanContractDetails, OverdraftContractDetails } from "../utils/types";
import { get_loan_contract_details, get_ovd_loan_contract_details } from "../API";
import { showServerError } from "../../../../utils/notifications";


interface Params {
    ref: string
    loanProduct: ELoanContractProduct
}

const useQueryLoanContractDetails = ({
    ref,
    loanProduct,
}: Params) => {

    const [loading, setLoading] = React.useState(false);
    const [contractDetails, setContractDetails] = React.useState<LoanContractDetails | null>(null);
    const [overdraftContractDetails, setOverdraftContractDetails] = React.useState<OverdraftContractDetails | null>(null);


    const fetchLoanContractDetails = async (ref: string) => {
        try {
            setLoading(true)

            const response = await get_loan_contract_details(ref);

            setContractDetails(response?.responseBody);
        } catch (error) {
            showServerError(error)
        } finally {
            setLoading(false);
        }
    }

    const fetchOverdraftLoanContractDetails = async (ref: string) => {
        try {
            setLoading(true)

            const response = await get_ovd_loan_contract_details(ref);

            setOverdraftContractDetails(response?.responseBody);
        } catch (error) {
            showServerError(error)
        } finally {
            setLoading(false);
        }
    }


    React.useEffect(() => {
        if (loanProduct === ELoanContractProduct.WORKING_CAPITAL) {
            fetchLoanContractDetails(ref)
        } else if (loanProduct === ELoanContractProduct.OVERDRAFT) {
            fetchOverdraftLoanContractDetails(ref)
        }
    }, [ref, loanProduct])


    return {
        loading,
        contractDetails,
        overdraftContractDetails,
        fetchLoanContractDetails,
        fetchOverdraftLoanContractDetails,
    }

}

export default useQueryLoanContractDetails;
