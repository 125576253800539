import server from "../../../../server";
import { initPagination } from "../../../../utils/pagination";


export const get_all_users_service = async (
  searchParams: Record<string, any> = {},
  pagination: Record<string, any> = initPagination
) => {
  let page = pagination.current - 1;
  const response = await server.get(`/users`, {
    params: { ...searchParams, page: page, size: pagination.pageSize }
  });

  return response.data;
};

export const send_activation_link_service = async (
  email: string
) => {
  const response = await server.post(`/users/resend-activation-email/${email}`);

  return response.data;
};



export const get_user_details_username_service = async (username: string) => {
  const response = await server.get(`/users/username/${encodeURIComponent(username)}`);

  return response.data;
};
