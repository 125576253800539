import { Button } from "antd";
import { Link } from "react-router-dom";
import { parseAmountToCurrencyValue } from "../../../../utils/dataParser";

export const FCOTableColumns = [
  {
    title: "FCO Name",
    dataIndex: "fcoName",
    key: "fcoName",
    render: (value: string) => {
      return <div>{value}</div>;
    },
  },
  {
    title: "Current Loan Portfolio",
    dataIndex: "currentLoanPortfolio",
    key: "currentLoanPortfolio",
    render: (value: string) => {
      return <div>{parseAmountToCurrencyValue(value,"₦")}</div>;
    },
  },
  {
    title: "Maximum Loan Portfolio Limit",
    dataIndex: "maximumLoanPortfolio",
    key: "maximumLoanPortfolio",
    render: (value: string) => {
      return <div>{parseAmountToCurrencyValue(value,"₦")}</div>;
    },
  },
  {
    title: "",
    dataIndex: "fcoCode",
    key: "fcoCode",
    render: (value: string, row: any) => {
      return (
        <Link to={`/dashboard/fco-management/details/${value}`}>
          <Button
            type="primary"
            className="bg-[#0190fe] border-[#0190fe] rounded"
          >
            View Details
          </Button>
        </Link>
      );
    },
  },
];
