import config from "../../../../config";
import server from "../../../../server";
import { IResponse } from "../../../../server/server.types";
import { initPagination } from "../../../../utils/pagination";
import { LoanAgreement } from "../utils/types";




export const get_all_loan_contracts_service = async (
    searchParams: Record<string, any> = {},
    pagination: Record<string, any> = initPagination
) => {
    let page = pagination.current - 1;

    const response = await server.get(`${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts`, {
        params: { ...searchParams, page: page, size: pagination.pageSize },
    });

    return response.data as IResponse<any>;
};

export const get_loan_contract_details = async (ref: string) => {

    const response = await server.get(`${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts/${ref}`);

    return response.data as IResponse<any>
}

export const get_ovd_loan_contract_details = async (ref: string) => {

    const response = await server.get(`${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts/overdraft/${ref}`);

    return response.data as IResponse<any>
}
export const get_loan_agreement = async (ref: string) => {

    const response = await server.get(`${config.WCL_LOANS_BO_BASE_URL}/agreement/${ref}`);

    return response.data as LoanAgreement[]
}
export const get_loan_agreement_file = async (ref: string) => {

    const response = await server.get(`${config.WCL_LOANS_BO_BASE_URL}/agreement/${ref}/document`, {
        responseType: 'blob',
    });
    return response.data
}

export const get_loan_contract_managers_service = async (ref: string) => {
    const response = await server.get(`${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts/${ref}/managers`);

    return response.data as IResponse<any>
}

export const reassign_loan_contract_participant = async (params: {
    officerType: string
    officerCode: string;
    reason: string;
    contractReference: string
}) => {
    const response = await server.post(
        `${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts/reassign`,
        params
    )

    return response.data;
}
