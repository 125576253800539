import React, { FC, useEffect, useState } from "react";
import { find } from "lodash";
import { Descriptions, Empty, Spin, Tree } from "antd";

import { useAppDispatch, useAppSelector } from "../../../../../store";
import {
  getFcosDetails,
  getAllLgs,
} from "../../../FCOManagement/fcoSlice/fco.thunks";

interface UnAssignLGAsToFCOWorkFlowProps {
  src: any;
}

const UnAssignLGAsToFCOWorkFlow: FC<UnAssignLGAsToFCOWorkFlowProps> = ({
  src,
}) => {
  const dispatch = useAppDispatch();
  const {
    fcos: { fcoDetailData, loading, allLgaData },
  } = useAppSelector((state) => state);

  const [assignedLga, setAssignedLga] = useState<any[]>([]);
  const [removeLga, setRemoveLga] = useState<any[]>([]);
  const saveLgsAndStateCode = (lgaData: [], searchArry: [], mode: string) => {
    const data: any = [];
    const removeData: any = [];
    if (mode === "assigned") {
      if (fcoDetailData?.lgaCodes) {
        for (let i = 0; i < searchArry.length; i++) {
          data.push(find(lgaData, { code: searchArry[i] }));
        }
      }
      if (data.length > 0) {
        setAssignedLga(data);
      }
    } else {
      for (let i = 0; i < searchArry.length; i++) {
        removeData.push(find(lgaData, { code: searchArry[i] }));
      }

      if (removeData.length > 0) {
        setRemoveLga(removeData);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && src && src.request && src.request.fcoCode) {
      dispatch(getAllLgs());
      dispatch(getFcosDetails(src.request.fcoCode));
    }
  }, [src, dispatch]);
  useEffect(() => {
    if (allLgaData && fcoDetailData) {
      saveLgsAndStateCode(allLgaData, fcoDetailData.lgaCodes, "assigned");

      saveLgsAndStateCode(allLgaData, src.request.lgaCodes, "removed");
    }
    // eslint-disable-next-line
  }, [allLgaData, fcoDetailData]);

  return (
    <React.Fragment>
      {loading ? (
        <section className="centered-container">
          <Spin tip="Loading Details..." />
        </section>
      ) : fcoDetailData ? (
        <>
          <Descriptions title="Request Details" layout="vertical">
            <Descriptions.Item label="User Name">
              {fcoDetailData?.username}
            </Descriptions.Item>
            <Descriptions.Item label="First Name">
              {fcoDetailData?.firstName}
            </Descriptions.Item>
            <Descriptions.Item label="Last Name">
              {fcoDetailData?.lastName}
            </Descriptions.Item>

            <Descriptions.Item label="Field Credit Officer Assigned LGAs">
              <Tree
                treeData={assignedLga.map((lga) => ({
                  title: lga.name,
                  key: lga.id,
                  children: [
                    { key: lga.code, title: lga.code },
                    { key: `${lga.state}-${lga.code}`, title: lga.state },
                  ],
                }))}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Field Credit Officer Removed LGAs">
              <Tree
                treeData={removeLga.map((lga) => ({
                  title: lga.name,
                  key: lga.id,
                  children: [
                    { key: lga.code, title: lga.code },
                    { key: `${lga.state}-${lga.code}`, title: lga.state },
                  ],
                }))}
              />
            </Descriptions.Item>
          </Descriptions>
        </>
      ) : (
        <section className="centered-container">
          <Empty />
        </section>
      )}
    </React.Fragment>
  );
};

export default UnAssignLGAsToFCOWorkFlow;
