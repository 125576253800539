import React, { FC, ReactNode} from "react";
import { Button, Typography } from "antd";

interface DeactivatePersonelProps {
  title: string;
  personelName?: string;
  onBack: () => void;
  onAccept: () => void;
  loading: boolean;
  usedCase: string;
  children?: ReactNode;
  errorTemplate?: ReactNode;
}
export enum EPERSONELACTIONS {
  FCODEACTIVATING = "FCODEACTIVATING"
}
export const ActionsPersonelComponent: FC<DeactivatePersonelProps> = ({
  title,
  onAccept,
  onBack,
  loading,
  errorTemplate,
  children
}) => {
  return (
    <>
      {!loading ? <><div className="flex justify-center">
        <Typography.Text strong></Typography.Text>
        {title}
      </div>
        {errorTemplate && <div>
          {errorTemplate}
        </div>}
        <div className="flex justify-center mt-[20px]">
          <Button
            type="primary"
            className=" border-[#0190fe] rounded mr-4"
            onClick={onBack}
            danger

          >
            No
          </Button>
          <Button
            type="primary"
            className="bg-[#0190fe] border-[#0190fe] rounded"
            onClick={onAccept}
          >
            Yes
          </Button>
        </div></> : children}
    </>
  );
};
 
 
