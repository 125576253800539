import { createSlice } from '@reduxjs/toolkit';
import { initPagination } from '../../../../utils/pagination';
import { IFcos } from './fco.types';

const initialState: IFcos = {
    fcoData: null,
    fcoDataPagination: initPagination,
    error: "",
    loading: false,
    fcoDetailData: null,
    fcoDetailError:"",
    success: false,
    enableFcoSuccess: false,
    enableFcoLoading: false,
    enableFcoErorr: "",
    unAssignedFcoSuccess: false,
    unAssignedFcoLoading: false,
    unAssignedFcoErorr: "",
    targetKeys: null,
    allLgaData:null,

    onboardFCOError: "",
    onboardFCOLoading: false,
};

const fcoSlice = createSlice({
    name: "fcodetails",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },

        saveAllFcoData: (state, action) => {

            state.fcoData = action.payload?.responseBody?.content
            state.error = ""
        },
        saveAllFcoDataPagination: (state, action) => {
            state.fcoDataPagination = action.payload
        },
        setAllFcosDataError: (state, action) => {
            state.error = action.payload
            state.success = false
        },
        saveFcoDetails: (state, action) => {

            state.fcoDetailData = action.payload?.responseBody
        },
        saveFcoDetailsError: (state, action) => {

            state.fcoDetailError= action.payload
        },
        enableFcoSuccessAction: (state,action) => {
        
            state.enableFcoSuccess =action.payload
        },
        enableFcoLoading: (state, action) => {

            state.enableFcoLoading = action.payload
        },
        enableFcoErorr: (state, action) => {
            state.enableFcoErorr = action.payload
        },
       unAssignedLgaFcoSuccessAction: (state,action) => {
        
            state.unAssignedFcoSuccess =action.payload
        },
        unAssignedLgaFcoLoading: (state, action) => {

            state.unAssignedFcoLoading = action.payload
        },
        unAssignedLgaFcoErorr: (state, action) => {
            state.unAssignedFcoErorr = action.payload
        },
        getTargetKeys: (state, action) => {
            state.targetKeys = action.payload
        },
        resetFcoEnableDetails: (state) => {
            state.enableFcoLoading = false
            state.enableFcoErorr = ""
            state.targetKeys = null
            state.enableFcoSuccess = false
        },
        resetUnAssignedLgaFcoDetails: (state) => {
            state.unAssignedFcoLoading  = false
            state.unAssignedFcoErorr = ""
            state.targetKeys = null
            state.unAssignedFcoSuccess = false
        },
        saveLgsData:(state,action)=>{
      state.allLgaData=action.payload?.responseBody
        },
        reset: (state) => {
            state.fcoData = null
            state.loading = false
            state.error = ""
            state.fcoDetailData = null
            state.success = false
        },

        setOnboardFCOLoading: (state, action) => {
            state.onboardFCOLoading = action.payload
        },
        setOnboardFCOError: (state, actiom) => {
            state.onboardFCOError = actiom.payload
        }
    }
})

export const { 
    setLoading, 
    saveAllFcoData,
    setAllFcosDataError,
    saveLgsData,
    reset, 
    saveFcoDetails, 
    enableFcoSuccessAction,
    enableFcoLoading,
    enableFcoErorr,
    getTargetKeys,
    resetFcoEnableDetails,
    saveFcoDetailsError,
    unAssignedLgaFcoSuccessAction,
    unAssignedLgaFcoLoading,
    unAssignedLgaFcoErorr,
    resetUnAssignedLgaFcoDetails,
    saveAllFcoDataPagination,

    setOnboardFCOError,
    setOnboardFCOLoading,
} = fcoSlice.actions;
const fcoReducer = fcoSlice.reducer


export default fcoReducer;