import { parseError } from "../../../../../utils/server";
import { showMessage } from "../../../../../utils/notifications";
import { trigger_business_verification } from "../../loanRequestApi";

export const triggerBusinessVerification = async (loanRequestReference: string) => {
    try {
        const response = await trigger_business_verification(loanRequestReference);
        if (response?.requestSuccessful) {
            showMessage(
                "Request Successful!", 
                "Successfuly sent request to re-trigger business Verification", 
                'success'
            );
        }
    } catch (error) {
        showMessage("Request Failed!", parseError(error), "error");
    }
}
