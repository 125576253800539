import React from "react";
import { Pagination, extractPaginationData, initPagination } from "../../../../utils/pagination";
import { get_all_state_cordinators_service} from "../stateCoordinatorApi";
import { parseError } from "../../../../utils/server";
import { showMessage } from "../../../../utils/notifications";



export interface StateCordinatorsQueryProps {
    payload: {
        searchParams?: Record<string, any>,
        pagination?: Pagination
    }
}

export const useQueryStateCordinators = (props: StateCordinatorsQueryProps) => {
    const {
        payload: {
            searchParams,
            pagination
        }
    } = props

    const [stateCordinatorsData, setStateCordinatorsData] = React.useState< any | null>(null);
    const [stateCoordinatorPagination, setStateCoordinatorPagination] = React.useState<Pagination>(initPagination);
    const [error, setError] = React.useState('')
    const [loading, setLoading] = React.useState(false)



    const getAllStatesCordinators= React.useCallback(async (
        searchParams?: Record<string, any>,
        pagination?: Pagination
    ) => {
            try {
                setError('')
                setLoading(true)

                const response = await get_all_state_cordinators_service(searchParams, pagination);
                const paginationData = extractPaginationData(response.responseBody)

                setStateCordinatorsData(response?.responseBody?.stateCoordinators)
                setStateCoordinatorPagination(paginationData)

                
            } catch (error: any) {
            
                showMessage("State Coordinators", parseError(error), "error");
                setError(parseError(error))
            } finally {
                setLoading(false)
            }

        }, [])

        
        React.useEffect(() => {

            getAllStatesCordinators(searchParams, pagination)

        }, [searchParams, pagination, getAllStatesCordinators])

        return {
            error,
            loading,
            stateCordinatorsData,
            stateCoordinatorPagination,
            getAllStatesCordinators,
        }

}