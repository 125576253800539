import { WorkflowCategory } from "../../utils/types";

const defaultResponseExtractor = (response: any) => {
    return { responseBody: response?.responseBody };
};
const wclBoResponseExtractor = (response: any) => {
    return { responseBody: response };
};

export const responseExtractorMap = {
    [WorkflowCategory.PORTFOLIO_MANAGEMENT]: defaultResponseExtractor,
    [WorkflowCategory.WORKING_CAPITAL_LOANS]: defaultResponseExtractor,
    [WorkflowCategory.WORKING_CAPITAL_LOANS_BO]: wclBoResponseExtractor,
}