import React from "react";
import  ViewPortfolioManagersTable from "../ViewPortfolioManagers";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";

const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "View Portfolio Managers",
    active: true,
  },
];

const ViewPortofolioManagers = () => {
  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <ViewPortfolioManagersTable/>
    </Page>
  );
};

export default ViewPortofolioManagers;
