import { Descriptions, Typography } from "antd";
import { IBusinessOwnerDetails } from "../../utils/types";

const { Text } = Typography;
type BusinessOwnerDetailsProps = {
  details?: IBusinessOwnerDetails;
};

const BusinessOwnerDetails = ({ details }: BusinessOwnerDetailsProps) => {
  return (
    <>
      <Descriptions layout="vertical" contentStyle={{ marginTop: '-16px' }}>
        <Descriptions.Item label={<Text strong>First Name</Text>}>
          {details?.firstName || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>Last Name</Text>}>
          {details?.lastName || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>Middle Name</Text>}>
          {details?.middleName || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>Phone Number</Text>}>
          {details?.phoneNumber || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>Email</Text>}>
          {details?.email || "N/A"}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
export default BusinessOwnerDetails;
