import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import LocalIcons from "../../../../../assets/SVGs";

type LoanOfferErrorDetailsProps = {
  isPermissionError?: boolean;
  errorMessage?: string;
  backProps?: {
    text?: string;
    show?: boolean;
  };
  retryProps?: {
    text?: string;
    onRetry?: () => void;
  };
};

const LoanOfferErrorDetails = ({
  isPermissionError = true,
  errorMessage,
  backProps = {
    text: "Go Back",
  },
  retryProps = {},
}: LoanOfferErrorDetailsProps) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const handleRetry = () => {
    if (retryProps?.onRetry) {
      retryProps.onRetry();
    }
  };
  const errorMessages = {
    errorTitle: isPermissionError ? "Permission Denied" : "Error occurred",
    errorDescription: isPermissionError
      ? errorMessage || "You do not have permission to view this loan offer"
      : errorMessage
  };
  

  return (
    <section className="flex flex-col items-center justify-center my-8  w-full h-full">
      <div className="w-1/2 bg-white flex items-center p-5 rounded-3xl justify-center flex-col pb-2">
        <img src={LocalIcons.ErrorIcon} alt="Error icon" />
        <div className="mt-2 font-medium text-lg">
          {errorMessages?.errorTitle}
        </div>
        <div className="mt-2 font-normal text-sm text-[#3B4454]">
          {errorMessages?.errorDescription}
        </div>
        <div className="flex sm:flex-row flex-col w-5/6 mt-6 justify-center items-center">
          {backProps?.text && backProps?.show && (
            <Button
              type="ghost"
              onClick={goBack}
              className="rounded-lg mb-2 border-[#C5D8FB] border-[1px] text-[#246df0] font-bold text-sm w-1/2 h-10"
            >
              {backProps?.text}
            </Button>
          )}
          {retryProps?.text && !isPermissionError && (
            <Button
              type="primary"
              className="rounded-lg mb-2 border-[#C5D8FB] border-[1px] text-white font-bold text-sm w-1/2 ml-2 h-10 bg-[#246DF0]"
              onClick={handleRetry}
            >
              {retryProps?.text}
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};
export default LoanOfferErrorDetails;
