import React from "react"
import { showServerError } from "../../../../utils/notifications";
import { parseError } from "../../../../utils/server";
import { ReassignmentMode } from "../../Utils/UtilsSlice/utilsSlice.types";
import { bulk_reassign_loan_requests } from "../fcoApi";

export const useReassignFCOLoanRequests = () => {
    const [loading, setLoading] = React.useState(false)


    interface ReassignFCOLoanRequestsParams {
        data: any;
        mode: ReassignmentMode
        callback({ requestSuccessful }: { requestSuccessful: boolean}): void
    }

    const reassignFCOLoanRequests = async({
        data,
        mode,
        callback,
    }: ReassignFCOLoanRequestsParams) => {
        try{
            setLoading(true)

            const response = await bulk_reassign_loan_requests(mode, data)

            callback(response)

        } catch (error) {
            callback({ requestSuccessful: false })
            showServerError(parseError(error))
        } finally {
            setLoading(false)
        }
    }


    return {
        loading,
        reassignFCOLoanRequests,
    }

}