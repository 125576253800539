import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Table, Button, Form, Input, Row, Col, Tag, Select } from "antd";
import { initPagination } from "../../../../utils/pagination";
import { statesInNigeria } from "../../../../utils/states-data";
import { parseTableSearchParams } from "../../../../utils/dataParser";
import { useQueryStateCordinators } from "./hook";

interface Props {
  searchParams?: any;
}

const FormItem = Form.Item;
const { Option } = Select;

const ViewStateCoordinatorsTable: React.FC<Props> = () => {
  const [form] = Form.useForm();

  const {
    stateCordinatorsData,
    loading,
    stateCoordinatorPagination,
    getAllStatesCordinators
  } = useQueryStateCordinators({ payload: {} });
  const [searchParams, setSearchParams] = useState({});

  const filtersStatesCordinators = () => {
    const formValues = form.getFieldsValue();

    let searchParams = parseTableSearchParams(formValues);

    setSearchParams(searchParams);
    getAllStatesCordinators(searchParams, initPagination);
  };
  const onClearForm = () => {
    form.resetFields();
    setSearchParams({})
    getAllStatesCordinators();
  };
  const handleTableChange = (pagination: any) => {
    fetchData(pagination, searchParams);
  };

  const fetchData = (pagination?: any, searchParams?: any) => {
    getAllStatesCordinators(searchParams, pagination);
  };

  const tableColumns = [
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstname",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastname",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Status",
      dataIndex: "enabledAsPm",
      key: "enabledAsPm",
      render: (value: boolean) =>
        value ? (
          <Tag color="success">Enabled</Tag>
        ) : (
          <Tag color="warning">Not Enabled</Tag>
        )
    },
    {
      title: "",
      dataIndex: "code",
      key: "code",
      render: (value: string) => {
        return (
          <Link
            to={`/dashboard/state-coordinator-mgt/state-coordinators/${value}`}
          >
            <Button
              type="primary"
              className="bg-[#0190fe] border-[#0190fe] rounded"
            >
              View Details
            </Button>
          </Link>
        );
      }
    }
  ];

  const renderFilterForm = () => {
    return (
      <Form layout="vertical" form={form} name="filter-fco">
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8}>
            <FormItem label="First Name" name={"firstName"} initialValue="">
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem label="Last Name" name={"lastName"} initialValue="">
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem label="State" name={"state"} initialValue="">
              <Select
                placeholder="Select a State"
                optionFilterProp="children"
                size="large"
                showSearch
                allowClear={true}
              >
                {statesInNigeria.map((state) => (
                  <Option key={state.name} value={state.name} name={state.name}>
                    {state.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col xs={24} sm={12} md={3}>
            <Button
              type="primary"
              className="w-full rounded"
              onClick={onClearForm}
              danger
            >
              Clear
            </Button>
          </Col>
          <Col xs={24} sm={12} md={4}>
            <Button
              type="primary"
              onClick={filtersStatesCordinators}
              className="w-full bg-[#0190fe] border-[#0190fe] rounded"
              htmlType="submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };
  return (
    <>
      <Card title="State Coordinators Filter" className="my-[18px]">
        {renderFilterForm()}
      </Card>
      <Card title="State Coordinators" className="my-[18px] w-full">
        <Table
          className="w-full overflow-x-auto"
          dataSource={stateCordinatorsData}
          columns={tableColumns}
          rowKey={(record) => record.id}
          loading={loading}
          pagination={stateCoordinatorPagination}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

export default ViewStateCoordinatorsTable;
