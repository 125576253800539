
import { Buffer } from 'buffer';
import { AppThunk } from '../../../../../store';
import { get_file_service } from '../fileApi';


// @ts-ignore
window.Buffer = Buffer;
export const fetchFileUrl =
    (fileRef: string,callback?:(url:string)=>void, fileExtension?:string): AppThunk =>
        async (dispatch) => {
            try {
          

                const response = await get_file_service(fileRef);
                
                   if(response){
                  
                    var base64Image =  Buffer.from(response, 'binary').toString('base64')
                    
                    const fileUrl = (fileExtension || 'data:image/jpeg;base64,') + base64Image
                     if(callback ){
                        callback(fileUrl)
                     }
                   
                   
                   }
                
            } catch (error: any) {
               
                // dispatch(saveFileError(parseError(error)))
            } finally {
                // dispatch(setLoading(false))
            }

        };
