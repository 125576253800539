import React from "react";
import { showServerError } from "../../../../utils/notifications";
import { parseError } from "../../../../utils/server";
import { bulk_reassign_loan_contracts } from "../fcoApi";
import { ReassignmentMode } from "../../Utils/UtilsSlice/utilsSlice.types";

export const useReassignFCOLoanContracts = () => {
    const [loading, setLoading] = React.useState(false)


    interface ReassignFCOLoanContractsParams {
        mode: ReassignmentMode
        data: any;
        callback({ requestSuccessful }: { requestSuccessful: boolean}): void
    }

    const reassignFCOLoanContracts = async({
        data,
        mode,
        callback,
    }: ReassignFCOLoanContractsParams) => {
        try{
            setLoading(true)

            const response = await bulk_reassign_loan_contracts(mode, data)

            callback(response)

        } catch (error) {
            callback({ requestSuccessful: false })
            showServerError(parseError(error))
        } finally {
            setLoading(false)
        }
    }


    return {
        loading,
        reassignFCOLoanContracts,
    }

}