import { Descriptions, Tag, Typography } from "antd"
import { parseAmountToCurrencyValue } from "../../../../utils/dataParser"
import { loanContractProductTypeMap } from "../utils/dataMaps"
import { ELoanContractProduct, LoanContractDetails } from "../utils/types"
import React from "react"
import { LoanContractStatusTag } from "../Components/LoanContractStatusTag"



interface Props {
    contractDetails: LoanContractDetails
}


const WCLDetails: React.FC<Props> = ({ contractDetails }) => {
    return (
        <Descriptions
            layout="vertical"
            column={{ xs: 1, md: 2, xl: 3 }}
        >
            <Descriptions.Item
                label={
                    <Typography.Text strong>
                        Business name
                    </Typography.Text>
                }
            >
                {contractDetails?.businessName}
            </Descriptions.Item>

            <Descriptions.Item
                label={
                    <Typography.Text strong>
                        Business Owner name
                    </Typography.Text>
                }
            >
                {contractDetails?.businessOwnerName}
            </Descriptions.Item>

            <Descriptions.Item
                label={
                    <Typography.Text strong>
                        Amount
                    </Typography.Text>
                }
            >
                {parseAmountToCurrencyValue(contractDetails?.loanAmount, "₦")}
            </Descriptions.Item>

            <Descriptions.Item
                label={
                    <Typography.Text strong>
                        Status
                    </Typography.Text>
                }
            >
                <LoanContractStatusTag status={contractDetails?.loanStatus} />
            </Descriptions.Item>

            <Descriptions.Item
                label={
                    <Typography.Text strong>
                        Approval Date
                    </Typography.Text>
                }
            >
                {new Date(contractDetails?.contractCreationDate).toDateString()}
            </Descriptions.Item>

            <Descriptions.Item
                label={
                    <Typography.Text strong>
                        Loan Product
                    </Typography.Text>
                }
            >
                <Tag color={loanContractProductTypeMap[ELoanContractProduct.WORKING_CAPITAL]?.color}>
                    {loanContractProductTypeMap[ELoanContractProduct.WORKING_CAPITAL]?.text}
                </Tag>
            </Descriptions.Item>
        </Descriptions>

    )
}

export default WCLDetails
