import React, { FC, useState } from "react";
import { Card, Form, Button, Select, Row, Col } from "antd";
import WorkflowRequests from "../WorkflowRequests";
import { WorkflowCategory } from "../workflowSlice/workflowSlice.types";
import { useQueryCompletedWorkflowRequests } from "../hooks/workflowRequests";
import { useQueryWorkflowRequestsTypes } from "../hooks/workflowRequestTypes";
import { showMessage } from "../../../../utils/notifications";

const FormItem = Form.Item;
const { Option } = Select;

interface CompletedWorkflowsProps {
  category: WorkflowCategory;
}

const CompletedWorkflows: FC<CompletedWorkflowsProps> = ({ category }) => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState({});
  const [showClearFilterButton, setShowClearFilterButton] =
    useState<Boolean>(false);

  const { loading, data, pagination, fetchWorkFlowRequests } =
    useQueryCompletedWorkflowRequests({ category });

  const { types } = useQueryWorkflowRequestsTypes({ payload: { category } });

  const onFilter = async () => {
    try {
      const values = await form.validateFields();
      if (!values.workflowRequestType) {
        showMessage("", "Workflow Request Type is required.", "error");
        return;
      }
      updateSearchParams(values);
      setShowClearFilterButton(true);
    } catch (error) {}
  };
  const resetFilter = () => {
    form.resetFields();
    updateSearchParams({});
    setShowClearFilterButton(false);
    showMessage("", "Workflow filter cleared successfully", "success");
  };

  const updateSearchParams = (values: any) => {
    setSearchParams(values);
    fetchWorkFlowRequests({ params: values });
  };
  const handleWorkflowTypeChange = () => setShowClearFilterButton(true);
  const renderSearchParameters = () => {
    return (
      <Form layout="vertical" className="w-full" form={form}>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8}>
            <FormItem label="Workflow type" name="workflowRequestType">
              <Select
                placeholder="Select type"
                size="large"
                onChange={handleWorkflowTypeChange}
              >
                {types.map((type, index) => (
                  <Option key={index} value={type.type}>
                    {type.description}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        {showClearFilterButton && (
          <Button
            type="primary"
            onClick={resetFilter}
            className="w-[150px] rounded mr-2"
            disabled={loading}
            danger
          >
            Clear
          </Button>
        )}
        <Button
          type="primary"
          onClick={onFilter}
          disabled={loading}
          className="w-[150px] bg-[#0190fe] border-[#0190fe] rounded"
        >
          Search
        </Button>
      </Form>
    );
  };

  return (
    <>
      <Card title="Workflow Filter" className="w-full">
        {renderSearchParameters()}
      </Card>
      <WorkflowRequests
        onFetchData={fetchWorkFlowRequests}
        data={data}
        loading={loading}
        pagination={pagination}
        enableActions={false}
        searchParams={searchParams}
        category={category}
      />
    </>
  );
};

export default CompletedWorkflows;
