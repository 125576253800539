import React, { useEffect } from "react"
import { useAppDispatch } from "../../../store"
import { clearUser } from "../../Auth/authSlice"


const Logout = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(clearUser())

    }, [dispatch])

    return (
        <main>
            Login out...
        </main>
    )
}


export default Logout
