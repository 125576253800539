import { Descriptions, Typography } from "antd"
import React from "react"


interface Props {
    fcoData: any
    title: string
}


export const FCODetails: React.FC<Props> = ({ fcoData, title }) => {
    return (
        <Descriptions title={title} layout="vertical" column={{ md: 2, sm: 1, xs: 1 }}>
            <Descriptions.Item
                label={<Typography.Text strong>Name</Typography.Text>}
            >
                {fcoData?.name}
            </Descriptions.Item>
            <Descriptions.Item 
                label={<Typography.Text strong>Email</Typography.Text>}
            >
                {fcoData?.email}
            </Descriptions.Item>
            <Descriptions.Item 
                label={<Typography.Text strong>Phone Number</Typography.Text>}
            >
                {fcoData?.phoneNumber}
            </Descriptions.Item>
            <Descriptions.Item 
                label={<Typography.Text strong>Code</Typography.Text>}
            >
                {fcoData?.code}
            </Descriptions.Item>
        </Descriptions>
    )
}

