import React from "react"
import { showMessage, showServerError } from "../../../../utils/notifications";
import { reassign_loan_contract_participant } from "../API";
import { LoanContractManagerType } from "../utils/types";



interface Params {
    payload: {
        officerType: LoanContractManagerType
        officerCode: string;
        reason: string;
        contractReference: string
    },
    callback(data: { requestSuccessfull: boolean }): void
}

const useReassignLoanContractParticipant = () => {
    const [reassignmentLoading, setReassignmentLoanding] = React.useState(false);

    const reassignLoanContractParticipant = async ({
        payload,
        callback
    }: Params) => {
        try {
            setReassignmentLoanding(true);

            const response = await reassign_loan_contract_participant(payload);

            if (response) {
                callback({ requestSuccessfull: true })
                showMessage("SuccessFull", "Reassignment request has been sent for approval.");
            }

        } catch (error) {
            callback({ requestSuccessfull: false })
            showServerError(error);
        } finally {
            setReassignmentLoanding(false);
        }
    }

    return {
        reassignmentLoading,
        reassignLoanContractParticipant
    }
}

export default useReassignLoanContractParticipant
