import React, { FC, useState } from "react";
import {
    Card,
    Table,
    Button,
    Form,
    Input,
    Row,
    Col,
    Tag,
    TableProps
} from "antd";
import { Pagination, initPagination } from "../../../../utils/pagination";
import { parseAmountToCurrencyValue, parseTableSearchParams } from "../../../../utils/dataParser";
import { loanContractProductTypeMap } from "../utils/dataMaps";
import { ELoanContractProduct } from "../utils/types";
import { LoanContractStatusTag } from "../Components/LoanContractStatusTag";


const FormItem = Form.Item;



interface Props {
    useQueryLoanContracts(prop: { payload: { searchParams?: Record<string, any>, pagination?: Pagination } }): {
        loading: boolean;
        loanContractsPagination: Pagination,
        loanContracts: any,
        getAllLoanContracts(searchParams?: Record<string, any>, pagination?: Pagination): void
    };
    extraColumns?: {
        title: string
        dataIndex: string
        key: string,
        render: (value: string, record: any) => React.ReactNode
    }[]
    extraFilterFields?: React.ReactNode;
    defaultSearchParams?: Record<string, any>;
}


const LoanContractsTable: FC<Props & TableProps<any>> = ({
    useQueryLoanContracts,
    extraColumns,
    rowSelection,
    defaultSearchParams
}) => {
    const [form] = Form.useForm();
    const {
        loading,
        loanContractsPagination,
        loanContracts,
        getAllLoanContracts
    } = useQueryLoanContracts({
        payload: {
            searchParams: defaultSearchParams
        }
    })
    const [searchParams, setSearchParams] = useState(defaultSearchParams);


    const filterLoanContract = () => {
        const formValues = form.getFieldsValue();
        const allValues = searchParams ? { ...searchParams, ...formValues } : formValues

        let validFields = parseTableSearchParams(allValues)

        setSearchParams(validFields);
        getAllLoanContracts(validFields, initPagination);
    };

    const onClearForm = () => {
        form.resetFields();
        setSearchParams(searchParams);
        getAllLoanContracts();
    };

    const handleTableChange = (pagination: any) => {
        fetchData(pagination, searchParams);
    };

    const fetchData = (pagination?: any, searchParams?: any) => {
        getAllLoanContracts(searchParams, pagination);
    };

    const tableColumns = [
        {
            title: "Business Name",
            dataIndex: "businessName",
            key: "businessName",
            render: (value: string) => {
                return <div>{value ? value : '-----'}</div>;
            },
        },
        {
            title: "Business Owner Name",
            dataIndex: "businessOwnerName",
            key: "businessOwnerName",
            render: (value: string) => {
                return <div>{value ? value : '-----'}</div>;
            },
        },
        {
            title: "Loan Product",
            dataIndex: "loanProduct",
            key: "loanProduct",
            render: (value: string) => {
                return (
                    <Tag color={loanContractProductTypeMap[value as ELoanContractProduct]?.color}>
                        {loanContractProductTypeMap[value as ELoanContractProduct]?.text}
                    </Tag>
                )
            },
        },
        {
            title: "Loan Amount",
            dataIndex: "loanAmount",
            key: "loanAmount",
            render: (value: string) => {
                return <div>{value ? parseAmountToCurrencyValue(value, "₦") : '-----'}</div>;
            },
        },
        {
            title: "Total Repaid",
            dataIndex: "totalRepaid",
            key: "totalRepaid",
            render: (value: string) => {
                return <div>{value ? parseAmountToCurrencyValue(value, "₦") : '-----'}</div>;
            },
        },
        {
            title: "Outstanding Balance",
            dataIndex: "outstandingBalance",
            key: "outstandingBalance",
            render: (value: string) => {
                return <div>{value ? parseAmountToCurrencyValue(value, "₦") : '-----'}</div>;
            },
        },
        {
            title: "Loan Status",
            dataIndex: "loanStatus",
            key: "loanStatus",
            render: (value: string) => {
                return (
                    <LoanContractStatusTag status={value} />
                )
            },
        },
        ...(extraColumns || [])
    ];



    return (
        <>
            <Card title="Loan Requests Filter" className="my-[18px]">
                <Form layout="vertical" form={form} name="filter-fco">
                    <Row gutter={24}>
                        <Col xs={24} sm={12} md={8}>
                            <FormItem
                                label="Business Name"
                                name={"businessName"}
                                initialValue=""
                            >
                                <Input size="large" placeholder="" />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <FormItem
                                label="Business Owner Name"
                                name={"businessOwnerName"}
                                initialValue=""
                            >
                                <Input size="large" placeholder="" />
                            </FormItem>
                        </Col>
                    </Row>

                    <Row gutter={[12, 12]}>
                        <Col xs={24} sm={12} md={3}>
                            <Button
                                type="primary"
                                className="w-full rounded"
                                onClick={onClearForm}
                                danger
                            >
                                Clear
                            </Button>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Button
                                type="primary"
                                onClick={filterLoanContract}
                                className="w-full bg-[#0190fe] border-[#0190fe] rounded"
                                htmlType="submit"
                            >
                                Search
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card title="Loan Requests" className="my-[18px] w-full">
                <Table
                    className="w-full overflow-x-auto"
                    rowSelection={rowSelection}
                    dataSource={loanContracts}
                    columns={tableColumns}
                    rowKey={(record) => record.loanContractReference}
                    loading={loading}
                    pagination={loanContractsPagination}
                    onChange={handleTableChange}
                />
            </Card>
        </>
    );
}

export default LoanContractsTable
