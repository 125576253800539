import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { message, Tooltip } from "antd";
import copy from "copy-text-to-clipboard";

type LoanManagerProps = {
  loanManager: any;
  managerRole: string;
};
export const getManagerAcronym = (name: string) => {
  const splitName = name.split(" ");
  return splitName.length > 1
    ? splitName[0].charAt(0) + splitName[1].charAt(0)
    : splitName[0].slice(0, 2);
};
const LoanManager = ({ loanManager, managerRole }: LoanManagerProps) => {
  const tooltipText = "This is the unique code assigned to the " + managerRole;
  const renderInfoIcon = () => {
    return (
      <Tooltip
        placement="bottomRight"
        title={tooltipText}
        className="cursor-pointer"
      >
        <InfoCircleOutlined className="text-[#246DF0] text-base mt-1 ml-2" />
      </Tooltip>
    );
  };
  const copyDetails = () => {
    const textToCopy = loanManager?.name + " - " + loanManager?.code;
    copy(textToCopy);
    message.success("Details copied to clipboard");
  };
  return (
    <div className="manager w-full flex mt-2 justify-between">
      <div className="w-1/3 flex">
        <div className="bg-black text-base font-semibold w-10 h-10 rounded-lg text-white flex items-center justify-center uppercase">
          {getManagerAcronym(loanManager.name)}
        </div>
        <div className="flex flex-col ml-2 justify-start mt-2">
          <div className="text-sm text-black font-medium">
            {loanManager.name}
          </div>
          <div className="text-xs font-normal">{managerRole}</div>
        </div>
      </div>
      <div className="w-2/3 ml-2">
        <span className="flex items-center justify-start">
          <div className="text-xs">{loanManager?.code} </div>
          {renderInfoIcon()}
        </span>
        <button
          className="bg-[#E3F6EB] text-[10px] border-none font-bold rounded-md text-[#0F7037] h-5 cursor-pointer"
          onClick={copyDetails}
        >
          Copy <CopyOutlined />
        </button>
      </div>
    </div>
  );
};
export default LoanManager;
