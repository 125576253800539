import { createSlice } from '@reduxjs/toolkit';
import { initPagination } from '../../../../utils/pagination';
import { IBusinessRm } from './brm.types';

const initialState: IBusinessRm = {
    brmData: null,
    brmPagination: initPagination,
    error: "",
    loading: false,
    brmDetailData: null,
    success: false,
    enableBrmLoading: false,
    enableBrmErorr: "",
    targetKeys:null,
    enableBrmsuccess:false,
};

const brmSlice = createSlice({
    name: "brmViewAll",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },

        saveAllBrmsData: (state, action) => {
            state.brmData = action.payload?.responseBody?.content
            state.error = ""
        },
        saveAllBrmsDataPagination: (state, action) => {
            state.brmPagination = action.payload
        },
        setAllBrmsDataError: (state, action) => {
            state.error = action.payload
            state.success = false
        },
        saveBrmDetails: (state, action) => {

            state.brmDetailData = action.payload?.responseBody
        },
        enableBrm: (state) => {

            state.enableBrmsuccess= true
        },
        enableBrmLoading: (state, action) => {
            
            state.enableBrmLoading = action.payload
        },
        enableBrmErorr: (state, action) => {
            state.enableBrmErorr = action.payload
        },
        getTargetKeys: (state, action) => {
            state.targetKeys = action.payload
        },
        resetRM:(state)=>{
            state.enableBrmLoading= false
            state.enableBrmErorr= ""
            state.targetKeys=null
            state.enableBrmsuccess=false
        },
        reset: (state) => {
            state.brmData = null
            state.loading = false
            state.error = ""
            state.brmDetailData = null
            state.success = false
        }
    }
})

export const { setLoading, saveAllBrmsData, setAllBrmsDataError, reset, saveBrmDetails, enableBrm,enableBrmLoading,enableBrmErorr,getTargetKeys,resetRM, saveAllBrmsDataPagination } = brmSlice.actions;
const brmReducer = brmSlice.reducer


export default brmReducer;