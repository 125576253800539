import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit'
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux'
import { initialState } from '../modules/Auth/authSlice'
import { getAuthFromLocalStorage } from '../modules/Auth/authSlice/authSlice.thunks'
import { rootReducer } from './rootReducer'

const localAuth = getAuthFromLocalStorage()

const store = configureStore({
  preloadedState: {
    auth: {
      ...initialState,
      ...localAuth
    }
  },
  reducer: rootReducer
})


export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store
