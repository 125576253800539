import React from "react";
import { parseError } from "../../../../../utils/server";
import { showMessage } from "../../../../../utils/notifications";
import { get_regional_manager_details_service } from "../../API";



export interface RegionalManagerDetailsQueryProps {
    payload: {
        id: string
    }
}

export const useQueryRegionalManagerDetails = (props: RegionalManagerDetailsQueryProps) => {
    const {
        payload: {
            id
        }
    } = props

    const [regionalManagerDetails, setRegionalManagerDetails] = React.useState<any | null>(null);
    const [regionalManagerDetailsLoading, setRegionalManagerDetailsLoading] = React.useState(false)



    const getRegionalManagerDetails = React.useCallback(async (id: string) => {
        try {
            setRegionalManagerDetailsLoading(true)

            const response = await get_regional_manager_details_service(id);

            setRegionalManagerDetails(response?.responseBody)
        } catch (error: any) {
            showMessage("Regional Manager", parseError(error), "error");
            setRegionalManagerDetails(null)
        } finally {
            setRegionalManagerDetailsLoading(false)
        }

    }, [])


    React.useEffect(() => {

        getRegionalManagerDetails(id)

    }, [getRegionalManagerDetails, id])

    return {
        regionalManagerDetailsLoading,
        regionalManagerDetails,
        getRegionalManagerDetails,
    }

}
