import server from "../../../../../server";
import config from "../../../../../config";

export const get_file_service = async (fileRef: string) => {
    const response = await server.get(
        `${config.WCL_PORTFOLIO_BASE_URL}/file-repo/view/${fileRef}`,
        {
            responseType:'arraybuffer'
        }
    );
  
    return response.data;
  };

  export const upload_file_service = async (data: any) => {
    const response = await server.post(
      `${config.WCL_PORTFOLIO_BASE_URL}/file-repo/upload`,
      data,
    );
  
    return response.data;
  };