import { Descriptions, Spin, Tag, Typography } from "antd";
import { useState } from "react";
import {
  ELoanContractProduct,
  ELoanRequestProduct,
} from "../../../LoanContractsManagement/utils/types";
import CustomDescriptionItem from "../../../LoanRequestsManagement/AllLoanRequests/components/loanDetails/LoanOfferItem";
import LoanOfferErrorDetails from "../../../LoanRequestsManagement/AllLoanRequests/components/LoanOfferErrorDetails";
import { convertDateToHumanReadableFrequency } from "../../../../../utils/dataParser";
import { loanContractProductTypeMap } from "../../../LoanContractsManagement/utils/dataMaps";
import { convertStatusTextToColor } from "../../../../../utils/workflow";
import { loanRequestStatusConverter } from "../../../LoanRequestsManagement/ViewLoanRequests";

const { Text } = Typography;
const UpdatedLoanOfferDetails = ({
  loanDetails,
  loading,
  error,
  updatedOffer,
}: {
  loanDetails: any;
  loading: boolean;
  error?: any;
  updatedOffer?: any;
}) => {
  const editMode = false;
  const [editedData, setEditedData] = useState({ ...loanDetails });
  if (error)
    return (
      <LoanOfferErrorDetails
        isPermissionError={false}
        errorMessage="Loan Offer details not found"
      />
    );
  return (
    <>
      {loading ? (
        <section className="flex items-center justify-center">
          <Spin tip="Loading Offer Details..." />
        </section>
      ) : (
        <div className="loan-offer">
          <Descriptions 
            layout="vertical" 
            column={2} 
            contentStyle={{ marginTop: '-16px', maxWidth: 'fit-content' }}
          >
            <Descriptions.Item label={<Text strong>Loan Product</Text>}>
              <Tag
                color={
                  loanContractProductTypeMap[
                    loanDetails?.loanProduct as ELoanContractProduct
                  ]?.color
                }
              >
                {
                  loanContractProductTypeMap[
                    loanDetails?.loanProduct as ELoanContractProduct
                  ]?.text
                }
              </Tag>
            </Descriptions.Item>
            {loanDetails?.offerStatus && (
              <Descriptions.Item label={<Text strong>Loan Offer Status</Text>}>
                <Tag color={convertStatusTextToColor(loanDetails?.offerStatus)}>
                  {loanRequestStatusConverter(loanDetails?.offerStatus)}
                </Tag>
              </Descriptions.Item>
            )}

            <Descriptions.Item label={<Text strong>Amount</Text>}>
              <CustomDescriptionItem
                value={editedData?.amount}
                editMode={editMode}
                setValue={(value) =>
                  setEditedData({ ...editedData, amount: value, edited: true })
                }
                editProps={{
                  type: "number",
                }}
                valueUpdated={updatedOffer?.amount}
                parseAmount
              />
            </Descriptions.Item>
            {editedData?.processingFee && (
              <Descriptions.Item label={<Text strong>Processing Fee</Text>}>
                <CustomDescriptionItem
                  value={editedData?.processingFee}
                  editMode={editMode}
                  setValue={(value) =>
                    setEditedData({
                      ...editedData,
                      processingFee: value,
                      edited: true,
                    })
                  }
                  editProps={{
                    type: "number",
                  }}
                  valueUpdated={updatedOffer?.processingFee}
                  parseAmount
                />
              </Descriptions.Item>
            )}
            <Descriptions.Item label={<Text strong>Management Fee</Text>}>
              <CustomDescriptionItem
                value={editedData?.managementFee}
                editMode={editMode}
                setValue={(value) =>
                  setEditedData({
                    ...editedData,
                    managementFee: value,
                    edited: true,
                  })
                }
                valueUpdated={updatedOffer?.managementFee}
                editProps={{
                  type: "number",
                }}
                parseAmount
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={<Text strong>Interest Calculation Frequency</Text>}
            >
              {editedData?.interestCalculationFrequency}
            </Descriptions.Item>
            <Descriptions.Item
              label={<Text strong>Daily Interest Rate Percentage</Text>}
              contentStyle={{
                padding: "0",
              }}
            >
              <CustomDescriptionItem
                value={editedData?.dailyInterestRateInPercentage}
                editMode={editMode}
                setValue={(value) =>
                  setEditedData({
                    ...editedData,
                    dailyInterestRateInPercentage: value,
                    edited: true,
                  })
                }
                renderProps={{
                  suffix: "% daily",
                  suffixForUpdatedValue: "% daily",
                }}
                valueUpdated={updatedOffer?.dailyInterestRateInPercentage}
                editProps={{
                  type: "number",
                }}
              />
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Tenure</Text>}>
              <CustomDescriptionItem
                value={editedData?.tenure}
                editMode={editMode}
                setValue={(value) =>
                  setEditedData({
                    ...editedData,
                    tenure: value,
                    loanTenure: value,
                    edited: true,
                  })
                }
                renderProps={{
                  suffix:
                    " " +
                    convertDateToHumanReadableFrequency(
                      editedData?.tenure,
                      editedData?.loanTenureUnit
                    ),
                  suffixForUpdatedValue:
                    " " +
                    convertDateToHumanReadableFrequency(
                      updatedOffer?.tenure,
                      updatedOffer?.loanTenureUnit || editedData?.loanTenureUnit
                    ),
                }}
                valueUpdated={updatedOffer?.tenure}
                editProps={{
                  type: "number",
                  maxLength: 5,
                }}
              />
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Loan Tenure Unit</Text>}>
              <CustomDescriptionItem
                dateTenure
                value={editedData?.loanTenureUnit}
                editMode={editMode}
                setValue={(value) =>
                  setEditedData({
                    ...editedData,
                    loanTenureUnit: value,
                    edited: true,
                  })
                }
                valueUpdated={updatedOffer?.loanTenureUnit}
              />
            </Descriptions.Item>

            {/* WCL items */}
            {loanDetails?.loanProduct === ELoanRequestProduct.WCL && (
              <>
                <Descriptions.Item
                  label={<Text strong>Repayment frequency</Text>}
                >
                  <CustomDescriptionItem
                    value={editedData?.repaymentFrequency}
                    editMode={editMode}
                    setValue={(value) =>
                      setEditedData({
                        ...editedData,
                        repaymentFrequency: value,
                        edited: true,
                      })
                    }
                    renderProps={{
                      prefix: "Every",
                      suffix:
                        " " +
                        convertDateToHumanReadableFrequency(
                          editedData?.repaymentFrequency,
                          editedData?.loanTenureUnit
                        ),
                      suffixForUpdatedValue:
                        " " +
                        convertDateToHumanReadableFrequency(
                          updatedOffer?.repaymentFrequency,
                          updatedOffer?.loanTenureUnit ||
                            editedData?.loanTenureUnit
                        ),
                    }}
                    editProps={{
                      type: "number",
                    }}
                    valueUpdated={updatedOffer?.repaymentFrequency}
                  />
                </Descriptions.Item>
              </>
            )}

            {/* "OVD ITEMS" */}
            {loanDetails?.loanProduct === ELoanContractProduct.OVERDRAFT && (
              <>
                <Descriptions.Item
                  label={<Text strong>Overdraft Account Number</Text>}
                >
                  {loanDetails?.loanAccountNuban}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Regularization Period</Text>}
                >
                  <CustomDescriptionItem
                    value={editedData?.regularizationPeriod}
                    editMode={editMode}
                    setValue={(value) =>
                      setEditedData({
                        ...editedData,
                        regularizationPeriod: value,
                        edited: true,
                      })
                    }
                    renderProps={{
                      prefix: "Every",
                      suffix:
                        " " +
                        convertDateToHumanReadableFrequency(
                          editedData?.regularizationPeriod,
                          editedData?.regularizationPeriodUnit
                        ),
                      suffixForUpdatedValue:
                        " " +
                        convertDateToHumanReadableFrequency(
                          updatedOffer?.regularizationPeriod,
                          updatedOffer?.regularizationPeriodUnit ||
                            editedData?.regularizationPeriodUnit
                        ),
                    }}
                    valueUpdated={updatedOffer?.regularizationPeriod}
                    editProps={{
                      type: "number",
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Regularization Period Unit</Text>}
                >
                  {loanDetails?.regularizationPeriodUnit}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Maximum Count to termination</Text>}
                >
                  <CustomDescriptionItem
                    value={editedData?.maxDefaultCountToTermination}
                    editMode={editMode}
                    setValue={(value) =>
                      setEditedData({
                        ...editedData,
                        maxDefaultCountToTermination: value,
                        edited: true,
                      })
                    }
                    valueUpdated={updatedOffer?.maxDefaultCountToTermination}
                    editProps={{
                      type: "number",
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Business Owner Code</Text>}
                >
                  {loanDetails?.businessOwnerCode}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        </div>
      )}
    </>
  );
};
export default UpdatedLoanOfferDetails;
