import { Link } from "react-router-dom";
import type { MenuProps } from "antd";
import { AppstoreOutlined, FolderOutlined } from "@ant-design/icons";
import { DashboardRoutePaths } from "./routePaths";
import { Feature } from "../../config/feature-config";

export type MenuItem = Required<MenuProps>["items"][number];

const menuItems: MenuItem[] = [
  {
    key: "dashboard",
    icon: <AppstoreOutlined className="h-3 w-3 text-gray-400" />,
    label: <Link to={DashboardRoutePaths.home.path}>Dashboard</Link>,
  },

  {
    key: "loan-requests",
    icon: <AppstoreOutlined className="h-3 w-3 text-gray-400" />,
    label: "Loan Requests",
    children: [
      {
        key: "loanRequests",
        label: (
          <Link to={DashboardRoutePaths.viewLoanRequests.path}>
            Loan Approvals
          </Link>
        ),
      },
      {
        key: 'all-loan-request',
        label: (
          <Link to={DashboardRoutePaths.viewAllLoanRequests.path}>
            All Loan Requests
          </Link>
        ),
      },
    ],
  },
  {
    key: "loan-contracts",
    icon: <AppstoreOutlined className="h-3 w-3 text-gray-400" />,
    label: "Loan Contracts",
    children: [
      {
        key: "loanContracts",
        label: (
          <Link to={DashboardRoutePaths.loanContracts.path}>
            Loan Contracts
          </Link>
        ),
      },
    ],
  },

  {
    key: "users-management",
    icon: <AppstoreOutlined className="h-3 w-3 text-gray-400" />,
    label: "Users",
    children: [
      {
        key: "viewUsers",
        label: (
          <Link to={DashboardRoutePaths.viewAllUsers.path}>View Users</Link>
        ),
      },
    ],
  },
  {
    key: "brm-management",
    icon: <AppstoreOutlined className="h-3 w-3 text-gray-400" />,
    label: "B.R.M Management",
    children: [
      {
        key: "viewRelationshipManagers",
        label: <Link to={DashboardRoutePaths.brms.path}>View B.R.Ms</Link>,
      },
    ],
  },
  {
    key: "fco-views-management",
    icon: <AppstoreOutlined className="h-3 w-3 text-gray-400" />,
    label: "F.C.O Management",
    children: [
      {
        key: "viewFcoManagers",
        label: (
          <Link to={DashboardRoutePaths.viewFcosManagers.path}>
            View F.C.Os
          </Link>
        ),
      },
    ],
  },
  {
    key: "stateCoordinators",
    icon: <AppstoreOutlined className="h-3 w-3 text-gray-400" />,
    label: "State Coordinators",
    children: [
      {
        key: "view-state-coordinators",
        label: (
          <Link to={DashboardRoutePaths.viewStateCoordinators.path}>
            View State Coordinators
          </Link>
        ),
      },
    ],
  },
  {
    key: "portfolio-management",
    icon: <AppstoreOutlined className="h-3 w-3 text-gray-400" />,
    label: "Portfolio Management",
    children: [
      {
        key: "viewPortfoliomanagers",
        label: (
          <Link to={DashboardRoutePaths.viewPortfolioManagements.path}>
            View Portfolio Managers
          </Link>
        ),
      },
      {
        key: "createFcoPortfolioLevel",
        label: (
          <Link to={DashboardRoutePaths.createPortfolioLevel.path}>
            Create FCO Level
          </Link>
        ),
      },
      {
        key: "settings",
        label: (
          <Link to={DashboardRoutePaths.portfolioManagementSettings.path}>
            Settings
          </Link>
        ),
      },
    ],
  },
  {
    key: "wcl-rm-management",
    icon: <AppstoreOutlined className="h-3 w-3 text-gray-400" />,
    label: "Regional Managers",
    children: [
      {
        key: "viewRegionalManagers",
        label: (
          <Link to={DashboardRoutePaths.WCLRegionalManagers.path}>
            View Regional Managers
          </Link>
        ),
      },
    ],
  },

  {
    key: "provisioning-template",
    icon: <AppstoreOutlined className="h-3 w-3 text-gray-400" />,
    label: "Provisioning Template",
    children: [
      {
        key: "viewProvisioningTemplate",
        label: (
          <Link to={DashboardRoutePaths.viewProvisioning.path}>
            View Provisioning Templates
          </Link>
        ),
      },
    ],
  },
  {
    key: Feature.LOAN_PROGRAM_MANAGEMENT,
    icon: <AppstoreOutlined className="h-3 w-3 text-gray-400" />,
    label: "Loan Programs",
    children: [
      {
        key: "loanProgram",
        label: (
          <Link to={DashboardRoutePaths.loanPrograms.path}>Loan Programs</Link>
        ),
      },
    ],
  },
  {
    key: Feature.LOCALGOVERNMENT_ACTIVATION,
    icon: <FolderOutlined className="h-3 w-3 text-gray-400" />,
    label: "LGA Activation",
    children: [
      {
        key: "lgaActivation",
        label: (
          <Link to={DashboardRoutePaths.wclLgaActivation.path}>
            LGA Activation
          </Link>
        ),
      },
    ],
  },

  {
    key: "workflow",
    icon: <AppstoreOutlined className="h-3 w-3 text-gray-400" />,
    label: "Workflow",
    children: [
      {
        key: 'backoffice-workflow-requests',
        label: "Backoffice Requests",
        children: [
          {
            key: "bor-pending-approvals",
            label: (
              <Link
                to={
                  DashboardRoutePaths.wclBackofficeWorkflowPendingApprovals
                    .path
                }
              >
                Pending Approvals
              </Link>
            ),
          },
          {
            key: "bor-completed-workflows",
            label: (
              <Link
                to={DashboardRoutePaths.viewBackofficeCompletedWorkflows.path}
              >
                Completed Workflows
              </Link>
            ),
          },
        ],
      },
      {
        key: "wcl",
        label: "Working Capital Loans",
        children: [
          {
            key: "wcl-pending-approvals",
            label: (
              <Link to={DashboardRoutePaths.wclWorkflowPendingApprovals.path}>
                Pending Approvals
              </Link>
            ),
          },
          {
            key: "wcl-completed-workflows",
            label: (
              <Link to={DashboardRoutePaths.wclCompletedWorkflow.path}>
                Completed Workflows
              </Link>
            ),
          },
        ],
      },
      {
        key: "portfolio-mgt",
        label: "Portfolio Management",
        children: [
          {
            key: "portfolio-mgt-pending-approvals",
            label: (
              <Link
                to={
                  DashboardRoutePaths
                    .portfolioManagementWorkflowPendingApprovals.path
                }
              >
                Pending Approvals
              </Link>
            ),
          },
          {
            key: "portfolio-mgt-completed-workflows",
            label: (
              <Link
                to={
                  DashboardRoutePaths.portfolioManagementCompletedWorkflow.path
                }
              >
                Completed Workflows
              </Link>
            ),
          },
        ],
      },
    ],
  },
];

export default menuItems;
