import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import UsersTable from "./UsersTable";
import { UsersTableStatus } from "../types";

import {
  DashboardRoutePaths,
  getStaticPathString
} from "../../../../routes/Dashboard/routePaths";

const ViewUsers = () => {
  const tableColumns = [
    {
      title: "Activation",
      dataIndex: "activated",
      key: "activated",
      render: (value: boolean) => {
        if (value) {
          return (
            <Button
              type="primary"
              style={{ background: "#28A745", borderColor: "#28A745" }}
            >
              Active
            </Button>
          );
        } else {
          return (
            <Button type="primary" disabled={true}>
              Inactive
            </Button>
          );
        }
      }
    },

    {
      title: "Status",
      dataIndex: "activationStatus",
      key: "activationStatus",
      render: (value: string) => {
        if (value === UsersTableStatus.ENABLED) {
          return (
            <Button
              type="primary"
              style={{ background: "#28A745", borderColor: "#28A745" }}
            >
              Enabled
            </Button>
          );
        } else {
          return (
            <Button type="primary" disabled={true}>
              Disabled
            </Button>
          );
        }
      }
    },
    {
      title: "",
      dataIndex: "username",
      key: "username",
      render: (value: string) => {
        return (
          <Link
            to={`${getStaticPathString(
              DashboardRoutePaths.viewUserDetails.path
            )}${value}`}
          >
            <Button
              type="primary"
              className="bg-[#0190fe] border-[#0190fe] rounded"
            >
              View Details
            </Button>
          </Link>
        );
      }
    }
  ];

  return (
    <>
      <UsersTable extraColumns={tableColumns} />
    </>
  );
};

export default ViewUsers;
