import React from "react";
import { parseError } from "../../../../../utils/server";
import { showMessage } from "../../../../../utils/notifications";
import { get_portfolio_manager_details_service } from "../../portfolioApi";



export interface PortfolioManagerDetailsQueryProps {
    payload: {
        id: string
    }
}

export const useQueryPortfolioManagerDetails = (props: PortfolioManagerDetailsQueryProps) => {
    const {
        payload: {
            id
        }
    } = props

    const [portfolioManagerDetails, setPortfolioManagerDetails] = React.useState<any | null>(null);
    const [portfolioManagerDetailsLoading, setPortfolioManagerDetailsLoading] = React.useState(false)



    const getPortfolioManagerDetails = React.useCallback(async (id: string) => {
        try {
            setPortfolioManagerDetailsLoading(true)

            const response = await get_portfolio_manager_details_service(id);

            setPortfolioManagerDetails(response?.responseBody)
        } catch (error: any) {
            showMessage("Portfolio Manager", parseError(error), "error");
            setPortfolioManagerDetails(null)
        } finally {
            setPortfolioManagerDetailsLoading(false)
        }

    }, [])


    React.useEffect(() => {

        if (id) {
            getPortfolioManagerDetails(id);
        }

    }, [getPortfolioManagerDetails, id])

    return {
        portfolioManagerDetailsLoading,
        portfolioManagerDetails,
        getPortfolioManagerDetails,
    }

}