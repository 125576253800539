import { Card } from "antd";
import LoanRequestTabs from "./LoanRequestTabs";

const AllLoanRequests = () => {
    return (
        <Card>
            <LoanRequestTabs />    
        </Card>
    )
}
export default AllLoanRequests;