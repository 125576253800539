import { LoanRequestStatus } from "../loanRequestSlice/loanRequest.types";

export const loanRequestsStatusMap: Record<LoanRequestStatus, string> = {
    [LoanRequestStatus.AWAITING_ASSIGNMENTS]: 'AWAITING ASSIGNMENTS',
    [LoanRequestStatus.AWAITING_VERIFICATIONS_AND_AGREEMENTS_DISPATCH]: 'AWAITING VERIFICATIONS AND AGREEMENTS DISPATCH',
    [LoanRequestStatus.AWAITING_VERIFICATIONS_AND_AGREEMENTS_EXECUTION]: 'AWAITING VERIFICATIONS AND AGREEMENTS EXECUTION',
    [LoanRequestStatus.AWAITING_FIRST_FCO_APPROVAL]: 'AWAITING FIRST FCO APPROVAL',
    [LoanRequestStatus.AWAITING_SECOND_FCO_APPROVAL]: 'AWAITING SECOND FCO APPROVAL',
    [LoanRequestStatus.AWAITING_PM_APPROVAL]: 'AWAITING PM APPROVAL',
    [LoanRequestStatus.AWAITING_FINAL_APPROVAL]: 'AWAITING FINAL APPROVAL',
    [LoanRequestStatus.APPROVED]: 'APPROVED',
    [LoanRequestStatus.REJECTED]: 'REJECTED',
}
