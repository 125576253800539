import { Card, Descriptions, Table, Empty, Spin, Typography } from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { getEachLoanProgram, getLoanLevelProgram } from "../loanProgramsSlice/loanPrograms.thunks";

const breadCrumbItems: BreadCrumbItem[] = [
    {
        title: "Dashboard",
        path: DashboardRoutePaths.home.path,
    },
    {
        title: "Loan Programs",
        path: DashboardRoutePaths.loanPrograms.path,
    },
    {
        title: "Loan Program Details",
        active: true,
    },
];

const LoanProgramDetails = () => {
    const params = useParams<{ "*": string; id: string }>();
    const dispatch = useAppDispatch()
    const {
        loanProgramsReducer: { eachLoanProgram, eachLoanProgramDetailsLoading, loanLevelProgramLoading, loanLevelProgramData },
    } = useAppSelector(state => state)

    useEffect(() => {
        if (params?.id) {
            dispatch(getEachLoanProgram(params?.id, (code) => {
                dispatch(getLoanLevelProgram(code))
            }))
        }
    }, [dispatch, params?.id])

    const handleTableChange = (pagination: any) => {
    }

    const getLoanProgramLevelCodeData = (code: string) => {
        if (loanLevelProgramData) {
            return loanLevelProgramData[code];
        } else {
            return [];
        }
    }

    const tableColumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (value: string) => {
                return <div>{value}</div>;
            },
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (value: string) => {
                return <div>{value}</div>;
            },
        },
        {
            title: "Contribution Level (%)",
            dataIndex: "loanContributionLevel",
            key: "loanContributionLevel",
            render: (value: string) => {
                return <div>{value}</div>;
            },
        },
        {
            title: "Demotion Score",
            dataIndex: "demotionScore",
            key: "demotionScore",
            render: (value: string) => {
                return <div>{value}</div>;
            },
        },
        {
            title: "Promotion Qualification Minimum Score",
            dataIndex: "promotionQualificationMinimumScore",
            key: "promotionQualificationMinimumScore",
            render: (value: string) => {
                return <div>{value}</div>;
            },
        },
        {
            title: "Promotion Qualification Minimum Score Count",
            dataIndex: "promotionQualificationMinimumScoreCount",
            key: "promotionQualificationMinimumScoreCount",
            render: (value: string) => {
                return <div>{value}</div>;
            },
        }, {
            title: "Promotion Qualification Minimum Value Borrowed",
            dataIndex: "promotionQualificationMinimumValueBorrowed",
            key: "promotionQualificationMinimumValueBorrowed",
            render: (value: string) => {
                return <div>{value}</div>;
            },
        },
    ];

    return (
        <>
            <Page breadCrumbItems={breadCrumbItems}>
                <Card
                    className="my-6"
                    title={
                        <Typography.Title level={4}>
                            Loan Program Details
                        </Typography.Title>
                    }
                >
                    {eachLoanProgramDetailsLoading ? (
                        <section className="centered-container">
                            <Spin tip="Loading Details..." />
                        </section>
                    ) : eachLoanProgram ? (
                        <>
                            <Descriptions
                                layout="vertical"
                                column={{ xs: 1, md: 2, xl: 3 }}
                            >
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            Name
                                        </Typography.Text>
                                    }
                                >
                                    {eachLoanProgram.name}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            Code
                                        </Typography.Text>
                                    }
                                >
                                    {eachLoanProgram.code}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            Business Type
                                        </Typography.Text>
                                    }
                                >
                                    {eachLoanProgram.businessType}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            Loan Type
                                        </Typography.Text>
                                    }
                                >
                                    {eachLoanProgram.loanType}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            Status
                                        </Typography.Text>
                                    }
                                >
                                    {eachLoanProgram.status}
                                </Descriptions.Item>
                            </Descriptions>
                            <Card
                                className="mt-12"
                                title="Level Loan Program"
                                style={{ position: "relative" }}
                            >
                                <Table
                                    className="w-full overflow-x-auto"
                                    dataSource={getLoanProgramLevelCodeData(eachLoanProgram.code)}
                                    columns={tableColumns}
                                    rowKey={(record: any) => record.id}
                                    loading={loanLevelProgramLoading}
                                    pagination={false}
                                    onChange={handleTableChange}
                                />
                            </Card>
                        </>
                    ) : (
                        <section className="centered-container">
                            <Empty />
                        </section>
                    )}
                </Card>
            </Page>
        </>
    )
}
export default LoanProgramDetails