import React from "react";
import { Steps } from "antd";
import { parseAmountToCurrencyValue } from "../../../../../../utils/dataParser";
import { ILoanRequestDetails } from "../../utils/types";
const { Step } = Steps;

type LoanStatusTrackerProps = {
  details: ILoanRequestDetails | undefined;
};

function LoanStatusTracker({ details }: LoanStatusTrackerProps) {
  const currentStatus = determineCurrentStatus(
    details?.approvalStatusDetails?.currentApprovalStatus
  );

  return (
    <Steps current={currentStatus} size="small" labelPlacement="vertical">
      <Step
        title="Loan Requested"
        description={`Amount: ${
          details?.amountRequested
            ? parseAmountToCurrencyValue(details?.amountRequested, "₦")
            : "UNAVAILABLE"
        }`}
      />
      <Step title="Loan Agreement Execution" />
      <Step title="Field Credit Officer 1 Approval" />
      <Step title="Field Credit Officer 2 Approval" />
      <Step title="Portfolio Manager Approval" />
      <Step title="Final Approval" />
    </Steps>
  );
}

function determineCurrentStatus(status: any) {
  switch (status) {
    case "Loan Requested":
      return 0;
    case "Loan Agreement Execution":
      return 1;
    // Add other cases for different statuses
    default:
      return 6;
  }
}

export default LoanStatusTracker;
