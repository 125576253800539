import {  Descriptions, Empty,  Spin,} from "antd";
import React, { FC} from "react";
import { useAppSelector } from "../../../../../store";


interface ApproveDisapproveFCOPortfolioLevelProps {
  src: any;
  data: any;
}

const ApproveDisapproveFCOPortfolioLevel: FC<
  ApproveDisapproveFCOPortfolioLevelProps
> = () => {

  const {
    brmReducer: { brmDetailData, loading },

  } = useAppSelector((state) => state);

 

  return (
    <React.Fragment>
      {loading ? (
        <section className="centered-container">
          <Spin tip="Loading Details..." />
        </section>
      ) : brmDetailData ? (
        <>
          <Descriptions title="Field Credit Risk Officer Portfolio">
            <Descriptions.Item label="Level Name">
              {brmDetailData.firstName}
            </Descriptions.Item>
            <Descriptions.Item label="Level Code">
              {brmDetailData.middleName}
            </Descriptions.Item>
            <Descriptions.Item label="Gross Loan Portfolio Value Limit">
              {brmDetailData.lastName}
            </Descriptions.Item>
            <Descriptions.Item label="Provisioned Value Limit">
              {brmDetailData.userName}
            </Descriptions.Item>
          </Descriptions>
        </>
      ) : (
        <section className="centered-container">
          <Empty />
        </section>
      )}
    </React.Fragment>
  );
};

export default ApproveDisapproveFCOPortfolioLevel;
