import React from "react";
import {
  Pagination,
  extractPaginationData,
  initPagination
} from "../../../../utils/pagination";
import {
  get_all_regional_managers_service,
  onboard_regional_manager_service
} from "../API";
import { parseError } from "../../../../utils/server";
import { showMessage } from "../../../../utils/notifications";
import { RegionalManagerOnboardingParams } from "../Slice/types";

export interface RegionalManagersQueryProps {
  payload: {
    searchParams?: Record<string, any>;
    pagination?: Pagination;
  };
}

export const useQueryRegionalManagers = (props: RegionalManagersQueryProps) => {
  const {
    payload: { searchParams, pagination }
  } = props;

  const [regionalManagers, setRegionalManagers] = React.useState<any | null>(
    null
  );
  const [regionalManagersPagination, setRegionalManagersPagination] =
    React.useState<Pagination>(initPagination);
  const [error, setError] = React.useState("");
  const [regionalManagersLoading, setRegionalManagersLoading] =
    React.useState(false);


  const getAllRegionalManagers = React.useCallback(
    async (searchParams?: Record<string, any>, pagination?: Pagination) => {
      try {
        setError("");
        setRegionalManagersLoading(true);

        const response = await get_all_regional_managers_service(
          searchParams,
          pagination
        );
        const paginationData = extractPaginationData(response.responseBody);

        setRegionalManagers(response?.responseBody?.content);
        setRegionalManagersPagination(paginationData);
      } catch (error: any) {
        setRegionalManagers(null);
        showMessage("Regional Managers", parseError(error), "error");
        setError(parseError(error));
      } finally {
        setRegionalManagersLoading(false);
      }
    },
    []
  );
 

  React.useEffect(() => {
    getAllRegionalManagers(searchParams, pagination);
  }, [searchParams, pagination, getAllRegionalManagers]);

  return {
    error,
    regionalManagersLoading,
    regionalManagers,
    regionalManagersPagination,
    getAllRegionalManagers,
 
  };
};
export const useOnboardingRegionalManager = () => {

const [regionalManagerOnboardingError, setRegionalManagerOnboardingError] =
  React.useState("");
const [
  regionalManagerOnboardingLoading,
  setRegionalManagerOnboardingLoading
] = React.useState(false);
const onboardRegionalManager = async (
  params: RegionalManagerOnboardingParams,
  onSuccess: (response: any) => void
) => {
  try {
    setRegionalManagerOnboardingError("");

    setRegionalManagerOnboardingLoading(true);

    const response = await onboard_regional_manager_service(params);

    if (response?.requestSuccessful) {
      onSuccess(response?.responseBody);
      showMessage(
        "Successfull",
        "Request to onboard user was sent successfully."
      );
    }
  } catch (error) {
    setRegionalManagerOnboardingError(parseError(error));
  } finally {
    setRegionalManagerOnboardingLoading(false);
  }
};

const onCloseOnBoardModal = () => {
  if (regionalManagerOnboardingLoading) return;
  if (regionalManagerOnboardingError) {
    setRegionalManagerOnboardingError("");
  }
};
return{
  regionalManagerOnboardingError,
  regionalManagerOnboardingLoading,
  onboardRegionalManager,
  onCloseOnBoardModal,
}
}
