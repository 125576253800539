import React from "react";
import { parseError } from "../../../../../utils/server";
import { showMessage } from "../../../../../utils/notifications";
import { get_loan_request_details_service } from "../../loanRequestApi";



export interface LoanRequestDetailsQueryProps {
    payload: {
        id: string
    }
}

export const useQueryLoanRequestDetails = (props: LoanRequestDetailsQueryProps) => {
    const {
        payload: {
            id
        }
    } = props

    const [loanRequestDetails, setLoanRequestDetails] = React.useState<any | null>(null);
    const [loanRequestDetailsLoading, setLoanRequestDetailsLoading] = React.useState(false)



    const getLoanRequestDetails = React.useCallback(async (id: string) => {
        try {
            setLoanRequestDetailsLoading(true)

            const response = await get_loan_request_details_service(id);

            setLoanRequestDetails(response?.responseBody)
        } catch (error: any) {
            showMessage("Loan Request Details", parseError(error), "error");
            setLoanRequestDetails(null)
        } finally {
            setLoanRequestDetailsLoading(false)
        }

    }, [])


    React.useEffect(() => {

        if (id) {
            getLoanRequestDetails(id);
        }

    }, [getLoanRequestDetails, id])

    return {
        loanRequestDetailsLoading,
        loanRequestDetails,
        getLoanRequestDetails,
    }

}
