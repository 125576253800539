import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Table, Button, Form, Input, Row, Col } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { showMessage } from "../../../../utils/notifications";
import { initPagination } from "../../../../utils/pagination";
import { getAllProvisioningTemplates } from "../provisionTemplateSlice/provisionTemplate.thunks";

interface Props {
  searchParams?: any
}

const FormItem = Form.Item;

const ViewProvisioningTemplateTable: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { provisioningTemplateReducer: { provisioningTemplateAllData, portfolioAllLoading, provisioningAllError, portfolioPagination }
  } = useAppSelector(state => state)
  const [searchParams, setSearchParams] = useState({})

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      dispatch(getAllProvisioningTemplates())
    }
  }, [dispatch]);

  useEffect(() => {
    if (provisioningAllError) {

      showMessage('Business Relationship Managers', provisioningAllError, 'error');
    }
  }, [provisioningAllError])

  const filterProvisioningTemplates = () => {
    const formValues = form.getFieldsValue();

    let searchParams = Object.keys(formValues).reduce((reducedObject: any, key: any) => {
      if (formValues[key]) {
        reducedObject[key] = formValues[key]
      }
      return reducedObject
    }, {})

    setSearchParams(searchParams)
    dispatch(getAllProvisioningTemplates(initPagination))
  }
  const onClearForm = () => {
    form.resetFields();
    dispatch(getAllProvisioningTemplates())
  };
  const handleTableChange = (pagination: any) => {
    fetchData(pagination, searchParams)
  }

  const fetchData = (pagination?: any, searchParams?: any) => {
    dispatch(getAllProvisioningTemplates(pagination))
  }

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value: string) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (value: string) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "",
      dataIndex: "pmCode",
      key: "pmCode",
      render: (value: string) => {
        return (
          <Link to={`/dashboard`}>
            <Button
              type="primary"
              className="bg-[#0190fe] border-[#0190fe] rounded"
            >
              View Details
            </Button>
          </Link>
        );
      },
    },
  ];

  const renderFilterForm = () => {
    return (
      <Form layout="vertical" form={form} name="filter-fco">
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8}>
            <FormItem label="Name" name={"name"} initialValue="">
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col xs={24} sm={12} md={3}>
            <Button
              type="primary"
              className="w-full rounded"
              onClick={onClearForm}
              danger
            >
              Clear
            </Button>
          </Col>
          <Col xs={24} sm={12} md={4}>
            <Button
              type="primary"
              onClick={filterProvisioningTemplates}
              className="w-full bg-[#0190fe] border-[#0190fe] rounded"
              htmlType="submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };
  return (
    <>
      <Card title="Provisioning Templates" className="my-[18px]">
        {renderFilterForm()}
      </Card>
      <Card title="Provisioning Templates" className="my-[18px] w-full">
        <Table
          className="w-full overflow-x-auto"
          dataSource={provisioningTemplateAllData}
          columns={tableColumns}
          rowKey={(record) => record.id}
          loading={portfolioAllLoading}
          pagination={portfolioPagination}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

export default ViewProvisioningTemplateTable;
