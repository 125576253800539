export const hasOnlyDigits = (str: string) => {
    return /^(\d+)$/.test(str);
};

export const startsWithMultipleZeros = (str: string) => {
    return str.trim().startsWith("00")
}

export const isIntOrTwoDecimalFloat = (str: string) => {
    const regx = /^(\d+)?(\.(\d{1,2})?)?$/;
    return regx.test(str);
}
