import React, { FC, useEffect, useState } from "react";
import { find } from "lodash";
import { Descriptions, Empty, Spin, Tree, Typography } from "antd";

import { useAppDispatch, useAppSelector } from "../../../../../store";
import {
  getFcosDetails,
  getAllLgs
} from "../../../FCOManagement/fcoSlice/fco.thunks";
import { useFcoPortfolioLevel } from "../../../FCOManagement/FCOdetails/hooks/useFcoPortfolioLevel";

interface Props {
  src: any;
}

const UpdatePortfolioLevelFCOWorkFlow: FC<Props> = ({ src }) => {
  const dispatch = useAppDispatch();
  const {
    fcos: { fcoDetailData, loading, allLgaData }
  } = useAppSelector((state) => state);

  const [assignedLga, setAssignedLga] = useState<any[]>([]);
  const [newPortfolioCode, setNewPortfolioCode] = useState<string>("");

  const { fcoPortfolioLevelData, getFcoPortfolioLevel } = useFcoPortfolioLevel({
    payload: {}
  });
  const saveLgsAndStateCode = (lgaData: [], searchArry: [], mode: string) => {
    const data: any = [];

    if (mode === "assigned") {
      if (fcoDetailData?.lgaCodes) {
        for (let i = 0; i < searchArry.length; i++) {
          data.push(find(lgaData, { code: searchArry[i] }));
        }
      }
      if (data.length > 0) {
        setAssignedLga(data);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && src && src.request && src.request.fcoCode) {
      dispatch(getAllLgs());
      dispatch(getFcosDetails(src.request.fcoCode));
      getFcoPortfolioLevel(src.request.fcoCode);
      if (src.request.portfolioLevelCode) {
        setNewPortfolioCode(src.request.portfolioLevelCode);
      }
    }
    // eslint-disable-next-line
  }, [src, dispatch]);
  useEffect(() => {
    if (allLgaData && fcoDetailData) {
      saveLgsAndStateCode(allLgaData, fcoDetailData.lgaCodes, "assigned");
    }
    // eslint-disable-next-line
  }, [allLgaData, fcoDetailData]);

  return (
    <React.Fragment>
      {loading ? (
        <section className="centered-container">
          <Spin tip="Loading Details..." />
        </section>
      ) : fcoDetailData ? (
        <>
          <Descriptions title="Request Details" layout="vertical">
            <Descriptions.Item
              label={<Typography.Text strong>User Name</Typography.Text>}
            >
              {fcoDetailData?.username}
            </Descriptions.Item>
            <Descriptions.Item
              label={<Typography.Text strong>First Name</Typography.Text>}
            >
              {fcoDetailData?.firstName}
            </Descriptions.Item>
            <Descriptions.Item
              label={<Typography.Text strong>Last Name</Typography.Text>}
            >
              {fcoDetailData?.lastName}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Typography.Text strong>
                  Previous Portfolio Level
                </Typography.Text>
              }
            >
              {fcoPortfolioLevelData?.portfolioLevel?.name || "--------"}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Typography.Text strong>New Portfolio Level</Typography.Text>
              }
            >
              {newPortfolioCode || "--------"}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Typography.Text strong>
                  Field Credit Officer Assigned LGAs
                </Typography.Text>
              }
            >
              <Tree
                treeData={assignedLga.map((lga) => ({
                  title: lga.name,
                  key: lga.id,
                  children: [
                    { key: lga.code, title: lga.code },
                    { key: `${lga.state}-${lga.code}`, title: lga.state }
                  ]
                }))}
              />
            </Descriptions.Item>
          </Descriptions>
        </>
      ) : (
        <section className="centered-container">
          <Empty />
        </section>
      )}
    </React.Fragment>
  );
};

export default UpdatePortfolioLevelFCOWorkFlow;
