import { createSlice } from '@reduxjs/toolkit';
import { initPagination } from '../../../../utils/pagination';
import { ILoanRequest } from './loanRequest.types';

const initialState:ILoanRequest = {

    loanRequestData: null,
    loanRequestDataPagination: initPagination,
    error: "",
    loading: false,
    success: false,
    loanRequestDataDetailData: null,
    loanRequestDataDetailError: "",
    loanRequestDataDetaiLoading: false,
    loanRequestDataDetailSuccess: false,
    loanReassignedData:null,
    loanReassignedError:"",
    
    loanReassignedLoading:false,

    loanReassignedSuccess:false,
};

const loanRequestSlice = createSlice({
    name: "loanRequestdetails",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },

        saveAllLoanRequest: (state, action) => {

            state.loanRequestData = action.payload?.responseBody?.content
            state.error = ""
        },
        saveAllLoanRequestDataPagination: (state, action) => {
            state.loanRequestDataPagination = action.payload
        },
        setLoanRequestDetailError: (state, action) => {
            state.loanRequestDataDetailError = action.payload
            state.success = false
        },
        saveLoanRequestDetails: (state, action) => {

            state.loanRequestDataDetailData = action.payload?.responseBody
        },
        saveLoanRequestError: (state, action) => {

            state.error= action.payload
        },
        setLoanRequestDetailsLoading: (state, action) => {
            state.loanRequestDataDetaiLoading = action.payload
        },
        setLoanReassignedLoading:(state, action) => {
            state.loanReassignedLoading = action.payload
        },
        setLoanReassignedError:(state, action) => {
            state.loanReassignedError = action.payload
        },
        setLoanReassignedSuccess:(state, action) => {
            state.loanReassignedSuccess = action.payload
        },
        reset: (state) => {
            state.loading=false
            state.error=""
            state.success=false
            state.loanRequestDataPagination=initPagination
            state.loanRequestDataDetailData=null 
        },
        resetLoanRequest: (state) => {
            state.loanRequestDataDetaiLoading=false
            state.loanRequestDataDetailError=""
            state.loanRequestDataDetailSuccess=false
           
            state.loanRequestDataDetailData=null 
        },
        resetLoanReassign:(state)=>{
            state.loanReassignedSuccess=false
            state.loanReassignedError=""
            state.loanReassignedLoading=false
            state.loanReassignedData=null
        }
    }
})

export const {resetLoanRequest,setLoanReassignedLoading,setLoanReassignedError, resetLoanReassign,setLoanReassignedSuccess,setLoanRequestDetailsLoading,setLoading,reset,saveLoanRequestError, saveLoanRequestDetails,setLoanRequestDetailError,saveAllLoanRequestDataPagination,saveAllLoanRequest} = loanRequestSlice.actions;
const loanRequestReducer = loanRequestSlice.reducer


export default loanRequestReducer;