import React, { useState, useEffect } from "react";
import { Button, Card, Form, Input } from "antd";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { showMessage } from "../../../../utils/notifications";
import {
  createPortfolioLevel,
  resetCreateFcoLevelSuccessState,
} from "../portfolioSlice/portofolio.thunks";


const FormItem = Form.Item;
const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "Create Field Credit Risk Officer Portfolio Level",
    active: true,
  },
];
const minNum = 2;
const maxNum = 255;
const CreateFcoPortfolioLevel: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    portFolioReducer: {
      createPortfolioLevelLoading,
      createPortfolioLevelloadingSucess,
      createPortfolioLevelError,
    },
  } = useAppSelector((state) => state);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);
  const onSubmit = (values: any) => {

    const data = {
      code: values?.code,
      name: values?.name,
      loanPortfolioLimit: Number(values?.loanPortfolioLimit),
      provisionedLimit: Number(values?.provisionedLimit),
    };
    dispatch(createPortfolioLevel(data));
  };
 
  useEffect(() => {
    if (createPortfolioLevelloadingSucess) {
      showMessage(
        "Field Credit Officer Portfolio Level",
        "Request is Pending approval",
        "success"
      );
      dispatch(resetCreateFcoLevelSuccessState());
      form.resetFields();
    }
  }, [createPortfolioLevelloadingSucess, dispatch,form]);

  useEffect(() => {
    if (createPortfolioLevelError) {
      showMessage(
        "Field Credit Officer Portfolio Level",
        createPortfolioLevelError,
        "error"
      );
    }
  }, [createPortfolioLevelError]);

  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <Card title="Create FCO Portfolio Level" className="w-full">
        <div className="place-items-center">
          <Form
            layout="vertical"
            className="pb-8"
            form={form}
            name="create-fc0-level"
            onFinish={onSubmit}
          >
            <FormItem
              label="Level Name"
              name="name"
              hasFeedback
              rules={[
                { required: true, message: "Please input Level Name!" },
                { min: minNum, message: "Minimum of two characters" },
                { max: maxNum, message: "Maximum of 255 characters" },
                {
                  pattern:new RegExp(/^[ A-Za-z0-9]*$/),
                  message:"Special character not allowed"
                }
              
              ]}
            >
              <Input size="large" placeholder="" className="w-3/6" />
            </FormItem>
            <FormItem
              label="Level Code"
              name="code"
              hasFeedback
              rules={[
                { required: true, message: "Please input Level Code!" },
                { min: minNum, message: "Minimum of two characters" },
                { max: maxNum, message: "Maximum of 255 characters" },
                {
                  pattern:new RegExp(/^[ A-Za-z0-9]*$/),
                  message:"Special character not allowed"
                }
              ]}
            >
              <Input size="large" placeholder="" className="w-3/6" />
            </FormItem>
            <FormItem
              label="FCO Gross Loan Portfolio Value limit"
              name="loanPortfolioLimit"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please input portfolio value limit!",
                },
                { min: 1, message: "Minimum of two characters" },
                { max: maxNum, message: "Maximum of 255 characters" },
                {
                  pattern: new RegExp(/^[0-9]*$/),
                  message: "Only Numbers are allowed",
                },
              ]}
            >
              <Input size="large" placeholder="" className="w-3/6" />
            </FormItem>
            <FormItem
              label="FCO Provisioned Value limit"
              name="provisionedLimit"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please input provisioned value limit!",
                },
                { min: 1, message: "Minimum of two characters" },
                { max: maxNum, message: "Maximum of 255 characters" },
                {
                  pattern: new RegExp(/^[0-9]*$/),
                  message: "Only Numbers are allowed",
                },
              ]}
            >
              <Input size="large" placeholder="" className="w-3/6" />
            </FormItem>
            <Form.Item shouldUpdate className="mt-10">
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={createPortfolioLevelLoading}
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                  className="w-[150px] bg-[#0190fe] border-[#0190fe] rounded"
                >
                  Create Fco level
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </Card>
    </Page>
  );
};

export default CreateFcoPortfolioLevel;
