import { ArrowLeftOutlined, ReloadOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Avatar, Button, Collapse, Descriptions, Empty, Form, Image, Input, Spin, Typography, } from "antd";
import React, { FC, useState } from "react";
import { ILocalGovernment } from "../UtilsApi/utilsAPI.types";
import AssignOrUnassignLGAsComponent from "../AssignOrUnassignLGAs";
import { useFileRef } from "../FilesUtils/hooks/useFileRef";
import { useQueryMoniepointUserAccountDetails } from "../hooks/moniepointUserAccount";

const { Panel } = Collapse

type OnboardingRequestProps = {
    accountNumber: string
    stateCode?: string
    lgaCodes?: string[]
}

interface OnboardingWizardProps {
    userType: "FCO" | "PM" | "WCLRM"
    onSubmitOnboardingRequest(payload: OnboardingRequestProps): void
    submitLoading: boolean
    submitError?: string
}

const OnboardingWizard: FC<OnboardingWizardProps> = ({
    userType,
    submitError,
    submitLoading,
    onSubmitOnboardingRequest,
}) => {

    const {
        moniepointUserAccountDetails,
        moniepointUserAccountDetailsError,
        moniepointUserAccountDetailsLoading,
        fetchMoniepointUserAccoutDetails
    } = useQueryMoniepointUserAccountDetails({
        payload: {}
    })

    const [form] = Form.useForm()
    const accountNumber = Form.useWatch('account-number', form)

    enum ActiveKey {
        ACCOUNT_NUMBER = "account-number",
        USER_DETAILS = "user-details",
        ASSIGN_STATE_AND_LGAS = "assign-state-and-lgas",
        SUBMIT = "submit",
    }

    const customPanelStyle = {
        background: '#f9fafc',
        borderRadius: 4,
    }

    const [activeKey, setActiveKey] = useState<ActiveKey>(ActiveKey.ACCOUNT_NUMBER)
    const [LGAsToAssign, setLGAsToAssign] = useState<string[]>([])
    const [stateToAssign, setStateToAssign] = useState("")
    const { image } = useFileRef(moniepointUserAccountDetails?.passportRef || "")


    const onFetchAccountDetails = () => {
        const accountNumber = form.getFieldValue("account-number")
        fetchMoniepointUserAccoutDetails(accountNumber)
        setActiveKey(ActiveKey.USER_DETAILS)
    }

    const onLGAsToAssignSelected = ({
        allTergets,
        onTransferFulfilled,
    }: {
        allTergets: ILocalGovernment[]
        onTransferFulfilled?: () => void
    }) => {
        setLGAsToAssign(allTergets.map(item => item.code))
        if (onTransferFulfilled) {
            onTransferFulfilled()
        }

       
    }

    const onStateSelected = (stateCode: string) => {
        setStateToAssign(stateCode)
    }

    const onSubmitrequest = () => {
        let requestParams: OnboardingRequestProps = { accountNumber: form.getFieldValue("account-number") }

        if (userType === "FCO" || userType === "PM") {
            requestParams = {
                ...requestParams,
                stateCode: stateToAssign,
                lgaCodes: LGAsToAssign,
            }
        }

        onSubmitOnboardingRequest(requestParams)
    }

    return (
        <>
            <Collapse
                bordered={false}
                activeKey={activeKey}
            >
                <Panel
                    showArrow={false}
                    header={
                        <Typography.Title level={5}>
                            {"Enter Account Number"}
                        </Typography.Title>
                    }
                    key={ActiveKey.ACCOUNT_NUMBER}
                    style={customPanelStyle}
                >
                    <Form
                        form={form}
                        name="onboarding-form"
                        layout="vertical"
                    >
                        <Form.Item
                            label="Moniepoint Account Number"
                            name="account-number"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a valid account number',
                                    validator(_, value) {
                                        if (value?.length > 10) {
                                            return Promise.reject(new Error('Account number can only have Ten(10) digits'))
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                }
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="Enter Moniepoint Account Number"
                                type="number"
                                className="input-number-no-control"
                            />
                        </Form.Item>

                        <Form.Item className="flex items-center justify-end">
                            <Button
                                type="primary" htmlType="submit"
                                disabled={!accountNumber?.length || accountNumber?.length !== 10}
                                onClick={onFetchAccountDetails}
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Panel>

                <Panel
                    showArrow={false}
                    header={
                        <Typography.Title level={5}>
                            {"Verify User Details"}
                        </Typography.Title>
                    }
                    key={ActiveKey.USER_DETAILS}
                    style={customPanelStyle}
                >
                    <Button
                        type="link"
                        size="large"
                        onClick={() => setActiveKey(ActiveKey.ACCOUNT_NUMBER)}
                        icon={<ArrowLeftOutlined />}
                        className="mb-4"
                    >
                        Back
                    </Button>

                    {
                        moniepointUserAccountDetailsLoading
                            ? (
                                <section className=" flex items-center justify-center">
                                    <Spin tip="Loading Details..." />
                                </section>
                            )
                            : moniepointUserAccountDetailsError
                                ? (
                                    <Alert
                                        description={
                                            <div className="flex items-center justify-center space-x-2">
                                                <span>
                                                    An error occurred while fetching user details.
                                                </span>
                                                <span
                                                    onClick={onFetchAccountDetails}
                                                >
                                                    <ReloadOutlined />
                                                </span>
                                            </div>
                                        }
                                        type="error"
                                        showIcon
                                    />
                                )
                                : moniepointUserAccountDetails
                                    ? (
                                        <>
                                            <Descriptions
                                                layout="vertical"
                                                column={{ md: 2, sm: 1, xs: 1 }}
                                            >
                                                <Descriptions.Item
                                                    label={
                                                        <Typography.Text strong>
                                                            Passport Photo
                                                        </Typography.Text>
                                                    }
                                                    span={3}
                                                >
                                                    {
                                                        image
                                                            ? <Image src={image} width={200} placeholder />
                                                            : <Avatar shape="square" size={200} icon={<UserOutlined />} />
                                                    }
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<strong>{'First Name'}</strong>}>
                                                    {moniepointUserAccountDetails?.firstName}
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<strong>{'Last Name'}</strong>}>
                                                    {moniepointUserAccountDetails?.lastName}
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<strong>{'Phone Number'}</strong>}>
                                                    {moniepointUserAccountDetails?.mobileNumber}
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<strong>{'Email'}</strong>}>
                                                    {moniepointUserAccountDetails?.emailAddress}
                                                </Descriptions.Item>
                                            </Descriptions>

                                            <Button
                                                type="primary"
                                                onClick={() => setActiveKey(
                                                    userType !== "WCLRM"
                                                        ? ActiveKey.ASSIGN_STATE_AND_LGAS
                                                        : ActiveKey.SUBMIT
                                                )}
                                                className=" w-full mt-4"
                                                disabled={!Boolean(moniepointUserAccountDetails)}
                                            >
                                                Continue
                                            </Button>
                                        </>
                                    )
                                    : (
                                        <section className=" flex items-center justify-center py-8">
                                            <Empty description="No data found" />
                                        </section>
                                    )
                    }
                </Panel>

                {(userType === "FCO" || userType === "PM") && (
                    <Panel
                        showArrow={false}
                        header={
                            <Typography.Title level={5}>
                                {"Assign state and LGAs"}
                            </Typography.Title>
                        }
                        key={ActiveKey.ASSIGN_STATE_AND_LGAS}
                        style={customPanelStyle}
                    >
                        <Button
                            type="link"
                            size="large"
                            onClick={() => setActiveKey(ActiveKey.USER_DETAILS)}
                            icon={<ArrowLeftOutlined />}
                            className="mb-4"
                        >
                            Back
                        </Button>

                        <AssignOrUnassignLGAsComponent
                            oneWay
                            onTransfer={onLGAsToAssignSelected}
                            onStateSelect={onStateSelected}
                        />

                        <Button
                            type="primary"
                            onClick={() => setActiveKey(ActiveKey.SUBMIT)}
                            className=" w-full mt-12"
                            disabled={
                                (!stateToAssign || !LGAsToAssign?.length)
                            }
                        >
                            Continue
                        </Button>
                    </Panel>
                )}

                <Panel
                    showArrow={false}
                    header={
                        <Typography.Title level={5}>
                            {"Submit Onboarding request"}
                        </Typography.Title>
                    }
                    key={ActiveKey.SUBMIT}
                    style={customPanelStyle}
                >
                    <Button
                        type="link"
                        size="large"
                        onClick={() => setActiveKey(
                            userType === "FCO" || userType === "PM"
                                ? ActiveKey.ASSIGN_STATE_AND_LGAS
                                : ActiveKey.USER_DETAILS
                        )}
                        icon={<ArrowLeftOutlined />}
                        className="mb-4"
                    >
                        Back
                    </Button>

                    {submitError && (
                        <div className="flex items-center justify-center">
                            <Alert
                                message={submitError || "Sorry an error occurred"}
                                type="error"
                                showIcon
                                className=" w-full px-6 py-3 rounded"
                            />
                        </div>
                    )}

                    <div className="flex items-center justify-end mt-4">
                        <Button
                            type="primary"
                            onClick={onSubmitrequest}
                            loading={submitLoading}
                        >
                            Submit Request
                        </Button>
                    </div>
                </Panel>
            </Collapse>
        </>
    )
}


export default OnboardingWizard
