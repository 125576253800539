import React, { useRef } from "react";
import { Table } from "antd";
import { FCOTableColumns } from "./componentDataStructures";
import { FCOListProps } from "./types";
import useQueryFCOList from "./hooks/useQueryFCOList";

const FCOList: React.FC<FCOListProps> = (props) => {
  const searchParams = useRef({});
  const {
    loadingFCOList,
    loanRequestDataPagination,
    fcoList,
    fetchFCOList,
  } = useQueryFCOList({
    payload: {
      id: props.loanRequestReference,
      searchParams,
    },
  });

  const handleTableChange = (pagination: any) => {
    fetchFCOList({
      loanRequestReference: props.loanRequestReference,
      searchParams,
      pagination,
    });
  };

  return (
    <>
      <Table
        className="w-full overflow-x-auto"
        dataSource={fcoList}
        columns={FCOTableColumns}
        rowKey={(record) => record.fcoCode}
        loading={loadingFCOList}
        pagination={loanRequestDataPagination}
        onChange={handleTableChange}
      />
    </>
  );
};

export default FCOList;
