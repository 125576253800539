import config from "../../../../config";
import server from "../../../../server";
import { IResponse } from "../../../../server/server.types";
import { initPagination } from "../../../../utils/pagination";
import { ReassignmentMode } from "../../Utils/UtilsSlice/utilsSlice.types";
import { FCOOnboardingParams } from "../fcoSlice/fco.types";

export const get_all_fcos_service = async (
  searchParams: Record<string, any> = {},
  pagination: Record<string, any> = initPagination
) => {
  let page = pagination.current - 1;
  const response = await server.get(`/field-credit-officers`, {
    params: { ...searchParams, page: page, size: pagination.pageSize },
  });

  return response.data;
};

export const get_fco_details_service = async (fcoCode: string) => {
  const response = await server.get(`/field-credit-officers/code/${encodeURIComponent(fcoCode)}`);

  return response.data;
};
export const assinged_lga_fco_service = async (
  fcoCode: string,
  lgaCodes: []
) => {
  const response = await server.post(`/field-credit-officers/assign-lgas`, {
    fcoCode,
    lgaCodes,
  });

  return response.data;
};
export const unassinged_lga_fco_service = async (
  fcoCode: string,
  lgaCodes: []
) => {
  const response = await server.post(`/field-credit-officers/remove-lgas`, {
    fcoCode,
    lgaCodes,
  });

  return response.data;
};


export const onboard_fco_service = async (
  params: FCOOnboardingParams
) => {
  const response = await server.post(
    `/field-credit-officers`,
    params
  )

  return response.data as IResponse<any>
}


const loanRequestsReassignmentModeToUrlMap = {
  [ReassignmentMode.AUTO]: `${config.WCL_PORTFOLIO_BASE_URL}/business-loan-requests/auto-reassign`,
  [ReassignmentMode.MANUAL]: `${config.WCL_PORTFOLIO_BASE_URL}/business-loan-requests/reassign-multiple`,
}

export const bulk_reassign_loan_requests = async (mode: ReassignmentMode, data: any) => {
  const response = await server.post(
      loanRequestsReassignmentModeToUrlMap[mode],
      data,
  )

  return response.data as IResponse<any>
}

export const auto_reassign_loan_requests = async (data: any) => {
  const response = await server.post(
    `${config.WCL_PORTFOLIO_BASE_URL}/business-loan-requests/auto-reassign`,
      data,
  )

  return response.data as IResponse<any>
}

export const manual_reassign_loan_requests = async (data: any) => {
  const response = await server.post(
    `${config.WCL_PORTFOLIO_BASE_URL}/business-loan-requests/reassign-multiple`,
      data,
  )

  return response.data as IResponse<any>
}



const loanContractsReassignmentModeToUrlMap = {
  [ReassignmentMode.AUTO]: `${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts/auto-reassign`,
  [ReassignmentMode.MANUAL]: `${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts/reassign-multiple`,
}

export const bulk_reassign_loan_contracts = async (mode: ReassignmentMode, data: any) => {
  const response = await server.post(
      loanContractsReassignmentModeToUrlMap[mode],
      data,
  )

  return response.data as IResponse<any>
}

export const auto_reassign_loan_contracts = async (data: any) => {
  const response = await server.post(
      `${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts/auto-reassign`,
      data,
  )

  return response.data as IResponse<any>
}

export const manual_reassign_loan_contracts = async (data: any) => {
  const response = await server.post(
      `${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts/reassign-multiple`,
      data,
  )

  return response.data as IResponse<any>
}
export const deactivate_fco_service = async (
  fcoCode: string,

) => {
  const response = await server.post(`${config.WCL_PORTFOLIO_BASE_URL}/field-credit-officers/deactivate`, {
    fcoCode,
 
  });

  return response.data;
};
export const promote_fco_service = async (
  fcoCode: string,

) => {
  const response = await server.post(`${config.WCL_PORTFOLIO_BASE_URL}/field-credit-officers/promote`, {
    fcoCode,
 
  });

  return response.data;
};
export const get_fco_portfolio_level_service = async (fcoCode: string) => {
  const response = await server.get(`${config.WCL_PORTFOLIO_BASE_URL}/field-credit-officers/${encodeURIComponent(fcoCode)}`);

  return response.data;
};

export const update_fco_portfolio_level_service  = async (data: any) => {
  const response = await server.post(
      `${config.WCL_PORTFOLIO_BASE_URL}/field-credit-officers/update-portfolio-limit`,
      data,
  )

  return response.data as IResponse<any>
}