import { Collapse } from "antd";
import { ILoanRequestDetails } from "../../utils/types";
import LoanManagers from "./LoanManagers";
import MoniepointManagers from "./MoniepointManagers";

const { Panel } = Collapse;

type LoanParticipantsProps = {
  details?: ILoanRequestDetails;
};

const LoanParticipants = ({ details }: LoanParticipantsProps) => {
  return (
    <div>
      <MoniepointManagers details={details} />
      <br />
      {details?.loanManagers && (
        <LoanManagers details={details?.loanManagers} />
      )}
      <br />
    </div>
  );
};

export default LoanParticipants;
