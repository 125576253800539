import { createSlice } from '@reduxjs/toolkit';
import { IAuthState } from './authSlice.types';



export const initialState: IAuthState = {
    user: null,
    isAuthenticated: false,
    authError: "",
    authLoading: false,
    resendActivationMailLoading: false
};


const authSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        saveUser: (state, action) => {
            state.user = action.payload
            state.isAuthenticated = true
        },
        setAuthLoading: (state, action) => {
            state.authLoading = action.payload
        },
        setAuthError: (state, action) => {
            state.authError = action.payload
        },
        setResendctivationMailLoading: (state, action) => {
            state.resendActivationMailLoading = action.payload
        },
        clearUser: (state) => {
            state.user = null
            state.isAuthenticated = false
        }
    }
})

export const { saveUser, setAuthError, setAuthLoading, clearUser, setResendctivationMailLoading } = authSlice.actions
const authReducer = authSlice.reducer


export default authReducer

