import { createSlice } from '@reduxjs/toolkit';
import { initPagination } from '../../../../utils/pagination';

const initialState: any = {
    provisioningTemplateAllData: null,
    portfolioPagination:initPagination,
};

const provisionTemplateSlice = createSlice({
    name: "provisioningSystem",
    initialState,
    reducers: {
        setAllViewProvisioningLoading: (state, action) => {
            state.portfolioAllLoading = action.payload;
        },
        saveAllProvisioningDataPagination: (state, action) => {
            state.portfolioPagination= action.payload
        },
        saveAllProvisioningTemplateData: (state, action) => {
            state.provisioningTemplateAllData = action.payload?.content
        },
        setAllViewProvisioningError: (state, action) => {
            state.portfolioAllError = action.payload?.responseBody
        },
         
    }
})


export const {
    setAllViewProvisioningLoading,
    saveAllProvisioningTemplateData,
    setAllViewProvisioningError,
    saveAllProvisioningDataPagination,
} = provisionTemplateSlice.actions;

const provisioningTemplateReducer = provisionTemplateSlice.reducer


export default provisioningTemplateReducer;