import { Descriptions, Empty, Spin, } from 'antd'
import React, { FC, useEffect } from 'react'
import { upperCase } from "lodash"
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { fetchPortfolioManagementSettings } from '../../../PortfolioManagement/portfolioSlice/portofolio.thunks';
import { PortfolioManagementSettingsEntry } from '../../../PortfolioManagement/portfolioApi/portfolioAPI.types';


interface PortfolioManagementSettingsUpdateProps {
    src: any;
    data: any
}

const PortfolioManagementSettingsUpdate: FC<PortfolioManagementSettingsUpdateProps> = ({ src, data }) => {

    const dispatch = useAppDispatch()
    const {
        portFolioReducer: { portfolioManagementSettingsLoading, portfolioManagementSettings }
    } = useAppSelector(state => state)


    useEffect(() => {
        dispatch(fetchPortfolioManagementSettings())
    }, [dispatch, src])

    return (
        <React.Fragment>
            {portfolioManagementSettingsLoading ? (
                <section className="centered-container">
                    <Spin tip="Loading Details..." />
                </section>
            )
                :
                <>
                    <Descriptions title="Updated Settings" layout='vertical' column={{ xs: 1, md: 2 }}>
                        {src?.settingsRequest?.settingsEntries?.length && src?.settingsRequest?.settingsEntries?.map
                            ? src?.settingsRequest?.settingsEntries?.map(
                                (item: PortfolioManagementSettingsEntry) => (
                                    <Descriptions.Item key={item.key} label={upperCase(item.key)}>
                                        <strong>{item.value}</strong>
                                    </Descriptions.Item>
                                ))
                            : (
                                <section className="centered-container">
                                    <Empty />
                                </section>
                            )
                        }
                    </Descriptions>
                    <Descriptions className='mt-8' title="Previous Settings" layout='vertical' column={{ xs: 1, md: 2 }}>
                        {portfolioManagementSettings?.length ? (
                            portfolioManagementSettings?.map(item => (
                                <Descriptions.Item label={upperCase(item.settingKey)}>
                                    <strong>{item.value}</strong>
                                </Descriptions.Item>
                            ))
                        ) : (
                            <section className="centered-container">
                                <Empty />
                            </section>
                        )}
                    </Descriptions>
                </>
            }
        </React.Fragment>
    )
}

export default PortfolioManagementSettingsUpdate
