import { parseError } from '../../../../utils/server';
import { AppThunk } from '../../../../store';
import { api_update_portfolio_management_settings, create_fco_portfolio_level_service, get_all_portfolio_managers_service, assinged_lga_portfolio_manager__service, get_portfolio_levels_service, get_portfolio_management_settings, get_portfolio_manager_details_service, get_fco_portfolio_level_service, removed_lga_portfolio_manager_service,onboard_user_as_portfolio_manager_service } from '../portfolioApi';
import {
    setOnBoardUsersAsPmLoading,
    setOnBoardUsersAsPmSucess,
    resetOnBoardUsersError,
    saveAllPortfolioDataPagination,
    resetRemoveLgaPmDetails,
    removedPmSuccessAction,
    removedPmLoading,
    removedPmErorr, setLoading, saveFcoLevel, enablePmErorr, resetPmEnableDetails, enablePmSuccessAction, enablePmLoading, createPortfolioLevelError, setCreatePortfolioLevelSuccess, setSettingsUpdateLoading, saveSettingsUpdateResponse, setSettingsUpdateError, setAllPortfolioManagersLoading, saveAllPortfolioManagerData, setAllPortfolioManagerError, setPortfolioLevelsLoading, savePortfolioLevels, setPortfolioManagementSettingsLoading, savePortfolioManagementSettings, setPortfolioManagerDetailsLoading, savePortfolioManagerDetail, saveOnBoardUsersAsPmError
} from '.';
import { IPortfolioManagementSettingsParams, } from '../portfolioApi/portfolioAPI.types';
import { showMessage, showServerError } from '../../../../utils/notifications';
import { extractPaginationData } from '../../../../utils/pagination';   
import { onBoardUsersAsPmTypes } from './portfolio.types';




export const createPortfolioLevel =
    (data: any): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(setLoading(true))
                dispatch(setCreatePortfolioLevelSuccess(false))
                const response = await create_fco_portfolio_level_service(data);
                if (response?.requestSuccessful) {

                    dispatch(setCreatePortfolioLevelSuccess(true));
                }

            } catch (error: any) {
                dispatch(setLoading(false))
                dispatch(setCreatePortfolioLevelSuccess(false))
                dispatch(createPortfolioLevelError(parseError(error)))
            } finally {
                dispatch(setLoading(false))
            }

        };
export const resetCreateFcoLevelSuccessState =
    (): AppThunk => async (dispatch) => {
        dispatch(setCreatePortfolioLevelSuccess(false));

    }

export const getAllPortfolioManagers =
    (searchParams?: Record<string, any>,
        pagination?: Record<string, any>): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(setAllPortfolioManagersLoading(true))

                const response = await get_all_portfolio_managers_service(searchParams, pagination);
                const paginationData = extractPaginationData(response.responseBody)
                dispatch(saveAllPortfolioManagerData(response));
                dispatch(saveAllPortfolioDataPagination(paginationData))
            }
            catch (error: any) {

                dispatch(setAllPortfolioManagerError(parseError(error)))
            } finally {
                dispatch(setAllPortfolioManagersLoading(false))
            }
        }

export const fetchPortfolioLevels = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setPortfolioLevelsLoading(true))

        const response = await get_portfolio_levels_service()

        dispatch(savePortfolioLevels(response.responseBody))
    } catch (error: any) {
        showServerError(error)
    } finally {
        dispatch(setPortfolioLevelsLoading(false))
    }
}

export const fetchPortfolioManagementSettings = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setPortfolioManagementSettingsLoading(true))

        const response = await get_portfolio_management_settings()

        dispatch(savePortfolioManagementSettings(response.responseBody))
    } catch (error: any) {
        showServerError(error)
    } finally {
        dispatch(setPortfolioManagementSettingsLoading(false))
    }
}


export const updatePortfoliomanagementSettings = (params: IPortfolioManagementSettingsParams): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSettingsUpdateLoading(true))

            const response = await api_update_portfolio_management_settings(params)

            dispatch(saveSettingsUpdateResponse(response))
            showMessage("Success", response?.responseMessage)
        } catch (error: any) {
            dispatch(setSettingsUpdateError(parseError(error)))
            showServerError(parseError(error))
        } finally {
            dispatch(setSettingsUpdateLoading(false))
        }
    }

export const assignedLgsToPortfolioManager = (
    pmCode: any, 
    lgaCodes: any, 
    callBack?: ({requestSuccessful}: {requestSuccessful: boolean}) => void
): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(resetPmEnableDetails())
                dispatch(enablePmLoading(true))

                const response = await assinged_lga_portfolio_manager__service(pmCode, lgaCodes);

                if (response?.requestSuccessful) {
                    showMessage(
                        "SUCCESSFUL",
                        "Request is Pending approval",
                        "success"
                    );
                    if (callBack) {
                        callBack({requestSuccessful: response?.requestSuccessful})
                    }
                    dispatch(enablePmSuccessAction(true));
                }

            } catch (error: any) {
                const errorMessage = parseError(error)

                showMessage("Request Failed", errorMessage, "error")
                if (callBack) {
                    callBack({requestSuccessful: false})
                }
                dispatch(enablePmErorr(errorMessage))
            } finally {
                dispatch(enablePmLoading(false))
            }

        };

export const resetEnableLgaSuccessState =
    (): AppThunk => async (dispatch) => {
        dispatch(enablePmSuccessAction(false))
    }

export const removedLgsToPortfolioManager = (
    pmCode: any, 
    lgaCodes: any, 
    callBack?: ({requestSuccessful}: {requestSuccessful: boolean}) => void
): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(resetRemoveLgaPmDetails())
                dispatch(removedPmLoading(true))
                const response = await removed_lga_portfolio_manager_service(pmCode, lgaCodes);

                if (response?.requestSuccessful) {
                    showMessage(
                        "SUCCESSFUL",
                        "Request is Pending approval",
                        "success"
                    );
                    if (callBack) {
                        callBack({requestSuccessful: response?.requestSuccessful})
                    }
                    dispatch(removedPmSuccessAction(true));
                }

            } catch (error: any) {
                const errorMessage = parseError(error)

                showMessage("Request Failed", errorMessage, "error")
                if (callBack) {
                    callBack({requestSuccessful: false})
                }
                dispatch(removedPmErorr(errorMessage))
            } finally {
                dispatch(removedPmLoading(false))
            }

        };

export const resetRemoveLgaFromPoSuccessState =
    (): AppThunk => async (dispatch) => {
        dispatch(enablePmSuccessAction(false))
    }
export const fetchFCOPortfolioLevels = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoading(true))

        const response = await get_fco_portfolio_level_service()

        dispatch(saveFcoLevel(response.responseBody))
    } catch (error: any) {
        dispatch(createPortfolioLevelError(parseError(error)))

    } finally {
        dispatch(setLoading(false))

    }
}
export const fetchPortfolioManagerDetails = (requestId: string): AppThunk => async (dispatch) => {
    try {
        dispatch(setPortfolioManagerDetailsLoading(true))

        const response = await get_portfolio_manager_details_service(requestId)

        dispatch(savePortfolioManagerDetail(response?.responseBody))
    } catch (error: any) {
        showServerError(error)
    } finally {
        dispatch(setPortfolioManagerDetailsLoading(false))
    }
}

export const onBoardUsersAsPM =
    (payLoad:onBoardUsersAsPmTypes): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(resetOnBoardUsersError())
                dispatch(setOnBoardUsersAsPmLoading(true))
                const response = await onboard_user_as_portfolio_manager_service(payLoad);

                if (response?.requestSuccessful) {

                    dispatch(setOnBoardUsersAsPmSucess(true));
                }

            } catch (error: any) {
               dispatch(saveOnBoardUsersAsPmError(parseError(error)))
            } finally {
                dispatch(setOnBoardUsersAsPmLoading(false))
            }

        };
