import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Collapse, Divider, Form, Radio, RadioChangeEvent, Typography, } from "antd";
import React, { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import FCOsTable from "../../FCOManagement/ViewFCOManagers/FCOsTable";
import { RowSelectionType } from "antd/es/table/interface";
import LoanRequestsTable from "../../LoanRequestsManagement/ViewLoanRequests/LoanRequestsTable";
import { useQueryOpenLoanRequests } from "../../LoanRequestsManagement/ViewLoanRequests/hook";
import LoanContractsTable from "../../LoanContractsManagement/loanContractsTable";
import { useQueryLoanContracts } from "../../LoanContractsManagement/hooks/useQueryLoanContracts";
import TextArea from "antd/lib/input/TextArea";
import { ReassignmentMode as HowToReassign, WhatToReassign } from "../UtilsSlice/utilsSlice.types"
import { buildRowSelection, loanContractsReassignmentObjectBuilder, loanRequestsReassignmentObjectBuilder } from "./helpers";
import { useReassignFCOLoanRequests } from "../../FCOManagement/hooks.tsx/useReassignFCOLoanRequests";
import { useReassignFCOLoanContracts } from "../../FCOManagement/hooks.tsx/useReassignFCOLoanContracts";
import { showMessage } from "../../../../utils/notifications";
import { FCOStatus, FieldCreditOfficer } from "../../FCOManagement/fcoSlice/fco.types";


const { Panel } = Collapse

const customPanelStyle = {
    background: '#f9fafc',
    borderRadius: 4,
}

enum ActiveKey {
    CONFIGURE_REASSIGNMENT = 'CONFIGURE_REASSIGNMENT',
    MAKE_REASSIGNMENT = 'MAKE_REASSIGNMENT',
    SUBMIT = 'SUBMIT',
}

interface Props {
    userRef: string
    onReassignmentCallback(props: { requestSuccessful: boolean }): void
}

const LoanRequestsOrContractsReassignmentWizard: FC<Props> = ({ userRef, onReassignmentCallback }) => {
    const [form] = Form.useForm()
    const [activeKey, setActiveKey] = useState<ActiveKey>(ActiveKey.CONFIGURE_REASSIGNMENT)

    const defaultTableParams = useRef({
        loanRequests: { fcoCode: userRef },
        loanContracts: {
            fcoCode: userRef,
            contractState: 'ACTIVE'
        }
    })

    const {
        loading: loanRequestReassignmentLoading,
        reassignFCOLoanRequests,
    } = useReassignFCOLoanRequests()

    const {
        loading: loanContractsReassignmentLoading,
        reassignFCOLoanContracts,
    } = useReassignFCOLoanContracts();

    const [whatToReassign, setWhatToReassign] = useState<WhatToReassign>()
    const [howToReassign, setHowToReassign] = useState<HowToReassign>()

    const [selectedFCOs, setSelectedFCOs] = useState<string[]>([])
    const [selectedLoanRequests, setSelectedLoanRequests] = useState<string[]>([])
    const [selectedLoanContracts, setSelectedLoanContracts] = useState<string[]>([])

    const onConfigChange = <T,>(dispatch: Dispatch<SetStateAction<T>>) => (e: RadioChangeEvent) => {
        dispatch(e.target.value)
        if (selectedFCOs.length) setSelectedFCOs([]);
        if (selectedLoanRequests) setSelectedLoanRequests([]);
        if (selectedLoanContracts) setSelectedLoanContracts([]);
    }

    const fcoRowSelection = buildRowSelection({
        key: 'fcoCode',
        type: howToReassign === HowToReassign.AUTO ? 'checkbox' : 'radio' as RowSelectionType,
        persistedRowKeys: selectedFCOs,
        updatePersistedRowKeys: (newKeys: string[]) => setSelectedFCOs(newKeys),
        getCheckboxProps: (record: FieldCreditOfficer) => ({
            disabled: record.fcoCode === userRef || record.status === FCOStatus.DISABLED,
            name: record.fcoCode,
        }),
    })
    const loanRequestsRowSelection = buildRowSelection({
        key: 'loanRequestReference',
        type: 'checkbox' as RowSelectionType,
        persistedRowKeys: selectedLoanRequests,
        updatePersistedRowKeys: (newKeys: string[]) => setSelectedLoanRequests(newKeys)
    })
    const loanContractsRowSelection = buildRowSelection({
        key: 'loanContractReference',
        type: 'checkbox' as RowSelectionType,
        persistedRowKeys: selectedLoanContracts,
        updatePersistedRowKeys: (newKeys: string[]) => setSelectedLoanContracts(newKeys)
    })

    const validateForm = async (onSucess: (value: any) => void) => {
        try {
            const values = await form.validateFields(['reason']);
            onSucess(values);
        } catch (error) {

        }
    }

    const onSubmitReassignment = () => {
        if (!whatToReassign || !howToReassign) return;

        validateForm((values) => {

            if (whatToReassign === WhatToReassign.LOAN_REQUESTS) {
                const payload = loanRequestsReassignmentObjectBuilder({
                    userRef,
                    howToReassign,
                    reason: values['reason'],
                    selectedFCOs,
                    selectedLoanRequests,
                });

                if (payload) {
                    reassignFCOLoanRequests({
                        ...payload,
                        callback: ({ requestSuccessful }) => {
                            if (requestSuccessful) {
                                showMessage(
                                    'SUCCESSFUL',
                                    'Reassignment request was sent successfuly'
                                )
                                onReassignmentCallback({ requestSuccessful })
                            }
                        }
                    })
                }
            } else {
                const payload = loanContractsReassignmentObjectBuilder({
                    userRef,
                    howToReassign,
                    reason: values['reason'],
                    selectedFCOs,
                    selectedLoanContracts,
                })

                if (payload) {
                    reassignFCOLoanContracts({
                        ...payload,
                        callback:  ({ requestSuccessful }) => {
                            if (requestSuccessful) {
                                showMessage(
                                    'SUCCESSFUL',
                                    'Reassignment request was sent successfuly'
                                )
                                onReassignmentCallback({ requestSuccessful })
                            }
                        }
                    })
                }
            }
        })
    }


    const canSubmit = () => {
        let canSubmit = false

        if (howToReassign === HowToReassign.AUTO) {
            canSubmit = Boolean(selectedFCOs.length)
        } else if (howToReassign === HowToReassign.MANUAL) {
            canSubmit = whatToReassign === WhatToReassign.LOAN_CONTRACTS
                ? Boolean(selectedLoanContracts.length)
                : Boolean(selectedFCOs.length && selectedLoanRequests.length)
        }

        return canSubmit
    }

    const defaultFCOsSearchParams = React.useRef({ status: "ENABLED" });

    return (
        <>
            <Collapse
                bordered={false}
                activeKey={activeKey}
            >
                <Panel
                    showArrow={false}
                    header={
                        <Typography.Title level={5}>
                            {"First Configure the reassignment"}
                        </Typography.Title>
                    }
                    key={ActiveKey.CONFIGURE_REASSIGNMENT}
                    style={customPanelStyle}
                >
                    <div className="w-full mb-8">
                        <Typography>
                            {"What do you want to reassign?"}
                        </Typography>
                        <Radio.Group onChange={onConfigChange(setWhatToReassign)} value={whatToReassign}>
                            <Radio value={WhatToReassign.LOAN_REQUESTS}>Loan Requests</Radio>
                            <Radio value={WhatToReassign.LOAN_CONTRACTS}>Loan Contracts</Radio>
                        </Radio.Group>
                    </div>

                    <div className="w-full mb-4">
                        <Typography>
                            {"How do you want to reassign?"}
                        </Typography>
                        <Radio.Group onChange={onConfigChange(setHowToReassign)} value={howToReassign}>
                            <Radio value={HowToReassign.AUTO}>Use Auto Reassignment</Radio>
                            <Radio value={HowToReassign.MANUAL}>Use Manual Reassignment</Radio>
                        </Radio.Group>
                    </div>


                    <Button
                        type="primary"
                        onClick={() => setActiveKey(ActiveKey.MAKE_REASSIGNMENT)}
                        className=" w-full mt-4"
                        disabled={!(whatToReassign && howToReassign)}
                    >
                        Continue
                    </Button>
                </Panel>

                <Panel
                    showArrow={false}
                    header={
                        <Typography.Title level={5}>
                            {"Make reassignments"}
                        </Typography.Title>
                    }
                    key={ActiveKey.MAKE_REASSIGNMENT}
                    style={customPanelStyle}
                >
                    <Button
                        type="link"
                        size="large"
                        onClick={() => {
                            setActiveKey(ActiveKey.CONFIGURE_REASSIGNMENT)
                        }}
                        icon={<ArrowLeftOutlined />}
                        className="mb-4"
                    >
                        Back
                    </Button>

                    <Collapse
                        defaultActiveKey={'fcos'}
                        accordion
                    >

                        <Panel
                            header={
                                <Typography>
                                    {
                                        howToReassign === HowToReassign.AUTO
                                            ? `Select all the FCOs you wish to automatically reassign ${whatToReassign} to.`
                                            : `Select an FCO, then proceed to select some ${whatToReassign} you wish to reassign to them.`
                                    }
                                </Typography>
                            }
                            key={'fcos'}
                        >
                            <FCOsTable
                                rowSelection={fcoRowSelection}
                                fixedSearchParams={defaultFCOsSearchParams.current}
                            />
                        </Panel>

                        {howToReassign === HowToReassign.MANUAL && (
                            <Panel
                                header={
                                    <Typography>
                                        Select {whatToReassign} to reassign to the FCO you selected above.
                                    </Typography>
                                }
                                key={'loans'}
                            >
                                {whatToReassign === WhatToReassign.LOAN_REQUESTS && (
                                    <LoanRequestsTable
                                        useQueryLoanRequests={useQueryOpenLoanRequests}
                                        defaultSearchParams={defaultTableParams.current.loanRequests}
                                        rowSelection={loanRequestsRowSelection}
                                    />
                                )}
                                {whatToReassign === WhatToReassign.LOAN_CONTRACTS && (
                                    <LoanContractsTable
                                        useQueryLoanContracts={useQueryLoanContracts}
                                        defaultSearchParams={defaultTableParams.current.loanContracts}
                                        rowSelection={loanContractsRowSelection}
                                    />
                                )}
                            </Panel>
                        )}
                    </Collapse>

                    <Form layout="vertical" className="mt-8" form={form}>
                        <Divider>Reassignment Reason</Divider>
                        <Form.Item
                            label="Reason"
                            name="reason"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Reason must be at least 6 characters',
                                },
                                { min: 6 },
                                { max: 255 },
                            ]}
                        >
                            <TextArea rows={2} placeholder="Briefly state your reason for reassignment" />
                        </Form.Item>
                    </Form>

                    <Button
                        type="primary"
                        onClick={onSubmitReassignment}
                        className=" w-full mt-6"
                        disabled={!canSubmit()}
                        loading={loanRequestReassignmentLoading || loanContractsReassignmentLoading}
                    >
                        Submit Reassignment
                    </Button>
                </Panel>
            </Collapse>
        </>
    )
}


export default LoanRequestsOrContractsReassignmentWizard
