import React from "react";
import ViewFcos from "../ViewFCOManagers";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";

const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "View Field Credit Officers",
    active: true,
  },
];

const ViewFcosManagers = () => {
  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <ViewFcos />
    </Page>
  );
};

export default ViewFcosManagers;
