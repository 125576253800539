import React from "react"
import { promote_fco_service } from "../../fcoApi";
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";



export const usePromoteFcos = () => {
    const [promoteFcoLoading, setPromoteFcoLoading] = React.useState<boolean>(false);
    const [promoteFcoError, setPromoteFcoError] = React.useState<string>('');
    
    const  promoteFCO= async (
        fcoCode: string,

        callBack?: () => void
    ) => {
        try {
            setPromoteFcoLoading(true)
            setPromoteFcoError('') 
            const response = await promote_fco_service(fcoCode);

            if (response?.requestSuccessful) {
                showMessage(
                    "SUCCESSFUL",
                    "Request is Pending approval",
                    "success"
                );
                if (callBack) {
                    callBack()
                }
            }

        } catch (error: any) {
            const errorMessage = parseError(error)
           
            showMessage("Request Failed", errorMessage, "error");
            setPromoteFcoError(parseError(error));
        } finally {
            setPromoteFcoLoading(false)
        }

    };

     const clearFCOPromotionError=()=>{
        setPromoteFcoError('');
     }
    return {
        promoteFcoLoading,
        promoteFcoError,
        promoteFCO,
        clearFCOPromotionError
    }


}




   