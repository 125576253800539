import { Pagination } from "../../../../utils/pagination";

export interface IFcos{
    fcoData: any | null;
    fcoDataPagination: Pagination
    error: string;
    loading: boolean;
    fcoDetailData:any|null;
    success:boolean;
    enableFcoSuccess:boolean;
    enableFcoLoading:boolean;
    enableFcoErorr:string;
    targetKeys:any | null;
    fcoDetailError:string;
    allLgaData:any | null;
    unAssignedFcoSuccess:boolean;
    unAssignedFcoLoading:boolean;
    unAssignedFcoErorr:string;
    onboardFCOLoading: boolean
    onboardFCOError: string
} 

export type FieldCreditOfficer = {
    id: number;
    createdOn: string;
    lastModifiedOn: string;
    createdBy: string;
    username: string;
    rmCode: string;
    fcoCode: string;
    incomeAccount: string;
    kyeVerificationReference: string;
    kyeVerificationStatus: string;
    kyeVerificationStatusMessage: string;
    cbaCustomerCode: string;
    bvn: string;
    status: string;
    lgaCodes: string[];
    stateCode: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    imageRef: string;
    bankAccountNumber: string;
}

export type FCOOnboardingParams = {
    accountNumber: string
    lgaCodes: string[]
    stateCode:string
}
export type FCOUPDATEPORTFOLIOLEVEL= {
  fcoCode:string
  portfolioLevelId:number
}

export enum FCOStatus {
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED'
}
export const mockFCOPortfolioLevel={
    requestSuccessful: true,
    responseMessag: "success",
    responseCode: "0",
    responseBody: {
        id: 1,
        fcoCode: "XcT75l0LKugor9IsUGSO1RBOEleGUK9tpSgK",
        portfolioLevel: {
            id: 4,
            code: "LVL0",
            name: "LEVEL ZERO"
        }
    }
}