import React, { Component } from 'react';

class Optional extends Component {
    render() {
        if (this.props.visible) {
            return (<React.Fragment>
                {this.props.children}
            </React.Fragment>);
        } else return null;
    }
}

export default Optional;