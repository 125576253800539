import React from "react";
import { parseError } from "../../../../../utils/server";
import { showMessage } from "../../../../../utils/notifications";
import { get_fco_details_service } from "../../fcoApi";



export interface FCODetailsQueryProps {
    payload: {
        id: string
    }
}

export const useQueryFCODetails = (props: FCODetailsQueryProps) => {
    const {
        payload: {
            id
        }
    } = props

    const [fcoDetailData, setFcoDetailData] = React.useState<any | null>(null);
    const [loading, setLoading] = React.useState(false)



    const getFCODetails = React.useCallback(async (id: string) => {
        try {
            setLoading(true)

            const response = await get_fco_details_service(id);

            setFcoDetailData(response?.responseBody)
        } catch (error: any) {
            showMessage("Field Credit Officer", parseError(error), "error");
            setFcoDetailData(null)
        } finally {
            setLoading(false)
        }

    }, [])


    React.useEffect(() => {

        if (id) {
            getFCODetails(id);
        }

    }, [getFCODetails, id])

    return {
        loading,
        fcoDetailData,
        getFCODetails,
    }

}