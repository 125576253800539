import React from "react";
import { Pagination, extractPaginationData, initPagination } from "../../../../utils/pagination";
import { get_all_loan_requests_service, get_open_loan_requests_service } from "../loanRequestApi";
import { parseError } from "../../../../utils/server";
import { showMessage } from "../../../../utils/notifications";




export interface LoanRequestsQueryProps {
    payload: {
        searchParams?: Record<string, any>,
        pagination?: Pagination
    }
}

export const useQueryLoanRequestsBuilder = (
    props:LoanRequestsQueryProps,
    service: (       
        searchParams?: Record<string, any>,
        pagination?: Pagination
    ) => Promise<any> 
) => {
    const {
        payload: {
            searchParams,
            pagination
        }
    } = props

    const [loanRequestData, setLoanRequestData] = React.useState< any | null>(null);
    const [loanRequestDataPagination, setLoanRequestDataPagination] = React.useState<Pagination>(initPagination);
    const [error, setError] = React.useState('')
    const [loading, setLoading] = React.useState(false)



    const getAllLoanRequests = React.useCallback(async (
        searchParams?: Record<string, any>,
        pagination?: Pagination
    ) => {
            try {
                setError('')
                setLoading(true)

                const response = await service(searchParams, pagination);
                const paginationData = extractPaginationData(response.responseBody)

                setLoanRequestData(response?.responseBody?.content)
                setLoanRequestDataPagination(paginationData)

                
            } catch (error: any) {
                showMessage("Loan Requests", parseError(error), "error");
                setError(parseError(error))
            } finally {
                setLoading(false)
            }

        }, [service])

        
        React.useEffect(() => {

            getAllLoanRequests(searchParams, pagination)

        }, [searchParams, pagination, getAllLoanRequests])

        return {
            error,
            loading,
            loanRequestData,
            loanRequestDataPagination,
            getAllLoanRequests,
        }

}


export const useQueryLoanRequests = (props:LoanRequestsQueryProps) => useQueryLoanRequestsBuilder(
    props,
    get_all_loan_requests_service
)

export const useQueryOpenLoanRequests = (props:LoanRequestsQueryProps) => useQueryLoanRequestsBuilder(
    props,
    get_open_loan_requests_service
)
