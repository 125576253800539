import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import LoanRequestsDetails from "./components/LoanRequestDetails";

const getBreadCrumbItems = (tab: string): BreadCrumbItem[] => [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "All Loan Requests",
    path: `${DashboardRoutePaths.viewAllLoanRequests.path}?requestTab=${tab}`,
  },
  {
    title: "Loan Request",
    active: true,
  },
];

const ViewAllLoanRequestDetails = (props: any) => {
  const { state } = useLocation();
  const params = useParams<{ id: string; status: string }>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const loanRequestStatus = searchParams.get("status");
  const loanRequestStatusTab = searchParams.get("loanRequestStatusTab") || "";
  const loanProduct = searchParams.get("loanProduct");
  const breadCrumbItems = getBreadCrumbItems(loanRequestStatusTab);
  const getBasicLoanDetails = (loanRequestReference: any, data: any) => {
    // this function returns basic loan details
    if (data) return { data, redirect: false };
    // returns the state if present
    // otherwise check if present in session storage
    // or fetch from api- #TODO
    // otherwise redirect
    return { redirect: true };
  };
  const { data, redirect } = getBasicLoanDetails(params.id, state);
  if (redirect) {
    navigate(DashboardRoutePaths.viewAllLoanRequests.path);
  }
  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <LoanRequestsDetails
        loanDetails={data}
        status={loanRequestStatus || ""}
        loanProduct={loanProduct || ""}
        loanRequestReference={params?.id || ""}
        loanRequestStatusTab={loanRequestStatusTab}
      />
    </Page>
  );
};

export default ViewAllLoanRequestDetails;
