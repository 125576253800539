import React from "react";
import { parseError } from "../../../../../utils/server";
import { showMessage } from "../../../../../utils/notifications";
import { get_state_cordinator_detail_service } from "../../stateCoordinatorApi";



export interface StateCoordinatorDetailsQueryProps {
    payload: {
        id: string
    }
}

export const useQueryStateCoordinatorDetails = (props: StateCoordinatorDetailsQueryProps) => {
    const {
        payload: {
            id
        }
    } = props

    const [stateCoordinatorDetails, setStateCoordinatorDetails] = React.useState<any | null>(null);
    const [stateCoordinatorDetailsLoading, setStateCoordinatorDetailsLoading] = React.useState(false)



    const getStateCoordinatorDetails = React.useCallback(async (id: string) => {
        try {
            setStateCoordinatorDetailsLoading(true)

            const response = await get_state_cordinator_detail_service(id);

            setStateCoordinatorDetails(response?.responseBody)
        } catch (error: any) {
            showMessage("State Coordinator", parseError(error), "error");
            setStateCoordinatorDetails(null)
        } finally {
            setStateCoordinatorDetailsLoading(false)
        }

    }, [])


    React.useEffect(() => {

        getStateCoordinatorDetails(id)

    }, [getStateCoordinatorDetails, id])

    return {
        stateCoordinatorDetailsLoading,
        stateCoordinatorDetails,
        getStateCoordinatorDetails,
    }

}