import React from "react";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import ViewProvisioningTemplateTable from "../ViewProvisioningTemplate";

const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "View Provisoning Template",
    active: true,
  },
];

const ProvisioningTemplate = () => {
  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <ViewProvisioningTemplateTable/>
    </Page>
  );
};

export default ProvisioningTemplate;
