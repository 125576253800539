import { Alert, Button,Form,Select } from "antd";
import { PortfolioManagementSettingsEntryKey } from "../PortfolioManagement/portfolioApi/portfolioAPI.types";
import React from "react";
import { IPortfolioLevel } from "../PortfolioManagement/portfolioSlice/portfolio.types";

const FormItem = Form.Item;
const { Option } = Select;
interface Props {
  portfolioUpdateLoading: boolean;
  updateAction: (portfolioLevelId: string) => void;
  portfolioLevels: IPortfolioLevel[];
  clearErrorAction: () => void;
  error: any;
}
const UpdatePortfolioForm: React.FC<Props> = ({
  portfolioUpdateLoading,
  updateAction,
  portfolioLevels,
  clearErrorAction,
  error
}) => {
  const [form] = Form.useForm();

  const onFinish = (
    values: Record<PortfolioManagementSettingsEntryKey, string | number>
  ) => {
    const validFields: Partial<
      Record<PortfolioManagementSettingsEntryKey, string | number>
    > = {};
    let field: PortfolioManagementSettingsEntryKey;

    for (field in values) {
      if (values[field]) {
        validFields[field] = values[field];
      }
    }

    const settingsEntries = Object.entries(validFields).map((entry) => ({
      key: entry[0],
      value: entry[1]
    }));

    /// call actions here.
    if (settingsEntries && settingsEntries.length > 0) {
      updateAction(settingsEntries[0]?.value as string);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    //
  };

  return (
    <>
      {error && (
        <Alert
          message={error}
          type="error"
          closable
          className="mt-4 mb-[20px]"
          onClose={clearErrorAction}
        />
      )}
      <Form
        layout="vertical"
        className="w-full pb-8 flex flex-col items-center"
        form={form}
        name="portfolio-management-settings-update-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <section className="w-[50%] space-y-4 mb-6">
          <FormItem
            label="Portfolio Level code"
            name="PORTFOLIO_LEVEL_CODE"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select placeholder="Select Code" size="large">
              {portfolioLevels.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.code}
                </Option>
              ))}
            </Select>
          </FormItem>
        </section>

        <FormItem shouldUpdate>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full bg-[#0190fe] border-[#0190fe] rounded"
            loading={portfolioUpdateLoading}
          >
            Update Portfolio Level
          </Button>
        </FormItem>
      </Form>
    </>
  );
};

export default UpdatePortfolioForm;
