import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Spin,
  Descriptions,
  Typography,
  Button,
  Modal,
  Space,
  Alert,
  Empty
} from "antd";
import { PlusSquareTwoTone } from "@ant-design/icons";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import AssignOrUnassignLGAsComponent from "../../Utils/AssignOrUnassignLGAs";
import { ILocalGovernment } from "../../Utils/UtilsApi/utilsAPI.types";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { fetchLGAs } from "../../Utils/UtilsSlice/utilsSlice.thunks";
import { OnTransferProps } from "../../Utils/AssignOrUnassignLGAs/types";
import { useQueryFCODetails } from "./hooks/useQueryFCODetails";
import { useFcoLgaAssignment } from "./hooks/useFcoLgaAssignment";
import LoanRequestsOrContractsReassignmentWizard from "../../Utils/LoanRequestsOrContractsReassignmentWizard";
import {
  ActionsPersonelComponent,
  EPERSONELACTIONS
} from "../../../../components/OfficersActionsView";
import { useDeactivateFcos } from "./hooks/useDeactivateFcos";
import { usePromoteFcos } from "./hooks/usePromoteFcos";
import { FCOStatus, FCOUPDATEPORTFOLIOLEVEL } from "../fcoSlice/fco.types";
import { useFcoPortfolioLevel } from "./hooks/useFcoPortfolioLevel";
import UpdatePortfolioForm from "../../UpdatePortfolioLevel";
import { fetchPortfolioLevels } from "../../PortfolioManagement/portfolioSlice/portofolio.thunks";
import { useUpdatePortfolioLevelFcos } from "./hooks/useUpdatePortfolioLevelFcos";
import { parseAmountToCurrencyValue } from "../../../../utils/dataParser";
const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path
  },
  {
    title: "View Field Credit Officers",
    path: DashboardRoutePaths.viewFcosManagers.path
  },
  {
    title: "View Field Credit Officer Details",

    active: true
  }
];

enum ModalView {
  CLOSED = "CLOSED",
  REASSIGNMENT = "REASSIGNMENT",
  DEACTIVATEFCO = "DEACTIVATEFCO",
  PROMOTEFCO = "PROMOTEFCO",
  UPDATEPORTFOLIOLEVEL = "UPDATEPORTFOLIOLEVEL"
}

const ViewFCODetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    utils: { LGAs },
    portFolioReducer: { portfolioLevels }
  } = useAppSelector((state) => state);
  const [showLgaDetails, setShowLgaDetails] = useState<any | null>(null);
  const params = useParams<{ "*": string; id: string }>();
  const [modalView, setModalView] = useState(ModalView.CLOSED);

  const { fcoDetailData, loading } = useQueryFCODetails({
    payload: {
      id: params?.id as string
    }
  });

  const {
    assignLGAsLoading,
    unAssignLGAsLoading,
    assignLGAsToFCO,
    unAssignLGAsToFCO
  } = useFcoLgaAssignment();
  const { fcoPortfolioLevelData } = useFcoPortfolioLevel({
    payload: {
      fcoCode: params?.id as string
    }
  });
  const {
    fcoDeactivingLoading,
    fcoDeactivingError,
    deactivateFCO,
    clearFcoDeactivatingError
  } = useDeactivateFcos();
  const {
    promoteFCO,
    promoteFcoLoading,
    clearFCOPromotionError,
    promoteFcoError
  } = usePromoteFcos();
  const {
    updatePortfolioLeveFcosError,
    updatePortfolioLeveFcosLoading,
    updatePortfolioLevelFcos,
    clearUpdatePortfolioLevelError
  } = useUpdatePortfolioLevelFcos();
  useEffect(() => {
    if (!LGAs?.length) {
      dispatch(fetchLGAs());
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch(fetchPortfolioLevels());
  }, [dispatch]);
  const assignedLGAs = useMemo(() => {
    const assignedLGAs: ILocalGovernment[] = [];

    if (fcoDetailData?.lgaCodes?.length && LGAs?.length) {
      for (const lgaCode of fcoDetailData?.lgaCodes) {
        const lgaObject = LGAs.find((lga) => lga.code === lgaCode);

        if (lgaObject) {
          assignedLGAs.push(lgaObject);
        }
      }
    }

    return assignedLGAs;
  }, [LGAs, fcoDetailData?.lgaCodes]);

  const showDetailsFunc = (key: any) => {
    if (key === showLgaDetails) {
      setShowLgaDetails(null);
    } else {
      setShowLgaDetails(key);
    }
  };

  const onTransferLGAs = ({
    movedItems,
    direction,
    onTransferFulfilled
  }: OnTransferProps) => {
    const lgsCodes = movedItems.map((LGA) => LGA.code);

    const handleRequestFulfilled = ({
      requestSuccessful
    }: {
      requestSuccessful: boolean;
    }) => {
      if (requestSuccessful && onTransferFulfilled) {
        onTransferFulfilled();
      }
    };

    if (direction === "right") {
      assignLGAsToFCO(fcoDetailData?.fcoCode, lgsCodes, handleRequestFulfilled);
    } else {
      unAssignLGAsToFCO(
        fcoDetailData.fcoCode,
        lgsCodes,
        handleRequestFulfilled
      );
    }
  };

  const onProceedToReassignLoanRequestsOrContracts = () => {
    setModalView(ModalView.REASSIGNMENT);
  };
  const onProceedToDeactivateFCO = () => {
    setModalView(ModalView.DEACTIVATEFCO);
  };
  const onProceedToPromoteFCO = () => {
    setModalView(ModalView.PROMOTEFCO);
  };
  const onProceedToUpdateFCOPortfolioLevel = () => {
    setModalView(ModalView.UPDATEPORTFOLIOLEVEL);
  };
  const onCloseModal = () => {
    setModalView(ModalView.CLOSED);
    clearFcoDeactivatingError();
    clearFCOPromotionError();
    clearUpdatePortfolioLevelError();
  };

  const onDeactivateFco = () => {
    if (fcoDetailData && fcoDetailData?.fcoCode) {
      deactivateFCO(fcoDetailData.fcoCode, () => {
        onCloseModal();
      });
    }
  };
  const onPromotingFco = () => {
    if (fcoDetailData && fcoDetailData?.fcoCode) {
      promoteFCO(fcoDetailData.fcoCode, () => {
        onCloseModal();
      });
    }
  };
  const switchToReassignWizard = () => {
    clearFcoDeactivatingError();
    setModalView(ModalView.REASSIGNMENT);
  };
  const updateFcoPortfolioLevelAction = (portfolioLevelCode: string) => {
   
    if (fcoDetailData && fcoDetailData?.fcoCode && portfolioLevelCode) {
      const payload: FCOUPDATEPORTFOLIOLEVEL = {
        fcoCode: fcoDetailData?.fcoCode,
        portfolioLevelId: Number(portfolioLevelCode)
      };
      updatePortfolioLevelFcos(payload, () => {
        onCloseModal();
      });
    }
  };
  const promotingFCOErrorTemplate = (error: string) => {
    if (error) {
      return (
        <Alert
          message={error}
          type="error"
          closable
          className="mt-4"
          onClose={clearFCOPromotionError}
        />
      );
    } else {
      return <></>;
    }
  };
  const deactivatingFCOErrorTemplate = (error: string, code: string) => {
    const haveOpenLoanRequest =
      error ===
      `Field credit officer with FCO code ${code} has open loan requests`;
    const haveOpenLoanContract =
      error ===
      `Field credit officer with FCO code ${code} has active loan contracts`;
    if (error) {
      return (
        <>
          {haveOpenLoanRequest ? (
            <Alert
              message={
                <div className="flex justify-center">
                  <span>The Field credit officer have open loan requests.</span>
                  <span
                    className="text-blue-600 cursor-pointer"
                    onClick={switchToReassignWizard}
                  >
                    ( Please kindly click here to reassigned loan requests
                    assign to this FCO ).
                  </span>
                </div>
              }
              type="error"
              closable
              className="mt-4"
              onClose={clearFcoDeactivatingError}
            />
          ) : haveOpenLoanContract ? (
            <Alert
              message={
                <div className="flex justify-center">
                  <span>
                    The Field credit officer have open loan contracts.
                  </span>
                  <span
                    className="text-blue-600 cursor-pointer"
                    onClick={switchToReassignWizard}
                  >
                    ( Please kindly click here to reassigned loan contracts
                    assign to this FC ).{" "}
                  </span>
                </div>
              }
              type="error"
              closable
              className="mt-4"
              onClose={clearFcoDeactivatingError}
            />
          ) : (
            <Alert
              message={error}
              type="error"
              closable
              className="mt-4"
              onClose={clearFcoDeactivatingError}
            />
          )}
        </>
      );
    } else {
      return <></>;
    }
  };

  const onDeactivatingPromotingSubPage = (action: string) => {
    return (
      <>
        <Space direction="vertical" style={{ width: "100%", height: "200px" }}>
          <Spin tip="Loading" size="large" className="my-[30px]">
            <div className="content" />
          </Spin>
          <div className="my-[80px] flex justify-center">
            <span>
              {`${action} this field credit officer (${fcoDetailData?.username})`}
            </span>
          </div>
        </Space>
      </>
    );
  };

  return (
    <>
      <Page breadCrumbItems={breadCrumbItems}>
        <Card
          title="View Field Credit Officer Details"
          style={{ margin: "18px 0" }}
        >
          {loading ? (
            <section className="flex items-center justify-center h-[400px]">
              <Spin tip="Loading Details..." />
            </section>
          ) : fcoDetailData ? (
            <>
              <Descriptions layout="vertical">
                <Descriptions.Item
                  label={<Typography.Text strong>User Name</Typography.Text>}
                >
                  {fcoDetailData?.username}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Typography.Text strong>First Name</Typography.Text>}
                >
                  {fcoDetailData?.firstName}
                </Descriptions.Item>

                <Descriptions.Item
                  label={<Typography.Text strong>Last Name</Typography.Text>}
                >
                  {fcoDetailData?.lastName}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Typography.Text strong>Email</Typography.Text>}
                >
                  {fcoDetailData?.email}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Typography.Text strong>FCO Code</Typography.Text>}
                >
                  {fcoDetailData?.fcoCode}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Typography.Text strong>RM Code</Typography.Text>}
                >
                  {fcoDetailData?.rmCode}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <Typography.Text strong>Income Account</Typography.Text>
                  }
                >
                  {fcoDetailData?.incomeAccount}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Typography.Text strong>BVN</Typography.Text>}
                >
                  {fcoDetailData?.bvn}
                </Descriptions.Item>
                {
                  <Descriptions.Item
                    label={
                      <Typography.Text strong>Portfolio Level</Typography.Text>
                    }
                  >
                    {fcoPortfolioLevelData?.portfolioLevel?.name || "--------"}
                  </Descriptions.Item>
                }

                <Descriptions.Item
                  label={
                    <Typography.Text strong>Maximum Loan Portfolio (Limit)</Typography.Text>
                  }
                >
                  {parseAmountToCurrencyValue(fcoPortfolioLevelData?.maximumLoanPortfolio, "₦") || "--------"}
                </Descriptions.Item>


                <Descriptions.Item
                  label={
                    <Typography.Text strong>Current Loan Portfolio</Typography.Text>
                  }
                >
                  {parseAmountToCurrencyValue(fcoPortfolioLevelData?.currentLoanPortfolio, "₦") || "--------"}
                </Descriptions.Item>


                <Descriptions.Item
                  label={
                    <Typography.Text strong>
                      List of Assigned LGAs
                    </Typography.Text>
                  }
                >
                  <ul className="pl-[10px]">
                    {assignedLGAs.length > 0 ? (
                      assignedLGAs.map((value: any, key: any) => (
                        <div key={key}>
                          <div>
                            <span onClick={() => showDetailsFunc(key)}>
                              <PlusSquareTwoTone
                                style={{
                                  paddingRight: "10px",
                                  cursor: "pointer"
                                }}
                              />
                            </span>

                            <span>{value?.name}</span>
                            <span className="ml-[5px]">L.G.A</span>
                          </div>
                          {showLgaDetails === key ? (
                            <div className="pl-[25px] mb-[20px]">
                              <div className="mb-[5px] mt-[5px]"></div>
                              <div>
                                <span>{value?.state}</span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))
                    ) : (
                      <span>No Local Government Area Assigned yet</span>
                    )}
                  </ul>
                </Descriptions.Item>
              </Descriptions>

              {fcoDetailData?.status !== FCOStatus.DISABLED && (
                <div className="flex flex-wrap gap-y-6 gap-x-3">
                  <Button
                    type="primary"
                    className="rounded"
                    onClick={onProceedToReassignLoanRequestsOrContracts}
                  >
                    Reassign Loan requests/contracts
                  </Button>
                  <Button
                    type="primary"
                    className="rounded"
                    onClick={onProceedToDeactivateFCO}
                  >
                    Deactivate Field Credit Officer
                  </Button>
                  <Button
                    type="primary"
                    className="rounded"
                    onClick={onProceedToPromoteFCO}
                  >
                    Promote Field Credit Officer
                  </Button>
                  <Button
                    type="primary"
                    className="rounded"
                    onClick={onProceedToUpdateFCOPortfolioLevel}
                  >
                    Update Field Credit Officer Portfolio Level
                  </Button>
                </div>
              )}
            </>
          ) : (
            <section className="flex items-center justify-center h-[400px]">
              <Empty />
            </section>
          )}
        </Card>

        {fcoDetailData && fcoDetailData?.status !== FCOStatus.DISABLED && (
          <Card
            title="Assign or Remove Local Government Areas"
            className="mt-12"
          >
            <Spin
              tip="Loading.."
              spinning={assignLGAsLoading || unAssignLGAsLoading}
            >
              <AssignOrUnassignLGAsComponent
                title="Assign or Remove Local Government Areas"
                initialLGAs={assignedLGAs}
                onTransfer={onTransferLGAs}
                stateCode={fcoDetailData?.stateCode}
                disable={assignLGAsLoading || unAssignLGAsLoading}
              />
            </Spin>
          </Card>
        )}
      </Page>

      <Modal
        destroyOnClose={true}
        centered
        className="modal-lg my-6"
        width={900}
        open={modalView !== ModalView.CLOSED}
        onCancel={onCloseModal}
        title={
          <Typography.Title level={4}>
            {modalView === ModalView.REASSIGNMENT
              ? "Reassign Loan Requests or Contracts"
              : modalView === ModalView.DEACTIVATEFCO
              ? "Deactive Field Credit Officer"
              : modalView === ModalView.UPDATEPORTFOLIOLEVEL
              ? "Update Field Credit Officer Portfolio Level"
              : "Promote A Field Credit Officer"}
          </Typography.Title>
        }
        footer={null}
      >
        {modalView === ModalView.REASSIGNMENT && (
          <LoanRequestsOrContractsReassignmentWizard
            userRef={params?.id as string}
            onReassignmentCallback={({ requestSuccessful }) => {
              if (requestSuccessful) onCloseModal();
            }}
          />
        )}
        {modalView === ModalView.DEACTIVATEFCO && (
          <ActionsPersonelComponent
            errorTemplate={deactivatingFCOErrorTemplate(
              fcoDeactivingError,
              fcoDetailData?.fcoCode
            )}
            title={`DO YOU WANT TO DEACTIVE THIS FIELD CREDIT OFFICER (${fcoDetailData?.username}) ?`}
            onBack={onCloseModal}
            onAccept={onDeactivateFco}
            usedCase={EPERSONELACTIONS.FCODEACTIVATING}
            loading={fcoDeactivingLoading}
          >
            {onDeactivatingPromotingSubPage("Deactivate")}
          </ActionsPersonelComponent>
        )}
        {modalView === ModalView.PROMOTEFCO && (
          <ActionsPersonelComponent
            errorTemplate={promotingFCOErrorTemplate(promoteFcoError)}
            title={`DO YOU WANT TO PROMOTE THIS FIELD CREDIT OFFICER (${fcoDetailData?.username}) ?`}
            onBack={onCloseModal}
            onAccept={onPromotingFco}
            usedCase={EPERSONELACTIONS.FCODEACTIVATING}
            loading={promoteFcoLoading}
          >
            {onDeactivatingPromotingSubPage("Promote")}
          </ActionsPersonelComponent>
        )}
        {modalView === ModalView.UPDATEPORTFOLIOLEVEL && (
          <UpdatePortfolioForm
            portfolioLevels={portfolioLevels}
            updateAction={updateFcoPortfolioLevelAction}
            portfolioUpdateLoading={updatePortfolioLeveFcosLoading}
            clearErrorAction={clearUpdatePortfolioLevelError}
            error={updatePortfolioLeveFcosError}
          />
        )}
      </Modal>
    </>
  );
};

export default ViewFCODetails;
