import React from "react";
import ViewStateCoordinatorsTable from "../ViewStateCoordinators";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";

const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "View State Coordinators",
    active: true,
  },
];

const ViewStateCoordinatorsManagers = () => {
  return (
    <Page breadCrumbItems={breadCrumbItems}>
     <ViewStateCoordinatorsTable/>
    </Page>
  );
};

export default ViewStateCoordinatorsManagers;
