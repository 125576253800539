import React from "react";
import { get_brm_details_service } from "../brmApi";
import { parseError } from "../../../../utils/server";
import { showMessage } from "../../../../utils/notifications";



export interface BuisinesRelationshipManagerDetailsQueryProps {
    payload: {
        id: string
    }
}

export const useQueryBuisinesRelationshipManagerDetails = (props: BuisinesRelationshipManagerDetailsQueryProps) => {
    const {
        payload: {
            id
        }
    } = props

    const [brmDetailData, setBrmDetailData] = React.useState<any | null>(null);
    const [loading, setLoading] = React.useState(false)



    const getBuisinesRelationshipManagerDetails = React.useCallback(async (id: string) => {
        try {
            setLoading(true)

            const response = await get_brm_details_service(id);

            setBrmDetailData(response?.responseBody)
        } catch (error: any) {
            showMessage("Business Relationship Manager", parseError(error), "error");
            setBrmDetailData(null)
        } finally {
            setLoading(false)
        }

    }, [])


    React.useEffect(() => {

        getBuisinesRelationshipManagerDetails(id)

    }, [getBuisinesRelationshipManagerDetails, id])

    return {
        loading,
        brmDetailData,
        getBuisinesRelationshipManagerDetails,
    }

}