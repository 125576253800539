import { createSlice } from "@reduxjs/toolkit";
import { initPagination } from "../../../../utils/pagination";
import { IWorkflowState } from "./workflowSlice.types";

const initialState: IWorkflowState = {
    data: [],
    pagination: initPagination,
    types: [],
    loading: false,
}


const workflowSlice = createSlice({
    name: "workflow",
    initialState,
    reducers: {
        fetchWorkflowData: (state, action) => {
            state.data = action.payload.content
            state.pagination.total = action.payload.totalElements
        },
        setWorkflowCurrentPage: (state, action) => {
            state.pagination.current = action.payload.current
            state.pagination.pageSize = action.payload.pageSize
        },
        fetchWorkflowTypes: (state, action) => {
            state.types = action.payload || []
        },
        setWorkflowsLoading: (state, action) => {
            state.loading = action.payload
        },
        resetWorkflowData: (state) => {
            state.data = []
            state.pagination = initPagination
            state.types = []
        }
    }
})


export const { fetchWorkflowData, setWorkflowCurrentPage, fetchWorkflowTypes, setWorkflowsLoading, resetWorkflowData } = workflowSlice.actions
const workflowReducer = workflowSlice.reducer



export default workflowReducer


