import { ICountry, ILocalGovernment, IState } from "../UtilsApi/utilsAPI.types";

export interface IUtilsState {
    countries: ICountry[]
    countriesLoading: boolean
    states: IState[]
    statesLoading: boolean
    statesByCountryCode: IState[];
    statesByCountryCodeLoading: boolean;
    LGAs: ILocalGovernment[]
    LGAsLoading: boolean
    LGAsByState: ILocalGovernment[]
    LGAsByStateLoading: boolean

    moniepointUserAccountDetails: MoniepointUserAccountDetails | null
    moniepointUserAccountDetailsLoading: boolean
    moniepointUserAccountDetailsError: string
}


export interface MoniepointUserAccountDetails {
    firstName: string
    lastName: string
    middleName: string
    emailAddress: string
    mobileNumber: string
    customerCode: string
    passportRef: string
    bvn: string
    accountName: string
}

export enum ReassignmentMode {
    AUTO = 'auto',
    MANUAL = 'manual'
}

export enum WhatToReassign {
    LOAN_REQUESTS = 'Loan Requests',
    LOAN_CONTRACTS = 'Loan Contracts',
}
