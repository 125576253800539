import React from "react";

import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";
import { ResendVerificationType } from "../../loanRequestSlice/loanRequest.types";
import { resend_verification_loan_request_service } from "../../loanRequestApi";

export const useResendLoanRequestVerification = () => {
  const [
    resendLoanRequestVerificatioLoading,
    setResendLoanRequestVerificationLoading
  ] = React.useState<boolean>(false);
  const [
    resendLoanRequestVerificatioError,
    setResendLoanRequestVerificationError
  ] = React.useState<string>("");

  const resendLoanRequestVerification = async (
    payLoad: ResendVerificationType,

    callBack?: () => void
  ) => {
    try {
      setResendLoanRequestVerificationLoading(true);
      setResendLoanRequestVerificationError("");
      const response = await resend_verification_loan_request_service(payLoad);

      if (response?.requestSuccessful) {
        showMessage("SUCCESSFUL", "Verification resend", "success");
        if (callBack) {
          callBack();
        }
      }
    } catch (error: any) {
      const errorMessage = parseError(error);

      showMessage("Request Failed", errorMessage, "error");
      setResendLoanRequestVerificationError(parseError(error));
    } finally {
      setResendLoanRequestVerificationLoading(false);
    }
  };

  const clearResendVerificationLoanRequestError = () => {
    setResendLoanRequestVerificationError("");
  };
  return {
    resendLoanRequestVerificatioLoading,
    resendLoanRequestVerificatioError,
    resendLoanRequestVerification,
    clearResendVerificationLoanRequestError
  };
};
