import React from "react";
import { send_activation_link_service } from "../usersApi";
import { parseError } from "../../../../utils/server";
import { showMessage } from "../../../../utils/notifications";

export const useSendActivationLinkEmail = () => {
  const [sendActivationLinkError, setSendActivationLinkError] =
    React.useState("");
  const [sendActivationLinkloading, setSendActivationLinkLoading] =
    React.useState(false);

  const sendActivationLink = React.useCallback(
    async (email: string, onSuccess: (response: any) => void) => {
      try {
        setSendActivationLinkError("");
        setSendActivationLinkLoading(true);

        const response = await send_activation_link_service(email);

        if (response?.requestSuccessful) {
          onSuccess(response?.responseBody);
          showMessage(
            "Successfull",
            "Activation link sent successfully to user email."
          );
        }
      } catch (error: any) {
        setSendActivationLinkError(parseError(error));
        setSendActivationLinkLoading(false);
      } finally {
        setSendActivationLinkLoading(false);
      }
    },
    []
  );

  const resetActivationStates = () => {
    setSendActivationLinkLoading(false);
    setSendActivationLinkError("");
  };

  return {
    sendActivationLinkError,
    sendActivationLinkloading,
    resetActivationStates,
    sendActivationLink
  };
};
