import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Col,
  Modal,
  Typography,
  Select
} from "antd";
import OnboardingWizard from "../../Utils/Onboarding";
import { FCOOnboardingParams } from "../fcoSlice/fco.types";
import {
  useOnboardFieldCreditOfficer,
} from "./hook";
import FCOsTable from "./FCOsTable";

const FormItem = Form.Item;
const { Option } = Select;

interface Props {
  searchParams?: any;
  hiddenFields?: boolean;
  fcoUserName?: string;
  firstFcoUserName?: string;
  secondFcoUserName?: string;
  reassignLoanRequestApi?: (data: any) => void;
}

const ViewFcos: React.FC<Props> = ({
  hiddenFields,
  firstFcoUserName,
  secondFcoUserName,
  fcoUserName,
  reassignLoanRequestApi
}) => {
  const {
    onboardFCO,
    onCloseOnBoardModal,
    onboardFCOError,
    onboardFCOLoading
  } = useOnboardFieldCreditOfficer();

  const [showOnboarding, setShowOnboarding] = useState(false);

  const onCloseOnboardingView = () => {
    onCloseOnBoardModal();
    setShowOnboarding(false);
  };

  const onSubmitOnboardingRequest = (payload: FCOOnboardingParams) => {
    onboardFCO(payload, (data: any) => {
      onCloseOnboardingView();
    });
  };
 


  const tableColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string) => {
        if (value === "ENABLED") {
          return (
            <Button
              type="primary"
              style={{ background: "#28A745", borderColor: "#28A745" }}
            >
              Enabled
            </Button>
          );
        } else {
          return (
            <Button type="primary" disabled={true}>
              Disabled
            </Button>
          );
        }
      }
    },
    {
      title: "",
      dataIndex: "fcoCode",
      key: "fcoCode",
      render: (value: string) => {
        return (
          <Link to={`/dashboard/fco-management/details/${value}`}>
            <Button
              type="primary"
              className="bg-[#0190fe] border-[#0190fe] rounded"
            >
              View Details
            </Button>
          </Link>
        );
      }
    }
  ];

  const extraFilterFields = (
    <>
      <Col xs={24} sm={12} md={8}>
        <FormItem label="First Name" name={"firstName"} initialValue="">
          <Input size="large" placeholder="" />
        </FormItem>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <FormItem label="Last Name" name={"lastName"} initialValue="">
          <Input size="large" placeholder="" />
        </FormItem>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <FormItem label="Status" name={"status"} initialValue="">
            <Select
                placeholder="Select a Status"
                optionFilterProp="children"
                size="large"
                showSearch
                allowClear={true}
            >
              <Option
                  key={"ENABLED"}
                  value={"ENABLED"}
                  name={"ENABLED"}
              >
                  {"ENABLED"}
              </Option>
              <Option
                  key={"DISABLED"}
                  value={"DISABLED"}
                  name={"DISABLED"}
              >
                  {"DISABLED"}
              </Option>
            </Select>
        </FormItem>
      </Col>
    </>
  )


  return (
    <>

        <section className=" w-full py-4 flex items-center justify-end">
          <Button
            type="primary"
            className="rounded"
            onClick={() => {
              setShowOnboarding(true);
            }}
          >
            Onboard New FCO
          </Button>
        </section>

      <FCOsTable
        extraColumns={tableColumns}
        extraFilterFields={extraFilterFields}
      />

      <Modal
        destroyOnClose={true}
        centered
        className="modal-lg my-6"
        width={900}
        open={showOnboarding}
        onCancel={onCloseOnboardingView}
        title={
          <Typography.Title level={4}>
            {"Onboard new Field Credit Officer"}
          </Typography.Title>
        }
        footer={null}
      >
        <OnboardingWizard
          userType="FCO"
          onSubmitOnboardingRequest={onSubmitOnboardingRequest}
          submitError={onboardFCOError}
          submitLoading={onboardFCOLoading}
        />
      </Modal>
    </>
  );
};

export default ViewFcos;
