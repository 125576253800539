import { Button, Card, Col, Form, Input, Row, Select, Table, TableProps } from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  fetchLGAsByStateCode,
  fetchStates,
  resetLgasByStateCode
} from "../../Utils/UtilsSlice/utilsSlice.thunks";
import { useQueryPortfolioManagers } from "./hook";
import { parseTableSearchParams } from "../../../../utils/dataParser";
import { initPagination } from "../../../../utils/pagination";

const FormItem = Form.Item;
const { Option } = Select;

interface PMsTableProps {
  extraColumns?: {
    title: string;
    dataIndex: string;
    key: string;
    render: (value: string, record: any) => React.ReactNode;
  }[];
  extraFilterFields?: React.ReactNode;
}

const PmsTable = ({ 
  extraColumns, 
  extraFilterFields,
  rowSelection,
}:PMsTableProps & TableProps<any>) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const {
    utils: { states, LGAsByState }
  } = useAppSelector((state) => state);

  const {
    portfolioAllData,
    portfolioAllLoading,
    getAllPortfolioManagers,
    portfolioPagination
  } = useQueryPortfolioManagers({ payload: {} });

  const [searchParams, setSearchParams] = useState({});

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      dispatch(fetchStates());
    }
  }, [dispatch]);

  const onStateChange = (value: string) => {
    if (value) {
      dispatch(fetchLGAsByStateCode(value));
    } else {
      dispatch(resetLgasByStateCode());
      form.setFieldValue("lgaCode", null);
    }
  };

  const filterPorfolioManagers = () => {
    const formValues = form.getFieldsValue();
    let searchParams = parseTableSearchParams(formValues);
    setSearchParams(searchParams);
    getAllPortfolioManagers(searchParams, initPagination);
  };

  const onClearForm = () => {
    form.resetFields();
    setSearchParams({});
    getAllPortfolioManagers();
  };
  const handleTableChange = (pagination: any) => {
    fetchData(pagination, searchParams);
  };

  const fetchData = (pagination?: any, searchParams?: any) => {
    getAllPortfolioManagers(searchParams, pagination);
  };

  const tableColumns = [
    {
        title: "Username",
        dataIndex: "username",
        key: "username",
        render: (value: string) => {
          return <div>{value}</div>;
        }
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstname",
        render: (value: string) => {
          return <div>{value}</div>;
        }
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastname",
        render: (value: string) => {
          return <div>{value}</div>;
        }
      },
    ...(extraColumns || [])
  ];

  const renderFilterForm = () => {
    return (
        <Form layout="vertical" form={form} name="filter-fco">
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8}>
            <FormItem label="First Name" name={"firstName"} initialValue="">
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8} style={{ height: "20px" }}>
            <FormItem label="Last Name" name={"lastName"} initialValue="">
              <Input size="large" placeholder="" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem label="State" name={"stateCode"} initialValue="">
              <Select
                placeholder="Select a State"
                optionFilterProp="children"
                onChange={onStateChange}
                size="large"
                showSearch
                allowClear={true}
              >
                {states.map((state) => (
                  <Option
                    key={state.id}
                    value={state.stateCode}
                    name={state.stateName}
                  >
                    {state.stateName}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem label="LGA" name={"lgaCode"} initialValue="">
              <Select
                placeholder="Select a LGA"
                optionFilterProp="children"
                size="large"
                showSearch
                allowClear={true}
              >
                {LGAsByState.map((lga) => (
                  <Option key={lga.id} value={lga.code} name={lga.name}>
                    {lga.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          {extraFilterFields}
        </Row>

        <Row gutter={[12, 12]}>
          <Col xs={24} sm={12} md={3}>
            <Button
              type="primary"
              className="w-full rounded"
              onClick={onClearForm}
              danger
            >
              Clear
            </Button>
          </Col>
          <Col xs={24} sm={12} md={4}>
            <Button
              type="primary"
              onClick={filterPorfolioManagers}
              className="w-full bg-[#0190fe] border-[#0190fe] rounded"
              htmlType="submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <>
      <Card title="Portfolio Managers Filter" className="my-[18px]">
        {renderFilterForm()}
      </Card>
      <Card title="Portfolio Managers" className="my-[18px] w-full">
        <Table
          className="w-full overflow-x-auto"
          dataSource={portfolioAllData}
          columns={tableColumns}
          rowSelection={rowSelection}
          rowKey={(record) => record.id}
          loading={portfolioAllLoading}
          pagination={portfolioPagination}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

export default PmsTable;
