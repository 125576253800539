import React, { FC, ReactNode, useState } from "react";
import { Button, Input } from "antd";

const { TextArea } = Input;
interface TestAreaProps {
    minCount?: number;
    maxCount?: number;
    callBackAction: (value: string) => void;
    isTextFieldCompulsory?: boolean;
    rows?: number;
    showCounter?: boolean;
    buttonLabel?: string;
    children?: ReactNode;
}

export const TextAreaComponent: FC<TestAreaProps> = ({
    minCount = 5,
    maxCount = 150,
    callBackAction,
    isTextFieldCompulsory = false,
    showCounter = false,
    rows = 4,
    buttonLabel = 'Submit',
    children,
}) => {
    const [characterCounter, setCharacterCounter] = useState<number>(0);
    const [comments, setComment] = useState<string>('')
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        if (e && e.target.value) {

            setCharacterCounter(Number(e.target.value?.length));
            setComment(e.target.value);
        } else {
            setCharacterCounter(0);
            setComment('');
        }

    };
    const onButtonClick = () => {
        if (comments) {
            callBackAction(comments)
        }

    }
    return (
        <>
            <TextArea
                rows={rows}
                placeholder={`maxLength is ${maxCount}`}
                maxLength={maxCount}
                onChange={onChange}

            />
            {showCounter && <div className="flex justify-between mt-[4px] text-[blue]">
                <div>
                    <span>minLength</span>
                    <span>:</span> <span>{minCount}</span>
                </div>
                <div>
                    {characterCounter}/{maxCount}
                </div>
            </div>}
            <div className="mt-[8px] flex justify-between">
                <div>{children}</div>
                <Button
                    type="primary"
                    className="rounded mt-[10px] mb-[10px]"
                    disabled={isTextFieldCompulsory ? !(characterCounter >= minCount && characterCounter <= maxCount) : false}
                    onClick={onButtonClick}
                >
                    {buttonLabel}
                </Button>
            </div>
        </>
    );
};
