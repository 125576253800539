import React from "react";
import {
  Pagination,
  extractPaginationData,
  initPagination
} from "../../../../utils/pagination";
import {
  get_all_portfolio_managers_service,
  onboard_user_as_portfolio_manager_service
} from "../portfolioApi";
import { parseError } from "../../../../utils/server";
import { showMessage } from "../../../../utils/notifications";
import { onBoardUsersAsPmTypes } from "../portfolioSlice/portfolio.types";

export interface PortfolioManagersQueryProps {
  payload: {
    searchParams?: Record<string, any>;
    pagination?: Pagination;
  };
}

export const useQueryPortfolioManagers = (
  props: PortfolioManagersQueryProps
) => {
  const {
    payload: { searchParams, pagination }
  } = props;

  const [portfolioAllData, setPortfolioAllData] = React.useState<any | null>(
    null
  );
  const [portfolioPagination, setPortfolioPagination] =
    React.useState<Pagination>(initPagination);
  const [error, setError] = React.useState("");
  const [portfolioAllLoading, setportfolioAllLoading] = React.useState(false);

  const getAllPortfolioManagers = React.useCallback(
    async (
      searchParams?: Record<string, any>,
      pagination?: Record<string, any>
    ) => {
      try {
        setError("");
        setportfolioAllLoading(true);

        const response = await get_all_portfolio_managers_service(
          searchParams,
          pagination
        );
        const paginationData = extractPaginationData(response.responseBody);

        setPortfolioAllData(response?.responseBody?.content);
        setPortfolioPagination(paginationData);
      } catch (error: any) {
        showMessage("Portfolio Managers", parseError(error), "error");
        setError(parseError(error));
      } finally {
        setportfolioAllLoading(false);
      }
    },
    []
  );


  React.useEffect(() => {
    getAllPortfolioManagers(searchParams, pagination);
  }, [searchParams, pagination, getAllPortfolioManagers]);

  return {
    error,
    portfolioAllData,
    portfolioPagination,
    portfolioAllLoading,
    getAllPortfolioManagers,
    
  };
};
export const useOnboardPortfolioManager = () => {
  const [onBoardUsersAsPmError, setOnBoardUsersAsPmError] = React.useState("");
  const [onBoardUsersAsPmLoading, setOnBoardUsersAsPmLoading] =
    React.useState(false);
    const onBoardUsersAsPM = async (
      payLoad: onBoardUsersAsPmTypes,
      onSuccess: (response: any) => void
    ) => {
      try {
        setOnBoardUsersAsPmError("");
        setOnBoardUsersAsPmLoading(true);
        const response = await onboard_user_as_portfolio_manager_service(payLoad);
  
        if (response?.requestSuccessful) {
          onSuccess(response?.responseBody);
          showMessage(
            "Successfull",
            "Request to onboard user was sent successfully."
          );
        }
      } catch (error: any) {
        setOnBoardUsersAsPmError(parseError(error));
      } finally {
        setOnBoardUsersAsPmLoading(false);
      }
    };
    const onCloseOnBoardModal = () => {
      if (onBoardUsersAsPmLoading) return;
      if (onBoardUsersAsPmError) {
        setOnBoardUsersAsPmError("");
      }
    };
  return {onBoardUsersAsPM,
    onBoardUsersAsPmError,
    onBoardUsersAsPmLoading,
    onCloseOnBoardModal};
};
