import React from "react"
import { MoniepointUserAccountDetails } from "../UtilsSlice/utilsSlice.types"
import { get_moniepoint_user_account_details } from "../UtilsApi"
import { parseError } from "../../../../utils/server"

export interface MoniepointUserAccountDetailsQueryProps {
    payload: {
        accountNumber?: string,
    }
}

export const useQueryMoniepointUserAccountDetails = (
    props: MoniepointUserAccountDetailsQueryProps
) => {

    const {
        payload: { accountNumber }
    } = props

    const [
        moniepointUserAccountDetails,
        setMoniepointUserAccountDetails
    ] = React.useState<MoniepointUserAccountDetails | null>(null)

    const [
        moniepointUserAccountDetailsLoading,
        setMoniepointUserAccountDetailsLoading
    ] = React.useState(false)

    const [
        moniepointUserAccountDetailsError,
        setMoniepointUserAccountDetailsError
    ] = React.useState('')


    const fetchMoniepointUserAccoutDetails = React.useCallback(
        async (accountNumber: string) => {
            try {
                setMoniepointUserAccountDetailsError("")
                setMoniepointUserAccountDetailsLoading(true)

                const response = await get_moniepoint_user_account_details(accountNumber)

                setMoniepointUserAccountDetails(response?.responseBody)
            } catch (error) {
                setMoniepointUserAccountDetailsError(parseError(error))
                setMoniepointUserAccountDetails(null)
            } finally {
                setMoniepointUserAccountDetailsLoading(false)
            }
        }, [])


    React.useEffect(() => {

        if (accountNumber) {
            fetchMoniepointUserAccoutDetails(accountNumber)
        } else {
            setMoniepointUserAccountDetails(null)
        }

    }, [accountNumber, fetchMoniepointUserAccoutDetails])


    return {
        moniepointUserAccountDetails,
        moniepointUserAccountDetailsError,
        moniepointUserAccountDetailsLoading,
        fetchMoniepointUserAccoutDetails
    }

}