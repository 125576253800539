import { Descriptions, Typography } from "antd";
import { useEffect } from "react";
import { mapLGACodeToName } from "../../utils";
import { fetchLGAs } from "../../../../Utils/UtilsSlice/utilsSlice.thunks";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import { IResidentialAddress } from "../../utils/types";

const { Text } = Typography;
type ResidentialAddressProps = {
  details?: IResidentialAddress;
};

const ResidentialAddress = ({ details }: ResidentialAddressProps) => {
  const dispatch = useAppDispatch();
  const {
    utils: { LGAs },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (!LGAs.length) {
      dispatch(fetchLGAs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <Descriptions layout="vertical" contentStyle={{ marginTop: '-16px' }}>
        <Descriptions.Item label={<Text strong>Address Line One</Text>}>
          {details?.addressLineOne || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>Address Line Two</Text>}>
          {details?.addressLineTwo || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>Nearest Landmark</Text>}>
          {details?.nearestLandmark || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>Nearest Bus-stop</Text>}>
          {details?.nearestBusstop || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>L.G.A</Text>}>
          {mapLGACodeToName(details?.lga, LGAs) || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>City</Text>}>
          {details?.city || "N/A"}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
export default ResidentialAddress;
