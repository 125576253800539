import { Select, Transfer, Typography } from "antd";
import { TransferDirection } from "antd/es/transfer";
import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { ILocalGovernment } from "../UtilsApi/utilsAPI.types";
import { resetByStateLGAs } from "../UtilsSlice";
import {
    fetchLGAsByStateCode,
    fetchStates,
} from "../UtilsSlice/utilsSlice.thunks";
import { OnTransferProps } from "./types";

interface AssignOrUnassignLGAsComponentProps {
    title?: string;
    oneWay?: boolean;
    initialLGAs?: ILocalGovernment[];
    onTransfer: (prop: OnTransferProps) => void;
    disable?: boolean;
    onStateSelect?: (state: string) => void;
    stateCode?: string
}

interface RecordType extends ILocalGovernment { key: string }

const AssignOrUnassignLGAsComponent: FC<AssignOrUnassignLGAsComponentProps> = ({
    initialLGAs,
    onTransfer,
    onStateSelect,
    oneWay,
    title,
    disable,
    stateCode,
}) => {
    const dispatch = useAppDispatch();
    const {
        utils: { states, LGAsByState },
    } = useAppSelector((state) => state);

    const [targetItems, setTargetItems] = useState<ILocalGovernment[]>(
        initialLGAs || []
    );
    const [selectedItems, setSelectedItems] = useState<{
        source: ILocalGovernment[]
        target: ILocalGovernment[]
    }>({ source: [], target: [] });

    useEffect(() => {

        if (stateCode) {
            dispatch(fetchLGAsByStateCode(stateCode))
        } else {
            dispatch(fetchStates())
        }

        return () => {
            dispatch(resetByStateLGAs());
        };
    }, [dispatch, stateCode]);

    useEffect(() => {
        if (initialLGAs) {
            setTargetItems(initialLGAs);
        }
    }, [initialLGAs]);


    const onStateSelected = (value: string) => {
        if (value) {
            if (onStateSelect) {
                onStateSelect(value);
            }
            dispatch(fetchLGAsByStateCode(value));
        }
    }

    const onChange = (
        nextTargetKeys: string[],
        direction: TransferDirection,
        moveKeys: string[]
    ) => {
        onTransfer({
            movedItems: LGAsByState.filter((item) => moveKeys.includes(item.code)),
            allTergets: LGAsByState.filter((item) =>
                nextTargetKeys.includes(item.code)
            ),
            direction,
            onTransferFulfilled: () => setTargetItems(
                LGAsByState.filter((item) => nextTargetKeys.includes(item.code))
            )
        });
    }

    const onTransferItemsSelected = (
        sourceSelectedKeys: string[],
        targetSelectedKeys: string[]
    ) => {
        setSelectedItems({
            source: LGAsByState.filter((item) =>
                sourceSelectedKeys.includes(item.code)
            ),
            target: LGAsByState.filter((item) =>
                targetSelectedKeys.includes(item.code)
            ),
        })
    }

    const filterOption = (inputValue: string, option: RecordType) => {
        let containsValue = false;

        try {
            containsValue = option?.name?.toLowerCase()?.includes(inputValue?.toLowerCase());
        } catch (error) {

        }

        return containsValue;
    }


    return (
        <section>
            <Typography.Text>
                {title ? title : "Assign Local Government Areas"}
            </Typography.Text>

            <br />
            <br />

            {!stateCode && (
                <Select
                    showSearch
                    placeholder="Select a State"
                    optionFilterProp="name"
                    onChange={onStateSelected}
                    className="mb-[30px] w-[12rem]"

                >
                    {states.map((state) => (
                        <Select.Option
                            key={state.id}
                            value={state.stateCode}
                            name={state.stateName}
                        >
                            {state.stateName}
                        </Select.Option>
                    ))}
                </Select>
            )}
            <Transfer
                dataSource={LGAsByState.map((LGA) => ({ ...LGA, key: LGA.code }))}
                selectedKeys={[...selectedItems.source, ...selectedItems.target].map(
                    (item) => item.code
                )}
                targetKeys={targetItems?.map((item) => item.code)}
                onChange={onChange}
                onSelectChange={onTransferItemsSelected}
                render={(item) => `${item.name}`}
                titles={["Available", "Assigned"]}
                operations={["ASSIGN", "UN-ASSIGN"]}
                filterOption={filterOption}
                showSearch
                oneWay={oneWay}
                disabled={disable}
                listStyle={{
                    width: 300,
                    height: 500,
                }}
            />
        </section>
    );
};

export default AssignOrUnassignLGAsComponent;
