import { Breadcrumb } from "antd";
import React, { FC, ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";


export type BreadCrumbItem = { title: string; delta?: number; path?: string; active?: boolean };

interface PageProps {
    children: ReactNode | null
    breadCrumbItems?: BreadCrumbItem[]
}

const Page: FC<PageProps> = ({ children, breadCrumbItems }) => {

    const navigate = useNavigate()

    return (
        <>
            <Breadcrumb className="bg-white border-t border-x-0 border-b-0 border-t-[#e4e9f0] border-solid ml-[35px] p-[24px] lg:ml-0">
                {breadCrumbItems?.length
                    ? breadCrumbItems.map(itm => (
                        <Breadcrumb.Item key={itm.title}>
                            {Number(itm.delta)
                                ? (
                                    <span className="cursor-pointer" onClick={() => navigate(-Number(itm.delta))}>
                                        {itm.title}
                                    </span>
                                )
                                : itm.path ? (
                                    <Link to={itm.path}>{itm.title}</Link>
                                )
                                    : itm.active ? (
                                        <span className="text-[blue]">
                                            {itm.title}
                                        </span>
                                    ) : null
                            }
                        </Breadcrumb.Item>
                    ))
                    : (
                        <Breadcrumb.Item>
                            <Link to='/dashboard'>Dashboard</Link>
                        </Breadcrumb.Item>
                    )
                }
            </Breadcrumb>
            <div className="p-[24px] min-h-[360px]">
                {children}
            </div>
        </>
    )
}

export default Page;
