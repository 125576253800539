import React from "react"
import { deactivate_fco_service } from "../../fcoApi";
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";



export const useDeactivateFcos = () => {
    const [fcoDeactivingLoading, setFcoDeactivingLoading] = React.useState<boolean>(false);
    const [fcoDeactivingError, setFcoDeactivingError] = React.useState<string>('');
    
    const deactivateFCO = async (
        fcoCode: string,

        callBack?: () => void
    ) => {
        try {
            setFcoDeactivingLoading(true)
            setFcoDeactivingError('')
            const response = await deactivate_fco_service(fcoCode);

            if (response?.requestSuccessful) {
                showMessage(
                    "SUCCESSFUL",
                    "Request is Pending approval",
                    "success"
                );
                if (callBack) {
                    callBack()
                }
            }

        } catch (error: any) {
            const errorMessage = parseError(error)
           
            showMessage("Request Failed", errorMessage, "error");
            setFcoDeactivingError(parseError(error));
        } finally {
            setFcoDeactivingLoading(false)
        }

    };

  const clearFcoDeactivatingError=()=>{
    setFcoDeactivingError('');
  }
    return {
        fcoDeactivingLoading,
        fcoDeactivingError,
        deactivateFCO,
        clearFcoDeactivatingError,
    }


}




  