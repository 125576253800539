import React from "react";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths, getStaticPathString } from "../../../../routes/Dashboard/routePaths";
import LoanContractsTable from "../loanContractsTable";
import { useQueryLoanContracts } from "../hooks/useQueryLoanContracts";
import { Link } from "react-router-dom";
import { Button } from "antd";



const breadCrumbItems: BreadCrumbItem[] = [
    {
        title: "Dashboard",
        path: DashboardRoutePaths.home.path,
    },
    {
        title: "View Loan Contracts",
        active: true,
    },
];



const ViewLoanContracts = () => {

    const extraColumns = [
        {
            title: "",
            dataIndex: "loanContractReference",
            key: "loanContractReference",
            render: (value: string, record: any) => {
                return (
                    <Link
                        to={`${getStaticPathString(DashboardRoutePaths.loanContractsDetails.path)
                            }${value}?loan-product=${record?.loanProduct}&loan-request=${
                                record?.loanRequestReference}`}
                    >
                        <Button
                            type="primary"
                            className="bg-[#0190fe] border-[#0190fe] rounded"
                        >
                            View Details
                        </Button>
                    </Link>
                );
            }
        }
    ]

    return (
        <Page breadCrumbItems={breadCrumbItems}>
            <LoanContractsTable
                useQueryLoanContracts={useQueryLoanContracts}
                extraColumns={extraColumns}
            />
        </Page>
    );
};

export default ViewLoanContracts;
