import React, { FC, useEffect, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { Layout } from 'antd';
import { DashBoardRoutes } from "./DashboardRoutes";
import menuItems from "./menuItems";
import SideBar from "../../components/SideBar";
import TopBar from "../../components/TopBar";
import { useAppSelector } from "../../store";



const { Header, Content } = Layout;


const Dashboard: FC = () => {

    const navigate = useNavigate()
    const { user, isAuthenticated } = useAppSelector(state => state.auth)
    const [collapsed, setCollapsed] = useState(false);
    const elements = useRoutes(DashBoardRoutes)

    useEffect(() => {
        let isMounted = true

        if (isMounted && !(isAuthenticated && user)) {
            navigate("/")
        }

        return () => {isMounted = false}
    }, [isAuthenticated, user, navigate])

    return (
        <>
            <Layout className="min-h-[100vh]">
                <SideBar
                    collapsed={collapsed}
                    onCollapse={value => setCollapsed(value)}
                    menuItems={menuItems}
                />
                <Layout>
                    <Header className="bg-white px-[24px]">
                        <TopBar />
                    </Header>
                    <Content>
                        {elements}
                    </Content>
                </Layout>
            </Layout>
        </>
    )
}


export default Dashboard
