import { Button, Card, Col, Form, Input, Row, Select, Table, TableProps } from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { fetchLGAsByStateCode, fetchStates, resetLgasByStateCode } from "../../Utils/UtilsSlice/utilsSlice.thunks";
import { useQueryFieldCreditOfficers } from "./hook";
import { parseTableSearchParams } from "../../../../utils/dataParser";
import { initPagination } from "../../../../utils/pagination";

const FormItem = Form.Item;
const { Option } = Select;

interface FCOsTableProps {
    extraColumns?: {
        title: string
        dataIndex: string
        key: string,
        render: (value: string, record: any) => React.ReactNode
    }[]
    extraFilterFields?: React.ReactNode
    fixedSearchParams?: Record<string, any>
}

const FCOsTable = ({
    extraColumns,
    extraFilterFields,
    rowSelection,
    fixedSearchParams
}: FCOsTableProps & TableProps<any>) => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const {
        utils: { states, LGAsByState }
    } = useAppSelector((state) => state);

    const { fcoData, loading, fcoDataPagination, getAllFcos } =
        useQueryFieldCreditOfficers({ payload: {
            searchParams: fixedSearchParams
        } });

    const [searchParams, setSearchParams] = useState({});


    React.useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            dispatch(fetchStates());
        }
    }, [dispatch]);


    const onStateChange = (value: string) => {
        if (value) {
            dispatch(fetchLGAsByStateCode(value));
        } else {
            dispatch(resetLgasByStateCode());
            form.setFieldValue("lgaCode", null);
        }
    };

    const filterFcos = () => {
        const formValues = form.getFieldsValue();
        const searchParams = parseTableSearchParams(formValues);
        setSearchParams(searchParams);
        fetchData(initPagination, searchParams)
    };

    const onClearForm = () => {
        form.resetFields();
        setSearchParams({});
        fetchData();
    };
    const handleTableChange = (pagination: any) => {
        fetchData(pagination, searchParams);
    };

    const fetchData = (pagination?: any, searchParams?: any) => {
        const validFixedSearchParams = parseTableSearchParams(fixedSearchParams || {});

        getAllFcos(
            searchParams ? { ...searchParams, ...validFixedSearchParams } : validFixedSearchParams, 
            pagination
        );
    };

    const tableColumns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
            render: (value: string) => {
                return <div>{value}</div>;
            }
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstname",
            render: (value: string) => {
                return <div>{value}</div>;
            }
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastname",
            render: (value: string) => {
                return <div>{value}</div>;
            }
        },
        ...(extraColumns || [])
    ];


    const renderFilterForm = () => {
        return (
            <Form layout="vertical" form={form} name="filter-fco">
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={8}>
                        <FormItem label="User Name" name={"username"} initialValue="">
                            <Input size="large" placeholder="" />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <FormItem label="State" name={"stateCode"} initialValue="">
                            <Select
                                placeholder="Select a State"
                                optionFilterProp="children"
                                size="large"
                                showSearch
                                allowClear={true}
                                onChange={onStateChange}
                            >
                                {states.map((state) => (
                                    <Option
                                        key={state.id}
                                        value={state.stateCode}
                                        name={state.stateName}
                                    >
                                        {state.stateName}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <FormItem label="LGA" name={"lgaCode"} initialValue="">
                            <Select
                                placeholder="Select a LGA"
                                optionFilterProp="children"
                                size="large"
                                showSearch
                                allowClear={true}
                            >
                                {LGAsByState.map((lga) => (
                                    <Option key={lga.id} value={lga.code} name={lga.name}>
                                        {lga.name}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>
                    {extraFilterFields}
                </Row>

                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={12} md={3}>
                        <Button
                            type="primary"
                            className="w-full rounded"
                            onClick={onClearForm}
                            danger
                        >
                            Clear
                        </Button>
                    </Col>
                    <Col xs={24} sm={12} md={4}>
                        <Button
                            type="primary"
                            onClick={filterFcos}
                            className="w-full bg-[#0190fe] border-[#0190fe] rounded"
                            htmlType="submit"
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <>
            <Card title="Field Credit Officers Filter" className="my-[18px]">
                {renderFilterForm()}
            </Card>
            <Card title="Field Credit Officers" className="my-[18px] w-full">
                <Table
                    className="w-full overflow-x-auto"
                    rowSelection={rowSelection}
                    dataSource={fcoData}
                    columns={tableColumns}
                    rowKey={(record) => record.fcoCode}
                    loading={loading}
                    pagination={fcoDataPagination}
                    onChange={handleTableChange}
                />
            </Card>
        </>
    )
}

export default FCOsTable;
