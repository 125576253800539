import { createSlice } from "@reduxjs/toolkit";
import { initPagination } from "../../../../utils/pagination";
import { ILoanPrograms } from "./loanPrograms.types";

const initialState: ILoanPrograms = {
  loanProgramsAllData: null,
  loanProgramAllLoading: false,
  loanProgramsAllError: "",
  eachLoanProgram: null,
  loanProgramsPagination: initPagination,
  eachLoanProgramDetailsLoading: false,
  eachLoanProgramDetailsError: "",
  loanProgramDetailsLoading: false,
  loanLevelProgramLoading: false,
  loanLevelProgramData: {},
  loanLevelProgramError: "",
  createLoanProgramRunning: false,
  createLoanProgramError: "",
  createLoanProgramSuccess: false,
  updateLoanProgramRunning: false,
  updateLoanProgramError: "",
  updateLoanProgramSuccess: false,
  businessTypes: null,
  businessTypesLoading: false,
  businessTypesError: "",
};

const loanProgramsSlice = createSlice({
  name: "provisioningSystem",
  initialState,
  reducers: {
    setAllViewLoanProgramsSliceLoading: (state, action) => {
      state.loanProgramAllLoading = action.payload;
    },
    setLoanLevelProgramLoading: (state, action) => {
      state.loanLevelProgramLoading = action.payload;
    },
    saveAllLoanProgramsDataPagination: (state, action) => {
      state.loanProgramsPagination = action.payload;
    },
    saveEachLoanProgramsData: (state, action) => {
      state.eachLoanProgram = action.payload;
    },
    addLoanLevelProgramsData: (state, action) => {
      state.loanLevelProgramData = {
        ...state.loanLevelProgramData,
        ...action.payload.loanProgramLevelData,
      };
    },
    saveAllLoanProgramsData: (state, action) => {
      state.loanProgramsAllData = action.payload?.content;
    },
    setAllLoanProgramsError: (state, action) => {
      state.loanProgramsAllError = action.payload?.responseBody;
    },
    eachLoanProgramDetailsLoading: (state, action) => {
      state.loanProgramDetailsLoading = action.payload;
    },
    setLoanProgramDetailsLoading: (state, action) => {
      state.eachLoanProgramDetailsLoading = action.payload;
    },
    setLoanProgramDetailsError: (state, action) => {
      state.eachLoanProgramDetailsError = action.payload;
    },
    setLoanLevelProgramError: (state, action) => {
      state.loanLevelProgramError = action.payload;
    },
    setCreateLoanProgramRunning: (state, action) => {
      state.createLoanProgramRunning = action.payload;
    },
    setCreateLoanProgramError: (state, action) => {
      state.createLoanProgramError = action.payload;
    },
    setCreateLoanProgramSuccess: (state, action) => {
      state.createLoanProgramSuccess = action.payload;
    },
    setUpdateLoanProgramRunning: (state, action) => {
      state.updateLoanProgramRunning = action.payload;
    },
    setUpdateLoanProgramError: (state, action) => {
      state.updateLoanProgramError = action.payload;
    },
    setUpdateLoanProgramSuccess: (state, action) => {
      state.updateLoanProgramSuccess = action.payload;
    },
    setBusinessTypes: (state, action) => {
      state.businessTypes = action.payload;
    },
    setBusinessTypesLoading: (state, action) => {
      state.businessTypesLoading = action.payload;
    },
    setBusinessTypesError: (state, action) => {
      state.businessTypesError = action.payload;
    },
  },
});

export const {
  setAllViewLoanProgramsSliceLoading,
  saveAllLoanProgramsData,
  setLoanProgramDetailsLoading,
  setLoanProgramDetailsError,
  setLoanLevelProgramLoading,
  setAllLoanProgramsError,
  eachLoanProgramDetailsLoading,
  saveAllLoanProgramsDataPagination,
  addLoanLevelProgramsData,
  setLoanLevelProgramError,
  saveEachLoanProgramsData,
  setCreateLoanProgramRunning,
  setCreateLoanProgramError,
  setCreateLoanProgramSuccess,
  setUpdateLoanProgramRunning,
  setUpdateLoanProgramError,
  setUpdateLoanProgramSuccess,
  setBusinessTypes,
  setBusinessTypesLoading,
  setBusinessTypesError,
} = loanProgramsSlice.actions;

const loanProgramsReducer = loanProgramsSlice.reducer;
export default loanProgramsReducer;
