import config from "../../../../config";
import server from "../../../../server";
import { IResponse } from "../../../../server/server.types";
import { ILocalGovernment } from "../../Utils/UtilsApi/utilsAPI.types";

export const api_get_Active_LGAs = async () => {
  const response = await server.get(
    `${config.WCL_MANAGEMENT_BASE_URL}/wcl/lga-activation/active`
  );

  // FIXME: the endpoint is not returning data in the right format, this is
  // just a tmp workaround
  return { responseBody: response.data } as IResponse<ILocalGovernment[]>;
};

export const api_activate_LGAs = async (lgaCodes: string[]) => {
  const response = await server.post(
    `${config.WCL_MANAGEMENT_BASE_URL}/wcl/lga-activation/activate`,
    { lgaCodes }
  );

  return response.data as IResponse<unknown>;
};

export const api_deactivate_LGAs = async (lgaCodes: string[]) => {
  const response = await server.post(
    `${config.WCL_MANAGEMENT_BASE_URL}/wcl/lga-activation/deactivate`,
    { lgaCodes }
  );

  return response.data as IResponse<unknown>;
};
