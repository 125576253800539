import { Button, Descriptions, Modal, Row, Spin, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import {
  convertDateToHumanReadableFrequency,
  convertStatusToHumanReadable,
} from "../../../../../../utils/dataParser";
import { showError } from "../../../../../../utils/notifications";
import { convertStatusTextToColor } from "../../../../../../utils/workflow";
import {
  ELoanContractProduct,
  ELoanRequestProduct,
} from "../../../../LoanContractsManagement/utils/types";
import { buildUpdatedLoanOfferData } from "../../../../Workflow/Viewers/LoanUpdateViewer";
import UpdatedLoanOfferDetails from "../../../../Workflow/Viewers/LoanUpdateViewer/UpdatedLoanOfferDetails";
import { useQueryLoanOfferDetails } from "../../hooks/useQueryLoanOfferDetails";
import { labels, updateRules } from "../../labels";
import { LoanOfferStatus } from "../../utils/types";
import PermissionDenied from "../LoanOfferErrorDetails";
import { validateLoanRequestFilter } from "../LoanRequestTabs";
import CustomDescriptionItem from "./LoanOfferItem";

const { Text } = Typography;
const LoanOfferDetails = ({
  loanDetails,
  loading,
  error,
}: {
  loanDetails: any;
  loading: boolean;
  error?: any;
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState({ ...loanDetails });
  const [showPreviewChangesModal, setShowPreviewChangesModal] = useState(false);
  const { editLoanOfferDetail, loading: editLoading } =
    useQueryLoanOfferDetails();
  const handleEdit = () => {
    setEditMode(true);
    setEditedData({ ...editedData, edited: false });
  };

  const updateEditedData = (updates: Record<string, any>) => {
    setEditedData((prevData: any) => ({ ...prevData, ...updates }));
  };

  const handleDeepCompareOfferDetails = (editedData: any, loanDetails: any) => {
    for (const key in editedData) {
      if(key === "edited") return false;
      // eslint-disable-next-line eqeqeq
      if (editedData[key] != loanDetails[key]) {
        return true;
      }
    }
    return false;
  };
  const handleSaveChanges = () => {
    // Perform actions to save edited data
    const ErrorValidation = validateLoanRequestFilter(
      editedData,
      updateRules,
      labels
    );
    if (ErrorValidation) {
      return showError(ErrorValidation);
    }
    // delete edited boolean
    if (!handleDeepCompareOfferDetails(editedData, loanDetails)) {
      return showError("No changes made");
    }
    //we want to show preview modal
    setShowPreviewChangesModal(true);
  };
  const saveEditedData = () => {
    setShowPreviewChangesModal(false)
    delete editedData.edited;
    setEditMode(false);
    editLoanOfferDetail(
      loanDetails.loanProduct,
      loanDetails.loanProduct === ELoanRequestProduct.WCL
        ? loanDetails.reference
        : loanDetails.offerReference,
      editedData
    );
  };
  useEffect(() => {
    setEditedData(loanDetails);
  }, [loanDetails]);
  useEffect(() => {
    // Recalculate fees when editedData.amount changes
    if (editedData?.amount && editMode) {
      handleRecalculateFees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedData?.amount]);
  const handleCancelEdit = () => {
    setEditMode(false);
    setEditedData({ ...loanDetails });
  };
  const handleBuildOfferDifference = () => {
    const comparedData = buildUpdatedLoanOfferData(loanDetails, editedData, false);
    if (comparedData.loanTenureUnit && !comparedData.tenure) {
      comparedData.tenure = loanDetails.tenure;
    }
    if (comparedData.loanTenureUnit && !comparedData.repaymentFrequency) {
      comparedData.repaymentFrequency = loanDetails.repaymentFrequency;
    }
    return comparedData;
  }
  const handleRecalculateFees = () => {
    const loanAmount = parseFloat(editedData.amount);
    var processingFee = 0.001 * loanAmount;
    if (processingFee < 5000) processingFee = 5000;
    if (processingFee > 50000) processingFee = 50000;
    const managementFee = loanAmount * 0.01 - processingFee;

    setEditedData((prevData: any) => ({
      ...prevData,
      processingFee: processingFee.toFixed(2),
      managementFee: managementFee < 1 ? 0 : managementFee.toFixed(2),
    }));
  };
  if (error)
    return (
      <PermissionDenied
        isPermissionError={false}
        errorMessage="Loan Offer details not found"
      />
    );
  return (
    <>
      {loading ? (
        <section className="flex items-center justify-center">
          <Spin tip="Loading Offer Details..." />
        </section>
      ) : (
        <>
          <Descriptions 
            layout="vertical" 
            contentStyle={{ marginTop: '-16px', maxWidth: 'fit-content' }}
            column={{ xs: 1, md: 2, xl: 3 }}
          >
            <Descriptions.Item label={<Text strong>Loan Product</Text>}>
                {loanDetails?.loanProduct}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Loan Offer Status</Text>}>
              <Tag
                color={convertStatusTextToColor(loanDetails?.loanRequestStatus)}
              >
                {convertStatusToHumanReadable(loanDetails?.loanRequestStatus)}
              </Tag>
            </Descriptions.Item>

            <Descriptions.Item label={<Text strong>Amount</Text>}>
              <CustomDescriptionItem
                value={editedData?.amount}
                editMode={editMode}
                setValue={(value) =>
                  updateEditedData({ amount: value, edited: true })
                }
                editProps={{
                  type: "number",
                }}
                parseAmount
              />
            </Descriptions.Item>
            {editedData?.processingFee && (
              <Descriptions.Item label={<Text strong>Processing Fee</Text>}>
                <CustomDescriptionItem
                  value={editedData?.processingFee}
                  editMode={editMode}
                  setValue={(value) =>
                    updateEditedData({
                      processingFee: value,
                      edited: true,
                    })
                  }
                  editProps={{
                    type: "number",
                  }}
                  parseAmount
                />
              </Descriptions.Item>
            )}

            <Descriptions.Item label={<Text strong>Loan Tenure Unit</Text>}>
              <CustomDescriptionItem
                dateTenure
                value={editedData?.loanTenureUnit}
                editMode={editMode}
                dateProps={{
                  disallowHourly: true,
                }}
                setValue={(value) =>
                  updateEditedData({
                    loanTenureUnit: value,
                    edited: true,
                  })
                }
              />
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Tenure</Text>}>
              <CustomDescriptionItem
                value={editedData?.tenure}
                editMode={editMode}
                setValue={(value) =>
                  updateEditedData({
                    tenure: value,
                    loanTenure: value,
                    edited: true,
                  })
                }
                renderProps={{
                  suffix:
                    " " +
                    convertDateToHumanReadableFrequency(
                      editedData?.tenure,
                      editedData?.loanTenureUnit
                    ),
                }}
                editProps={{
                  type: "number",
                  maxLength: 5,
                }}
              />
            </Descriptions.Item>

            <Descriptions.Item label={<Text strong>Management Fee</Text>}>
              <CustomDescriptionItem
                value={editedData?.managementFee}
                editMode={editMode && loanDetails?.loanProduct === ELoanContractProduct.OVERDRAFT}
                setValue={(value) =>
                  updateEditedData({
                    managementFee: value,
                    edited: true,
                  })
                }
                editProps={{
                  type: "number",
                }}
                parseAmount={true}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={<Text strong>Daily Interest Rate Percentage</Text>}
            >
              <CustomDescriptionItem
                value={editedData?.dailyInterestRateInPercentage}
                editMode={editMode}
                setValue={(value) =>
                  updateEditedData({
                    dailyInterestRateInPercentage: value,
                    edited: true,
                  })
                }
                renderProps={{
                  suffix: "% daily",
                }}
                editProps={{
                  type: "number",
                }}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={<Text strong>Interest Calculation Frequency</Text>}
            >
              {editedData?.interestCalculationFrequency}
            </Descriptions.Item>

            {/* WCL items */}
            {loanDetails?.loanProduct === ELoanRequestProduct.WCL && (
              <>
                <Descriptions.Item
                  label={<Text strong>Repayment frequency</Text>}
                >
                  <CustomDescriptionItem
                    value={editedData?.repaymentFrequency}
                    editMode={editMode}
                    setValue={(value) =>
                      updateEditedData({
                        repaymentFrequency: value,
                        edited: true,
                      })
                    }
                    editProps={{
                      type: "number",
                    }}
                    renderProps={{
                      prefix: "Every",
                      suffix:
                        " " +
                        convertDateToHumanReadableFrequency(
                          editedData?.repaymentFrequency,
                          editedData?.loanTenureUnit
                        ),
                    }}
                  />
                </Descriptions.Item>
              </>
            )}

            {/* "OVD ITEMS" */}
            {loanDetails?.loanProduct === ELoanContractProduct.OVERDRAFT && (
              <>
                <Descriptions.Item
                  label={<Text strong>Overdraft Account Number</Text>}
                >
                  {loanDetails?.loanAccountNuban}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Regularization Period</Text>}
                >
                  <CustomDescriptionItem
                    value={editedData?.regularizationPeriod}
                    editMode={editMode}
                    setValue={(value) =>
                      updateEditedData({
                        regularizationPeriod: value,
                        edited: true,
                      })
                    }
                    editProps={{
                      type: "number",
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Regularization Period Unit</Text>}
                >
                  {loanDetails?.regularizationPeriodUnit}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Maximum Count to termination</Text>}
                >
                  <CustomDescriptionItem
                    value={editedData?.maxDefaultCountToTermination}
                    editMode={editMode}
                    setValue={(value) =>
                      updateEditedData({
                        maxDefaultCountToTermination: value,
                        edited: true,
                      })
                    }
                    editProps={{
                      type: "number",
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Business Owner Code</Text>}
                >
                  {loanDetails?.businessOwnerCode}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
          <Row justify="end">
            {!editMode &&
              loanDetails?.loanRequestStatus ===
                LoanOfferStatus.PERUSING_LOAN_OFFER && (
                <Button
                  type="primary"
                  className="rounded mb-2"
                  onClick={handleEdit}
                >
                  {editLoading ? "Saving details" : "Edit Loan Offer"}
                </Button>
              )}
            {editMode && (
              <>
                <Button
                  type="primary"
                  className="rounded mb-2 mr-4"
                  onClick={handleSaveChanges}
                  disabled={editLoading || !editedData?.edited}
                  loading={editLoading}
                >
                  {editLoading ? "Saving details" : "Save Changes"}
                </Button>
                <Button
                  type="ghost"
                  className="rounded mb-2"
                  onClick={handleCancelEdit}
                  disabled={editLoading}
                >
                  {" "}
                  Cancel
                </Button>
              </>
            )}
          </Row>
        </>
      )}
      <Modal
        destroyOnClose={true}
        centered
        open={showPreviewChangesModal}
        className="modal-lg my-6 top-0"
        title={<Typography.Title level={4}>Preview Changes</Typography.Title>}
        width={800}
        okText={"Proceed to save!"}
        cancelText="Cancel"
        okButtonProps={{ disabled: loading }}
        onOk={saveEditedData}
        onCancel={() => setShowPreviewChangesModal(false)}
      >
        <div style={{ textAlign: "center" }}>
          <UpdatedLoanOfferDetails
            loanDetails={loanDetails}
            loading={loading}
            error={error}
            updatedOffer={showPreviewChangesModal ? handleBuildOfferDifference() : {}}
          />
        </div>
      </Modal>
    </>
  );
};
export default LoanOfferDetails;
