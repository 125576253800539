import React, { FC } from 'react';
import { Descriptions, Empty, Spin, Typography } from 'antd';
import { useQueryPortfolioManagerDetails } from '../../../PortfolioManagement/portfolioManagerDetails/hooks/useQueryPortfolioManagerDetails';
import { useQueryPMSPortfolioManagerDetails } from '../../../PortfolioManagement/portfolioManagerDetails/hooks/useQueryPMSPortfolioManagersDetails';
import { parseAmountToCurrencyValue } from '../../../../../utils/dataParser';



interface UpdatePmMaxApprovableAmountProps {
    src: any;
    data: any;
}

const UpdatePmMaxApprovableAmount: FC<UpdatePmMaxApprovableAmountProps> = ({ src }) => {
    const {
        portfolioManagerDetails,
        portfolioManagerDetailsLoading,
    } = useQueryPortfolioManagerDetails({
        payload: { id: src?.request?.pmCode }
    })
    const {
        details,
        detailsLoading
    } = useQueryPMSPortfolioManagerDetails({
        payload: { id: src?.request?.pmCode }
    })



    return (
        <React.Fragment>
            {(portfolioManagerDetailsLoading || detailsLoading) ? (
                <section className="centered-container">
                    <Spin tip="Loading Details..." />
                </section>
            ) : (portfolioManagerDetails && details) ? (
                <>
                    <Descriptions title="Request Details" layout="vertical" column={{ md: 2, sm: 1, xs: 1 }}>
                        <Descriptions.Item label={<Typography.Text strong>First Name</Typography.Text>}>
                            {portfolioManagerDetails.firstName}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Typography.Text strong>Last Name</Typography.Text>}>
                            {portfolioManagerDetails.lastName}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Typography.Text strong>Email</Typography.Text>}>
                            {portfolioManagerDetails.email}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Typography.Text strong>pmCode</Typography.Text>}>
                            {portfolioManagerDetails.pmCode}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Typography.Text strong>Current Max Approvable Amount</Typography.Text>}>
                            {details ? parseAmountToCurrencyValue(details?.maxApprovableAmount) : "--"}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Typography.Text strong>New Max Approvable Amount</Typography.Text>}>
                            {parseAmountToCurrencyValue(src?.request?.maxApprovableAmount) || "--"}
                        </Descriptions.Item>
                    </Descriptions>
                </>
            ) : (
                <section className="centered-container">
                    <Empty />
                </section>
            )}
        </React.Fragment>
    )
}

export default UpdatePmMaxApprovableAmount;
