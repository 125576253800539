import Kuwego from "./kuwego.svg";
import KuwegoWhite from "./kuwego_white.svg";
import Moniepoint from "./Moniepoint.svg"
import MoniepointLight from "./MoniepointLight.svg"
import MoniepointIcon from "./MoniepointIcon.svg"
import ErrorIcon from "./ErrorIcon.svg";

const LocalIcons = {
    Kuwego,
    KuwegoWhite,
    Moniepoint,
    MoniepointLight,
    MoniepointIcon,
    ErrorIcon
}

export default LocalIcons
