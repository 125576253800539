import { Alert, Spin } from "antd";
import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import LocalIcons from "../../../assets/SVGs";
import { useAppDispatch, useAppSelector } from "../../../store";
import { cosmosLogin } from "../authSlice/authSlice.thunks";


const CosmosLoginPage = () => {

    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const { authError, authLoading } = useAppSelector(state => state.auth)

    useEffect(() => {
        let isMounted = true;

        if (isMounted && searchParams.get("code")) {
            dispatch(cosmosLogin(searchParams.get("code") as string))
        }

    }, [searchParams, dispatch])

    return (
        <main
            className="_bg-gray-lighten-more w-full h-full flex flex-col relative"
        >
            <img src={LocalIcons.Moniepoint} alt="Moniepoint" className="absolute top-10 left-10 max-w-[11.5rem] max-h-[3.84rem]" />
            <section className="w-full h-full flex flex-col items-center justify-center">
                <section
                    className="w-[90%] sm:w-[450px] md:w-[500px] lg:w-[45%] xl:w-[35%] shadow-2xl bg-white p-6 rounded-xl flex flex-col items-center"
                >
                    {searchParams.get("error") ? (
                        <section className="centered-container mb-3">
                            <Alert message={searchParams.get("error") || 'An error occured.'} type="error" />
                        </section>
                    ) : searchParams.get("code") ? (
                        authLoading ? (
                            <section className="centered-container mb-3">
                                <Spin tip="Loging you in" />
                            </section>
                        ) : authError ? (
                            <section className="centered-container mb-3">
                                <Alert message={authError || 'An error occured.'} type="error" />
                            </section>
                        ) : (
                            <section className="centered-container mb-3">
                                <Alert message="Cosmos SSO Login" type="info" />
                            </section>
                        )
                    ) : (
                        <section className="centered-container mb-3">
                            <Alert message="Invalid authorization details!" type="error" />
                        </section>
                    )}
                </section>

                <section className="mt-16">
                    <Link to="/">
                        <h4 className="text-base font-medium">
                            Return to login...
                        </h4>
                    </Link>
                </section>
            </section>
        </main>
    )
}

export default CosmosLoginPage;
