import React from "react";
import { showServerError } from "../../../../utils/notifications";
import { get_loan_agreement, get_loan_agreement_file } from "../API";
import { ELoanContractProduct, LoanAgreement } from "../utils/types";

interface Params {
  ref: string;
  loanProduct: ELoanContractProduct;
}

const useQueryLoanAgreements = ({ ref, loanProduct }: Params) => {
  const [loadingAgreements, setLoadingAgreements] =
    React.useState<boolean>(false);
  const [loadingAgreementFile, setLoadingAgreementFile] =
    React.useState<boolean>(false);
  const [loanAgreements, setLoanAgreements] = React.useState<
    LoanAgreement[] | null
  >(null);
  const [loanAgreementFile, setLoanAgreementFile] = React.useState<
    string | null
  >(null);
  const [loanAgreementPermissionError, setLoanAgreementPermissionError] =
    React.useState<boolean | null>(false);

  const fetchLoanAgreements = async (ref: string) => {
    try {
      setLoadingAgreements(true);
      const response = await get_loan_agreement(ref);
      setLoanAgreements(response);
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        setLoanAgreementPermissionError(true);
        return; //exit early
      }
      // handle 404 no agreements found
      if (error.response && error.response.status === 404) {
        showServerError("We're unable to find the loan agreement for this contract. Please try again or contact support if the error persists.");
        return; //exit early
      }
      showServerError(error);
    } finally {
      setLoadingAgreements(false);
    }
  };
  const fetchLoanAgreementFile = async (laeReference: string) => {
    try {
      setLoadingAgreementFile(true);
      setLoanAgreementFile(null);
      setLoanAgreementPermissionError(false);
      const response = await get_loan_agreement_file(laeReference);
      const file = URL.createObjectURL(response);
      setLoanAgreementFile(file);
    } catch (error: any) {
      // handle error 403
      if (error.response && error.response.status === 403) {
        setLoanAgreementPermissionError(true);
        return; //exit early
      }
      // handle 404 no file found
      if (error.response && error.response.status === 404) {
        showServerError("We are unable to find the PDF file for the agreement. Please retry, and if the issue persists, contact support.");
        return; //exit early
      }
      showServerError(error);
    } finally {
      setLoadingAgreementFile(false);
    }
  };

  React.useEffect(() => {
    fetchLoanAgreements(ref);
  }, [ref, loanProduct]);

  return {
    loadingAgreementFile,
    loadingAgreements,
    loanAgreementPermissionError,
    fetchLoanAgreementFile,
    loanAgreementFile,
    loanAgreements,
  };
};

export default useQueryLoanAgreements;
