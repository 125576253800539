import {
  Alert,
  Button,
  Modal,
  Table,
  Tag,
  Typography,
  Form,
  Input
} from "antd";
import { Store } from "antd/lib/form/interface";
import React, { useEffect, useState } from "react";

import moment from "moment";
import {
  LoanRequestVerificationAcceptanceStatus,
  LoanRequestVerificationStatus
} from "../loanRequestSlice/loanRequest.types";
import { convertStatusTextToColor } from "../../../../utils/workflow";
import { useResendLoanRequestVerification } from "./hooks/useResendVerification";


interface Props {
  data?: any;
}
export const verificationStatusConverter = (status: string) => {
  if (status === LoanRequestVerificationStatus.CANCELLED_DUE_TO_REASSIGNMENT) {
    return "CANCELLED DUE TO REASSIGNMENT";
  }
  if (status === LoanRequestVerificationStatus.CANCELLED_DUE_TO_REJECTION) {
    return "CANCELLED DUE TO REJECTION";
  }
  if (status === LoanRequestVerificationStatus.FAILED) {
    return "FAILED";
  }
  if (status === LoanRequestVerificationStatus.SUCCESSFUL) {
    return "SUCCESSFUL";
  }
  if (status === LoanRequestVerificationStatus.SENT_FOR_VERIFICATION) {
    return "SENT FOR VERIFICATION";
  }
  if (status === LoanRequestVerificationStatus.PENDING) {
    return "PENDING";
  }
};
export const verificationAcceptanceStatusConverter = (status: string) => {
  if (status === LoanRequestVerificationAcceptanceStatus.ACCEPTED) {
    return "ACCEPTED";
  }
  if (status === LoanRequestVerificationAcceptanceStatus.REJECTED) {
    return "REJECTED";
  }
};
interface RowItem {
  type: string;
  verificationDate: string;
  status: string;
  acceptanceStatus: string;
  reference: string;
}
const maxCount = 100;
const minCount = 5;
const FormItem = Form.Item;
const LoanRequestVerificationTable: React.FC<Props> = ({ data }) => {
  const [modalView, setModalView] = useState<boolean>(false);
  const[verificationRef,setVerificationRef]=useState<string>("")
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const {
    resendLoanRequestVerificatioLoading,
    resendLoanRequestVerificatioError,
    resendLoanRequestVerification,
    clearResendVerificationLoanRequestError
  } = useResendLoanRequestVerification();
  useEffect(() => {
    forceUpdate({});
  }, []);
  const clearError = () => {
    clearResendVerificationLoanRequestError();
  };
 
  const onCloseModal = () => {
    setModalView(false);
    clearError();
  };
  const openModal = (verificationRef:string) => {
    setModalView(true);
    setVerificationRef(verificationRef);
  };
  const tableColumns = [
    {
      title: "Verification Type",
      dataIndex: "type",
      key: "type",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Date",
      dataIndex: "verificationDate",
      key: "verificationDate",
      render: (value: string) => {
        if (value) {
          return <div>{moment(value).format("YYYY-MM-DD")}</div>;
        } else {
          return <div>{"-----"}</div>;
        }
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string) => {
        if (value === LoanRequestVerificationStatus.PENDING) {
          return (
            <Tag color={"#f39834"}>{verificationStatusConverter(value)}</Tag>
          );
        }
        if (value === LoanRequestVerificationStatus.SENT_FOR_VERIFICATION) {
          return (
            <Tag color={"#f39834"}>{verificationStatusConverter(value)}</Tag>
          );
        }
        if (value === LoanRequestVerificationStatus.SUCCESSFUL) {
          return (
            <Tag color={"#87d068"}>{verificationStatusConverter(value)}</Tag>
          );
        }
        if (value === LoanRequestVerificationStatus.FAILED) {
          return <Tag color={"#f50"}>{verificationStatusConverter(value)}</Tag>;
        }
        if (
          value === LoanRequestVerificationStatus.CANCELLED_DUE_TO_REASSIGNMENT
        ) {
          return (
            <Tag color={"#f39834"}>{verificationStatusConverter(value)}</Tag>
          );
        }
        if (
          value === LoanRequestVerificationStatus.CANCELLED_DUE_TO_REJECTION
        ) {
          return (
            <Tag color={"#f39834"}>{verificationStatusConverter(value)}</Tag>
          );
        }
      }
    },
    {
      title: "Acceptance Status",
      dataIndex: "acceptanceStatus",
      key: "acceptanceStatus",
      render: (value: string) => {
        return (
          <Tag color={convertStatusTextToColor(value)}>
            {verificationAcceptanceStatusConverter(value)}
          </Tag>
        );
      }
    },
    {
      title: "",
      dataIndex: "reference",
      key: "reference",
      render: (value: string, row: RowItem) => {
        if (
          row.acceptanceStatus ===
            LoanRequestVerificationAcceptanceStatus.ACCEPTED &&
          row.status === LoanRequestVerificationStatus.FAILED
        )
          return (
            <Button
              type="primary"
              className="bg-[#0190fe] border-[#0190fe] rounded"
              onClick={()=>openModal(value)}
            >
              Resend Verification
            </Button>
          );
      }
    }
  ];
 

  const onFinish = (values: Store) => {
 
    if(verificationRef&&values&&values?.reason){
      const paylod={
        verificationReference:verificationRef,
        reverificationReason:values?.reason,
      }
      resendLoanRequestVerification(paylod,()=>{
        onCloseModal();
      });
    }
  };

  return (
    <>
      <Table
        className="w-full overflow-x-auto"
        dataSource={data}
        columns={tableColumns}
        rowKey={(record) => record.reference}
        loading={false}
      />
      <Modal
        destroyOnClose={true}
        centered
        className="modal-lg my-6"
        width={900}
        open={modalView}
        onCancel={onCloseModal}
        title={
          <Typography.Title level={4}>{"Resend Verification"}</Typography.Title>
        }
        footer={null}
      >
       {resendLoanRequestVerificatioError&&<Alert
          message={resendLoanRequestVerificatioError}
          type="error"
          closable
          className="mt-4"
          onClose={clearError}
        />}
        <Form
          layout="vertical"
          form={form}
          name="resend-verification"
          onFinish={onFinish}
        >
          <FormItem
            label="Reason"
            name="reason"
            rules={[
              {
                required: true,
                message: "Reason cannot be empty"
              },
              { min: minCount, message: `Minimum of ${minCount} characters` },
              { max: maxCount, message: `Maximum of ${maxCount} characters` }
            ]}
          >
            <Input.TextArea
              size="large"
              placeholder=""
              className="w-full"
              rows={4}
              showCount
              maxLength={maxCount}
            />
          </FormItem>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue }) => {
              const { reason } = getFieldsValue();
              const formIsComplete = !!reason;
              return (
                <div className="flex justify-end mt-[30px]">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !formIsComplete ||
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length > 0
                    }
                    loading={resendLoanRequestVerificatioLoading}
                  
                  >
                    Submit
                  </Button>
                </div>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LoanRequestVerificationTable;
