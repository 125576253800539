import { createSlice } from '@reduxjs/toolkit';
import { initPagination } from '../../../../utils/pagination';

import { IPortfolio } from './portfolio.types';

const initialState: IPortfolio = {
    createPortfolioLevelError: "",
    createPortfolioLevelLoading: false,
    createPortfolioLevelloadingSucess: false,
    portfolioDetailsError: "",
    portfolioDetailsData: null,
    portfolioDetailsLoading: false,
    settingsUpdateLoading: false,
    settingsUpdateError: "",
    settingsUpdateResponse: null,
    portfolioLevels: [],
    portfolioLevelsLoading: false,
    portfolioManagementSettings: [],
    portfolioManagementSettingsLoading: false,
    portfolioAllData: null,
    portfolioAllLoading: false,
    portfolioAllError: "",
    enablePmSuccess: false,
    enablePmLoading: false,
    enablePmErorr: "",
    removeLgasPmSuccess: false,
    removeLgasPmLoading: false,
    removeLgasErorr: "",
    fcoPoLevelData: null,
    portfolioPagination: initPagination,
    portfolioManagerDetailsLoading: "",
    portfolioManagerDetails: null,
    onBoardUsersAsPmLoading:false,
    onBoardUsersAsPmError:"",
    onBoardUsersAsPmSuccess:false,
};




const portfolioSlice = createSlice({
    name: "portfolioSystem",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.createPortfolioLevelLoading = action.payload;
        },
        setCreatePortfolioLevelSuccess: (state, action) => {
            state.createPortfolioLevelloadingSucess = action.payload;
        },

        createPortfolioLevelError: (state, action) => {
            state.createPortfolioLevelError = action.payload

        },
        savePortfolioManagerDetails: (state, action) => {

            state.portfolioDetailsData = action.payload?.responseBody
        },
        portfolioManagerDetailsError: (state, action) => {

            state.portfolioDetailsError = action.payload
        },
        portfolioManagerDetailsLoading: (state, action) => {

            state.portfolioDetailsLoading = action.payload
        },
        setSettingsUpdateLoading: (state, action) => {
            state.settingsUpdateLoading = action.payload
        },
        setSettingsUpdateError: (state, action) => {
            state.settingsUpdateError = action.payload
        },
        saveSettingsUpdateResponse: (state, action) => {
            state.settingsUpdateResponse = action.payload
        },
        setPortfolioLevelsLoading: (state, action) => {
            state.portfolioLevelsLoading = action.payload
        },
        savePortfolioLevels: (state, action) => {
            state.portfolioLevels = action.payload
        },
        setPortfolioManagementSettingsLoading: (state, action) => {
            state.portfolioManagementSettingsLoading = action.payload
        },
        savePortfolioManagementSettings: (state, action) => {
            state.portfolioManagementSettings = action.payload
        },
        setAllPortfolioManagersLoading: (state, action) => {
            state.portfolioAllLoading = action.payload;
        },
        saveAllPortfolioDataPagination: (state, action) => {
            state.portfolioPagination = action.payload
        },
        saveAllPortfolioManagerData: (state, action) => {

            state.portfolioAllData = action.payload?.responseBody?.content
        },
        setAllPortfolioManagerError: (state, action) => {

            state.portfolioAllError = action.payload
        },
        enablePmSuccessAction: (state, action) => {

            state.enablePmSuccess = action.payload
        },
        enablePmLoading: (state, action) => {

            state.enablePmLoading = action.payload
        },
        enablePmErorr: (state, action) => {
            state.enablePmErorr = action.payload
        },
        removedPmSuccessAction: (state, action) => {

            state.removeLgasPmSuccess = action.payload
        },
        removedPmLoading: (state, action) => {

            state.removeLgasPmLoading = action.payload
        },
        removedPmErorr: (state, action) => {
            state.removeLgasErorr = action.payload
        },
        saveFcoLevel: (state, action) => {
            state.fcoPoLevelData = action.payload
        },
        setPortfolioManagerDetailsLoading: (state, action) => {
            state.portfolioManagerDetailsLoading = action.payload
        },
        savePortfolioManagerDetail: (state, action) => {
            state.portfolioManagerDetails = action.payload
        },
        reset: (state) => {
            state.createPortfolioLevelError = ""
            state.createPortfolioLevelLoading = false;
            state.createPortfolioLevelloadingSucess = false;
        },
        resetPmEnableDetails: (state) => {
            state.enablePmLoading = false
            state.enablePmErorr = ""
            state.enablePmSuccess = false
        },
        resetRemoveLgaPmDetails: (state) => {
            state.removeLgasPmLoading = false
            state.removeLgasErorr = ""
            state.removeLgasPmSuccess = false
        },
        setOnBoardUsersAsPmLoading: (state, action) => {
            state.onBoardUsersAsPmLoading = action.payload
        },
        saveOnBoardUsersAsPmError: (state, action) => {
            state.onBoardUsersAsPmError = action.payload
        },
        setOnBoardUsersAsPmSucess: (state, action) => {
            state.onBoardUsersAsPmSuccess = action.payload
        },
        resetOnBoardUsersAsPm:(state)=>{
            state.onBoardUsersAsPmLoading=false
            state.onBoardUsersAsPmError=""
            state.onBoardUsersAsPmSuccess=false
        },
        resetOnBoardUsersError:(state)=>{
            state.onBoardUsersAsPmError=""  
        },
        resetAllPortfolioManagers:(state)=>{
            state.portfolioAllData= null
            state.portfolioAllLoading= false
            state.portfolioAllError= ""
            state.portfolioPagination =initPagination
        }
    }
})




export const {
    setLoading,
    createPortfolioLevelError,
    reset,
    setCreatePortfolioLevelSuccess,
    savePortfolioManagerDetails,
    portfolioManagerDetailsError,
    portfolioManagerDetailsLoading,
    setSettingsUpdateLoading,
    setSettingsUpdateError,
    saveSettingsUpdateResponse,
    setPortfolioLevelsLoading,
    savePortfolioLevels,
    setPortfolioManagementSettingsLoading,
    savePortfolioManagementSettings,
    setAllPortfolioManagersLoading,
    saveAllPortfolioManagerData,
    setAllPortfolioManagerError,
    enablePmSuccessAction,
    enablePmLoading,
    enablePmErorr,
    resetPmEnableDetails,
    saveFcoLevel,
    setPortfolioManagerDetailsLoading,
    savePortfolioManagerDetail,
    removedPmSuccessAction,
    removedPmLoading,
    removedPmErorr,
    resetRemoveLgaPmDetails,
    saveAllPortfolioDataPagination,
    setOnBoardUsersAsPmLoading,
    saveOnBoardUsersAsPmError,
    setOnBoardUsersAsPmSucess,
    resetOnBoardUsersError,
    resetOnBoardUsersAsPm,
    resetAllPortfolioManagers
} = portfolioSlice.actions;

const portFolioReducer = portfolioSlice.reducer


export default portFolioReducer;