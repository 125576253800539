import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Spin,
  Descriptions,
  Typography,
  Button,
  Empty,
  Modal,
  Alert
} from "antd";

import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import { useQueryUserDetailsManagers } from "../hooks/useUserDetails";
import { useSendActivationLinkEmail } from "../hooks/useSendActivationLinkEmail";
import { showMessage } from "../../../../utils/notifications";
const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path
  },
  {
    title: "View Users",
    path: DashboardRoutePaths.viewAllUsers.path
  },
  {
    title: "View User Details",

    active: true
  }
];

const ViewUserDetails: React.FC = () => {
  const params = useParams<{ "*": string; id: string }>();
  const { loading, usersDetailsData,retryUserDetails } = useQueryUserDetailsManagers({
    payload: { userName: params?.id as string }
  });
  const {
    sendActivationLinkError,
    sendActivationLinkloading,
    resetActivationStates,
    sendActivationLink
  } = useSendActivationLinkEmail();
  const [showActivationModel, setShowActivationModel] = useState(false);
  const onCloseActivationLinkModel = () => {
    resetActivationStates();
    setShowActivationModel(false);
  };

  const onSendActivationLink = (email: string) => {
    if(email){
      sendActivationLink(email, (data: any) => {
        onCloseActivationLinkModel();
        retryUserDetails();
      });
    }else{
      showMessage(
        "Error",
        "User email cannot be empty.",
        "error"
      );
    }
   
  };
  return (
    <>
      <Page breadCrumbItems={breadCrumbItems}>
        <Card
          title="View Field Credit Officer Details"
          style={{ margin: "18px 0" }}
        >
          {loading ? (
            <section className="flex items-center justify-center h-[400px]">
              <Spin tip="Loading Details..." />
            </section>
          ) : usersDetailsData ? (
            <>
              <Descriptions layout="vertical">
                <Descriptions.Item
                  label={<Typography.Text strong>User Name</Typography.Text>}
                >
                  {usersDetailsData?.username || "-----"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Typography.Text strong>First Name</Typography.Text>}
                >
                  {usersDetailsData?.firstName || "-----"}
                </Descriptions.Item>

                <Descriptions.Item
                  label={<Typography.Text strong>Middle Name</Typography.Text>}
                >
                  {usersDetailsData?.middleName || "-----"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Typography.Text strong>Last Name</Typography.Text>}
                >
                  {usersDetailsData?.lastName || "-----"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Typography.Text strong>Email</Typography.Text>}
                >
                  {usersDetailsData?.email || "-----"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Typography.Text strong>Phone Number</Typography.Text>}
                >
                  {usersDetailsData?.phoneNumber || "-----"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Typography.Text strong>Activated</Typography.Text>}
                >
                  {usersDetailsData?.activated ? "YES" : "NO"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Typography.Text strong>Status</Typography.Text>}
                >
                  {usersDetailsData?.activationStatus
                    ? usersDetailsData?.activationStatus
                    : "-----"}
                </Descriptions.Item>
              </Descriptions>
              {!usersDetailsData?.activated ? (
                <>
                  {" "}
                  <Button
                    type="primary"
                    className="rounded"
                    onClick={() => {
                      setShowActivationModel(true);
                    }}
                  >
                    Send Activation Link
                  </Button>
                </>
              ) : null}
            </>
          ) : (
            <section className="flex items-center justify-center h-[400px]">
              <Empty />
            </section>
          )}
        </Card>
      </Page>
      <Modal
        destroyOnClose={true}
        centered
        className="modal-lg my-6"
        width={"fit-content"}
        open={showActivationModel}
        onCancel={onCloseActivationLinkModel}
        closable={false}
        footer={null}
      >
        <div className="flex items-center justify-center mt-[10px]">
          <Typography.Title level={5}>
            {usersDetailsData?.username
              ? `Do you want to send activation link to ${usersDetailsData?.username} email ?`
              : "Do you want to send activation link ?"}
          </Typography.Title>
        </div>
        {sendActivationLinkError&&<div className="mt-[10px] mb-[10px] flex items-center justify-center">
        <Alert message={sendActivationLinkError||"Sorry an error occurred"} type="error" />
        </div>}
        <div className="flex items-center justify-center mt-[20px]">
          <Button
            type="primary"
            onClick={()=>onSendActivationLink(usersDetailsData?.email)}
            className=" w-[20%] bg-[#0190fe] border-[#0190fe] rounded mr-[20px]"
           loading={sendActivationLinkloading}
          >
            Yes
          </Button>
          <Button
            type="primary"
            className="w-[20%] rounded"
            onClick={onCloseActivationLinkModel}
            danger
          >
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ViewUserDetails;
