import { loadLGAs, loadLGAsByState, loadStates, loadStatesByCountryCode, setCountriesLoading, setStatesByCountryCodeLoading, setLGAsByStateLoading, setLGAsLoading, setStatesLoading,resetByStateLGAs, setMoniepointUserAccountDetailsError, setMoniepointUserAccountDetailsLoading, saveMoniepointUserAccountDetails, resetMoniepointUserAccountDetails, loadCountries } from ".";
import { AppThunk } from "../../../../store";
import { parseError } from "../../../../utils/server";
import { MoniepointUserAccountDetails } from "./utilsSlice.types";
import { api_get_countries, api_get_LGAs, api_get_LGAs_by_state_code, api_get_states, api_get_states_by_country_code, get_moniepoint_user_account_details } from "../UtilsApi";

export const fetchCountries = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setCountriesLoading(true))

        const response = await api_get_countries();

        dispatch(loadCountries(response.responseBody))
    } catch (error: any) {
        
    } finally {
        dispatch(setCountriesLoading(false))
    }
}


export const fetchStatesByCountryCode = (countryCode: string): AppThunk => async (dispatch) => {
    try {
        dispatch(setStatesByCountryCodeLoading(true))

        const response = await api_get_states_by_country_code(countryCode);

        dispatch(loadStatesByCountryCode(response.responseBody))
    } catch (error: any) {
        
    } finally {
        dispatch(setStatesByCountryCodeLoading(false))
    }
}

export const resetStatesByCountryCode = ():AppThunk => (dispatch) => {
    try {
       dispatch(loadStatesByCountryCode([]))
    } catch (error: any) {
        
    } 
}

export const fetchStates = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setStatesLoading(true))

        const response = await api_get_states();

        dispatch(loadStates(response.responseBody))
    } catch (error: any) {
        
    } finally {
        dispatch(setStatesLoading(false))
    }
}

export const fetchLGAs = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLGAsLoading(true))

        const response = await api_get_LGAs();

        dispatch(loadLGAs(response.responseBody))
    } catch (error: any) {
        
    } finally {
        dispatch(setLGAsLoading(false))
    }
}

export const fetchLGAsByStateCode = (stateCode: string): AppThunk => async (dispatch) => {
    try {
        dispatch(setLGAsByStateLoading(true))

        const response = await api_get_LGAs_by_state_code(stateCode);

        dispatch(loadLGAsByState(response.responseBody))
    } catch (error: any) {
        
    } finally {
        dispatch(setLGAsByStateLoading(false))
    }
}

export const resetLgasByStateCode = (): AppThunk => async (dispatch) => {
    try {
       dispatch(resetByStateLGAs())
    } catch (error: any) {
        
    } 
}


export const fetchMoniepointUserAccoutDetails = (
    accountNumber: string,
    onSuccess?: (data: MoniepointUserAccountDetails) => void,
): AppThunk => async (dispatch, getState) => {
    try {
        const {
            utils: {
                moniepointUserAccountDetailsError
            }
        } = getState()

        if (moniepointUserAccountDetailsError) {
            dispatch(setMoniepointUserAccountDetailsError(""))
        }
        dispatch(setMoniepointUserAccountDetailsLoading(true))

        const response = await get_moniepoint_user_account_details(accountNumber)

        dispatch(saveMoniepointUserAccountDetails(response?.responseBody))
        if (onSuccess) {
            onSuccess(response?.responseBody)
        }
    } catch (error) {
        dispatch(setMoniepointUserAccountDetailsError(parseError(error)))
        dispatch(resetMoniepointUserAccountDetails())
    } finally {
        dispatch(setMoniepointUserAccountDetailsLoading(false))
    }
}

