import React from "react";
import { Pagination, extractPaginationData, initPagination } from "../../../../utils/pagination";
import { parseError } from "../../../../utils/server";
import { showMessage } from "../../../../utils/notifications";
import { get_all_loan_contracts_service } from "../API";



export interface LoanContractsQueryProps {
    payload: {
        searchParams?: Record<string, any>,
        pagination?: Pagination
    }
}

export const useQueryLoanContracts = (props: LoanContractsQueryProps) => {
    const {
        payload: {
            searchParams,
            pagination
        }
    } = props

    const [loanContracts, setLoanContracts] = React.useState<any | null>(null);
    const [loanContractsPagination, setLoanContractsPagination] = React.useState<Pagination>(initPagination);
    const [error, setError] = React.useState('')
    const [loading, setLoading] = React.useState(false)



    const getAllLoanContracts = React.useCallback(async (
        searchParams?: Record<string, any>,
        pagination?: Pagination
    ) => {
        try {
            setError('')
            setLoading(true)

            const response = await get_all_loan_contracts_service(searchParams, pagination);
            const paginationData = extractPaginationData(response.responseBody)

            setLoanContracts(response?.responseBody?.content)
            setLoanContractsPagination(paginationData)


        } catch (error: any) {
            showMessage("Loan Requests", parseError(error), "error");
            setError(parseError(error))
        } finally {
            setLoading(false)
        }

    }, [])


    React.useEffect(() => {

        getAllLoanContracts(searchParams, pagination)

    }, [searchParams, pagination, getAllLoanContracts])

    return {
        error,
        loading,
        loanContracts,
        loanContractsPagination,
        getAllLoanContracts,
    }

}