import { Rules } from "./components/LoanRequestTabs";

export const updateRules: Rules = {
    amount: { required: true, greaterThanZero: true  },
    tenure: { required: true, digitsOnly: true, greaterThanZero: true  },
    loanTenureUnit: {required: true},
    processingFee: { required: false, notLessThanZero: true },
    managementFee: { required: true, greaterThanZero: true  },
    hourlyInterestRateInPercentage: { required: true, greaterThanZero: true  },
    dailyInterestRateInPercentage: { required: true, greaterThanZero: true  },
    repaymentFrequency: { required: true, digitsOnly: true, greaterThanZero: true  },
    regularizationPeriod: { required: true, digitsOnly: true, greaterThanZero: true  },
    maximumDefaultCountToTermination: { required: true, digitsOnly: true, greaterThanZero: true  },
  };
export const labels : { [key: string]: string } = {
    amount: "Amount",
    tenure: "Tenure",
    loanTenureUnit: "Loan Tenure Unit",
    processingFee: "Processing Fee",
    managementFee: "Management Fee",
    hourlyInterestRateInPercentage: "Hourly Interest Rate",
    dailyInterestRateInPercentage: "Daily Interest Rate",
    repaymentFrequency: "Repayment Frequency",
    regularizationPeriod: "Regularization Period",
    maximumDefaultCountToTermination: "Maximum Default Count To Termination",
  }