import React, { FC, useEffect, useMemo } from 'react';
import { Descriptions, Empty, List, Spin, Tree, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { fetchLGAs } from '../../../Utils/UtilsSlice/utilsSlice.thunks';
import { ILocalGovernment } from '../../../Utils/UtilsApi/utilsAPI.types';
import { fetchPortfolioManagerDetails } from '../../../PortfolioManagement/portfolioSlice/portofolio.thunks';



interface AssignOrUnassignLGAsToPMWorkFlowProps {
    src: any;
    data: any;
}

const AssignOrUnassignLGAsToPMWorkFlow: FC<AssignOrUnassignLGAsToPMWorkFlowProps> = ({ src, data }) => {
    const dispatch = useAppDispatch()
    const { portFolioReducer: { portfolioManagerDetails, portfolioManagerDetailsLoading }, utils: { LGAs } } = useAppSelector(state => state)

    useEffect(() => {
        let isMounted = true

        if (isMounted && src && src?.request?.pmCode) {
            dispatch(fetchPortfolioManagerDetails(src?.request?.pmCode))
            dispatch(fetchLGAs())
        }

        return () => { isMounted = false }
    }, [src, dispatch, data])

    const LGAsData = useMemo(
        () => {
            const workflowLGAs: ILocalGovernment[] = []
            const pmLGAs: ILocalGovernment[] = []

            if (src.request?.lgaCodes.length && LGAs.length) {
                for (const code of src.request?.lgaCodes) {
                    const lgaObject = LGAs.find(lga => lga.code === code)

                    if (lgaObject) {
                        workflowLGAs.push(lgaObject)
                    }
                }
            }

            if (portfolioManagerDetails?.assignedLgas?.length && LGAs.length) {
                for (const lgaIfo of portfolioManagerDetails?.assignedLgas) {
                    const lgaObject = LGAs.find(lga => lga.code === lgaIfo.lgaCode)

                    if (lgaObject) {
                        pmLGAs.push(lgaObject)
                    }
                }
            }

            return { workflowLGAs, pmLGAs }
        },

        [LGAs, src, portfolioManagerDetails]
    )



    return (
        <React.Fragment>
            {portfolioManagerDetailsLoading ? (
                <section className="centered-container">
                    <Spin tip="Loading Details..." />
                </section>
            ) : portfolioManagerDetails ? (
                <>
                    <Descriptions title="Request Details" layout="vertical">
                        <Descriptions.Item label="First Name">
                            {portfolioManagerDetails.firstName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Last Name">
                            {portfolioManagerDetails.lastName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Email">
                            {portfolioManagerDetails.email}
                        </Descriptions.Item>
                        <Descriptions.Item label="pmCode">
                            {portfolioManagerDetails.pmCode}
                        </Descriptions.Item>

                        <Descriptions.Item label="PM LGAs">
                            <Tree
                                treeData={LGAsData.pmLGAs.map(lga => ({
                                    title: lga.name,
                                    key: lga.id,
                                    children: [
                                        { key: lga.code, title: lga.code },
                                        { key: `${lga.state}-${lga.code}`, title: lga.state }
                                    ]
                                }))}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                    <List
                        header={
                            <Typography.Title level={5}>
                                {data.workflowRequestType === "ASSIGN_LGAS_TO_PORTFOLIO_MANAGER" ? "Assigned LGAs" : "Removed LGAs"}
                            </Typography.Title>}
                        dataSource={LGAsData.workflowLGAs}
                        renderItem={item => (
                            <List.Item>
                                <Typography.Text>{item.code}</Typography.Text> - <Typography.Text >{item.name}</Typography.Text> - <Typography.Text>{item.state}</Typography.Text>
                            </List.Item>
                        )}

                    />
                </>
            ) : (
                <section className="centered-container">
                    <Empty />
                </section>
            )}
        </React.Fragment>
    )
}

export default AssignOrUnassignLGAsToPMWorkFlow;
