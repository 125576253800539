import React from "react"
import { WorkflowCategory } from "../workflowSlice/workflowSlice.types"
import { api_fetch_workflow_types } from "../workflowApi"
import { parseError } from "../../../../utils/server"
import { showServerError } from "../../../../utils/notifications"
import { responseExtractorMap } from "./utils/helper"


interface WorkflowRequestsTypesQueryParams {
    payload: {
        category: WorkflowCategory
    }
}

export const useQueryWorkflowRequestsTypes = ({
    payload: {
        category
    }
}: WorkflowRequestsTypesQueryParams) => {

    const [types, setTypes] = React.useState<any[]>([])


    const fetchWorkFlowRequestsTypes = React.useCallback(async() => {
        try {
            const response = await api_fetch_workflow_types(category)
            const { responseBody } = responseExtractorMap[category]?.(response)
    
            setTypes(responseBody)
        } catch (error: any) {
            showServerError(parseError(error))
        }
    }, [category])



    React.useEffect(() => {
        fetchWorkFlowRequestsTypes()
    }, [fetchWorkFlowRequestsTypes])


    return {
        types,
        fetchWorkFlowRequestsTypes
    }

}
