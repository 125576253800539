import { createSlice } from "@reduxjs/toolkit";
import { IUtilsState } from "./utilsSlice.types";

const initialState: IUtilsState = {
    countries: [],
    countriesLoading: false,
    states: [],
    statesLoading: false,
    statesByCountryCode: [],
    statesByCountryCodeLoading: false,
    LGAs: [],
    LGAsLoading: false,
    LGAsByState: [],
    LGAsByStateLoading: false,
    moniepointUserAccountDetails: null,
    moniepointUserAccountDetailsLoading: false,
    moniepointUserAccountDetailsError: "",
}

const utilsSlice = createSlice({
    name: "utils",
    initialState,
    reducers: {
        loadCountries: (state, action) => {
            state.countries = action.payload
        },
        setCountriesLoading: (state, action)  => {
            state.countriesLoading = action.payload
        },
        loadStates: (state, action) => {
            state.states = action.payload
        },
        setStatesLoading: (state, action) => {
            state.statesLoading = action.payload
        },
        loadStatesByCountryCode: (state, action) => {
            state.statesByCountryCode = action.payload;
        },
        setStatesByCountryCodeLoading: (state, action) => {
            state.statesByCountryCodeLoading = action.payload
        },
        loadLGAs: (state, action) => {
            state.LGAs = action.payload
        },
        setLGAsLoading: (state, action) => {
            state.LGAsLoading = action.payload
        },
        loadLGAsByState: (state, action) => {
            state.LGAsByState = action.payload
        },
        setLGAsByStateLoading: (state, action) => {
            state.LGAsByStateLoading = action.payload
        },
        resetByStateLGAs: (state) => {
            state.LGAsByState = []
        },

        saveMoniepointUserAccountDetails: (state, action) => {
            state.moniepointUserAccountDetails = action.payload
        },
        setMoniepointUserAccountDetailsLoading: (state, action) => {
            state.moniepointUserAccountDetailsLoading = action.payload
        },
        setMoniepointUserAccountDetailsError: (state, action) => {
            state.moniepointUserAccountDetailsError = action.payload
        },
        resetMoniepointUserAccountDetails: (state) => {
            state.moniepointUserAccountDetails = null
        }
    }
})


export const {
    loadCountries,
    setCountriesLoading,
    loadStates, 
    setStatesLoading, 
    loadStatesByCountryCode,
    setStatesByCountryCodeLoading,
    loadLGAs, 
    setLGAsLoading, 
    loadLGAsByState, 
    setLGAsByStateLoading, 
    resetByStateLGAs,
    saveMoniepointUserAccountDetails,
    setMoniepointUserAccountDetailsError,
    setMoniepointUserAccountDetailsLoading,
    resetMoniepointUserAccountDetails,
} = utilsSlice.actions

const utilsReducer = utilsSlice.reducer


export default utilsReducer
