import React, { FC, useEffect } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { useAppSelector } from "../../store";
import { AuthRoutes } from "./AuthRoutes";


const Auth: FC = () => {

    const navigate = useNavigate()
    const { user, isAuthenticated } = useAppSelector(state => state.auth)
    const elements = useRoutes(AuthRoutes);

    useEffect(() => {
        if (isAuthenticated && user) {
            navigate("/dashboard")
        }
    }, [isAuthenticated, user, navigate])

    return (
        <>
            {elements}
        </>
    )
}


export default Auth
