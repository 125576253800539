import { createSlice } from "@reduxjs/toolkit";
import { initPagination } from "../../../../utils/pagination";
import { RegionalManagerState } from "./types";




const initialState: RegionalManagerState = {
    regionalManagers: [],
    regionalManagersPagination: initPagination,
    regionalManagersLoading: false,
    regionalManagersError: "",
    regionalManagerDetails: null,
    regionalManagerDetailsLoading: false,
    regionalManagerOnboardingError: "",
    regionalManagerOnboardingLoading: false,
}



const regionalManagerSlice = createSlice({
    name: "regionalManagerState",
    reducers: {
        saveRegionalManagers: (state, action) => {
            state.regionalManagers = action.payload
        },
        saveRegionalmanagersError: (state, action) => {
            state.regionalManagersError = action.payload
        },
        saveRegionalManagersPagination: (state, action) => {
            state.regionalManagersPagination = action.payload
        },
        setRegionalManagersLoading: (state, action) => {
            state.regionalManagersLoading = action.payload
        },

        saveRegionalManagerDetails: (state, action) => {
            state.regionalManagerDetails = action.payload
        },
        setRegionalManagerDetailsLoading: (state, action) => {
            state.regionalManagerDetailsLoading = action.payload
        },

        setRegionalManagerOnboardingLoading: (state, action) => {
            state.regionalManagerOnboardingLoading = action.payload
        },
        setRegionalManagerOnboardingError: ( state, action) => {
            state.regionalManagerOnboardingError = action.payload
        }
    },
    initialState
})


export const {
    saveRegionalManagers,
    saveRegionalmanagersError,
    saveRegionalManagersPagination,
    setRegionalManagersLoading,
    saveRegionalManagerDetails,
    setRegionalManagerDetailsLoading,
    setRegionalManagerOnboardingError,
    setRegionalManagerOnboardingLoading,
} = regionalManagerSlice.actions


const regionalManagerReducer = regionalManagerSlice.reducer
export default regionalManagerReducer
