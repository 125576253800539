import { Modal, Spin, Typography } from "antd";
import { LoanAgreement } from "../utils/types";
import ServerErrorAlert from "../../../../components/ServerErrorAlert";

const AgreementDetails = ({
  agreement,
  showAgreementModal,
  agreementFile,
  loading,
  closeAgreement,
}: {
  agreement: LoanAgreement | null;
  showAgreementModal: boolean;
  agreementFile: string | null;
  closeAgreement: () => void;
  loading: boolean;
}) => {
  const handleDownloadAgreement = () => {
    if (agreementFile) {
      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = agreementFile;
      link.download = getAgreementFileName(); // Set the desired file name
      link.click();
      link.remove();
    }
  };
  const getAgreementFileName = () => {
    return `${agreement?.type}-${
      agreement?.name
    }-${agreement?.laeReference.slice(0, 10)}.pdf`;
  };
  return (
    <Modal
      destroyOnClose={true}
      centered
      open={showAgreementModal}
      className="modal-lg my-6 top-0"
      title={
        <Typography.Title level={4}>
          Loan agreement for {agreement?.name}{" "}
        </Typography.Title>
      }
      width={1200}
      okText={loading ? <Spin size="small" /> : "Download Agreement"}
      cancelText="Close"
      okButtonProps={{ disabled: loading }}
      onOk={handleDownloadAgreement}
      onCancel={closeAgreement}
    >
      <div style={{ textAlign: "center" }}>
        {loading ? (
          <Spin size="large" />
        ) : agreementFile ? (
          <object
            data={`${agreementFile}#zoom=100`}
            type="application/pdf"
            width="100%"
            height="700"
          >
            <p>PDF cannot be displayed, You can download the agreement to view</p>
          </object>
        ) : (
          <ServerErrorAlert message="We are unable to find the PDF file for the agreement. Please retry, and if the issue persists, contact support." />
        )}
      </div>
    </Modal>
  );
};

export default AgreementDetails;
