import {
  loadActiveLGAs,
  setActivateLGAsError,
  setActivateLGAsLoading,
  setActiveLGAsError,
  setActiveLGAsLoading,
  setDeactivateLGAsError,
  setDeactivateLGAsLoading,
} from ".";
import { AppThunk } from "../../../../store";
import { showMessage } from "../../../../utils/notifications";
import { parseError } from "../../../../utils/server";
import {
  api_activate_LGAs,
  api_deactivate_LGAs,
  api_get_Active_LGAs,
} from "../lgaActivationApi";

export const fetchActiveLGAs = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setActiveLGAsLoading(true));
    const response = await api_get_Active_LGAs();
    dispatch(loadActiveLGAs(response.responseBody));
  } catch (error: any) {
    dispatch(setActiveLGAsError(parseError(error)));
  } finally {
    dispatch(setActiveLGAsLoading(false));
  }
};

export const activateLGAs =
  (lgaCodes: string[]): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        lgaActivation: { activeLGAs },
      } = getState();

      dispatch(setActivateLGAsLoading(true));
      const response = await api_activate_LGAs(lgaCodes);

      if (response?.requestSuccessful) {
        dispatch(loadActiveLGAs([...(activeLGAs || []), ...lgaCodes]));
        showMessage(
          "Successful",
          "Request to activate LGAs was sent successfully"
        );
      }
    } catch (error) {
      dispatch(setActivateLGAsError(parseError(error)));
    } finally {
      dispatch(setActivateLGAsLoading(false));
    }
  };

export const deactivateLGAs =
  (lgaCodes: string[]): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        lgaActivation: { activeLGAs },
      } = getState();

      dispatch(setDeactivateLGAsLoading(true));
      const response = await api_deactivate_LGAs(lgaCodes);

      if (response?.requestSuccessful) {
        dispatch(
          loadActiveLGAs(
            (activeLGAs || []).filter(
              (lgaCode: string) => !lgaCodes.includes(lgaCode)
            )
          )
        );
        showMessage(
          "Successful",
          "Request to deactivate LGAs was sent successfully"
        );
      }
    } catch (error) {
      dispatch(setDeactivateLGAsError(parseError(error)));
    } finally {
      dispatch(setDeactivateLGAsLoading(false));
    }
  };
