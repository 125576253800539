import config from "../../../../config";
import server from "../../../../server";
import { IResponse } from "../../../../server/server.types";
import { initPagination } from "../../../../utils/pagination";
import { RegionalManagerOnboardingParams } from "../Slice/types";


export const get_all_regional_managers_service = async (
    searchParams: Record<string, any> = {},
    pagination: Record<string, any> = initPagination
) => {

    let page = pagination.current - 1;

    const response = await server.get(
        `${config.WCL_PORTFOLIO_BASE_URL}/regional-managers`,
        {
            params: { ...searchParams, page: page, size: pagination.pageSize }
        }
    );

    return response.data
};


export const get_regional_manager_details_service = async (code: string) => {

    const response = await server.get(
        `${config.WCL_PORTFOLIO_BASE_URL}/regional-managers/code/${encodeURIComponent(code)}`,

    );

    return response.data
};



export const onboard_regional_manager_service = async (params: RegionalManagerOnboardingParams) => {
    const response = await server.post(
        `${config.WCL_PORTFOLIO_BASE_URL}/regional-managers/register`,
        params
    )

    return response.data as IResponse<any>
}
