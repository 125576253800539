import React, { FC, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Loading } from "../components/Loaders";
import Auth from "./Auth";
import Dashboard from "./Dashboard";



const RouteModules: FC = () => {
    return (
            <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/*" element={<Auth />} />
                    <Route path="/dashboard/*" element={<Dashboard />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}


export default RouteModules
