import { useEffect, useState } from "react"
import { useAppDispatch } from "../../../../../store"
import { fetchFileUrl } from "../fileSlice/fileSlice.thunk"


export const useFileRef = (fileRef: string) => {
    const dispatch = useAppDispatch()
    const [image, setImage] = useState("")


    useEffect(() => {
        if (fileRef) {
            dispatch(fetchFileUrl(fileRef, url => {
                setImage(url)
            }))
        } else {
            setImage('')
        }
    }, [dispatch, fileRef])

    return ({ image })
}

