import { Button, Modal, Typography } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import RegionalManagersTable from "./RegionalManagersTable";
import {
  DashboardRoutePaths,
  getStaticPathString
} from "../../../../routes/Dashboard/routePaths";
import OnboardingWizard from "../../Utils/Onboarding";
import { RegionalManagerOnboardingParams } from "../Slice/types";
import { useOnboardingRegionalManager} from "./hook";



const ViewRegionalManagers = () => {
  
  const {
    onCloseOnBoardModal,
    onboardRegionalManager,
    regionalManagerOnboardingError,
    regionalManagerOnboardingLoading
  } = useOnboardingRegionalManager();
  const [showOnboarding, setShowOnboarding] = useState(false);

  const closeOnboardingView = () => {
    onCloseOnBoardModal();
    setShowOnboarding(false);
  };

  const onSubmitOnboardingRequest = ({
    accountNumber
  }: RegionalManagerOnboardingParams) => {
    onboardRegionalManager({ accountNumber }, (data: any) => {
      closeOnboardingView();
    });
  };

  return (
    <>
      <section className=" w-full py-4 flex items-center justify-end">
        <Button
          type="primary"
          className="rounded"
          onClick={() => {
            setShowOnboarding(true);
          }}
        >
          Onboard New Regional Manager
        </Button>
      </section>

      <RegionalManagersTable
        columns={[
          {
            title: "",
            dataIndex: "code",
            key: "code",
            render: (value: string) => {
              return (
                <Link
                  to={`${getStaticPathString(
                    DashboardRoutePaths.WCLRegionalManagersDetails.path
                  )}${value}`}
                >
                  <Button
                    type="primary"
                    className="bg-[#0190fe] border-[#0190fe] rounded"
                  >
                    View Details
                  </Button>
                </Link>
              );
            }
          }
        ]}
      />

      <Modal
        destroyOnClose={true}
        centered
        className="modal-lg my-6"
        width={900}
        open={showOnboarding}
        onCancel={closeOnboardingView}
        title={
          <Typography.Title level={4}>
            {"Onboard new Regional Manager"}
          </Typography.Title>
        }
        footer={null}
      >
        <OnboardingWizard
          userType="WCLRM"
          onSubmitOnboardingRequest={onSubmitOnboardingRequest}
          submitError={regionalManagerOnboardingError}
          submitLoading={regionalManagerOnboardingLoading}
        />
      </Modal>
    </>
  );
};

export default ViewRegionalManagers;
