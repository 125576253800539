import React from "react"
import { Pagination, extractPaginationData, initPagination } from "../../../../utils/pagination"
import { COMPLETED_REQUESTS_URL_MAP, PENDING_REQUESTS_URL_MAP, api_fetch_workflow_requests } from "../workflowApi"
import { parseError } from "../../../../utils/server"
import { showServerError } from "../../../../utils/notifications"
import { WorkflowCategory } from "../workflowSlice/workflowSlice.types"
import { responseExtractorMap } from "./utils/helper"


export interface WorkflowRequestsQueryProps {
    payload: {
        url: string
        params?: Record<string, any>
        pagination?: Record<string, any>
    }
    responseExtractor(response: Record<string, any>): { responseBody: Record<string, any> }
}

export const useQueryWorkflowRequests = (props: WorkflowRequestsQueryProps) => {
    const {
        payload: {
            url,
            params,
            pagination,
        },
        responseExtractor,
    } = props

    const [data, setData] = React.useState<any[]>([])
    const [queryPagination, setQueryPagination] = React.useState<Pagination>(initPagination)
    const [loading, setLoading] = React.useState(false)


    const fetchWorkFlowRequests = React.useCallback(async ({
        params,
        pagination
    }: {
        params?: Record<string, any>;
        pagination?: Record<string, any>;
    }) => {
        try {
            setLoading(true)

            const response = await api_fetch_workflow_requests(url, params, pagination)
            const { responseBody } = responseExtractor(response)

            const currentPagination = extractPaginationData(responseBody)


            setQueryPagination(currentPagination)
            setData(responseBody?.content)
        } catch (error: any) {
            showServerError(parseError(error))
            setData([])
            setQueryPagination(initPagination)
        } finally {
            setLoading(false)
        }
    }, [url, responseExtractor])



    React.useEffect(() => {

        fetchWorkFlowRequests({ params, pagination })

    }, [fetchWorkFlowRequests, params, pagination])


    return {
        data,
        loading,
        pagination: queryPagination,
        fetchWorkFlowRequests
    }

}




interface FecthWorkflowRequestsParams {
    category: WorkflowCategory
    params?: Record<string, any>
    pagination?: Record<string, any>
}

export const useQueryPendingWorkflowRequests =
    ({
        category,
        params,
        pagination
    }: FecthWorkflowRequestsParams) => useQueryWorkflowRequests({
        payload: {
            url: PENDING_REQUESTS_URL_MAP[category],
            params,
            pagination
        },
        responseExtractor: responseExtractorMap[category]
    })

export const useQueryCompletedWorkflowRequests =
    ({
        category,
        params,
        pagination
    }: FecthWorkflowRequestsParams) => useQueryWorkflowRequests({
        payload: {
            url: COMPLETED_REQUESTS_URL_MAP[category],
            params,
            pagination
        },
        responseExtractor: responseExtractorMap[category]
    })