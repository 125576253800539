import { Descriptions, Empty, List, Spin, Typography } from "antd";
import React, { FC, useEffect, useMemo} from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { useQueryFCODetails } from "../../../FCOManagement/FCOdetails/hooks/useQueryFCODetails";
import { ILocalGovernment } from "../../../Utils/UtilsApi/utilsAPI.types";
import { fetchLGAs } from "../../../Utils/UtilsSlice/utilsSlice.thunks";



interface DeactivateFCOProps {
  src: any;
  data: any;
}

const PromoteDeactivateFCO: FC<DeactivateFCOProps> = ({ src }) => {
  const dispatch = useAppDispatch();
  const {
    utils: { LGAs }
  } = useAppSelector((state) => state);

  const { fcoDetailData, loading } = useQueryFCODetails({
    payload: {
      id: src?.request?.fcoCode as string
    }
  });

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      dispatch(fetchLGAs());
    }

    return () => {
      isMounted = false;
    };
  }, [src, dispatch]);

  const assignedLGAs = useMemo(() => {
    const assignedLGAs: ILocalGovernment[] = [];

    if (fcoDetailData?.lgaCodes?.length && LGAs?.length) {
      for (const lgaCode of fcoDetailData?.lgaCodes) {
        const lgaObject = LGAs.find((lga) => lga.code === lgaCode);

        if (lgaObject) {
          assignedLGAs.push(lgaObject);
        }
      }
    }

    return assignedLGAs;
  }, [LGAs, fcoDetailData?.lgaCodes]);

  return (
    <React.Fragment>
      {loading ? (
        <section className=" flex items-center justify-center py-10">
          <Spin tip="Loading Details..." />
        </section>
      ) : fcoDetailData ? (
        <>
          <Descriptions
            title="Request Details"
            layout="vertical"
            column={{ md: 2, sm: 1, xs: 1 }}
          >
            <Descriptions.Item
              label={<Typography.Text strong>User Name</Typography.Text>}
            >
              {fcoDetailData?.username}
            </Descriptions.Item>
            <Descriptions.Item
              label={<Typography.Text strong>First Name</Typography.Text>}
            >
              {fcoDetailData?.firstName}
            </Descriptions.Item>

            <Descriptions.Item
              label={<Typography.Text strong>Last Name</Typography.Text>}
            >
              {fcoDetailData?.lastName}
            </Descriptions.Item>
            <Descriptions.Item
              label={<Typography.Text strong>Email</Typography.Text>}
            >
              {fcoDetailData?.email}
            </Descriptions.Item>
            <Descriptions.Item
              label={<Typography.Text strong>FCO Code</Typography.Text>}
            >
              {fcoDetailData?.fcoCode}
            </Descriptions.Item>
            <Descriptions.Item
              label={<Typography.Text strong>RM Code</Typography.Text>}
            >
              {fcoDetailData?.rmCode}
            </Descriptions.Item>

            <Descriptions.Item
              label={<Typography.Text strong>Income Account</Typography.Text>}
            >
              {fcoDetailData?.incomeAccount}
            </Descriptions.Item>
            <Descriptions.Item
              label={<Typography.Text strong>BVN</Typography.Text>}
            >
              {fcoDetailData?.bvn}
            </Descriptions.Item>
          </Descriptions>
          <List
            header={
              <Typography.Title level={5}>Assigned LGAs</Typography.Title>
            }
            dataSource={assignedLGAs}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text>{item.code}</Typography.Text> -{" "}
                <Typography.Text>{item.name}</Typography.Text>
              </List.Item>
            )}
          />
        </>
      ) : (
        <section className="centered-container">
          <Empty />
        </section>
      )}
    </React.Fragment>
  );
};

export default PromoteDeactivateFCO;
