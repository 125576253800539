import { createSlice } from '@reduxjs/toolkit';
import { initPagination } from '../../../../utils/pagination';
import { IStatesCordinators } from './state.types';

const initialState: IStatesCordinators = {
    stateCordinatorsData: null,
    stateCoordinatorPagination:initPagination,
    error: "",
    loading: false,
    success: false,
    stateCoordinatorDetails: null,
    stateCoordinatorDetailsLoading: false,
    stateCoordinatorDetailsError: "",
    enableStateCoordLoading: false,
    enableStateCoordResponse: null,
};




const stateCordinatorSlice = createSlice({
    name: "statesCordinatorsdetails",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },

        saveAllStateCordinatorsData: (state, action) => {

            state.stateCordinatorsData = action.payload?.responseBody?.stateCoordinators
            state.error = ""
        },
        saveAllStateCordinatorsPagination: (state, action) => {
            state.stateCoordinatorPagination= action.payload
        },
        saveAllStateCordinatorsError: (state, action) => {
            state.error = action.payload
            state.success = false
        },
        setStateCoordinatorDetailsLoading: (state, action) => {
            state.stateCoordinatorDetailsLoading = action.payload
        },
        setStateCoordinatorDetailsError: (state, action) => {
            state.stateCoordinatorDetailsError = action.payload
        },
        saveStateCoordinatorDetails: (state, action) => {
            state.stateCoordinatorDetails = action.payload
        },
        setEnableStateCoordLoading: (state, action) => {
            state.enableStateCoordLoading = action.payload
        },
        saveEnableStateCoordResponse: (state, action) => {
            state.enableStateCoordResponse = action.payload
        },
        reset: (state) => {
            state.stateCordinatorsData = null
            state.loading = false
            state.error = ""
            state.success = false
        }

    }
})




export const {
    setLoading, 
    saveAllStateCordinatorsData, 
    saveAllStateCordinatorsError,
    setStateCoordinatorDetailsLoading,
    setStateCoordinatorDetailsError,
    saveStateCoordinatorDetails,
    setEnableStateCoordLoading,
    saveEnableStateCoordResponse,
    reset,
    saveAllStateCordinatorsPagination,
} = stateCordinatorSlice.actions;


const stateCordinatorsReducer = stateCordinatorSlice.reducer


export default stateCordinatorsReducer;