import React from "react";
import { Pagination, extractPaginationData, initPagination } from "../../../../utils/pagination";
import { get_all_users_service} from "../usersApi";
import { parseError } from "../../../../utils/server";
import { showMessage } from "../../../../utils/notifications";



export interface UsersQueryProps {
    payload: {
        searchParams?: Record<string, any>,
        pagination?: Pagination
    }
}

export const useQueryUserManagers = (props:UsersQueryProps) => {
    const {
        payload: {
            searchParams,
            pagination
        }
    } = props

    const [usersData, setUsersData] = React.useState< any | null>(null);
    const [usersPagination, setUsersPagination] = React.useState<Pagination>(initPagination);
    const [error, setError] = React.useState('')
    const [loading, setLoading] = React.useState(false)



    const getAllUsersManagers = React.useCallback(async (
        searchParams?: Record<string, any>,
        pagination?: Pagination
    ) => {
            try {
                setError('')
                setLoading(true)

                const response = await get_all_users_service(searchParams, pagination);
                const paginationData = extractPaginationData(response.responseBody)

                setUsersData(response?.responseBody?.content)
                setUsersPagination(paginationData)

                
            } catch (error: any) {
                showMessage("Users management", parseError(error), "error");
                setError(parseError(error))
            } finally {
                setLoading(false)
            }

        }, [])

        
        React.useEffect(() => {

            getAllUsersManagers(searchParams, pagination)

        }, [searchParams, pagination, getAllUsersManagers])

        return {
            error,
            loading,
            usersData,
            usersPagination,
            getAllUsersManagers,
        }

}