import { ILocalGovernment } from "../../../Utils/UtilsApi/utilsAPI.types";

export const mapLGACodeToName = (
  code: string | undefined,
  LGAs: ILocalGovernment[]
) => {
  if (!code) {
    return "N/A";
  }
  return LGAs.find((lga) => lga.code === code)?.name || 'N/A';
};
