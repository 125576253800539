import { Avatar, Descriptions, Empty, Image, List, Spin, Typography } from "antd";
import React, { FC } from "react";
import { useQueryFCODetails } from "../../../FCOManagement/FCOdetails/hooks/useQueryFCODetails";
import { UserOutlined } from "@ant-design/icons";
import { useFileRef } from "../../../Utils/FilesUtils/hooks/useFileRef";


interface Props {
    src: any;
    data: any;
}

const AutoReassignLoanRequestsOrContracts: FC<Props> = ({ src }) => {

    const {
        fcoDetailData,
        loading,
    } = useQueryFCODetails({
        payload: {
            id: src?.request?.oldFcoCode as string
        }
    })

    const { image } = useFileRef(fcoDetailData?.imageRef)

    return (
        <React.Fragment>
            {src?.request ? (
                <>
                    {loading ? (
                        <section className="centered-container">
                            <Spin tip="Loading Details..." />
                        </section>
                    )
                        : fcoDetailData ? (
                            <>
                                <Descriptions
                                    title="Previously assigned FCO Details"
                                    layout="vertical"
                                    column={{ md: 2, sm: 1, xs: 1 }}
                                >
                                    <Descriptions.Item
                                        label={<Typography.Text strong>Passport Photo</Typography.Text>}
                                        span={3}
                                    >
                                        {
                                            image
                                                ? <Image src={image} width={200} placeholder />
                                                : <Avatar shape="square" size={200} icon={<UserOutlined />} />
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={<Typography.Text strong>User Name</Typography.Text>}
                                    >
                                        {fcoDetailData?.username}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={<Typography.Text strong>First Name</Typography.Text>}
                                    >
                                        {fcoDetailData?.firstName}
                                    </Descriptions.Item>

                                    <Descriptions.Item
                                        label={<Typography.Text strong>Last Name</Typography.Text>}
                                    >
                                        {fcoDetailData?.lastName}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={<Typography.Text strong>Email</Typography.Text>}
                                    >
                                        {fcoDetailData?.email}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={<Typography.Text strong>FCO Code</Typography.Text>}
                                    >
                                        {fcoDetailData?.fcoCode}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={<Typography.Text strong>RM Code</Typography.Text>}
                                    >
                                        {fcoDetailData?.rmCode}
                                    </Descriptions.Item>

                                    <Descriptions.Item
                                        label={
                                            <Typography.Text strong>Income Account</Typography.Text>
                                        }
                                    >
                                        {fcoDetailData?.incomeAccount}
                                    </Descriptions.Item>
                                </Descriptions>
                            </>
                        ) : (
                            <section className="centered-container">
                                <Empty />
                            </section>
                        )}
                    <List
                        className="mt-8"
                        header={<Typography.Title level={5}>Officers to reassign to</Typography.Title>}
                        dataSource={src?.request?.newFcoCodes}
                        renderItem={(item: string) => (
                            <List.Item>
                                <Typography.Text>{item}</Typography.Text>
                            </List.Item>
                        )}
                    />
                </>
            ) : (
                <section className="centered-container">
                    <Empty />
                </section>
            )}
        </React.Fragment>
    );
};

export default AutoReassignLoanRequestsOrContracts;
