import { UserOutlined } from "@ant-design/icons";
import { Avatar, Descriptions, Image, Typography } from "antd";
import React, { FC } from "react";
import { MoniepointUserAccountDetails } from "../../modules/Dashboard/Utils/UtilsSlice/utilsSlice.types";



interface Props {
    user: MoniepointUserAccountDetails
    image: string
}

const MoniepointUserAccountDetailsView: FC<Props> = ({
    user,
    image
}) => {
    return (
        <Descriptions
            layout="vertical"
            column={{ md: 2, sm: 1, xs: 1 }}
        >
            <Descriptions.Item
                label={
                    <Typography.Text strong>
                        Passport Photo
                    </Typography.Text>
                }
                span={3}
            >
                {
                    image
                        ? <Image src={image} width={200} placeholder />
                        : <Avatar shape="square" size={200} icon={<UserOutlined />} />
                }
            </Descriptions.Item>
            <Descriptions.Item label={<strong>{'First Name'}</strong>}>
                {user?.firstName}
            </Descriptions.Item>
            <Descriptions.Item label={<strong>{'Last Name'}</strong>}>
                {user?.lastName}
            </Descriptions.Item>
            <Descriptions.Item label={<strong>{'Phone Number'}</strong>}>
                {user?.mobileNumber}
            </Descriptions.Item>
            <Descriptions.Item label={<strong>{'Email'}</strong>}>
                {user?.emailAddress}
            </Descriptions.Item>
        </Descriptions>
    )
}


export default MoniepointUserAccountDetailsView
