import React from "react";
import { Navigate, RouteObject } from "react-router-dom";
import CosmosLoginPage from "../../modules/Auth/CosmosLoginPage";


const Login = React.lazy(() => import("../../modules/Auth/Login"))


export const AuthRoutes: RouteObject[] = [
    {
        path: "/",
        element: <Login />
    },
    {
        path: "/cosmos-sso-login",
        element: <CosmosLoginPage />
    },


    {
        path: "*",
        element: <Navigate to="/" replace />
    },
]