import config from "../../../../config";
import server from "../../../../server";
import { initPagination } from "../../../../utils/pagination";

export const get_all_loan_programs_service = async (
  searchParams: Record<string, any> = {},
  pagination: Record<string, any> = initPagination
) => {
  let page = pagination.current -1;
  const response = await server.get(`${config.WCL_MANAGEMENT_BASE_URL}/wcl/backoffice/loan-program?`, {
    params: {  ...searchParams,  page: page, size: pagination.pageSize },
  });
  return response.data;
};

export const get_each_loan_programs_service = async (
  id: Record<number, any> = {}
) => {
  const response = await server.get(`${config.WCL_MANAGEMENT_BASE_URL}/wcl/backoffice/loan-program/${id}`, {
  });
  return response.data;
};


export const get_loan_level_program = async (
  code: string
) => {
  const response = await server.post(`${config.WCL_MANAGEMENT_BASE_URL}/wcl/backoffice/loan-program-level`, {
    loanProgramCode: code ,
  });
  return response.data;
};

export const create_loan_program = async (data: any)=> {
  const response = await server.post(
    `${config.WCL_MANAGEMENT_BASE_URL}/wcl/backoffice/loan-program`,
    data
  );

  return response.data;
}

export const update_loan_program = async (id: any, data: any)=> {
  const response = await server.put(
    `${config.WCL_MANAGEMENT_BASE_URL}/wcl/backoffice/loan-program/update/${id}`,
    data
  );

  return response.data;
}

export const get_loan_program_business_types = async () => {
  const response = await server.get(`${config.WCL_MANAGEMENT_BASE_URL}/wcl/backoffice/loan-program/business/types?pageNumber=1&pageSize=100`);
  return response.data;
};
