import server from "../../../../server";
import { IResponse } from "../../../../server/server.types";
import { initPagination } from "../../../../utils/pagination";
import { IStatesCordinatorDetails } from "../stateCoordinatorSlice/state.types";

export const get_all_state_cordinators_service = async (
  searchParams: Record<string, any> = {},
  pagination: Record<string, any> = initPagination
) => {
  let page = pagination.current;
  const response = await server.get(`/state-coordinators`, {
    params: { ...searchParams, page: page, size: pagination.pageSize },
  });

  return response.data;
};

export const get_state_cordinator_detail_service = async (id: string) => {
  const response = await server.get(`/state-coordinators/${encodeURIComponent(id)}`);

  return response.data as IResponse<IStatesCordinatorDetails>;
};

export const enable_state_coordinator_service = async (scCode: string, stateCode: string, lgaCodes: string[]) => {

  const response = await server.post(
    `/portfolio-managers`,
    {
      scCode,
      stateCode,
      lgaCodes
    }
  );

  return response.data
};