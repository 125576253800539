import { Spin, Tag } from "antd";
import { FC, useEffect, useState } from "react";
import { convertStatusTextToColor } from "../../../../../utils/workflow";
import {
  LoanProductType,
  loanTypeToProductMap,
  WorkflowRequestStatus,
} from "../../../LoanContractsManagement/utils/types";
import { useQueryLoanOfferDetails } from "../../../LoanRequestsManagement/AllLoanRequests/hooks/useQueryLoanOfferDetails";
import UpdatedLoanOfferDetails from "./UpdatedLoanOfferDetails";

interface LoanUpdateWorkflowViewerProps {
  src: any;
  data?: any;
}
export const buildUpdatedLoanOfferData = (
  currentObject: any,
  updatedObject: any,
  isApprovedLoanWorkflow = false
) => {
  const updatedData: any = {};
  console.log(currentObject, updatedObject);
  for (const key in currentObject) {
    if (Object.prototype.hasOwnProperty.call(currentObject, key)) {
      // eslint-disable-next-line eqeqeq
      if (currentObject[key] != updatedObject[key]) {
        updatedData[key] = updatedObject[key];
      } else {
        updatedData[key] = false;
      }
    }
  }
  return updatedData;
};
const LoanUpdateViewer: FC<LoanUpdateWorkflowViewerProps> = ({ src, data }) => {
  const json = src;

  const { loading, loanOfferDetails, getLoanOfferDetail, error } =
    useQueryLoanOfferDetails();
  const [offerDiff, setOfferDiff] = useState<any>(null);
  const isApprovedLoanWorkflow =
    data?.requestStatus === WorkflowRequestStatus.APPROVED;
  const loanProduct =
    loanTypeToProductMap[json.request?.loanProduct as LoanProductType];
  const workflowReference = data?.reference;
  useEffect(() => {
    if (json) {
      getLoanOfferDetail(
        loanProduct,
        json?.loanOfferRef as string,
        isApprovedLoanWorkflow,
        workflowReference
      );
    }
  }, [getLoanOfferDetail, json]);
  useEffect(() => {
    if (loanOfferDetails && !loading) {
      const comparedData = buildUpdatedLoanOfferData(
        loanOfferDetails,
        isApprovedLoanWorkflow
          ? loanOfferDetails?.modificationRequest
          : json.request?.updateRequest[
              json.request?.loanProduct as "WCL" | "OVD"
            ],
        isApprovedLoanWorkflow
      );
      // check if both tenureUnit and tenure Changed
      if (comparedData.loanTenureUnit && !comparedData.tenure) {
        comparedData.tenure = loanOfferDetails.tenure;
      }
      if (comparedData.loanTenureUnit && !comparedData.repaymentFrequency) {
        comparedData.repaymentFrequency = loanOfferDetails.repaymentFrequency;
      }
      setOfferDiff(comparedData);
    }
  }, [
    json.request?.loanProduct,
    json.request?.updateRequest,
    loading,
    loanOfferDetails,
  ]);
  return (
    <div className="p-[25px]">
      {loading ? (
        <section className="flex items-center justify-center">
          <Spin tip="Loading Offer Details..." />
        </section>
      ) : (
        <>
        <UpdatedLoanOfferDetails
          loanDetails={loanOfferDetails}
          loading={loading}
          error={error}
          updatedOffer={offerDiff}
        />
      {
        isApprovedLoanWorkflow && loanOfferDetails?.updateRequestStatus && (
          <div className="mt-4">
            <h3 className="text-sm font-semibold">Update Request Status</h3>
            <div className="mt-2">
              <Tag color={convertStatusTextToColor(loanOfferDetails?.updateRequestStatus)}>
              {loanOfferDetails?.updateRequestStatus}
              </Tag>
            </div>
          </div>
        )
      }
        </>
      )}
    </div>
  );
};
export default LoanUpdateViewer;
