import { notification } from "antd"
import { parseError } from "./server"


type NotificationType = 'success' | 'info' | 'warning' | 'error';


export const showServerError = (error: any) => {
  const errorMessage = parseError(error)
  showMessage('Request Failed', errorMessage, 'error')
}

export const showError = (error: any) => {
  const errorMessage = parseError(error)
  showMessage('Error!', errorMessage, 'error')
}

export const showMessage = (title: string, description: string, type: NotificationType = 'success') => {
  notification.open({
    type: type,
    message: title,
    description: description,
  })
}
