import { parseError } from "../../../../utils/server";
import { AppThunk } from "../../../../store";
import {
  saveAllLoanProgramsDataPagination,
  setAllViewLoanProgramsSliceLoading,
  saveAllLoanProgramsData,
  setAllLoanProgramsError,
  saveEachLoanProgramsData,
  setLoanLevelProgramLoading,
  addLoanLevelProgramsData,
  setLoanLevelProgramError,
  setCreateLoanProgramRunning,
  setCreateLoanProgramError,
  setCreateLoanProgramSuccess,
  setUpdateLoanProgramRunning,
  setUpdateLoanProgramError,
  setUpdateLoanProgramSuccess,
  setBusinessTypesLoading,
  setBusinessTypes,
  setBusinessTypesError,
  setLoanProgramDetailsLoading,
  setLoanProgramDetailsError,
} from ".";
import { extractPaginationData } from "../../../../utils/pagination";
import {
  create_loan_program,
  update_loan_program,
  get_all_loan_programs_service,
  get_each_loan_programs_service,
  get_loan_level_program,
  get_loan_program_business_types,
} from "../loanProgramsAPI";

export const createLoanProgram =
  (data: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCreateLoanProgramRunning(true));
      const response = await create_loan_program(data);

      if (response?.id) {
        dispatch(setCreateLoanProgramSuccess(true));

        setTimeout(() => {
          dispatch(setCreateLoanProgramSuccess(null));
        }, 2000);
      }
    } catch (error: any) {
      dispatch(setCreateLoanProgramError(parseError(error)));
    } finally {
      dispatch(setCreateLoanProgramRunning(false));
    }
  };

export const updateLoanProgram =
  (id: any, data: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setUpdateLoanProgramRunning(true));
      const response = await update_loan_program(id, data);

      if (response?.id) {
        dispatch(setUpdateLoanProgramSuccess(true));

        setTimeout(() => {
          dispatch(setUpdateLoanProgramSuccess(null));
        }, 2000);
      }
    } catch (error: any) {
      dispatch(setUpdateLoanProgramError(parseError(error)));
    } finally {
      dispatch(setUpdateLoanProgramRunning(false));
    }
  };

export const getAllLoanPrograms =
  (
    searchParams?: Record<string, any>,
    pagination?: Record<string, any>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAllViewLoanProgramsSliceLoading(true));
      const response = await get_all_loan_programs_service(
        searchParams,
        pagination
      );
      dispatch(saveAllLoanProgramsData(response));
      const paginationData = extractPaginationData(response.responseBody);
      dispatch(saveAllLoanProgramsDataPagination(paginationData));
    } catch (error: any) {
      dispatch(setAllLoanProgramsError(parseError(error)));
    } finally {
      dispatch(setAllViewLoanProgramsSliceLoading(false));
    }
  };

export const getEachLoanProgram =
  (
    paramsId: Record<number, any>,
    onGetLoanProgram: (code: string) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoanProgramDetailsLoading(true));
      const response = await get_each_loan_programs_service(paramsId);
      dispatch(saveEachLoanProgramsData(response));
      onGetLoanProgram(response.code);
    } catch (error: any) {
      dispatch(setLoanProgramDetailsError(parseError(error)));
    } finally {
      dispatch(setLoanProgramDetailsLoading(false));
    }
  };

export const getLoanLevelProgram =
  (code: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoanLevelProgramLoading(true));
      const response = await get_loan_level_program(code);
      var loanProgramLevelData = {};
      // @ts-ignore
      loanProgramLevelData[code] = response;
      dispatch(addLoanLevelProgramsData({ loanProgramLevelData }));
    } catch (error: any) {
      dispatch(setLoanLevelProgramError(parseError(error)));
    } finally {
      dispatch(setLoanLevelProgramLoading(false));
    }
  };

export const getBusinessTypes = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setBusinessTypesLoading(true));
    const response = await get_loan_program_business_types();
    dispatch(setBusinessTypes(response));
  } catch (error: any) {
    dispatch(setBusinessTypesError(parseError(error)));
  } finally {
    dispatch(setBusinessTypesLoading(false));
  }
};

