import { RowSelectionType } from "antd/lib/table/interface";
import { ReassignmentMode as HowToReassign } from "../UtilsSlice/utilsSlice.types"

export const loanRequestsReassignmentObjectBuilder = ({
    howToReassign,
    reason,
    userRef,
    selectedFCOs,
    selectedLoanRequests,
}: {
    howToReassign: HowToReassign;
    reason: string;
    userRef: string;
    selectedFCOs: string[];
    selectedLoanRequests: string[];
}) => {
    const builderMap = {
        [HowToReassign.AUTO]: {
            data: {
                newFcoCodes: selectedFCOs,
                oldFcoCode: userRef,
                reason,
            },
            mode: howToReassign,
        },
        [HowToReassign.MANUAL]: {
            data: {
                loanRequestReferences: selectedLoanRequests,
                newFcoCode: selectedFCOs[0],
                oldFcoCode: userRef,
                reason,
            },
            mode: howToReassign,
        }
    }

    if (builderMap[howToReassign]) {
        return builderMap[howToReassign] as {mode: HowToReassign, data: Record<string, any>}
    }
}

export const loanContractsReassignmentObjectBuilder = ({
    howToReassign,
    reason,
    userRef,
    selectedFCOs,
    selectedLoanContracts,
}: {
    howToReassign: HowToReassign;
    reason: string;
    userRef: string;
    selectedFCOs: string[];
    selectedLoanContracts: string[]
}) => {
    const builderMap = {
        [HowToReassign.AUTO]: {
            mode: howToReassign,
            data: {
                newFcoCodes: selectedFCOs,
                oldFcoCode: userRef,
                reason,
            }
        },
        [HowToReassign.MANUAL]: {
            mode: howToReassign,
            data: {
                contractReferences: selectedLoanContracts,
                newFcoCode: selectedFCOs[0] || null,
                oldFcoCode: userRef,
                reason,
            }
        }
    }

    if (builderMap[howToReassign]) {
        return builderMap[howToReassign] as {mode: HowToReassign, data: Record<string, any>}
    }
}


export const buildRowSelection = ({
    key,
    type,
    persistedRowKeys,
    updatePersistedRowKeys,
    getCheckboxProps,
}: {
    key: string;
    type: RowSelectionType;
    persistedRowKeys: string[];
    updatePersistedRowKeys(keys: string[]): void;
    getCheckboxProps?(record: any): {
        disabled: boolean;
        name: string
    }
}) => {
    return {
        type,
        onSelect: (record: any, selected: boolean) => {
            if (type === 'radio') {
                updatePersistedRowKeys([record[key]])
            } else {
                if (selected && !persistedRowKeys.includes(record[key])) {
                    updatePersistedRowKeys(persistedRowKeys.concat(record[key]))
                } else {
                    updatePersistedRowKeys(persistedRowKeys.filter(item => item !== record[key]))
                }
            }
        },
        onSelectAll: (selected: boolean, selectedRows: any[], changeRows: any[]) => {
            if (selected) {
                updatePersistedRowKeys(
                    persistedRowKeys.concat(selectedRows?.map(item => item[key])?.filter(item => !persistedRowKeys.includes(item)))
                )
            } else {
                updatePersistedRowKeys(
                    persistedRowKeys.filter(item => !changeRows?.map(item => item[key])?.includes(item))
                )
            }
        },
        selectedRowKeys: persistedRowKeys,
        getCheckboxProps,
    }
}
