import { Button, Card, Col, Form, Input, Row, Table, TableProps } from "antd";
import React, { useState } from "react";
import { parseTableSearchParams } from "../../../../utils/dataParser";
import { initPagination } from "../../../../utils/pagination";

import { useQueryUserManagers } from "../hooks/useQueryUsers";





const FormItem = Form.Item;


interface UsersTableProps {
    extraColumns?: {
        title: string 
        dataIndex: string
        key: string,
        render: (value: string |any , record: any) => React.ReactNode
    }[]
    extraFilterFields?: React.ReactNode
}

const UsersTable = ({
    extraColumns,
    extraFilterFields,
    rowSelection,
}: UsersTableProps & TableProps<any>) => {
   
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useState({});
    const {loading,
        usersData,
        usersPagination,
        getAllUsersManagers}=useQueryUserManagers({payload:{}})
      
    const filterUser = () => {
        const formValues = form.getFieldsValue();
        let searchParams = parseTableSearchParams(formValues);
        setSearchParams(searchParams);
        getAllUsersManagers(searchParams, initPagination);
    };
    const onClearForm = () => {
        form.resetFields();
        setSearchParams({});
        getAllUsersManagers();
    };
    const handleTableChange = (pagination: any) => {
        fetchData(pagination, searchParams);
    };

    const fetchData = (pagination?: any, searchParams?: any) => {
        getAllUsersManagers(searchParams, pagination);
    };

    const tableColumns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
            render: (value: string) => {
                return <div>{value}</div>;
            }
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (value: string) => {
                return <div>{value}</div>;
            }
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName",
            render: (value: string) => {
                return <div>{value}</div>;
            }
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName",
            render: (value: string) => {
                return <div>{value}</div>;
            }
        },
        ...(extraColumns || [])
    ];


    const renderFilterForm = () => {
        return (
            <Form layout="vertical" form={form} name="filter-fco">
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={8}>
                        <FormItem label="User Name" name={"username"} initialValue="">
                            <Input size="large" placeholder="" />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <FormItem label="Email" name={"email"} initialValue="">
                            <Input size="large" placeholder="" />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <FormItem label="First Name" name={"firstName"} initialValue="">
                            <Input size="large" placeholder="" />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <FormItem label="Last Name" name={"lastName"} initialValue="">
                            <Input size="large" placeholder="" />
                        </FormItem>
                    </Col>
                    {extraFilterFields}
                </Row>

                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={12} md={3}>
                        <Button
                            type="primary"
                            className="w-full rounded"
                            onClick={onClearForm}
                            danger
                        >
                            Clear
                        </Button>
                    </Col>
                    <Col xs={24} sm={12} md={4}>
                        <Button
                            type="primary"
                            onClick={filterUser}
                            className="w-full bg-[#0190fe] border-[#0190fe] rounded"
                            htmlType="submit"
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <>
            <Card title="Users Filter" className="my-[18px]">
                {renderFilterForm()}
            </Card>
            <Card title="Users" className="my-[18px] w-full">
                <Table
                    className="w-full overflow-x-auto"
                    rowSelection={rowSelection}
                    dataSource={usersData}
                    columns={tableColumns}
                    rowKey={(record) => record.bvn}
                    loading={loading}
                    pagination={usersPagination}
                    onChange={handleTableChange}
                />
            </Card>
        </>
    )
}

export default UsersTable;
   


   


  

   


