import React from "react";
import ViewLoanRequests from "../ViewLoanRequests";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";

const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "Loan Requests",
    active: true,
  },
];

const ViewLoanRequestsManagers = () => {
  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <ViewLoanRequests/>
    </Page>
  );
};

export default ViewLoanRequestsManagers;
