import {  Descriptions, Empty} from "antd";
import React, {FC} from "react";


interface Props {
  src: any;
  data: any;
}

const FCOPortfolioLevelApproval: FC<
  Props
> = ({src}) => {
 
  
 
  return (
    <React.Fragment>
      {src?.request ? (
        <>
          <Descriptions title="Field Credit Risk Officer Portfolio Level" layout="vertical">
            <Descriptions.Item label="Level Name">
           {src?.request?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Level Code">
            {src?.request?.code}
            </Descriptions.Item>
            <Descriptions.Item label="Gross Loan Portfolio Value Limit">
           {src?.request?.loanPortfolioLimit}
            </Descriptions.Item>
            <Descriptions.Item label="Provisioned Value Limit">
            {src?.request?.provisionedLimit}
            </Descriptions.Item>
          </Descriptions>
        </>
      ) : (
        <section className="centered-container">
          <Empty />
        </section>
      )}
    </React.Fragment>
  );
};

export default FCOPortfolioLevelApproval;
