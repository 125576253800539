import React from "react"
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";
import { assinged_lga_portfolio_manager__service, removed_lga_portfolio_manager_service } from "../../portfolioApi";

export const usePmLgaAssignment = () => {
    const [assignLGAsLoading, setAssignLGAsLoading] = React.useState(false)
    const [unAssignLGAsLoading, setUnAssignLGAsLoading] = React.useState(false)


    const assignLGAsToPortfolioManager = async (
        pmCode: any,
        lgaCodes: any,
        callBack?: ({ requestSuccessful }: { requestSuccessful: boolean }) => void
    ) => {
        try {
            setAssignLGAsLoading(true)

            const response = await assinged_lga_portfolio_manager__service(pmCode, lgaCodes);

            if (response?.requestSuccessful) {
                showMessage(
                    "SUCCESSFUL",
                    "Request is Pending approval",
                    "success"
                );
                if (callBack) {
                    callBack({ requestSuccessful: response?.requestSuccessful })
                }
            }

        } catch (error: any) {
            const errorMessage = parseError(error)

            showMessage("Request Failed", errorMessage, "error")
            if (callBack) {
                callBack({ requestSuccessful: false })
            }
        } finally {
            setAssignLGAsLoading(false)
        }

    };


    const unAssignLGAsToPortfolioManager = async (
        pmCode: any,
        lgaCodes: any,
        callBack?: ({ requestSuccessful }: { requestSuccessful: boolean }) => void
    ) => {
        try {
            setUnAssignLGAsLoading(true)

            const response = await removed_lga_portfolio_manager_service(pmCode, lgaCodes);

            if (response?.requestSuccessful) {
                showMessage(
                    "SUCCESSFUL",
                    "Request is Pending approval",
                    "success"
                );
                if (callBack) {
                    callBack({ requestSuccessful: response?.requestSuccessful })
                }
            }

        } catch (error: any) {
            const errorMessage = parseError(error)

            showMessage("Request Failed", errorMessage, "error")
            if (callBack) {
                callBack({ requestSuccessful: false })
            }
        } finally {
            setUnAssignLGAsLoading(false)
        }

    };


    return {
        assignLGAsLoading,
        unAssignLGAsLoading,
        assignLGAsToPortfolioManager,
        unAssignLGAsToPortfolioManager
    }


}