import React from "react";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import ViewRegionalManagers from "../ViewRegionalManagers";


const breadCrumbItems: BreadCrumbItem[] = [
    {
        title: "Dashboard",
        path: DashboardRoutePaths.home.path,
    },
    {
        title: "View Regional Managers",
        active: true,
    },
];

const RegionalManagersPage = () => {
    return (
        <Page breadCrumbItems={breadCrumbItems}>
            <ViewRegionalManagers />
        </Page>
    )
}

export default RegionalManagersPage
