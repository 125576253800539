import { ELoanContractProduct, LoanContractManagerType } from "./types";


export const loanContractReassignmentViewTitleMap = {
    [LoanContractManagerType.FIRST_FCO]: "Reassign Field Credit Officer",
    [LoanContractManagerType.SECOND_FCO]: "Reassign Field Credit Officer",
    [LoanContractManagerType.PM]: "Reassign Portfolio Manager",
}

export const loanContractProductTypeMap = {
    [ELoanContractProduct.OVERDRAFT]: {
        text: "OVERDRAFT",
        color: "#1B97DB",
    },
    [ELoanContractProduct.WORKING_CAPITAL]: {
        text: "WORKING CAPITAL",
        color: "#9B51E0",
    }
}
