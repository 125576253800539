import { FC } from "react";
import { upperCase } from 'lodash'
import { Descriptions, Empty, Spin, Tag, Typography } from "antd";
import { convertStatusTextToColor } from "../../../../../utils/workflow";
import { parseAmountToCurrencyValue } from "../../../../../utils/dataParser";
import { useQueryLoanRequestDetails } from "../../../LoanRequestsManagement/LoanRequestdetails/hooks/useQueryLoanRequestDetails";
import { getFormattedDate } from "../../../../../utils/date";

interface Props {
    src: any;
    data: any;
}

const TriggerBusinessVerification: FC<Props> = ({ src }) => {

    const {
        loanRequestDetails,
        loanRequestDetailsLoading
    } = useQueryLoanRequestDetails({
        payload: { id: src?.request?.loanRequestReference }
    });

    if (loanRequestDetailsLoading) {
        return (
            <section className="flex items-center justify-center p-10">
                <Spin tip="Loading Details..." />
            </section>
        );
    }

    if (loanRequestDetails) {
        return (
            <Descriptions title="Trigger Loan Request Business Verification" layout="vertical">
                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Business Name
                        </Typography.Text>
                    }
                >
                    {loanRequestDetails?.businessName || "---"}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Business Owner Name
                        </Typography.Text>
                    }
                >
                    {loanRequestDetails?.businessOwnerName || "---"}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Date Requested
                        </Typography.Text>
                    }
                >
                    {loanRequestDetails?.dateRequested ? getFormattedDate(loanRequestDetails?.dateRequested) : "-----"}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            First FCO User Name
                        </Typography.Text>
                    }
                >
                    {loanRequestDetails?.firstFcoUsername || "-----"}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Second FCO User Name
                        </Typography.Text>
                    }
                >
                    {loanRequestDetails?.secondFcoUsername || "-----"}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Portfolio Manager
                        </Typography.Text>
                    }
                >
                    {loanRequestDetails?.pmUsername || "-----"}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>Loan Amount</Typography.Text>
                    }
                >
                    {parseAmountToCurrencyValue(loanRequestDetails?.loanAmount, "₦") || "---"}
                </Descriptions.Item>
                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Request Status
                        </Typography.Text>
                    }
                >
                    <Tag
                        color={convertStatusTextToColor(loanRequestDetails?.requestStatus)}
                    >
                        {upperCase(loanRequestDetails?.requestStatus)}
                    </Tag>
                </Descriptions.Item>
            </Descriptions>
        );
    }

    return (
        <section className="flex items-center justify-center p-10">
            <Empty />
        </section>
    );
};

export default TriggerBusinessVerification;
