import React from "react";
import { parseError } from "../../../../../utils/server";
import { showMessage } from "../../../../../utils/notifications";
import { get_PMS_portfolio_manager_details_service } from "../../portfolioApi";
import { PMSPortfolioManagerDetails } from "../../portfolioApi/portfolioAPI.types";



export interface QueryProps {
    payload: {
        id: string
    }
}

export const useQueryPMSPortfolioManagerDetails = (props: QueryProps) => {
    const {
        payload: {
            id
        }
    } = props

    const [details, setDetails] = React.useState<PMSPortfolioManagerDetails | null>(null);
    const [detailsLoading, setDetailsLoading] = React.useState(false)



    const getPMSPortfolioManagerDetails = React.useCallback(async (id: string) => {
        try {
            setDetailsLoading(true)

            const response = await get_PMS_portfolio_manager_details_service(id);

            setDetails(response?.responseBody)
        } catch (error: any) {
            showMessage("Portfolio Manager", parseError(error), "error");
            setDetails(null)
        } finally {
            setDetailsLoading(false)
        }

    }, [])


    React.useEffect(() => {

        if (id) {
            getPMSPortfolioManagerDetails(id);
        }

    }, [getPMSPortfolioManagerDetails, id])

    return {
        detailsLoading,
        details,
        getPMSPortfolioManagerDetails,
    }

}