import React from "react";
import Page, { BreadCrumbItem } from "../../../components/Page";

const breadCrumbItems: BreadCrumbItem[] = [
    {
        title: "Dashboard",
        active: true
    }
]

const DashboardHome = () => {
    return (
        <Page breadCrumbItems={breadCrumbItems}>
            <div>
                Dashboard Home
            </div>
        </Page>
    )
}

export default DashboardHome
