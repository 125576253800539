import { parseError } from '../../../../utils/server';
import { AppThunk } from '../../../../store';

import { get_all_loan_requests_service, get_loan_request_details_service, reassing_loan_request_service } from '../loanRequestApi';
import { setLoanReassignedLoading, setLoanReassignedError,resetLoanRequest ,resetLoanReassign, setLoanReassignedSuccess, setLoanRequestDetailsLoading, setLoading, saveAllLoanRequest, saveAllLoanRequestDataPagination, saveLoanRequestError, setLoanRequestDetailError, saveLoanRequestDetails } from '.';
import { extractPaginationData } from '../../../../utils/pagination';



export const getAllLoanRequests =
    (searchParams?: Record<string, any>,
        pagination?: Record<string, any>): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(setLoading(true))
                dispatch(saveLoanRequestError(""))
                const response = await get_all_loan_requests_service(searchParams, pagination);
                const paginationData = extractPaginationData(response.responseBody)

                dispatch(saveAllLoanRequest(response));
                dispatch(saveAllLoanRequestDataPagination(paginationData))
            } catch (error: any) {

                dispatch(saveLoanRequestError(parseError(error)))
            } finally {
                dispatch(setLoading(false))
            }

        };

export const getLoanRequestDetails =
    (loanRequestReference: string): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(setLoanRequestDetailsLoading(true))
                dispatch(setLoanRequestDetailError(""))
                const response = await get_loan_request_details_service(loanRequestReference);

                dispatch(saveLoanRequestDetails(response));
            } catch (error: any) {

                dispatch(setLoanRequestDetailError(parseError(error)))
            } finally {
                dispatch(setLoanRequestDetailsLoading(false))
            }

        };
export const resetLoanRequestDetailsStates =
    (): AppThunk => async (dispatch) => {
        dispatch(resetLoanRequest())
    }
//loanRequestReference: string, officerType: string, officerCode: string
export const reassignedLoanRequest =
    (payload:{loanRequestReference: string, officerType: string, officerCode: string}): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(resetLoanReassign())
                dispatch(setLoanReassignedLoading(true))
                const response = await reassing_loan_request_service(payload);

                if (response?.requestSuccessful) {

                    dispatch(setLoanReassignedSuccess(true));
                }

            } catch (error: any) {

                dispatch(setLoanReassignedError(parseError(error)))
            } finally {
                dispatch(setLoanReassignedLoading(false))
            }

        };

export const resetAllAssignedLoanRequestStates =
    (): AppThunk => async (dispatch) => {
        dispatch(resetLoanReassign())
    }
