import { Descriptions, Empty } from "antd";
import React, { FC } from "react";


interface Props {
    src: any;
    data: any;
}

const ReAssignBusinessLoanRequestHandler: FC<Props> = ({ src }) => {


    return (
        <React.Fragment>
            {src?.request ? (
                <>
                    <Descriptions title="Re-assign business loan request handler" layout="vertical">
                        <Descriptions.Item label="Loan Request Reference">
                            {src?.request?.loanRequestReference}
                        </Descriptions.Item>
                        <Descriptions.Item label="Officer Type">
                            {src?.request?.officerType}
                        </Descriptions.Item>
                        <Descriptions.Item label="Officer Code">
                            {src?.request?.officerCode}
                        </Descriptions.Item>
                    </Descriptions>
                </>
            ) : (
                <section className="centered-container">
                    <Empty />
                </section>
            )}
        </React.Fragment>
    );
};

export default ReAssignBusinessLoanRequestHandler;
