import React, { FC, useEffect, useMemo } from 'react';
import { Alert, Descriptions, Empty, List, Spin, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getFcosDetails } from '../../../FCOManagement/fcoSlice/fco.thunks';
import { fetchLGAs } from '../../../Utils/UtilsSlice/utilsSlice.thunks';
import { ILocalGovernment } from '../../../Utils/UtilsApi/utilsAPI.types';



interface AssignLGAsToFCOWorkFlowProps {
    src: any;
}

const AssignLGAsToFCOWorkFlow: FC<AssignLGAsToFCOWorkFlowProps> = ({ src }) => {

    const dispatch = useAppDispatch()
    const { fcos: { fcoDetailError, fcoDetailData, loading }, utils: { LGAs } } = useAppSelector(state => state)

    useEffect(() => {
        let isMounted = true

        if (isMounted && src && src.request) {
            dispatch(getFcosDetails(src?.request?.fcoCode))
            dispatch(fetchLGAs())
        }

        return () => { isMounted = false }
    }, [src, dispatch])

    const assignedLGAs = useMemo(
        () => {
            const assignedLGAs: ILocalGovernment[] = []

            if (src.request?.lgaCodes.length && LGAs.length) {
                for (const code of src.request?.lgaCodes) {
                    const lgaObject = LGAs.find(lga => lga.code === code)

                    if (lgaObject) {
                        assignedLGAs.push(lgaObject)
                    }
                }
            }

            return assignedLGAs
        },

        [LGAs, src]
    )


    return (
        <React.Fragment>
            {loading ? (
                <section className="centered-container">
                    <Spin tip="Loading Details..." />
                </section>
            )
                : fcoDetailError ? (
                    <Alert
                        message="Error"
                        description={fcoDetailError}
                        type="error"
                        showIcon
                    />
                )
                    : fcoDetailData ? (
                        <>
                            <Descriptions title="Request Details">
                                <Descriptions.Item label="User Name">
                                    {fcoDetailData.username}
                                </Descriptions.Item>
                                <Descriptions.Item label="First Name">
                                    {fcoDetailData.firstName}
                                </Descriptions.Item>
                                <Descriptions.Item label="Last Name">
                                    {fcoDetailData.lastName}
                                </Descriptions.Item>
                            </Descriptions>

                            <List
                                header={<Typography.Title level={5}>Assigned LGAs</Typography.Title>}
                                dataSource={assignedLGAs}
                                renderItem={item => (
                                    <List.Item>
                                        <Typography.Text>{item.code}</Typography.Text> - <Typography.Text >{item.name}</Typography.Text>
                                    </List.Item>
                                )}

                            />
                        </>
                    ) : (
                        <section className="centered-container">
                            <Empty />
                        </section>
                    )}
        </React.Fragment>
    )
}

export default AssignLGAsToFCOWorkFlow;
