import { Descriptions, Tag, Typography } from "antd"
import { ELoanContractProduct, OverdraftContractDetails } from "../utils/types"
import { FC } from "react"
import { parseAmountToCurrencyValue } from "../../../../utils/dataParser"
import { loanContractProductTypeMap } from "../utils/dataMaps"
import { LoanContractStatusTag } from "../Components/LoanContractStatusTag"


interface Props {
    contractDetails: OverdraftContractDetails
    loanRequestDetails: any
}

const OVDDetails: FC<Props> = ({ contractDetails, loanRequestDetails }) => {
    return (
        <>
            <Descriptions
                layout="vertical"
                column={{ xs: 1, md: 2, xl: 3 }}
            >
                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Business name
                        </Typography.Text>
                    }
                >
                    {loanRequestDetails?.businessName}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Business Owner name
                        </Typography.Text>
                    }
                >
                    {loanRequestDetails?.businessOwnerName}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Amount
                        </Typography.Text>
                    }
                >
                    {parseAmountToCurrencyValue(contractDetails?.amount, "₦")}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Status
                        </Typography.Text>
                    }
                >
                    <LoanContractStatusTag status={contractDetails?.contractStatus} />
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Approval Date
                        </Typography.Text>
                    }
                >
                    {new Date(contractDetails?.acceptanceDate).toDateString()}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Loan Product
                        </Typography.Text>
                    }
                >
                    <Tag color={loanContractProductTypeMap[ELoanContractProduct.OVERDRAFT]?.color}>
                        {loanContractProductTypeMap[ELoanContractProduct.OVERDRAFT]?.text}
                    </Tag>
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default OVDDetails
