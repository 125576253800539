import { Collapse, Form, Typography, Button, } from "antd"
import { useState } from "react"
import { parseAmountToCurrencyValue } from "../../../../../utils/dataParser"
import { useUpdateMaxApprovableAmount } from "../hooks/useUpdateMaxApprovableAmount"
import { CurrencyInput } from "../../../../../components/CurrencyInput"
import { DoubleRightOutlined } from "@ant-design/icons"

enum ActiveKey {
    ENTER_AMOUNT = "ENTER_AMOUNT",
    CONFIRM = "CONFIRM",
}

const { Panel } = Collapse


interface Props {
    managerName: string
    managerCode: string
    currentAmount?: number
    onUpdateSubmited(): void
}

export const MaxApprovableAmountUpdate = ({
    managerCode, managerName, onUpdateSubmited, currentAmount
}: Props) => {
    const {
        Loading,
        updateMaxApprovableAmount
    } = useUpdateMaxApprovableAmount()
    const [form] = Form.useForm()
    const updatedAmount = Form.useWatch('updated-amount', form)
    const [activeKey, setActiveKey] = useState<ActiveKey>(ActiveKey.ENTER_AMOUNT);

    const onConfirm = () => {
        setActiveKey(ActiveKey.CONFIRM);
    }

    const handleSubmit = async () => {
        await updateMaxApprovableAmount({
            pmCode: managerCode,
            maxApprovableAmount: updatedAmount
        })

        if (!Loading) {
            onUpdateSubmited()
        }
    }

    return (
        <Collapse
            bordered={false}
            activeKey={activeKey}
        >
            <Panel
                showArrow={false}
                header={
                    <Typography.Text strong>
                        {"Enter New Amount "}
                        <DoubleRightOutlined
                            rotate={activeKey === ActiveKey.ENTER_AMOUNT ? 90 : 0}
                            style={{ paddingLeft: "5px" }}
                        />
                    </Typography.Text>
                }
                key={ActiveKey.ENTER_AMOUNT}
            >
                <Form
                    form={form}
                    name="pm-max-approveable-amount-update-form"
                    layout="vertical"
                    initialValues={{
                        'updated-amount': currentAmount
                    }}
                >
                    <Form.Item
                        className=" w-full"
                        label="New Max Approvable Loan Amount"
                        name="updated-amount"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a valid amount',
                                validator(_, value) {
                                    if (!Number(value)) {
                                        return Promise.reject(new Error('Invalid amount provided'))
                                    } else {
                                        return Promise.resolve()
                                    }
                                },
                            }
                        ]}
                    >
                        <CurrencyInput />
                    </Form.Item>
                    <Form.Item className="flex items-center justify-end">
                        <Button
                            type="primary" htmlType="submit"
                            disabled={!Number(updatedAmount)}
                            onClick={onConfirm}
                        >
                            Confirm
                        </Button>
                    </Form.Item>
                </Form>
            </Panel>
            <Panel
                showArrow={false}
                header={
                    <Typography.Text strong>
                        {"Confirm And Submit "}
                        <DoubleRightOutlined
                            rotate={activeKey === ActiveKey.CONFIRM ? 90 : 0}
                            style={{ paddingLeft: "5px" }}
                        />
                    </Typography.Text>
                }
                key={ActiveKey.CONFIRM}
            >
                <Typography.Text>
                    Submit request to update Maximum Approvable Loan Amount for
                    {" "} <Typography.Text strong>{managerName}</Typography.Text> to
                    {" "}<Typography.Text strong>{parseAmountToCurrencyValue(updatedAmount)}</Typography.Text>?
                </Typography.Text>

                <div
                    className="mt-6 flex items-center justify-end space-x-2"
                >
                    <Button
                        type="ghost"
                        onClick={() => setActiveKey(ActiveKey.ENTER_AMOUNT)}
                    >
                        Go Back
                    </Button>

                    <Button
                        type="primary"
                        disabled={!Number(updatedAmount) || !managerCode}
                        onClick={handleSubmit}
                        loading={Loading}
                    >
                        Yes Submit
                    </Button>
                </div>
            </Panel>
        </Collapse>
    )
}