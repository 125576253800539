import { Avatar, Button, Card, Descriptions, Empty, List, Modal, Spin, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { parseAmountToCurrencyValue, parsePhoneNumberWithCountryCode } from "../../../../utils/dataParser";
import AssignOrUnassignLGAsComponent from "../../Utils/AssignOrUnassignLGAs";
import { fetchLGAs } from "../../Utils/UtilsSlice/utilsSlice.thunks";
import { ILocalGovernment } from "../../Utils/UtilsApi/utilsAPI.types";
import { useFileRef } from "../../Utils/FilesUtils/hooks/useFileRef";
import { OnTransferProps } from "../../Utils/AssignOrUnassignLGAs/types";
import { useQueryPortfolioManagerDetails } from "./hooks/useQueryPortfolioManagerDetails";
import { usePmLgaAssignment } from "./hooks/usePmLgaAssignment";
import { MaxApprovableAmountUpdate } from "./components/MaxApprovableAmountUpdate";
import { useQueryPMSPortfolioManagerDetails } from "./hooks/useQueryPMSPortfolioManagersDetails";

const breadCrumbItems: BreadCrumbItem[] = [
    {
        title: "Dashboard",
        path: DashboardRoutePaths.home.path,
    },
    {
        title: "Portfolio Managers",
        path: DashboardRoutePaths.viewPortfolioManagements.path,
    },
    {
        title: "Portfolio Manager Details",
        active: true,
    },
];

const PortfolioManagerDetails = () => {
    const params = useParams<{ "*": string; id: string }>();
    const dispatch = useAppDispatch()
    const {
        utils: { LGAs },
    } = useAppSelector(state => state)

    const [
        showMaxApprovableAmountUpdateModal,
        setShowMaxApprovableAmountUpdateModal
    ] = useState(false);

    const {
        portfolioManagerDetails,
        portfolioManagerDetailsLoading,
    } = useQueryPortfolioManagerDetails({
        payload: { id: params?.id as string }
    })
    const {
        details,
    } = useQueryPMSPortfolioManagerDetails({
        payload: { id: params?.id as string }
    })

    const {
        assignLGAsLoading,
        unAssignLGAsLoading,
        assignLGAsToPortfolioManager,
        unAssignLGAsToPortfolioManager,
    } = usePmLgaAssignment()

    const { image } = useFileRef(portfolioManagerDetails?.imageReference as string)

    useEffect(() => {
        if (!LGAs.length) {
            dispatch(fetchLGAs())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])




    const assignedLGAs = useMemo(
        () => {
            const assignedLGAs: ILocalGovernment[] = []

            if (portfolioManagerDetails?.assignedLgas?.length && LGAs?.length) {
                for (
                    const lgaInfo of portfolioManagerDetails?.assignedLgas
                ) {
                    const lgaObject = LGAs.find(lga => lga.code === lgaInfo?.lgaCode)

                    if (lgaObject) {
                        assignedLGAs.push(lgaObject)
                    }
                }
            }

            return assignedLGAs
        },

        [LGAs, portfolioManagerDetails]
    )


    const onTransferLGAs = ({
        movedItems,
        direction,
        onTransferFulfilled
    }: OnTransferProps) => {
        const lgsCodes = movedItems.map((LGA) => LGA.code);

        const handleRequestFulfilled = ({ requestSuccessful }: { requestSuccessful: boolean }) => {
            if (requestSuccessful && onTransferFulfilled) {
                onTransferFulfilled()
            }
        }

        if (direction === "right") {
            assignLGAsToPortfolioManager(
                portfolioManagerDetails?.pmCode,
                lgsCodes,
                handleRequestFulfilled
            );
        } else {
            unAssignLGAsToPortfolioManager(
                portfolioManagerDetails?.pmCode,
                lgsCodes,
                handleRequestFulfilled
            );
        }
    }

    const onShowMaxApprovableAmountUpdateModal = () => {
        setShowMaxApprovableAmountUpdateModal(true);
    }
    const onCloseMaxApprovableAmountUpdateModal = () => {
        setShowMaxApprovableAmountUpdateModal(false);
    }


    return (
        <>
            <Page breadCrumbItems={breadCrumbItems}>
                <Card
                    className="my-6"
                    title={
                        <Typography.Title level={4}>
                            Portfolio Managers Details
                        </Typography.Title>
                    }
                >
                    {portfolioManagerDetailsLoading ? (
                        <section className="centered-container">
                            <Spin tip="Loading Details..." />
                        </section>
                    ) : portfolioManagerDetails ? (
                        <>
                            <Descriptions
                                layout="vertical"
                                column={{ xs: 1, md: 2, xl: 3 }}
                            >
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            Passport Photo
                                        </Typography.Text>
                                    }
                                    span={3}
                                >
                                    <Avatar src={image} shape="square" size={200} icon="" />
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            PM CODE
                                        </Typography.Text>
                                    }
                                >
                                    {portfolioManagerDetails.pmCode}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            First Name
                                        </Typography.Text>
                                    }
                                >
                                    {portfolioManagerDetails.firstName}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            Last Name
                                        </Typography.Text>
                                    }
                                >
                                    {portfolioManagerDetails.lastName}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            Username
                                        </Typography.Text>
                                    }
                                >
                                    {portfolioManagerDetails.username}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            Email
                                        </Typography.Text>
                                    }
                                >
                                    {portfolioManagerDetails.email}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            Phone Number
                                        </Typography.Text>
                                    }
                                >
                                    {parsePhoneNumberWithCountryCode(portfolioManagerDetails.phone || "")}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        <Typography.Text strong>
                                            Max Approvable Loan Amount
                                        </Typography.Text>
                                    }
                                >
                                    {!!details ? (
                                        <div className=" space-y-2">
                                            <div>
                                                {parseAmountToCurrencyValue(details?.maxApprovableAmount)}
                                            </div>
                                            <Button
                                                type="primary"
                                                className=" rounded"
                                                onClick={onShowMaxApprovableAmountUpdateModal}
                                            >
                                                Update Amount
                                            </Button>
                                        </div>
                                    ) : "--"}
                                </Descriptions.Item>
                            </Descriptions>

                            <List
                                header={<Typography.Title level={5}>Assigned LGAs</Typography.Title>}
                                dataSource={assignedLGAs}
                                renderItem={item => (
                                    <List.Item>
                                        <Typography.Text>{item.code}</Typography.Text> - <Typography.Text >{item.name}</Typography.Text>
                                        <Typography.Text > - </Typography.Text> <Typography.Text >{item.state}</Typography.Text>
                                    </List.Item>
                                )}

                            />
                            <Card
                                className="mt-12"
                                title="Assign or Remove Local Government Areas"
                            >
                                <Spin
                                    tip="Loading.."
                                    spinning={assignLGAsLoading || unAssignLGAsLoading}
                                >
                                    <AssignOrUnassignLGAsComponent
                                        title="Assign or Remove Local Government Areas"
                                        initialLGAs={assignedLGAs}
                                        onTransfer={onTransferLGAs}
                                        disable={assignLGAsLoading || unAssignLGAsLoading}
                                        stateCode={portfolioManagerDetails?.stateCode}
                                    />
                                </Spin>
                            </Card>
                        </>
                    ) : (
                        <section className="centered-container">
                            <Empty />
                        </section>
                    )}
                </Card>
            </Page>

            {!!details && (<Modal
                destroyOnClose={true}
                centered
                className=" max-w-[650px] my-6"
                width={800}
                open={showMaxApprovableAmountUpdateModal}
                onCancel={onCloseMaxApprovableAmountUpdateModal}
                title={
                    <Typography.Title level={5}>
                        {"Update Portfolio Manager's Max Approval Loan Amount"}
                    </Typography.Title>
                }
                footer={null}
            >
                <MaxApprovableAmountUpdate
                    managerCode={details?.pmCode}
                    managerName={details?.name}
                    currentAmount={details?.maxApprovableAmount}
                    onUpdateSubmited={() => {
                        setShowMaxApprovableAmountUpdateModal(false)
                    }}
                />
            </Modal>)}
        </>
    )
}


export default PortfolioManagerDetails
