import { Alert } from "antd";
import React from "react";

interface Props {
  message?: string;
  onRetry?: () => void;
}

const ServerErrorAlert: React.FC<Props> = ({ message, onRetry }) => {
  return (
    <Alert
      message={
        <div className="flex items-center justify-between space-x-2">
          <span>{message || "Sorry, an error occured."}</span>
          {onRetry && (
            <span
              className=" text-PrimaryBlue underline cursor-pointer"
              onClick={onRetry}
            >
              Retry
            </span>
          )}
        </div>
      }
      type="error"
      showIcon
      className=" rounded"
    />
  );
};

export default ServerErrorAlert;
