import React from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Spin,
  Descriptions,
  Image,
  Typography,
  Tag,
} from "antd";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import { parsePhoneNumberWithCountryCode } from "../../../../utils/dataParser";
import { useQueryBuisinesRelationshipManagerDetails } from "./hook";
import { useFileRef } from "../../Utils/FilesUtils/hooks/useFileRef";
const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "View Business Relationship Managers",
    path: DashboardRoutePaths.brms.path,
  },
  {
    title: "View Business Relationship Manager Details",

    active: true,
  },
];

const ViewBusinessRelationshipManagerDetails: React.FC = () => {
  const params = useParams<{ "*": string; id: string }>();


  const {
    brmDetailData,
    loading,
  } = useQueryBuisinesRelationshipManagerDetails({
    payload: {
      id: params?.id as string
    }
  })

  const {
    image
  } = useFileRef(brmDetailData?.passport)
 

  return (
    <>
      <Page breadCrumbItems={breadCrumbItems}>
        <Card
          title="Business Relationship Manager Details"
          style={{ margin: "18px 0" }}
        >
          {loading ? (
            <section className="flex items-center justify-center">
              <Spin tip="Loading Details..." />
            </section>
          ) : (
            <section>
              <section className="mb-[50px]">
                <Image
                  width={200}
                  height={200}
                  src={image}
                  alt="business Relationship passport"
                />
              </section>
              <section className="flex items-center justify-center">
                <Descriptions layout="vertical">
                  <Descriptions.Item
                    label={<Typography.Text strong>User Name</Typography.Text>}
                  >
                    {brmDetailData?.userName}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<Typography.Text strong>First Name</Typography.Text>}
                  >
                    {brmDetailData?.firstName}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="Middle Name">
                                        {demoRMs[0].lastName}
                                    </Descriptions.Item> */}
                  <Descriptions.Item
                    label={<Typography.Text strong>Last Name</Typography.Text>}
                  >
                    {brmDetailData?.lastName}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<Typography.Text strong>Email</Typography.Text>}
                  >
                    {brmDetailData?.email}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Typography.Text strong>Phone Number</Typography.Text>
                    }
                  >
                    {parsePhoneNumberWithCountryCode(brmDetailData?.phone)}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <Typography.Text strong>
                        Residential Address
                      </Typography.Text>
                    }
                  >
                    {brmDetailData?.residentialAddress}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Typography.Text strong>
                        Enable As Field Credit Officer 
                      </Typography.Text>
                    }
                  >
                    {brmDetailData?.enabledAsFco ? (
                      <Tag color="success">Enabled</Tag>
                      ) : (
                        <Tag color="warning">Not Enabled</Tag>
                      )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Typography.Text strong>
                        Brm Code
                      </Typography.Text>
                    }
                  >
                    {brmDetailData?.brmCode}
                  </Descriptions.Item>
                </Descriptions>
              </section>
            </section>
          )}
        </Card>
      </Page>
    </>
  );
};

export default ViewBusinessRelationshipManagerDetails;
