import server from "../../../server";
import { IResponse } from "../../../server/server.types";
import { ILoginResponse, IUserLogin } from "./authAPI.types";


export const api_login = async (params: IUserLogin) => {
    const response = await server.post(
        "",
        params
    );
    return response.data as ILoginResponse;
};

export const api_cosmos_login = async (code: string) => {
    const response = await server.post(
        `/auth/token?authenticationCode=${code}`,
        {}
    );

    return response.data as IResponse<ILoginResponse>;
};

export const api_resend_activation_email = async (email: string) => {
    const response = await server.post(`/users/resend-activation-email/${email}`, {})

    return response.data as IResponse<any>
}
