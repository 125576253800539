import React from "react";
import {
  Pagination,
  extractPaginationData,
  initPagination
} from "../../../../utils/pagination";
import { get_all_fcos_service, onboard_fco_service } from "../fcoApi";
import { parseError } from "../../../../utils/server";
import { showMessage } from "../../../../utils/notifications";
import { FCOOnboardingParams } from "../fcoSlice/fco.types";

export interface FieldCreditOfficersQueryProps {
  payload: {
    searchParams?: Record<string, any>;
    pagination?: Pagination;
  };
}

export const useQueryFieldCreditOfficers = (
  props: FieldCreditOfficersQueryProps
) => {
  const {
    payload: { searchParams, pagination}
  } = props;

  const [fcoData, setFcoData] = React.useState<any | null>(null);
  const [fcoDataPagination, setFcoPagination] =
    React.useState<Pagination>(initPagination);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
 

  const getAllFieldCreditOfficers = React.useCallback(
    async (searchParams?: Record<string, any>, pagination?: Pagination) => {
      try {
        setError("");
        setLoading(true);

        const response = await get_all_fcos_service(searchParams, pagination);
        const paginationData = extractPaginationData(response.responseBody);

        setFcoData(response?.responseBody?.content);
        setFcoPagination(paginationData);
      } catch (error: any) {
        showMessage("Field Credit Officers",parseError(error), "error");
        setError(parseError(error));
      } finally {
        setLoading(false);
      }
    },
    []
  );



  React.useEffect(() => {
    getAllFieldCreditOfficers(searchParams, pagination);
  }, [searchParams, pagination, getAllFieldCreditOfficers]);

  return {
    error,
    loading,
    fcoData,
    fcoDataPagination,
    getAllFcos: getAllFieldCreditOfficers,
  
  };
};

export const useOnboardFieldCreditOfficer = (

) => {
  const [onboardFCOError, setOnboardFCOError] = React.useState("");
  const [onboardFCOLoading, setOnboardFCOLoading] = React.useState(false);
  const onboardFCO = async (
    fcoOnBoardparams: FCOOnboardingParams,
    onSuccess: (response: any) => void
  ) => {
    try {
    
        setOnboardFCOError("");
    
      setOnboardFCOLoading(true);

      const response = await onboard_fco_service(fcoOnBoardparams);

      if (response?.requestSuccessful) {
        onSuccess(response?.responseBody);
        showMessage(
          "Successfull",
          "Request to onboard user was sent successfully."
        );
      }
    } catch (error) {
      setOnboardFCOError(parseError(error));
    } finally {
      setOnboardFCOLoading(false);
    }
  };

  const onCloseOnBoardModal = () => {
    if (onboardFCOLoading) return;
    if (onboardFCOError) {
      setOnboardFCOError("");
    }
  };
  return{
    onboardFCOError,
    onboardFCOLoading,
    onboardFCO,
    onCloseOnBoardModal
  }
}