import { Descriptions, Typography } from "antd";
import { ILoanManagers } from "../../utils/types";
import LoanManager from "./LoanManager";

const { Text } = Typography;

type LoanManagersProps = {
  details?: ILoanManagers | null;
};

const LoanManagers = ({ details }: LoanManagersProps) => {
  return (
    <div className="mt-4">
    <div className="font-semibold text-base">Loan Managers</div>
      <div className="managers mt-4">
        {details?.firstFco?.name && (
          <LoanManager
            loanManager={details.firstFco}
            managerRole="First Field Credit Officer"
          />
        )}
        {details?.secondFco?.name && (
          <LoanManager
            loanManager={details.secondFco}
            managerRole="Second Field Credit Officer"
          />
        )}
        {details?.pm?.name && (
          <LoanManager
            loanManager={details.pm}
            managerRole="Portfolio Manager"
          />
        )}
        {details?.rm?.name && (
          <LoanManager
            loanManager={details.rm}
            managerRole="Relationship Manager"
          />
        )}
      </div>
    </div>
  );
};
export default LoanManagers;
