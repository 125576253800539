import server from "../../../../server";
import { initPagination } from "../../../../utils/pagination";


export const get_all_brm_service = async ( searchParams: Record<string, any> = {},
    pagination: Record<string, any> = initPagination) => {
    let page = pagination.current;
    const response = await server.get(
        `/business-relationship-managers`,
        {
            params:{...searchParams,page:page,size:pagination.pageSize}
        }
    );
    
    return response.data 
};

export const get_brm_details_service = async ( code:string
) => {
 
    const response = await server.get(
        `/business-relationship-managers/${encodeURIComponent(code)}`,
       
    );
    
    return response.data 
};

export const enable_brm_service = async ( rmCode: string,lgaCodes:[],stateCode:string
    ) => {
     
        const response = await server.post(
            `/field-credit-officers`,
           {
            stateCode,
            rmCode,
            lgaCodes
           }
        );
        
        return response.data 
    };




