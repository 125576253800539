import React from "react";
import { get_fco_portfolio_level_service } from "../../fcoApi";
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";


export interface FCOPortfolioLevelProps {
    payload: {
        fcoCode?: string
    }
}


export const useFcoPortfolioLevel= (props:FCOPortfolioLevelProps) => {
    const {
        payload: {
           fcoCode
        }
    } = props 
  const [fcoPortfolioLevelLoading, setFcoPortfolioLevelLoading] =
    React.useState<boolean>(false);
  const [fcoPortfolioLevelError, setFcoPortfolioLevelError] =
    React.useState<string>("");
  const [fcoPortfolioLevelData, setFcoPortfolioLevelData] = React.useState<
    any | null
  >(null);

  const getFcoPortfolioLevel =React.useCallback( async (fcoCode: string) => {
    try {
      setFcoPortfolioLevelLoading(true);
      setFcoPortfolioLevelError("");
      const response = await get_fco_portfolio_level_service(fcoCode);
  
      if (response?.responseBody) {
        setFcoPortfolioLevelData(response?.responseBody);
      }
    } catch (error: any) {
      const errorMessage = parseError(error);

      showMessage("Request Failed", errorMessage, "error");
      setFcoPortfolioLevelError(parseError(error));
    } finally {
      setFcoPortfolioLevelLoading(false);
    }
  },[]);
  React.useEffect(() => {
   if(fcoCode){
    getFcoPortfolioLevel(fcoCode)
   }


}, [getFcoPortfolioLevel, fcoCode])

  return {
    fcoPortfolioLevelData,
    fcoPortfolioLevelError,
    fcoPortfolioLevelLoading,
    getFcoPortfolioLevel,
  };
};
