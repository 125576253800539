import { Button, Card, Col, Form, Input, Row, Table, TableProps } from "antd";
import React, { FC,  useState } from "react";
import { parseTableSearchParams } from "../../../../utils/dataParser";

import { initPagination } from "../../../../utils/pagination";
import { useQueryRegionalManagers } from "./hook";

const RegionalManagersTable: FC<TableProps<any>> = ({
  rowSelection,
  columns
}) => {
  
  const {
    regionalManagers,
    regionalManagersLoading,
    regionalManagersPagination,
    getAllRegionalManagers
  } = useQueryRegionalManagers({ payload: {} });

  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState({});

  const onFilter = () => {
    const formValues = form.getFieldsValue();
    const searchParams = parseTableSearchParams(formValues);

    setSearchParams(searchParams);

    getAllRegionalManagers(searchParams, initPagination);
  };

  const onClearFilter = () => {
    form.resetFields();
    setSearchParams({});
    getAllRegionalManagers();
  };

  const handleTableChange = (pagination: any) => {
    getAllRegionalManagers(searchParams, pagination);
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (value: string) => {
        return <div>{value}</div>;
      }
    },
    ...(columns || [])
  ];

  return (
    <>
      <Card title="Filter Regional Managers" className="w-full my-5">
        <Form name="WCL-RM-Filter" form={form} layout="vertical">
          <Row gutter={24}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Name" name={"name"} initialValue="">
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Username" name={"username"} initialValue="">
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Email" name={"email"} initialValue="">
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[12, 12]}>
            <Col xs={24} sm={12} md={3}>
              <Button
                type="primary"
                className="w-full rounded"
                onClick={onClearFilter}
                danger
              >
                Clear
              </Button>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Button
                type="primary"
                onClick={onFilter}
                className="w-full bg-[#0190fe] border-[#0190fe] rounded"
                htmlType="submit"
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>

      <Card title="Regional Managers" className="w-full my-5">
        <Table
          rowSelection={rowSelection}
          className="w-full overflow-x-auto"
          dataSource={regionalManagers}
          columns={tableColumns}
          rowKey={(record) => record.code}
          loading={regionalManagersLoading}
          pagination={regionalManagersPagination}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

export default RegionalManagersTable;
