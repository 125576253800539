import React, { useState } from "react";
import { get_loan_details_service } from "../API";
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";
import { ILoanRequestDetails } from "../utils/types";

const useQueryLoanRequest = ({
  loanReference,
}: {
  loanReference: string | null;
}) => {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [permissionError, setPermissionError] = React.useState<boolean | null>(
    false
  );

  const [loanRequestDetails, setLoanRequestDetails] = useState<
    ILoanRequestDetails | undefined
  >();

  const getLoanDetails = React.useCallback(async () => {
    if (!loanReference) return;
    try {
      setLoading(true);
      setError("");
      setPermissionError(false);
      const response = await get_loan_details_service(loanReference || "");
      setLoanRequestDetails(response);
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        setPermissionError(true);
        return; //exit early
      }
      showMessage("Loan Requests", parseError(error), "error");
      setError(parseError(error));
    } finally {
      setLoading(false);
    }
  }, [loanReference]);

  return {
    error,
    loading,
    loanRequestDetails,
    permissionError,
    getLoanDetails,
  };
};

export default useQueryLoanRequest;
