import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import AllLoanRequests from "./components/AllLoanRequests";

const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "All Loan Requests",
    active: true,
  }
];

const ViewAllLoanRequests = () => {
  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <AllLoanRequests />
    </Page>
  );
};

export default ViewAllLoanRequests;
